<template>
    <div>
        <div ref="mychildcomponent" hidden id='customerBalancePrintReport' class="col-md-7">
            <!--HEADER-->
            <div v-if="itemTotal<=22">
                <div class="col-md-12" style="height:45mm;border:1px solid #000000;background-color:white" v-if="IsPaksitanClient">
                    <table class="table table-borderless">
                        <tr>
                            <td style="width:30%;" class="text-left pt-0 pb-0 pl-0 pr-0">
                                <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px;  margin:0 !important;padding:5px">
                            </td>
                            <td style="width:40%;" class="text-center ">

                                <u style="font-size:14px;color:black !important;font-weight:bold;">
                                    Sales Tax Invoice
                                </u><br />
                                <span style="font-size:23px;color:black !important;font-weight:bold;">{{headerFooters.company.nameEnglish}}</span><br />
                                <span style="font-size:14px;color:black !important;font-weight:bold;">{{headerFooters.company.addressEnglish}}</span><br />
                                <span style="font-size:14px;color:black !important;font-weight:bold;float:left">NTN :&nbsp;&nbsp;&nbsp; {{headerFooters.company.vatRegistrationNo}}</span>&nbsp;
                                <span style="font-size:14px;color:black !important;font-weight:bold;float:right">STR:&nbsp;&nbsp;&nbsp;   {{headerFooters.company.companyRegNo}}</span><br />
                                <span style="font-size:23px;color:black !important;font-weight:bold;padding-bottom:5px !important">
                                    <span v-if="formName=='Customer'">{{ $t('CustomerBalancePrintReport.CustomerBalanceReport') }} </span>
                                    <span v-if="formName=='Supplier'">{{ $t('CustomerBalancePrintReport.SupplierBalanceReport') }}</span>
                                </span>

                            </td>
                            <td style="width:30%;" class="text-left "> </td>

                        </tr>


                    </table>



                </div>
                <div class="col-md-12" style="height:45mm;border:2px solid #000000;" v-else-if="isHeaderFooter=='true'">
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table table-borderless">
                                <tr>
                                    <td style="width:36%;" class="text-left pt-0 pb-0 pl-0 pr-0">
                                        <p class="mb-0">
                                            <span style="font-size:23px;color:black !important;font-weight:bold;">{{headerFooters.company.nameEnglish}}</span><br />
                                            <span style="font-size:15px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryEnglish}}</span><br />
                                            <span style="font-size:14px;color:black !important;font-weight:bold;">VAT No.: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                            <span style="font-size:14px;color:black !important;font-weight:bold;">Cr No.:{{headerFooters.company.companyRegNo}}</span><br />
                                            <span style="font-size:13px;color:black !important;font-weight:bold;">
                                                Tel: {{headerFooters.company.phoneNo}}
                                            </span>
                                        </p>
                                    </td>
                                    <td style="width:26%;" class="text-center pt-0 pb-0 pl-0 pr-0">
                                        <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                    </td>
                                    <td style="width:38%;" class="pt-0 pb-0 pl-0 pr-0">
                                        <p class="text-right mb-0" v-if="arabic=='true'">
                                            <span style="font-size:23px;color:black !important;font-weight:bold;">{{headerFooters.company.nameArabic}}.</span><br />
                                            <span style="font-size:15px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryArabic}}</span><br />
                                            <span style="font-size:14px;color:black !important;font-weight:bold;">رقم ضريبة القيمة المضافة: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                            <span style="font-size:14px;color:black !important;font-weight:bold;">رقم السجل التجاري :{{headerFooters.company.companyRegNo}}</span><br />
                                            <span style="font-size:13px;color:black !important;font-weight:bold;">
                                                هاتف: {{headerFooters.company.phoneNo}}:
                                            </span>
                                        </p>
                                    </td>
                                </tr>

                                <tr>

                                    <td style="width:100%;" class="pt-0 pb-0 pl-0 pr-0" colspan="3">
                                        <div style="text-align: center;">
                                            <span style="font-size:20px;color:black !important;font-weight:bold;padding-bottom:5px !important" v-if="formName=='Customer'">{{ $t('CustomerBalancePrintReport.CustomerBalanceReport') }}</span>
                                            <span style="font-size:20px;color:black !important;font-weight:bold;padding-bottom:5px !important" v-if="formName=='Supplier'">{{ $t('CustomerBalancePrintReport.SupplierBalanceReport') }}</span>
                                        </div>

                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div style="height:45mm;" v-else></div>

                <div style="height:40mm;margin-top:1mm; border:2px solid #000000;">
                    <div class="row">
                        <div class="col-md-12 ">
                            <table class="table table-borderless">
                                <!--Row 1-->
                                <tr>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">From Date:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;">{{fromDate}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'">:من التاريخ</td>

                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">To Date:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;">{{toDate}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'">  :حتي اليوم</td>
                                </tr>

                                <!--Row 2-->
                                <tr v-if="formName=='Customer'">
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">Customer Name:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;" v-if="ledgers.customerNameAr !=''">{{ledgers.customerNameAr}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;" v-else-if="ledgers.customerNameEn !=''">{{ledgers.customerNameEn}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;" v-else>{{ledgers.customerNameEn}} {{ledgers.customerNameAr}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'">:رقم العميل</td>

                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;"><span>Customer VAT:</span> </td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;"><span><span>{{ledgers.customerVat}}</span> </span> </td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'"><span v-if="headerFooters.cargoName"> :العميل ضريبة </span> </td>
                                </tr>
                                <tr v-if="formName=='Supplier'">
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">Supplier Name:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;" v-if="ledgers.customerNameAr !=''">{{ledgers.customerNameAr}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;" v-else-if="ledgers.customerNameEn !=''">{{ledgers.customerNameEn}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;" v-else>{{ledgers.customerNameEn}} {{ledgers.customerNameAr}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'"> :اسم المورد</td>

                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;"><span>Customer VAT:</span> </td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;"><span><span>{{ledgers.customerVat}}</span> </span> </td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'"><span v-if="headerFooters.cargoName"> :العميل ضريبة </span> </td>
                                </tr>

                                <!--Row 3-->
                                <tr>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">Customer No:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;">{{ledgers.customerNo}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'"> :رقم العميل</td>

                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">Tel:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;">{{ledgers.phoneNumber}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'">  :هاتف</td>
                                </tr>
                                <tr>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;"><span>Address:</span> </td>
                                    <td colspan="4" class="pl-0 pr-0 pt-0 pb-0" style="width:75%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;"><span>{{ledgers.address}}</span> </td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'"><span>:عنوان</span></td>

                                </tr>
                                <tr>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;"><span>Opening Balance:</span> </td>
                                    <td colspan="4" class="pl-0 pr-0 pt-0 pb-0" style="width:60%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;"><span>{{ledgers.openingBalance>0?'Dr':'Cr'}} {{nonNegative(ledgers.openingBalance) }}</span> </td>
                                    <td class="pr-3 pt-0 pb-0" style="width:26%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'"><span>:الرصيد الافتتاحي</span></td>

                                </tr>





                            </table>
                        </div>

                    </div>
                </div>


                <div style="border:2px solid #000000;">
                    <div class="row">
                        <div class="col-md-12" style="height:240mm">
                            <table class="table">
                                <tr class="heading" style="font-size:15px !important;padding-top:5px;">
                                    <th class="text-left" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">#</th>
                                    <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">  {{ $t('CustomerBalancePrintReport.Date') }}</th>
                                    <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">{{ $t('CustomerBalancePrintReport.TransactionType') }}</th>
                                    <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">{{ $t('CustomerBalancePrintReport.DocumentNumber') }}</th>
                                    <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">{{ $t('CustomerBalancePrintReport.Description') }}</th>
                                    <th class="text-right" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"> {{ $t('CustomerBalancePrintReport.Debit') }}</th>
                                    <th class="text-right" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">{{ $t('CustomerBalancePrintReport.Credit') }}</th>
                                    <th class="text-right" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                        {{ $t('CustomerBalancePrintReport.Balance') }}
                                    </th>

                                </tr>
                                <tr style="font-size:13px !important;" v-for="(item, index) in ledgers.contactList" v-bind:key="item.id">
                                    <td class="text-left" style="padding-top:8px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">{{index+1}}</td>
                                    <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{item.date}}</td>
                                    <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{getTransactionType(item.transactionType)}}</td>
                                    <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{item.documentNumber}}</td>
                                    <td class="text-left" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{getTransactionType(item.description)}}</td>
                                    <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                        {{  nonNegative(item.debitAmount)}}
                                    </td>
                                    <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                        {{nonNegative(item.creditAmount)}}
                                    </td>
                                    <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                        {{item.openingBalance>0?'Dr':'Cr'}} {{nonNegative(item.openingBalance) }}
                                    </td>
                                </tr>

                                <tr style="font-size:13px;">
                                    <td class="text-right" colspan="5" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 30px !important; padding-bottom: 3px !important;color:black !important;">
                                        <h6>{{ $t('CustomerBalancePrintReport.ClosingBalance') }}:</h6>
                                    </td>
                                    <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 30px !important; padding-bottom: 3px !important;color:black !important;">
                                        <h6>{{  nonNegative(ledger.totalDebit)}}</h6>
                                    </td>
                                    <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 30px !important; padding-bottom: 3px !important;color:black !important;">
                                        <h6>{{nonNegative(ledger.totalCredit)}}</h6>
                                    </td>
                                    <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 30px !important; padding-bottom: 3px !important;color:black !important;">
                                        <h6> {{ledger.runningBalance>0?'Dr':'Cr'}} {{nonNegative(ledger.runningBalance) }}</h6>
                                    </td>
                                </tr>
                            </table>

                        </div>
                    </div>
                    <div class="col-12 pl-2 pr-2" style=" background-color:white;font-size:16px">
                        <div class="col-12">
                            <table class="table text-center">
                                <tr>
                                    <td style="width: 33%;text-decoration-line: overline;border:0px;color:black;font-weight:bold">
                                        <span style=" border-top: 1px solid black;">
                                            Prepared By
                                        </span>
                                    </td>
                                    <td style="width: 33%;text-decoration-line: overline;border:0px;color:black;font-weight:bold">
                                        <span style=" border-top: 1px solid black;">
                                            Approved By
                                        </span>

                                    </td>
                                    <td style="width: 33%;text-decoration-line: overline;border:0px;color:black;font-weight:bold">

                                        <span style=" border-top: 1px solid black;">
                                            Received By
                                        </span>


                                    </td>
                                </tr>


                            </table>
                            <table class="table text-center">

                                <tr>
                                    <td style="width: 20%;border:0px;color:black;font-weight:bold">
                                    </td>
                                    <td style="width: 60%;border:0px;color:black;font-weight:bold">
                                        Tel: &nbsp; &nbsp; &nbsp;{{headerFooters.company.phoneNo}} &nbsp; &nbsp; &nbsp; Email:&nbsp; &nbsp; &nbsp;{{headerFooters.company.email}}
                                    </td>
                                    <td style="width: 20%;border:0px;color:black;font-weight:bold">
                                    </td>
                                </tr>

                            </table>
                        </div>

                    </div>
                </div>
            </div>
            <div v-else>
                <div class="col-md-12" style="height:45mm;border:1px solid #000000;background-color:white" v-if="IsPaksitanClient">
                    <table class="table table-borderless">
                        <tr>
                            <td style="width:30%;" class="text-left pt-0 pb-0 pl-0 pr-0">
                                <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px;  margin:0 !important;padding:5px">
                            </td>
                            <td style="width:40%;" class="text-center ">

                                <u style="font-size:14px;color:black !important;font-weight:bold;">
                                    Sales Tax Invoice
                                </u><br />
                                <span style="font-size:23px;color:black !important;font-weight:bold;">{{headerFooters.company.nameEnglish}}</span><br />
                                <span style="font-size:14px;color:black !important;font-weight:bold;">{{headerFooters.company.addressEnglish}}</span><br />
                                <span style="font-size:14px;color:black !important;font-weight:bold;float:left">NTN :&nbsp;&nbsp;&nbsp; {{headerFooters.company.vatRegistrationNo}}</span>&nbsp;
                                <span style="font-size:14px;color:black !important;font-weight:bold;float:right">STR:&nbsp;&nbsp;&nbsp;   {{headerFooters.company.companyRegNo}}</span><br />
                                <span style="font-size:23px;color:black !important;font-weight:bold;padding-bottom:5px !important">
                                    <span v-if="formName=='Customer'">{{ $t('CustomerBalancePrintReport.CustomerBalanceReport') }} </span>
                                    <span v-if="formName=='Supplier'">{{ $t('CustomerBalancePrintReport.SupplierBalanceReport') }}</span>
                                </span>

                            </td>
                            <td style="width:30%;" class="text-left "> </td>

                        </tr>


                    </table>



                </div>
                <div class="col-md-12" style="height:45mm;border:2px solid #000000;" v-else-if="isHeaderFooter=='true'">
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table table-borderless">
                                <tr>
                                    <td style="width:36%;" class="text-left pt-0 pb-0 pl-0 pr-0">
                                        <p class="mb-0">
                                            <span style="font-size:23px;color:black !important;font-weight:bold;">{{headerFooters.company.nameEnglish}}</span><br />
                                            <span style="font-size:15px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryEnglish}}</span><br />
                                            <span style="font-size:14px;color:black !important;font-weight:bold;">VAT No.: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                            <span style="font-size:14px;color:black !important;font-weight:bold;">Cr No.:{{headerFooters.company.companyRegNo}}</span><br />
                                            <span style="font-size:13px;color:black !important;font-weight:bold;">
                                                Tel: {{headerFooters.company.phoneNo}}
                                            </span>
                                        </p>
                                    </td>
                                    <td style="width:26%;" class="text-center pt-0 pb-0 pl-0 pr-0">
                                        <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                    </td>
                                    <td style="width:38%;" class="pt-0 pb-0 pl-0 pr-0">
                                        <p class="text-right mb-0" v-if="arabic=='true'">
                                            <span style="font-size:23px;color:black !important;font-weight:bold;">{{headerFooters.company.nameArabic}}.</span><br />
                                            <span style="font-size:15px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryArabic}}</span><br />
                                            <span style="font-size:14px;color:black !important;font-weight:bold;">رقم ضريبة القيمة المضافة: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                            <span style="font-size:14px;color:black !important;font-weight:bold;">رقم السجل التجاري :{{headerFooters.company.companyRegNo}}</span><br />
                                            <span style="font-size:13px;color:black !important;font-weight:bold;">
                                                هاتف: {{headerFooters.company.phoneNo}}:
                                            </span>
                                        </p>
                                    </td>
                                </tr>

                                <tr>

                                    <td style="width:100%;" class="pt-0 pb-0 pl-0 pr-0" colspan="3">
                                        <div style="text-align: center;">
                                            <span style="font-size:20px;color:black !important;font-weight:bold;padding-bottom:5px !important" v-if="formName=='Customer'">{{ $t('CustomerBalancePrintReport.CustomerBalanceReport') }}</span>
                                            <span style="font-size:20px;color:black !important;font-weight:bold;padding-bottom:5px !important" v-if="formName=='Supplier'">{{ $t('CustomerBalancePrintReport.SupplierBalanceReport') }}</span>
                                        </div>

                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div style="height:45mm;" v-else></div>

                <div style="height:40mm;margin-top:1mm; border:2px solid #000000;">
                    <div class="row">
                        <div class="col-md-12 ">
                            <table class="table table-borderless">
                                <!--Row 1-->
                                <tr>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">From Date:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;">{{fromDate}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'">:من التاريخ</td>

                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">To Date:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;">{{toDate}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'">  :حتي اليوم</td>
                                </tr>

                                <!--Row 2-->
                                <tr v-if="formName=='Customer'">
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">Customer Name:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;" v-if="ledgers.customerNameAr !=''">{{ledgers.customerNameAr}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;" v-else-if="ledgers.customerNameEn !=''">{{ledgers.customerNameEn}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;" v-else>{{ledgers.customerNameEn}} {{ledgers.customerNameAr}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'">:رقم العميل</td>

                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;"><span>Customer VAT:</span> </td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;"><span><span>{{ledgers.customerVat}}</span> </span> </td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'"><span v-if="headerFooters.cargoName"> :العميل ضريبة </span> </td>
                                </tr>
                                <tr v-if="formName=='Supplier'">
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">Supplier Name:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;" v-if="ledgers.customerNameAr !=''">{{ledgers.customerNameAr}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;" v-else-if="ledgers.customerNameEn !=''">{{ledgers.customerNameEn}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;" v-else>{{ledgers.customerNameEn}} {{ledgers.customerNameAr}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'"> :اسم المورد</td>

                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;"><span>Customer VAT:</span> </td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;"><span><span>{{ledgers.customerVat}}</span> </span> </td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'"><span v-if="headerFooters.cargoName"> :العميل ضريبة </span> </td>
                                </tr>

                                <!--Row 3-->
                                <tr>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">Customer No:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;">{{ledgers.customerNo}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'"> :رقم العميل</td>

                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">Tel:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;">{{ledgers.phoneNumber}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'">  :هاتف</td>
                                </tr>
                                <tr>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;"><span>Address:</span> </td>
                                    <td colspan="4" class="pl-0 pr-0 pt-0 pb-0" style="width:75%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;"><span>{{ledgers.address}}</span> </td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'"><span>:عنوان</span></td>

                                </tr>
                                <tr>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;"><span>Opening Balance:</span> </td>
                                    <td colspan="4" class="pl-0 pr-0 pt-0 pb-0" style="width:60%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;"><span>{{ledgers.openingBalance>0?'Dr':'Cr'}} {{nonNegative(ledgers.openingBalance) }}</span> </td>
                                    <td class="pr-3 pt-0 pb-0" style="width:26%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'"><span>:الرصيد الافتتاحي</span></td>

                                </tr>





                            </table>
                        </div>

                    </div>
                </div>


                <div>
                    <div class="row">
                        <div class="col-md-12" style="height:240mm">
                            <table class="table">
                                <tr class="heading" style="font-size:15px !important;padding-top:5px;">
                                    <th class="text-left" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;width:5%;">#</th>
                                    <th style="padding-top: 3px !important; padding-bottom: 3px !important; color: black !important; border-top: 0px !important; width: 10%;">  {{ $t('CustomerBalancePrintReport.Date') }}</th>
                                    <th class="text-center" style="padding-top: 3px !important; padding-bottom: 3px !important; color: black !important; border-top: 0px !important; width: 10%;">{{ $t('CustomerBalancePrintReport.TransactionType') }}</th>
                                    <th class="text-center" style="padding-top: 3px !important; padding-bottom: 3px !important; color: black !important; border-top: 0px !important; width: 10%;">{{ $t('CustomerBalancePrintReport.DocumentNumber') }}</th>
                                    <th style="padding-top: 3px !important; padding-bottom: 3px !important; color: black !important; border-top: 0px !important; width: 29%;">{{ $t('CustomerBalancePrintReport.Description') }}</th>
                                    <th class="text-right" style="padding-top: 3px !important; padding-bottom: 3px !important; color: black !important; border-top: 0px !important; width: 12%;"> {{ $t('CustomerBalancePrintReport.Debit') }}</th>
                                    <th class="text-right" style="padding-top: 3px !important; padding-bottom: 3px !important; color: black !important; border-top: 0px !important; width: 12%;">{{ $t('CustomerBalancePrintReport.Credit') }}</th>
                                    <th class="text-right" style="padding-top: 3px !important; padding-bottom: 3px !important; color: black !important; border-top: 0px !important; width: 12%;">
                                        {{ $t('CustomerBalancePrintReport.Balance') }}
                                    </th>

                                </tr>
                                <tr style="font-size:13px !important;" v-for="(item, index) in ledgers.contactList" v-bind:key="item.id">
                                    <td class="text-left" style="padding-top:8px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">{{index+1}}</td>
                                    <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{item.date}}</td>
                                    <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{getTransactionType(item.transactionType)}}</td>
                                    <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{item.documentNumber}}</td>
                                    <td class="text-left" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{getTransactionType(item.description)}}</td>
                                    <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                        {{  nonNegative(item.debitAmount)}}
                                    </td>
                                    <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                        {{nonNegative(item.creditAmount)}}
                                    </td>
                                    <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                        {{item.openingBalance>0?'Dr':'Cr'}} {{nonNegative(item.openingBalance) }}
                                    </td>
                                </tr>

                                <tr style="font-size:13px;">
                                    <td class="text-right" colspan="5" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 30px !important; padding-bottom: 3px !important;color:black !important;">
                                        <h6>{{ $t('CustomerBalancePrintReport.ClosingBalance') }}:</h6>
                                    </td>
                                    <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 30px !important; padding-bottom: 3px !important;color:black !important;">
                                        <h6>{{  nonNegative(ledger.totalDebit)}}</h6>
                                    </td>
                                    <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 30px !important; padding-bottom: 3px !important;color:black !important;">
                                        <h6>{{nonNegative(ledger.totalCredit)}}</h6>
                                    </td>
                                    <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 30px !important; padding-bottom: 3px !important;color:black !important;">
                                        <h6> {{ledger.runningBalance>0?'Dr':'Cr'}} {{nonNegative(ledger.runningBalance) }}</h6>
                                    </td>
                                </tr>
                            </table>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>

</template>

<script>
    import moment from "moment";
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        props: ['printDetails', 'formName', 'isPrint', 'fromDate', 'toDate', 'headerFooter', 'ledger'],
        data: function () {
            return {
                list: [],
                invoicePrint: '',
                ledgers: {
                    contactList: [],
                    openingBalance: 0,
                    runningBalance: 0,
                    totalCredit: 0,
                    totalDebit: 0,
                    customerNameEn: '',
                    customerNameAr: '',
                    address: '',
                    customerNo: '',
                    customerVat: '',
                    phoneNumber: '',
                },
                Print: false,
                openingCash: 0,
                totalBalance: 0,
                render: 0,
                customerNameEn: '',
                customerNameAr: '',
                isHeaderFooter: '',

                address: '',
                phoneNumber: '',
                customerNo: '',
                customerVat: '',
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
                english: '',
                arabic: '',
                listItemP1: [],
                listItemP2: [],
                listItemP3: [],
                itemTotal: 0,
                IsPaksitanClient: false,

            }
        },

        mounted: function () {
            this.IsPaksitanClient = localStorage.getItem('IsPaksitanClient') == "true" ? true : false;
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.invoicePrint = localStorage.getItem('InvoicePrint');
            var root = this;
            this.headerFooters = this.headerFooter
            root.Print = root.isPrint;
            this.ledgers = this.ledger;
            if (this.ledgers.contactList.length > 0) {
                var totalItem = root.ledgers.contactList;

                root.itemTotal = totalItem.length;
                if (totalItem < 21) {
                    for (var i = 0; i < totalItem; i++) {
                        root.listItemP1.push(root.ledgers.contactList[i]);
                    }
                }
                else if (totalItem >= 21 && totalItem < 40) {
                    for (var k = 0; k < totalItem; k++) {
                        if (k < 21) {
                            root.listItemP1.push(root.ledgers.contactList[k]);
                        }
                        else {
                            root.listItemP2.push(root.ledgers.contactList[k]);
                        }
                    }
                }
                setTimeout(function () {
                    root.printInvoice();
                }, 125)
            }

        },
        methods: {
            getTransactionType(transactionType) {
                if (transactionType == 'StockOut') return 'Stock Out'
                else if (transactionType == 'JournalVoucher') return 'Journal Voucher'
                else if (transactionType == 'BankPay') return 'Bank Pay'
                else if (transactionType == 'ExpenseVoucher') return 'Expense Voucher'
                else if (transactionType == 'Expense') return 'Expense'

                else if (transactionType == 'BankReceipt') return 'Bank Receipt'
                else if (transactionType == 'StockIn') return 'Stock In'
                else if (transactionType == 'SaleInvoice') return 'Sale Invoice'
                else if (transactionType == 'PurchaseReturn') return 'PurchaseReturn'
                else if (transactionType == 'PurchasePost') return 'Purchase'
                else if (transactionType == 'CashReceipt') return 'Cash Receipt'
                else {
                    return transactionType;
                }
            },
            nonNegative: function (value) {
                return parseFloat(Math.abs(value)).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
            },
            convertDate: function (x) {
                return moment(x).format('DD MMM YYYY');
            },
            printInvoice: function () {

                
                var form = new FormData();
                form.append('htmlString', this.$refs.mychildcomponent.innerHTML);
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.post('/Report/PrintPdf', form, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        var date = moment().format('DD MMM YYYY');
                        if (root.formName == 'Customer') {
                            link.setAttribute('download', root.ledgers.customerNameEn==null?'':root.ledgers.customerNameEn + ' ' + root.ledgers.customerNameAr==null?'':root.ledgers.customerNameAr +' '+'Customer Balance Report '  + date + '.pdf');

                        }
                        else {
                            link.setAttribute('download', root.ledgers.customerNameEn==null?'':root.ledgers.customerNameEn + ' ' + root.ledgers.customerNameAr==null?'':root.ledgers.customerNameAr +' '+ 'Supplier Balance Report ' + date + '.pdf');
                        }
                        document.body.appendChild(link);
                        link.click();

                        root.$emit('close');
                    });
            }
        }
    }
</script>