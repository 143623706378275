<template>
    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
        <div class=" table-responsive">
            <table class="table add_table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <thead>
                    <tr>
                        <th style="width: 30px;">
                            #
                        </th>
                        <th style="width: 150px;">
                            {{ $t('SaleOrderViewItem.Product') }}
                        </th>
                        <th style="width: 150px;">
                            {{ $t('SaleOrderItem.Description') }}
                        </th>
                        <th style="width: 110px;" class="text-center">
                            {{ $t('SaleOrderViewItem.UnitPrice') }}
                        </th>
                        <th style="width: 110px;" class="text-center" v-if="isMultiUnit=='true'">
                            {{ $t('SaleOrderViewItem.HighQty') }}
                        </th>
                        <th style="width: 110px;" class="text-center">
                            {{ $t('SaleOrderViewItem.Qty') }}
                        </th>
                        <th style="width: 110px;" class="text-center" v-if="isMultiUnit=='true'">
                            {{ $t('SaleOrderViewItem.TOTALQTY') }}
                        </th>
                        <!--<th style="width: 110px;" class="text-center">
        {{ $t('UseQuantity') }}
    </th>-->
                        <!--<th style="width:110px;" class="text-center" v-if="purchaseProducts.filter(x => x.isExpire).length > 0">
        {{ $t('SaleOrderViewItem.BatchNo') }}
    </th>-->
                        <th style="width: 100px;" class="text-center">
                            {{ $t('SaleOrderViewItem.Disc%') }}
                        </th>
                        
                        <th style="width: 100px;" class="text-center">
                            {{ $t('SaleOrderViewItem.VAT%') }}
                        </th>
                        <th class="text-center" style="width: 60px;">
                            {{ $t('SaleItem.Free') }}
                        </th>
                        <th style="width: 100px;" class="text-right">
                            {{ $t('SaleOrderViewItem.LineTotal') }}
                        </th>
                    </tr>
                </thead>
                <tbody id="purchase-item">
                    <template v-for="(prod , index) in purchaseProducts">

                        <tr :key="prod.productId + index" style="border-bottom: 1px solid #d6d6d6; ">
                            <td class="border-top-0">
                                {{index+1}}
                            </td>
                            <td class="border-top-0">
                                <span v-if="prod.productId!=null">
                                    {{($i18n.locale == 'en' ||isLeftToRight())? products.find(x => x.id == prod.productId).englishName!=''? products.find(x => x.id == prod.productId).englishName : products.find(x => x.id == prod.productId).arabicName :    products.find(x => x.id == prod.productId).arabicName!=''? products.find(x => x.id == prod.productId).arabicName : products.find(x => x.id == prod.productId).englishName}}

                                </span>
                            </td>

                            <td class="border-top-0">
                                {{prod.description}}
                            </td>

                            <td class="border-top-0 text-center">
                                {{currency}}  {{ parseFloat(prod.unitPrice).toFixed(3).slice(0,-1)}}
                            </td>
                            <th class="border-top-0 text-center" v-if="isMultiUnit=='true'">
                                {{prod.highQty }}<br />
                                <small style="font-weight: 500;font-size:70%;">
                                    {{prod.levelOneUnit}}
                                </small>
                            </th>
                            <td class="border-top-0 text-center">
                                {{prod.quantity}}<br />
                                <small v-if="isMultiUnit=='true'" style="font-weight: 500;font-size:70%;">
                                    {{prod.basicUnit}}
                                </small>
                            </td>
                            <td class="border-top-0 text-center" v-if="isMultiUnit=='true'">
                                {{parseInt(parseFloat(prod.highQty*prod.unitPerPack) + parseFloat(prod.quantity))}}
                            </td>
                            <!--<td class="border-top-0 text-center" v-if="isMultiUnit=='true'">
        {{parseInt(prod.totalQty)-parseInt(prod.quantityOut)}}
    </td>
    <td class="border-top-0 text-center" v-else>
        {{prod.quantity-prod.quantityOut}}
    </td>-->
                            <!--<td class="border-top-0 text-center" v-if="purchaseProducts.filter(x => x.isExpire).length > 0">
        {{prod.batchNo}}
    </td>-->
                        <td class="text-center" v-if="!isDiscountOnTransaction">
                                {{prod.isFree? '-' :prod.discount == 0 ? prod.fixDiscount : prod.discount}}{{prod.discountSign}}
                            </td>
                            <td class="border-top-0 text-center">
                                {{prod.taxRate}}%
                            </td>
                            <td class="border-top-0 text-center">
                                <span class="badge badge-success" v-if="prod.isFree">{{ $t('QuotationViewItem.Yes') }}</span>
                                <span class="badge badge-success" v-else>{{ $t('QuotationViewItem.No') }}</span>
                            </td>
                            <td class="border-top-0 text-right">
                                {{currency}}  {{parseFloat(prod.lineTotal).toFixed(3).slice(0,-1) | formatAmount}}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

        <div class=" table-responsive mt-3"
             v-bind:key="rendered + 'g'">
            <table class="table table-striped table-hover add_table_list_bg">
                <thead class="m-0">
                    <tr class="text-right">
                        <th class="text-center" style="width:85px;">
                            {{ $t('SaleOrderViewItem.NoItem') }}
                        </th>
                        <th class="text-center" style="width:100px;" v-if="isMultiUnit=='true'">
                            {{ $t('SaleOrderViewItem.TotalCarton') }}
                        </th>
                        <th class="text-center" style="width:100px;" v-if="isMultiUnit=='true'">
                            {{ $t('SaleOrderViewItem.TotalPieces') }}
                        </th>
                        <th class="text-center" style="width:100px;">
                            {{ $t('SaleOrderViewItem.TotalQty') }}
                        </th>
                        <th style="width:100px;">
                            {{ $t('SaleOrderViewItem.Total') }}
                        </th>
                        <th style="width:100px;">
                            {{ $t('SaleOrderViewItem.Disc') }}
                        </th>
                        <th style="width:155px;">
                            {{ $t('SaleOrderViewItem.TotalAfterDisc') }}
                        </th>
                        <th style="width:100px;">
                            {{ $t('SaleOrderViewItem.TotalVAT') }}
                        </th>
                        <th style="width:140px;">
                            {{ $t('SaleOrderViewItem.TotalwithVAT') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-right">
                        <td class="text-center">
                            {{ summary.item }}
                        </td>
                        <td class="text-center" v-if="isMultiUnit=='true'">
                            {{ summary.totalCarton}}
                        </td>
                        <td class="text-center" v-if="isMultiUnit=='true'">
                            {{ summary.totalPieces}}
                        </td>
                        <td class="text-center">
                            {{ summary.qty }}
                        </td>

                        <td>
                            {{currency}}   {{ parseFloat(summary.total).toFixed(3).slice(0,-1)}}
                        </td>
                        <td>
                            {{currency}} {{  parseFloat(summary.discount).toFixed(3).slice(0,-1)}}
                        </td>
                        <td>
                            {{currency}}   {{  parseFloat(summary.withDisc).toFixed(3).slice(0,-1)}}
                        </td>
                        <td>
                            {{currency}}  {{ (parseFloat(summary.vat)+summary.inclusiveVat).toFixed(3).slice(0,-1) }}
                        </td>
                        <td>
                            {{currency}}   {{  parseFloat(summary.withVAt).toFixed(3).slice(0,-1)}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style scoped>
    #sale-item td {
        padding-bottom: 0px;
        padding-top: 0px;
    }

    .input-border {
        border: transparent;
        background-color: transparent !important;
    }

        .input-border:focus {
            outline: none !important;
            border: none !important;
        }

    .multiselect__tags {
        background-color: transparent !important;
    }

    .multiselect__input, .multiselect__single {
        background-color: transparent !important;
    }

    .tableHoverOn {
        background-color: #F4F6FC !important;
    }
</style>

<script>
    import moment from "moment";
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        name: "PurchaseItem",
        props: ['purchase', 'purchaseItems'],

        data: function () {
            return {
                decimalQuantity: false,
                rendered: 0,
                product: {
                    id: "",
                },
                products: [],
                purchaseProducts: [],
                loading: false,
                vats: [],
                summary: {
                    item: 0,
                    qty: 0,
                    total: 0,
                    discount: 0,
                    withDisc: 0,
                    vat: 0,
                    withVAt: 0,
                    inclusiveVat: 0,
                    totalCarton: 0,
                    totalPieces: 0
                },
                currency: '',
                searchTerm: '',
                productList: [],
                isMultiUnit: '',
            };
        },
        validations: {},
        filters: {

        },
        methods: {
            GetProductList: function () {

                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }

                this.isRaw = this.raw == undefined ? false : this.raw;
                //search = search == undefined ? '' : search;
                // var url = this.wareHouseId != undefined ? "/Product/GetProductInformation?searchTerm=" + search + '&wareHouseId=' + this.wareHouseId + "&isDropdown=true" + '&isRaw=' + root.isRaw : "/Product/GetProductInformation?searchTerm=" + search + '&status=' + root.status + "&isDropdown=true" + '&isRaw=' + root.isRaw;

                this.$https
                    .get("/Product/GetProductBarcode", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.productList = response.data.results.products;

                        }
                    });


            },
            onBarcodeScanned(barcode) {


                var root = this;
                if (root.productList.length > 0) {
                    var product = this.productList.find(x => x.barCode == barcode)
                    if (product != null) {
                        root.addProduct(product.id, product)
                    }
                }


            },

            changeProduct: function (NewProdId, rowId) {
                this.purchaseProducts = this.purchaseProducts.filter(x => x.rowId != rowId);
                this.addProduct(NewProdId);

            },
            getTotalAmount: function () {


                return this.summary.withVAt;
            },
            calcuateSummary: function () {
                this.summary.item = this.purchaseProducts.length;
                if (this.decimalQuantity) {
                    this.summary.totalPieces = this.purchaseProducts.reduce((totalQty, prod) => totalQty + parseFloat(prod.quantity), 0);
                }
                else {
                    this.summary.totalPieces = this.purchaseProducts.reduce((totalQty, prod) => totalQty + parseInt(prod.quantity), 0);
                }

                if (this.decimalQuantity) {
                    this.summary.totalCarton = this.purchaseProducts.reduce((totalCarton, prod) => totalCarton + parseFloat(prod.highQty), 0);
                }
                else {
                    this.summary.totalCarton = this.purchaseProducts.reduce((totalCarton, prod) => totalCarton + parseInt(prod.highQty), 0);
                }

                if (this.decimalQuantity) {
                    this.summary.qty = this.purchaseProducts.reduce((qty, prod) => qty + parseFloat(prod.totalPiece == '' ? 0 : prod.totalPiece), 0);
                }
                else {
                    this.summary.qty = this.purchaseProducts.reduce((qty, prod) => qty + parseInt(prod.totalPiece == '' ? 0 : prod.totalPiece), 0);
                }

                this.summary.total = this.purchaseProducts.reduce((total, prod) =>
                    total + (prod.totalPiece) * prod.unitPrice, 0).toFixed(3).slice(0, -1);

                if (!this.isDiscountOnTransaction) {
                    this.transactionLevelDiscount = 0;
                }
                var vatRate = 0;
                var discountOnly = 0;
                var discountForInclusiveVat = 0;
                var root = this;
                const taxIdList = [...new Set(this.purchaseProducts.map(item => item.taxRateId))];
                root.paidVatList = []
                //'isDiscountOnTransaction', 'transactionLevelDiscount'
                taxIdList.forEach(function (taxId) {
                    vatRate = root.vats.find((value) => value.id == taxId);
                    var filteredRecord = root.purchaseProducts
                        .filter((x) => x.taxRateId === taxId);
                    var totalQtyWithotFree = root.purchaseProducts.reduce((qty, prod) => qty + parseInt(prod.totalPiece == '' ? 0 : prod.totalPiece), 0);

                    discountOnly += filteredRecord
                        .filter((x) => x.discount != 0 || x.discount != "" || x.offerQuantity != 0)
                        .reduce((discount, prod) =>
                            discount + (prod.totalPiece ? (prod.offerQuantity ? 0 : (((prod.totalPiece * prod.unitPrice) * prod.discount) / 100)) : 0), 0);

                    discountOnly += filteredRecord
                        .filter((x) => x.fixDiscount != 0 || x.fixDiscount != "" || x.offerQuantity != 0)
                        .reduce((discount, prod) =>
                            discount + (prod.totalPiece ? (prod.offerQuantity ? 0 : (root.taxMethod == ("Inclusive" || "شامل") ? prod.fixDiscount + (prod.fixDiscount * vatRate.rate / 100) : prod.fixDiscount)) : 0), 0);

                    var paidVat = filteredRecord
                        .reduce((vat, prod) => (vat + ((prod.taxMethod == ("Inclusive" || "شامل")) ? ((parseFloat(prod.lineTotal) - (root.isBeforeTax ? (((prod.totalPiece * prod.unitPrice) * root.transactionLevelDiscount) / 100) : 0)) * vatRate.rate) / (100 + vatRate.rate) : ((parseFloat(prod.lineTotal) - (root.isBeforeTax && !root.isFixed && root.isDiscountOnTransaction ? (((prod.totalPiece * prod.unitPrice) * root.transactionLevelDiscount) / 100) : (root.isBeforeTax && root.isFixed && root.isDiscountOnTransaction ? (root.transactionLevelDiscount / parseFloat(totalQtyWithotFree) * prod.totalPiece) : 0))) * vatRate.rate) / 100)), 0).toFixed(3).slice(0, -1)
                    discountForInclusiveVat += parseFloat(filteredRecord
                        .reduce((vat, prod) => (vat + ((prod.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(prod.lineTotal) * vatRate.rate) / (100 + vatRate.rate) : 0)), 0).toFixed(3).slice(0, -1))

                    root.paidVatList.push({
                        name: vatRate.name,
                        amount: paidVat
                    })

                });
                //root.transactionLevelDiscount = root.transactionLevelDiscount;
                this.summary.discount = discountOnly
                this.summary.withDisc = (this.summary.total - this.summary.discount).toFixed(3).slice(0, -1);

                this.summary.vat = this.paidVatList.reduce((vat, paidVat) => (vat + parseFloat(paidVat.amount)), 0).toFixed(3).slice(0, -1);

                var exclusiveVat = this.taxMethod == ("Inclusive" || "شامل") ? 0 : parseFloat(this.summary.vat);
                this.transactionLevelTotalDiscount = ((this.isBeforeTax && this.isDiscountOnTransaction) ? (this.taxMethod == ("Inclusive" || "شامل") ? (parseFloat(this.transactionLevelDiscount) * (this.summary.withDisc - discountForInclusiveVat) / 100) : (this.isFixed ? parseFloat(this.transactionLevelDiscount) : parseFloat(this.transactionLevelDiscount) * this.summary.withDisc / 100)) : (this.isFixed ? parseFloat(this.transactionLevelDiscount) : (parseFloat(this.summary.withDisc) + parseFloat(exclusiveVat)) * parseFloat(this.transactionLevelDiscount) / 100)).toFixed(3).slice(0, -1)

                var totalIncDisc = (this.isBeforeTax && this.isDiscountOnTransaction && this.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(this.transactionLevelDiscount) * (this.summary.withDisc) / 100) : parseFloat(this.transactionLevelTotalDiscount)
                this.adjustment = (this.adjustment == '' || this.adjustment == null) ? 0 : parseFloat(this.adjustment)

                this.summary.withVat = (parseFloat(this.summary.withDisc) + parseFloat(exclusiveVat) + (this.adjustmentSign == '+' ? this.adjustment : (-1) * this.adjustment)).toFixed(3).slice(0, -1);

                this.summary.withVat = (parseFloat(this.summary.withVat) - totalIncDisc).toFixed(3).slice(0, -1);



                //calculate bundle Amount
                if (this.purchaseProducts.filter(x => x.isBundleOffer).length > 0) {

                    //get bundle get quantity
                    var bundle = {
                        item: 0,
                        qty: 0,
                        total: 0,
                        discount: 0,
                        withDisc: 0,
                        vat: 0,
                        withVat: 0,
                        quantityLimit: 0
                    };

                    var bundleProducts = this.purchaseProducts.filter(x => x.isBundleOffer != undefined && x.offerQuantity > 0);

                    bundle.total = bundleProducts.reduce((total, prod) =>
                        total + prod.offerQuantity * prod.unitPrice, 0).toFixed(3).slice(0, -1);

                    //var bundleExclusiveTax = bundleProducts.reduce((total, prod) =>
                    //    total + (prod.taxMethod == "Exclusive" ? (bundle.total * prod.rate/100) : 0), 0);

                    var discountBundle = bundleProducts.filter((x) => x.discount != 0 || x.discount != "")
                        .reduce((discount, prod) =>
                            discount + (prod.offerQuantity * prod.unitPrice * prod.discount) / 100, 0);

                    var fixDiscountBundle = bundleProducts
                        .filter((x) => x.fixDiscount != 0 || x.fixDiscount != "")
                        .reduce((discount, prod) => discount + prod.fixDiscount, 0);

                    bundle.discount = (parseFloat(discountBundle) + parseFloat(fixDiscountBundle)).toFixed(3).slice(0, -1);

                    bundle.withDisc = (bundle.total - bundle.discount).toFixed(3).slice(0, -1);

                    bundle.vat = bundleProducts
                        .reduce((vat, prod) => vat + (((prod.unitPrice * prod.offerQuantity) -
                            ((prod.unitPrice * prod.offerQuantity * prod.discount) / 100)) *
                            parseFloat(prod.rate)) / ((prod.taxMethod == "Exclusive" || prod.taxMethod == "غير شامل") ? 100 : prod.rate + 100), 0).toFixed(3).slice(0, -1);

                    this.summary.bundleAmount = (parseFloat(bundle.withDisc) + parseFloat(exclusiveVat)).toFixed(3).slice(0, -1);
                    this.summary.withVat = (this.summary.withVat - bundle.withDisc);
                } else {
                    this.summary.bundleAmount = 0;
                }
                this.$emit("input", this.purchaseProducts, this.adjustment, this.adjustmentSign, parseFloat(this.transactionLevelDiscount));

                this.$emit("summary", this.summary);
            },

            updateLineTotal: function (e, prop, product) {
                var root = this;
                if (e != undefined) {
                    var discount = product.discount == 0 || product.discount == "" ? product.fixDiscount == 0 || product.fixDiscount == ""
                        ? 0
                        : product.fixDiscount
                        : product.discount;

                    if (prop == "unitPrice") {
                        product.unitPrice = e;
                    }

                    if (prop == "quantity") {
                        if (e <= 0 || e == '') {
                            e = 0;
                        }
                        if (String(e).split('.').length > 1 && String(e).split('.')[1].length > 2)
                            e = parseFloat(String(e).slice(0, -1))
                        product.quantity = this.decimalQuantity ? e : Math.round(e);
                    }
                    if (prop == "highQty") {
                        if (e < 0 || e == '' || e == undefined) {
                            e = 0;
                        }
                        product.highQty = Math.round(e);
                    }
                    product.totalPiece = (parseFloat(product.highQty == undefined ? 0 : product.highQty) * parseFloat(product.unitPerPack == null ? 0 : product.unitPerPack)) + parseFloat(product.quantity == '' ? 0 : product.quantity);

                    if (product.productId != null) {
                        var prod = root.products.find((x) => x.id == product.productId);

                        if (prod.promotionOffer != null) {
                            if (product.totalPiece > 0 && moment().format("DD MMM YYYY") >= moment(prod.promotionOffer.fromDate).format("DD MMM YYYY") &&
                                moment().format("DD MMM YYYY") <= moment(prod.promotionOffer.toDate).format("DD MMM YYYY")) {
                                product.fixDiscount = prod.promotionOffer.fixedDiscount;
                                product.discount = prod.promotionOffer.discountPercentage;
                                product.offerQuantity = prod.promotionOffer.totalPiece;
                                this.$set(product, 'isOfferQty', true);
                            } else {
                                if (product.isOfferQty) {
                                    product.fixDiscount = 0;
                                    product.discount = 0;
                                    product.offerQuantity = 0;
                                }
                                this.$set(product, 'isOfferQty', false);
                            }
                        }

                        if (prod.bundleCategory != null) {
                            if (product.totalPiece >= prod.bundleCategory.buy && moment().format("DD MMM YYYY") >= moment(prod.bundleCategory.fromDate).format("DD MMM YYYY") &&
                                moment().format("DD MMM YYYY") <= moment(prod.bundleCategory.toDate).format("DD MMM YYYY")) {
                                this.$set(product, 'bundleOffer', prod.bundleCategory.buy.toString() + " + " + prod.bundleCategory.get.toString());
                                this.$set(product, 'get', prod.bundleCategory.get);
                                this.$set(product, 'buy', prod.bundleCategory.buy);
                                this.$set(product, 'quantityLimit', prod.bundleCategory.quantityLimit);
                                this.$set(product, 'isBundleOffer', true);
                            } else {
                                this.$set(product, 'bundleOffer', "");
                                this.$set(product, 'get', 0);
                                this.$set(product, 'buy', 0);
                                this.$set(product, 'quantityLimit', 0);
                                this.$set(product, 'isBundleOffer', false);
                            }
                            //bundle category calculation
                            if (product.quantityLimit != undefined && parseFloat(product.totalPiece) >= (product.get + product.buy)) {
                                if ((product.get + product.buy) > 0) {
                                    product.offerQuantity = Math.floor(parseFloat(product.totalPiece) / (product.get + product.buy));
                                    if ((prod.bundleCategory.quantityOut + product.offerQuantity) <= prod.bundleCategory.stockLimit) {
                                        if (product.offerQuantity <= product.quantityLimit) {
                                            product.offerQuantity = product.offerQuantity * product.get;
                                        }
                                        else {
                                            product.offerQuantity = product.quantityLimit * product.get;
                                        }
                                    }
                                    else {
                                        var diffBundle = prod.bundleCategory.stockLimit - prod.bundleCategory.quantityOut;
                                        if (diffBundle > product.quantityLimit) {
                                            product.offerQuantity = product.quantityLimit * product.get;
                                        }
                                        else {
                                            product.offerQuantity = diffBundle * product.get;
                                        }
                                    }
                                }
                                else {
                                    product.offerQuantity = 0;
                                }
                            }
                            else {
                                if ((product.get + product.buy) > 0) {
                                    product.offerQuantity = Math.floor(parseFloat(product.totalPiece) / (product.get + product.buy));
                                }
                                else {
                                    product.offerQuantity = 0;
                                }
                            }
                            //bundle category calculation end
                        }
                    }


                    if (!this.invoiceWoInventory && product.productId != null && this.formName != 'Quotation') {
                        var bundleQuantity = product.bundleOfferQuantity == undefined ? 0 : product.bundleOfferQuantity;
                        if (prod.inventory != null) {
                            if (parseFloat(product.totalPiece) + bundleQuantity > (prod.inventory.currentQuantity + ((this.saleOrderId != null && this.saleOrderId != '' && this.soInventoryReserve) ? parseFloat(product.soQty) : 0))) {
                                this.$set(product, 'outOfStock', true);
                            } else {
                                this.$set(product, 'outOfStock', false);
                            }
                        }
                        else {
                            this.$set(product, 'outOfStock', true);
                        }
                    }


                    //End Calculate offer
                    if (prop == "discount") {
                        if (e == "" || e < 0) {
                            e = 0;
                        }
                        else if (e > 100) {
                            e = 100;
                        }
                        product.discount = e;
                    }

                    if (prop == "fixDiscount") {
                        if (e == "" || e < 0) {
                            e = 0;
                        }
                        else if (e > product.unitPrice) {
                            e = product.unitPrice;
                        }
                        product.fixDiscount = e;
                    }

                    var vat = 0;
                    var total = 0;
                    var calculateVAt = 0;
                    //here we will select quantity after deduct bundle quantity
                    if (product.offerQuantity > 0) {

                        if (product.isOfferQty) {
                            if (product.totalPiece > 0) {
                                if ((product.totalPiece) <= (prod.promotionOffer.stockLimit - prod.promotionOffer.quantityOut)) {
                                    this.$set(product, 'remainingStockLimit', (prod.promotionOffer.stockLimit - prod.promotionOffer.quantityOut));

                                    if (product.totalPiece <= product.offerQuantity) {
                                        discount = product.discount == 0 ? (product.fixDiscount * product.totalPiece) : (product.totalPiece * product.unitPrice * product.discount) / 100;

                                    }
                                    else {
                                        discount = product.discount == 0 ? (product.fixDiscount * product.offerQuantity) : (product.offerQuantity * product.unitPrice * product.discount) / 100;
                                    }
                                }
                                else {
                                    discount = product.discount == 0 ? (product.fixDiscount * (prod.promotionOffer.stockLimit - prod.promotionOffer.quantityOut)) : ((prod.promotionOffer.stockLimit - prod.promotionOffer.quantityOut) * product.unitPrice * product.discount) / 100;
                                }

                                vat = this.vats.find((value) => value.id == product.taxRateId);
                                total = product.totalPiece * product.unitPrice - discount;
                                if (product.taxMethod == 'Inclusive' || product.taxMethod == 'شامل') {
                                    calculateVAt = (total * vat.rate) / (100 + vat.rate);
                                    product.lineItemVAt = calculateVAt;
                                    //product.lineTotal = total - calculateVAt;
                                    product.lineTotal = total;
                                }
                                else {
                                    calculateVAt = (total * vat.rate) / 100;
                                    product.lineItemVAt = calculateVAt;
                                    product.lineTotal = total + calculateVAt;
                                }

                            }
                            else {
                                total = product.offerQuantity * product.unitPrice - discount;
                                if (product.taxMethod == 'Inclusive' || product.taxMethod == 'شامل') {
                                    calculateVAt = (total * vat.rate) / (100 + vat.rate);
                                    product.lineItemVAt = calculateVAt;
                                    //product.lineTotal = total - calculateVAt;
                                    product.lineTotal = total;
                                }
                                else {
                                    calculateVAt = (total * vat.rate) / 100;
                                    product.lineItemVAt = calculateVAt;
                                    product.lineTotal = total + calculateVAt;
                                }
                            }
                        } else {
                            discount = product.discount == 0 ? product.fixDiscount : (product.offerQuantity * product.unitPrice * product.discount) / 100;
                            vat = this.vats.find((value) => value.id == product.taxRateId);

                            total = (product.totalPiece - product.offerQuantity) * product.unitPrice - discount;
                            if (product.taxMethod == 'Inclusive' || product.taxMethod == 'شامل') {
                                calculateVAt = (total * vat.rate) / (100 + vat.rate);
                                product.lineItemVAt = calculateVAt;
                                //product.lineTotal = total - calculateVAt;
                                product.lineTotal = total;
                            }
                            else {
                                calculateVAt = (total * vat.rate) / 100;
                                product.lineItemVAt = calculateVAt;
                                product.lineTotal = total + calculateVAt;
                            }
                        }

                        this.$set(this.purchaseProducts, product, product);
                        this.calcuateSummary();
                        this.$emit("input", this.purchaseProducts, this.adjustment, this.adjustmentSign, parseFloat(this.transactionLevelDiscount));

                    }
                    else {
                        //isDiscountBeforeVat
                        vat = this.vats.find((value) => value.id == product.taxRateId);


                        discount = product.discount == 0 ? (this.taxMethod == ("Inclusive" || "شامل") ? product.fixDiscount + (product.fixDiscount * vat.rate / 100) : product.fixDiscount) : (product.totalPiece * product.unitPrice * product.discount) / 100;
                        product.lineTotal = product.totalPiece * product.unitPrice - discount;


                        this.$set(this.purchaseProducts, product, product);
                        this.calcuateSummary();
                        this.$emit("input", this.purchaseProducts, this.adjustment, this.adjustmentSign, parseFloat(this.transactionLevelDiscount));
                    }
                }
            },

            addProduct: function (productId, newProduct, qty, price, quotation) {


                if (this.products.find(x => x.id == newProduct.id) == undefined || this.products.length <= 0) {
                    this.products.push(newProduct);
                }

                var prod = this.products.find((x) => x.id == productId);

                var rate = 0;
                if (prod.taxRateId != "00000000-0000-0000-0000-000000000000" && prod.taxRateId != undefined) {
                    rate = this.getVatValue(prod.taxRateId, prod);
                }

                this.purchaseProducts.push({
                    rowId: this.createUUID(),
                    productId: prod.id,
                    unitPrice: quotation ? price : '0',
                    quantity: quotation ? qty : 1,
                    discount: 0,
                    fixDiscount: 0,
                    taxRateId: prod.taxRateId,
                    rate: rate,
                    taxMethod: prod.taxMethod,
                    expiryDate: "",
                    isExpire: prod.isExpire,
                    batchNo: "",
                    lineTotal: 0,
                    levelOneUnit: prod.levelOneUnit,
                    basicUnit: prod.basicUnit,
                });

                var product = this.purchaseProducts.find((x) => {
                    return x.productId == productId;
                });

                this.getVatValue(product.taxRateId, product);

                this.product.id = "";
                this.rendered++;
            },
            EmtypurchaseProductsList: function () {
                this.purchaseProducts = [];
            },
            createUUID: function () {
                var dt = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (dt + Math.random() * 16) % 16 | 0;
                    dt = Math.floor(dt / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },
            getVatValue: function (id, prod) {

                var vat = this.vats.find((value) => value.id == id);
                prod.taxRateId = id;
                prod.rate = vat.rate;
                this.updateLineTotal(prod.unitPrice, "unitPrice", prod);
                return vat.rate;
            },
            getVatValueForSummary: function (id, prod) {

                var vat = this.vats.find((value) => value.id == id);
                prod.taxRateId = id;
                prod.rate = vat.rate;
                return vat.rate;
            },
            removeProduct: function (id) {

                this.purchaseProducts = this.purchaseProducts.filter((prod) => {
                    return prod.rowId != id;
                });

                this.calcuateSummary();
            },

            getData: function () {
                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }

                root.$https
                    .get("/Product/TaxRateList", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.vats = response.data.taxRates;
                        }
                    }).then(function () {

                        if (root.$route.query.data != undefined) {
                            if (root.$route.query.data.saleOrderItems != undefined) {
                                //Sale Order Edit

                                root.$route.query.data.saleOrderItems.forEach(function (item) {
                                    if (item.productId != null) {
                                        root.purchaseProducts.push({
                                            rowId: item.id,
                                            id: item.id,
                                            batchNo: item.batchNo,
                                            discount: item.discount,
                                            expiryDate: item.expiryDate,
                                            isExpire: item.isExpire,
                                            fixDiscount: item.fixDiscount,
                                            product: item.product,
                                            currentQuantity: item.product.inventory == null ? 0 : item.product.inventory.currentQuantity,
                                            salePrice: item.product.salePrice == null ? 0 : item.product.salePrice,
                                            productId: item.productId,
                                            purchaseId: item.purchaseId,
                                            quantity: item.quantity,
                                            highQty: item.highQty,
                                            taxMethod: item.taxMethod,
                                            taxRateId: item.taxRateId,
                                            unitPrice: item.unitPrice,
                                            unitPerPack: item.unitPerPack,
                                            levelOneUnit: item.product.levelOneUnit,
                                            basicUnit: item.product.basicUnit,
                                            description: item.description,
                                            serviceItem: item.serviceItem,
                                            isFree: item.isFree,
                                            taxRate: item.taxRate,
                                        });
                                    }
                                    else {
                                        root.purchaseProducts.push({
                                            rowId: item.id,
                                            id: item.id,
                                            batchNo: item.batchNo,
                                            discount: item.discount,
                                            expiryDate: item.expiryDate,
                                            isExpire: item.isExpire,
                                            fixDiscount: item.fixDiscount,
                                            product: item.product,
                                            currentQuantity: 0,
                                            salePrice: 0,
                                            taxRate: item.taxRate,
                                            productId: item.productId,
                                            purchaseId: item.purchaseId,
                                            quantity: item.quantity,
                                            highQty: item.highQty,
                                            taxMethod: item.taxMethod,
                                            taxRateId: item.taxRateId,
                                            unitPrice: item.unitPrice,
                                            unitPerPack: item.unitPerPack,
                                            levelOneUnit: '',
                                            basicUnit: '',
                                            description: item.description,
                                            serviceItem: item.serviceItem,
                                            isFree: item.isFree,
                                        });
                                    }
                                    
                                });

                                for (var k = 0; k < root.purchaseProducts.length; k++) {
                                    if (root.purchaseProducts[k].productId != null) {
                                        root.products.push(root.purchaseProducts[k].product);
                                        root.updateLineTotal(root.purchaseProducts[k].highQty, "highQty", root.purchaseProducts[k]);
                                    }                                    
                                    root.updateLineTotal(root.purchaseProducts[k].quantity, "quantity", root.purchaseProducts[k]);
                                    root.updateLineTotal(root.purchaseProducts[k].unitPrice, "unitPrice", root.purchaseProducts[k]);
                                    root.updateLineTotal(root.purchaseProducts[k].discount, "discount", root.purchaseProducts[k]);
                                    root.updateLineTotal(root.purchaseProducts[k].fixDiscount, "fixDiscount", root.purchaseProducts[k]);
                                }
                                root.calcuateSummary()
                            }
                        }
                    });
            },
        },
        created: function () {
            //this.$barcodeScanner.init(this.onBarcodeScanned);
            this.getData();
        },
        mounted: function () {
            this.GetProductList();
            this.currency = localStorage.getItem('currency');
            this.decimalQuantity = localStorage.getItem('decimalQuantity') == 'true' ? true : false;
            this.isMultiUnit = 'false';
        },
        //destroyed() {
        //    // Remove listener when component is destroyed
        //    this.$barcodeScanner.destroy()
        //},
    };
</script>
