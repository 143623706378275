<template>
    <div>
        <div hidden id='inventoryDetailReport' class="col-md-12">
            <div class="border border-dark col-md-12 mb-1 " style="height:40mm;" v-if="isHeaderFooter == 'true'">
                <div class="row">
                    <div class="col-md-4 text-center">
                        <table class="m-auto">
                            <tr>
                                <td>
                                    <p>
                                        <span style="font-size:25px;color:black !important;font-weight:bold;">{{
                                                headerFooters.company.nameEnglish
                                        }}</span><br />
                                        <span style="font-size:17px;color:black !important;font-weight:bold;">{{
                                                headerFooters.company.categoryEnglish
                                        }}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">VAT No.:
                                            {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                            No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                        <span style="font-size:15px;color:black !important;font-weight:bold;">
                                            Tel: {{ headerFooters.company.phoneNo }}
                                        </span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-4 text-center my-5" style="padding:0px !important; margin:0 !important">
                        <img :src="headerFooters.company.logoPath"
                            style="width:auto;max-width:300px; height:auto; max-height:120px; padding:5px !important; margin:0 !important">
                        <p
                            style="text-align: center; margin: 0px; padding: 0px; font-size: 10px; line-height: 1; font-family:'Times New Roman', Times;">
                            <span style="font-size:14px;">Supplier Purchase Report</span>
                        </p>
                    </div>
                    <div class="col-md-4 " style="height:40mm;">
                        <table class="text-right" v-if="arabic == 'true'">
                            <tr>
                                <td>
                                    <p>
                                        <span style="font-size:25px;color:black !important;font-weight:bold;">{{
                                                headerFooters.company.nameArabic
                                        }}.</span><br />
                                        <span style="font-size:17px;color:black !important;font-weight:bold;">{{
                                                headerFooters.company.categoryArabic
                                        }}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">رقم ضريبة
                                            القيمة المضافة: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">رقم السجل
                                            التجاري :{{ headerFooters.company.companyRegNo }}</span><br />
                                        <span style="font-size:15px;color:black !important;font-weight:bold;">
                                            هاتف: {{ headerFooters.company.phoneNo }}:
                                        </span>
                                    </p>
                                </td>

                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div style="height:45mm;" v-else></div>

            <div class="border border-dark" style="height:15mm;margin-top:1mm;">
                <div class="row">
                    <div class="col-md-12 ml-2 mr-2" style="height:10mm;font-size:12px;">
                        <div class="row">
                            <div class="col-md-2 text-center font-weight-bold">From Date:</div>
                            <div class="col-md-2 text-center font-weight-bold">{{ list.fromDate }}</div>
                            <div class="col-md-2 text-center font-weight-bold" v-if="arabic == 'true'">:من التاريخ</div>

                            <div class="col-md-2 text-center font-weight-bold">To Date:</div>
                            <div class="col-md-2 text-center font-weight-bold">{{ list.toDate }}</div>
                            <div class="col-md-2 text-center font-weight-bold" v-if="arabic == 'true'">:حتى الآن</div>
                        </div>
                    </div>
                </div>
            </div>
            <!--INFORMATION-->
            <div v-for="(purchase) in list.inventoryList" v-bind:key="purchase.id">

                <h2 class="mt-2">
                    {{ purchase.invoiceNo }} - <span>{{ purchase.dates }}</span>
                </h2>

                <div class=" col-md-12 ">
                    <div class="row ">
                        <table class="table col-md-12 m-auto">
                            <tr class="heading" style="font-size:14px;">
                                <th class="text-left">#</th>
                                <th class="text-left">English Name</th>
                                <th class="text-left">Arabic Name</th>
                                <th class="text-left">Text Method</th>
                                <th class="text-left">Quantity</th>
                                <th class="text-left">Unit Price</th>
                                <th class="text-left">Total</th>
                            </tr>
                            <tr style="font-size:13px;" v-for="(item, index) in purchase.purchasePostItems"
                                v-bind:key="item.id">
                                <td class="text-left">{{ index + 1 }}</td>
                                <td class="text-left">{{ item.productName }}</td>
                                <td class="text-left">{{ item.productNameArabic }}</td>
                                <td class="text-left">{{ item.taxMethod }}</td>
                                <td class="text-left">{{ item.quantity }}</td>
                                <td class="text-left">{{ item.unitPrice }}</td>
                                <td class="text-left">{{ item.total }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div style="text-align: end; padding-right: 10px;">
                            <p><b>Grand Total:</b> <b>{{ purchase.netAmount }}</b></p>
                        </div>
                <hr />
            </div>
            <!--INFORMATION FOOTER-->
        </div>
    </div>
</template>
<script>
import moment from "moment";
import clickMixin from '@/Mixins/clickMixin'
export default {
    mixins: [clickMixin],
    props: ['printDetails', 'isPrint', 'isShown', 'headerFooter'],
    data: function () {
        return {
            arabic: '',
            english: '',
            language: '',
            isMultiUnit: '',
            isHeaderFooter: '',
            list: {
                toDate: '',
                fromDate: '',
                inventoryList:
                    [

                    ]
            },
            Print: false,
            render: 0,
            headerFooters: {
                footerEn: '',
                footerAr: '',
                company: ''
            }
        }
    },
    mounted: function () {
        this.english = localStorage.getItem('English');
        this.arabic = localStorage.getItem('Arabic');
        this.isMultiUnit = localStorage.getItem('IsMultiUnit');

        var root = this;
        this.language = this.$i18n.locale;
        this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
        root.Print = root.isPrint;

        if (this.printDetails.length > 0) {
            this.headerFooters = this.headerFooter;
            this.list.inventoryList = this.printDetails;
            this.list.fromDate = moment(this.printDetails.fromDate).format('DD MMM YYYY');
            this.list.toDate = moment(this.printDetails.toDate).format('DD MMM YYYY');

            setTimeout(function () {
                root.printInvoice();
            }, 125)
        }
    },
    methods: {
        printInvoice: function () {
            this.$htmlToPaper('inventoryDetailReport');
        }
    }
}
</script>