import { render, staticRenderFns } from "./SalaryTemplateModel.vue?vue&type=template&id=80b6bdd0&scoped=true&"
import script from "./SalaryTemplateModel.vue?vue&type=script&lang=js&"
export * from "./SalaryTemplateModel.vue?vue&type=script&lang=js&"
import style0 from "./SalaryTemplateModel.vue?vue&type=style&index=0&id=80b6bdd0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80b6bdd0",
  null
  
)

export default component.exports