<template>
    <div class="row" v-if="isValid('CanViewServiceInvoiceDetail')">
        <div class="col-lg-12">
            <div class="row">
                <div class="col">
                    <h5 class="page_title">{{ $t('InvoiceView.SaleInvoice') }}</h5>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('InvoiceView.Home') }}</a></li>
                        <li class="breadcrumb-item active">
                            {{ $t('InvoiceView.SaleInvoice') }}
                        </li>
                    </ol>
                </div>

                <div class="col-auto align-self-center">

                    <a v-on:click="goToSale" href="javascript:void(0);"
                       class="btn btn-sm btn-outline-danger mx-1">
                        <i class="fas fa-arrow-circle-left fa-lg"></i>

                    </a>
                    <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                       class="btn btn-sm btn-outline-danger">
                        {{ $t('SaleOrder.Close') }}
                    </a>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">
            <div class="card">
                <div class="card-body">
                    <div class="row" >
                      
                       

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
                            <h5>{{ $t('InvoiceView.From') }}</h5>
                            <div class="card" style="border: 1px #dddddd solid;">
                                <div class="card-body">
                                    <label>{{($i18n.locale == 'en' ||isLeftToRight())?headerFooter.company.nameEnglish:headerFooter.company.companyNameArabic}}</label>
                                    <br />
                                    <label>{{($i18n.locale == 'en' ||isLeftToRight())?headerFooter.company.addressEnglish:headerFooter.company.addressArabic}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
                            <h5>{{ $t('InvoiceView.InvoiceTo') }}</h5>
                            <div class="card" style="border: 1px #dddddd solid;">
                                <div class="card-body">
                                    <label v-if="!sale.isCredit">{{($i18n.locale == 'en' ||isLeftToRight())?sale.cashCustomer:(sale.cashCustomer==''?sale.cashCustomer:sale.cashCustomer)}}</label> <label>{{($i18n.locale == 'en' ||isLeftToRight())? (sale.customerNameEn==''?sale.customerNameAr:sale.customerNameEn):(sale.customerNameAr==''?sale.customerNameEn:sale.customerNameAr)}}</label>
                                    <br />
                                    <label>{{sale.customerAddress}}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" >
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
                            <!--<div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <label class="font-weight-bold">{{ $t('Sale.Mobile') }}</label>
                                <p>{{ sale.mobile }}</p>
                            </div>
                        </div>-->

                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <label class="font-weight-bold">{{ $t('InvoiceView.Area') }}</label>
                                    <p>{{sale.areaName}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <label class="font-weight-bold">{{ $t('InvoiceView.DispatchNote') }}</label>
                                    <p>{{sale.dispatchNote}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" >

                        </div>
                    </div>

                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'arabicLanguage'">
                        <div class="col-md-12">
                            <sale-service-view-item :saleItems="sale.saleItems" ref="childComponentRef" :wareHouseId="sale.wareHouseId" :saleOrderId="sale.saleOrderId" @input="SaveSaleItems" @summary="updateSummary" @discountChanging="updateDiscountChanging" :taxMethod="sale.taxMethod" :taxRateId="sale.taxRateId" :adjustmentProp="sale.discount" :adjustmentSignProp="adjustmentSignProp" :isDiscountOnTransaction="sale.isDiscountOnTransaction" :transactionLevelDiscountProp="sale.transactionLevelDiscount" :isFixed="sale.isFixed" :isBeforeTax="sale.isBeforeTax" />
                            <div class="row ">
                                <div class="col-md-6"></div>
                                <div class="col-md-6" v-if="!sale.isCredit">
                                    <table class="table" style="background-color: #F1F5FA;">
                                        <tr>
                                            <td style="font-weight: bolder; font-size: 16px;" colspan="5" class="p-1">{{ $t('InvoiceView.PaymentMode') }}</td>
                                        </tr>
                                        <tr style="font-size:17px;padding-bottom:10px;" v-for="payment in sale.paymentTypes" v-bind:key="payment.id">
                                            <td style="border-top: 0; padding-bottom:4px;padding-top:4px;" colspan="3" class=" p-1">{{payment.name}} ({{getType(payment.paymentType)}}) <span v-if="payment.paymentType==2">(Rate={{payment.amountCurrency}}* Amount={{payment.rate}})</span>:</td>
                                            <td style="border-top: 0;padding-bottom:4px;padding-top:4px;" colspan="2" class="text-end p-1">{{parseFloat(payment.amount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                        </tr>
                                        <tr style="font-size:17px;">
                                            <th style="font-weight: bolder; font-size: 16px;" colspan="3">{{ $t('InvoiceView.ChangeDue') }}:</th>
                                            <th colspan="2" style=" border-top: 0; padding-top: 0;" class="text-end p-1"> {{ sale.change }}</th>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-sm-12" v-else>
                                    <div class=" table-responsive">
                                        <table class="table table_list_bg">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th style="width:20%;">{{ $t('InvoiceView.VoucherNumber') }} </th>
                                                    <th style="width:20%;">{{ $t('InvoiceView.Date') }} </th>
                                                    <th class="text-right">{{ $t('InvoiceView.Amount') }} </th>
                                                    <th class="text-center">{{ $t('InvoiceView.PaymentMode') }} </th>
                                                    <th>{{ $t('InvoiceView.Description') }} </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(payment,index) in sale.paymentVoucher" v-bind:key="index">
                                                    <td>
                                                        {{index+1}}
                                                    </td>
                                                    <th>{{payment.voucherNumber}}</th>
                                                    <th>{{getDate(payment.date)}}</th>
                                                    <th class="text-right">{{currency}} {{parseFloat(payment.amount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</th>
                                                    <th class="text-center"><span v-if="payment.paymentMode==0">{{ $t('InvoiceView.Cash') }}</span><span v-if="payment.paymentMode==1">{{ $t('InvoiceView.Bank') }}</span></th>
                                                    <th>{{payment.narration}}</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 mt-4 mb-5">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-8" style="border-right: 1px solid #eee;">

                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group ps-3">
                                                        <div class="font-xs mb-1">{{ $t('InvoiceView.Bank') }}{{ $t('InvoiceView.Attachment') }}</div>
                                                        <button v-on:click="Attachment()" type="button" class="btn btn-light btn-square btn-outline-dashed mb-1"><i class="fas fa-cloud-upload-alt"></i> {{ $t('InvoiceView.Attachment') }} </button>
                                                        <div>
                                                            <small class="text-muted">
                                                                {{ $t('InvoiceView.FileSize') }}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 ">
                                    <button class="btn btn-sm btn-outline-danger" v-on:click="goToSale">
                                        {{ $t('InvoiceView.Cancel') }}
                                    </button>
                                </div>

                            </div>


                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3" >
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <h5 class="view_page_title">{{ $t('InvoiceView.BasicInfo') }}</h5>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="invoice_lbl">{{ $t('InvoiceView.SaleInvoice') }}#</label>
                            <hr style="margin-top: 0.3rem; margin-bottom: 0.1rem;" />
                            <label>{{sale.registrationNo}}-<span v-if='sale.isCredit'> {{ $t('InvoiceView.Credit') }}</span><span v-else> {{ $t('InvoiceView.Cash') }}</span></label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('InvoiceView.WareHouse') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label> {{($i18n.locale == 'en' ||isLeftToRight())?sale.wareHouseName:(sale.wareHouseNameAr==''?sale.wareHouseName:sale.wareHouseNameAr)}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('InvoiceView.Mobile') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label> {{ sale.mobile }}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('InvoiceView.Date') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{sale.date}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('InvoiceView.DueDate') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{sale.dueDate}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('InvoiceView.SendCopyTo') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{ $t('InvoiceView.Email') }}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <!--<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <button class="btn btn-primary btn-block">Send Invoice</button>
                        </div>-->
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-2">
                            <button class="btn btn-light btn-block text-left">PDF <i class="fas fa-file-pdf float-right" style="color:#EB5757;"></i></button>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-2">
                            <button class="btn btn-light btn-block text-left">Sheets <i class="fas fa-file-excel float-right" style="color:#198754;"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <!--<div class="card" style="background-color:#EBF2FF;margin-bottom:0;">
            <div class="card-body">

                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 mb-2">
                        <label>{{ $t('Sale.Customer') }} :</label>
                        <div v-if="!sale.isCredit">{{($i18n.locale == 'en' ||isLeftToRight())?sale.cashCustomer:(sale.cashCustomer==''?sale.cashCustomer:sale.cashCustomer)}}</div> <div>{{($i18n.locale == 'en' ||isLeftToRight())?sale.customerNameEn:(sale.customerNameAr==''?sale.customerNameEn:sale.customerNameAr)}}</div>
                    </div>
                </div>
            </div>
        </div>-->
            <bulk-attachment :documentid="sale.id" :show="show" v-if="show" @close="attachmentSave" />
            <saleInvoice :printDetails="printDetails" v-if="printDetails.length != 0" v-bind:key="printRender"></saleInvoice>
        </div>

    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>
<style scoped>
    .bg_color {
        background-color: #e5e5e5;
    }

    .vue-radio-button {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .icon {
        object-fit: contain;
    }

    .v-radio-button-active {
        background-color: #b6d7ff73 !important;
    }

    .title {
        font-size: 11px;
        margin-left: -10px;
    }
</style>
<script>
    /* END EXTERNAL SOURCE */
    /* BEGIN EXTERNAL SOURCE */

    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import clickMixin from '@/Mixins/clickMixin'
    //import VueBarcode from 'vue-barcode';
    export default {
        name: "AddSale",
        mixins: [clickMixin],
        data: function () {
            return {
                discountTypeOption: 'At Line Item Level',
                adjustmentSignProp: '+',
                isRaw: '',
                isMultiUnit: '',
                isArea: '',
                show: false,
                removeCheck: false,
                options: [],
                value: "",
                bankList: [],
                currencyList: [],
                autoNumber: '',
                autoNumberCredit: '',
                toggleCash: false,
                toggleCredit: true,
                currency: '',
                togglePayCash: 'Cash',
                language: 'Nothing',

                toggleBank: 'Bank',
                toggleOtherCurrency: 'OtherCurrency',

                rendered: 0,
                sale: {
                    id: "00000000-0000-0000-0000-000000000000",
                    dispatchNote: [],
                    date: "",
                    registrationNo: "",
                    customerId: null,
                    dueDate: "",
                    wareHouseId: "",
                    saleItems: [],
                    isCredit: false,
                    cashCustomer: "",
                    mobile: "",
                    code: "",
                    invoiceType: "",
                    employeeId: "",
                    areaId: "",
                    change: 0,
                    salePayment: {
                        dueAmount: 0,
                        received: 0,
                        balance: 0,
                        change: 0,
                        paymentTypes: [],
                        paymentType: 'Cash',
                        paymentOptionId: null,
                    },
                    otherCurrency: {
                        currencyId: null,
                        rate: 0,
                        amount: 0
                    },
                    isButtonDisabled: false,
                    discount: 0,
                    isDiscountOnTransaction: false,
                    isFixed: false,
                    isBeforeTax: true,
                    transactionLevelDiscount: 0
                },
                printId: '00000000-0000-0000-0000-000000000000',
                printDetails: [],
                printRender: 0,
                loading: false,
                summary: Object,
                companyId: '00000000-0000-0000-0000-000000000000',
                CompanyID: '',
                UserID: '',
                employeeId: '',
                isDayAlreadyStart: false,
                IsProduction: false,
                headerFooter: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
            };
        },
        computed: {
            isPaymentValid: function () {
                var paymentTypesAmount;
                if (this.sale.salePayment.paymentTypes.length > 0) {
                    paymentTypesAmount = this.sale.salePayment.paymentTypes.reduce((total, payment) =>
                        total + payment.amount, 0);
                } else {
                    paymentTypesAmount = 0;
                }

                if (this.sale.salePayment.paymentType == "Bank") {
                    if (this.sale.salePayment.paymentOptionId == "00000000-0000-0000-0000-000000000000" ||
                        this.sale.salePayment.paymentOptionId == null) {
                        return false;
                    }
                }

                if (this.sale.salePayment.paymentType == "OtherCurrency") {
                    if (this.sale.otherCurrency.currencyId == "00000000-0000-0000-0000-000000000000" ||
                        this.sale.otherCurrency.currencyId == null) {
                        return false;
                    }
                }

                if ((parseFloat(this.sale.salePayment.received) <= parseFloat(this.sale.salePayment.dueAmount))) {
                    if (((parseFloat(this.sale.salePayment.received) + paymentTypesAmount)) >= this.sale.salePayment.dueAmount) {
                        return true
                    }
                } else {
                    if (((parseFloat(this.sale.salePayment.received) + paymentTypesAmount)) >= this.sale.salePayment.dueAmount) {
                        return true
                    }
                }
                return false;
            },

        },
        methods: {
            GotoPage: function (link) {
                this.$router.push({ path: link });
            },
            Attachment: function () {
                this.show = true;
            },

            attachmentSave: function () {
                this.show = false;
            },

            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.sale.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/AddSaleService');
                    }
                    else {
                        this.$swal({
                            title: this.$t('Error'),
                            text: this.$t('ChangeLanguageError'),
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },

            GetDispatchNoteDetail: function (id, index) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (id != undefined) {
                    root.$https.get('/Sale/DispatchNoteDetail?Id=' + id[index - 1].id, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(function (response) {
                            if (response.data != null) {
                                response.data.dispatchNoteItems.forEach(function (dn) {
                                    root.$refs.childComponentRef.addProduct(dn.productId, dn.product, dn.quantity);
                                });
                            }
                        },
                            function (error) {
                                this.loading = false;
                                console.log(error);
                            });
                }
            },
            getBank: function () {
                var root = this;
                var token = '';

                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/Product/PaymentOptionsList', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.bankList = response.data.paymentOptions;
                    }
                })
            },
            getType: function (type) {
                if (type == '1') {
                    return 'Bank'
                }
                else if (type == '0') {
                    return 'Cash'
                }
                else {
                    return 'Other Currency';
                }
            }
            ,
            selectBank: function (id) {
                this.isActive = true;
                this.sale.salePayment.paymentOptionId = id;
            },
            getCurrency: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/Product/CurrencyList', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.currencyList = response.data.currencies;
                    }
                })
            },
            selectCurrency: function (id) {

                this.isActive = true;
                this.sale.otherCurrency.currencyId = id;
            },
            isCredit: function (credit) {

                this.sale.isCredit = credit;
                if (!this.sale.isCredit) {
                    this.sale.customerId = null;
                }
            },
            isPayment: function (credit) {
                this.sale.salePayment.paymentType = credit;
            },
            emptyCashCustomer: function (customerId) {
                this.sale.customerId = customerId;

                if (this.sale.customerId != '00000000-0000-0000-0000-000000000000' && this.sale.customerId != null) {
                    this.sale.cashCustomer = "Walk-In";
                    this.sale.mobile = "";
                }

                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.options = [];
                this.$https
                    .get('/Sale/DispatchNoteList?isDropdown=' + true + '&customerId=' + customerId, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {

                        if (response.data != null) {
                            response.data.results.forEach(function (dn) {
                                root.options.push({
                                    id: dn.id,
                                    name: dn.registrationNumber,
                                });
                            });
                        }
                    })
                    .then(function () {
                        root.value = root.options.find(function (x) {
                            return x.id == root.values;
                        });
                    });
            },

            setCustomer: function (value) {
                console.log(value);
            },
            OnEditInvoice: function () {


                var total = 0;
                if (this.sale.salePayment.paymentTypes != '' && this.sale.salePayment.paymentTypes != undefined) {
                    this.sale.salePayment.paymentTypes.forEach(x => {

                        total += x.amount;
                    });
                    total = total + parseFloat(this.sale.salePayment.received);
                }
                if (total != 0) {
                    if (this.sale.salePayment.balance <= total) {
                        this.sale.salePayment.balance = 0;
                    }
                    this.sale.salePayment.change = total - this.sale.salePayment.dueAmount;
                }
            },
            calculatBalance: function (received) {

                if (received == '') {
                    received = 0;
                }
                var paymentTypesAmount;
                if (this.sale.salePayment.paymentTypes.length > 0) {
                    paymentTypesAmount = this.sale.salePayment.paymentTypes.reduce((total, payment) =>
                        total + payment.amount, 0);
                } else {
                    paymentTypesAmount = 0;
                }

                this.sale.salePayment.balance = (parseFloat(received) + paymentTypesAmount) - this.sale.salePayment.dueAmount < 0 ? (parseFloat(received) + paymentTypesAmount) - this.sale.salePayment.dueAmount : 0;
                this.sale.salePayment.change = (parseFloat(received) + paymentTypesAmount) - this.sale.salePayment.dueAmount > 0 ? (parseFloat(received) + paymentTypesAmount) - this.sale.salePayment.dueAmount : 0;
                this.sale.change = this.sale.salePayment.change;
            },

            addPayment: function (amount, paymentType) {
                this.sale.salePayment.received = 0;
                var payment = this.sale.salePayment.paymentTypes.find((x) => x.paymentType == paymentType)
                if (payment != undefined) {
                    payment.amount += parseFloat(amount);
                } else {
                    this.sale.salePayment.paymentTypes.push({ paymentType: paymentType, amount: parseFloat(amount) });
                }

                this.calculatBalance(0);
            },

            removeFromPaymentList: function (paymentType) {
                this.sale.salePayment.paymentTypes = this.sale.salePayment.paymentTypes.filter((x) =>
                    x.paymentType != paymentType);

                this.calculatBalance(0);
            },

            updateSummary: function (summary) {
                this.summary = summary;
                this.sale.salePayment.dueAmount = parseFloat(this.summary.withVat)
            },
            SaveSaleItems: function (saleItems, discount, adjustmentSignProp, transactionLevelDiscount) {
                this.sale.saleItems = saleItems;
                this.sale.discount = (discount == '' || discount == null) ? 0 : (adjustmentSignProp == '+' ? parseFloat(discount) : (-1) * parseFloat(discount))

                this.sale.transactionLevelDiscount = (transactionLevelDiscount == '' || transactionLevelDiscount == null) ? 0 : parseFloat(transactionLevelDiscount)
            },
            updateDiscountChanging: function (isFixed, isBeforeTax) {
                this.sale.isFixed = isFixed
                this.sale.isBeforeTax = isBeforeTax
            },

            goToSale: function () {

                this.$router.push({
                    path: '/SaleService',
                    query: {
                        data: 'AddSaleService'
                    }
                });
            },
            getDate: function (date) {
                if (date == null || date == undefined) {
                    return "";
                }
                else {
                    return moment(date).format('LLL');
                }
            },
            GetHeaderDetail: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get("/Company/GetCompanyDetail?id=" + localStorage.getItem('CompanyID'), { headers: { Authorization: `Bearer ${token}` }, })
                    .then(function (response) {
                        if (response.data != null) {
                            root.headerFooter.company = response.data;
                        }
                    });
            },
        },
        created: function () {

            if (this.$route.query.data != undefined) {
                var data = this.$route.query.data;
                this.sale.id = data.id;
                this.sale.invoiceType = data.invoiceType;
                this.sale.cashCustomer = data.cashCustomer;
                this.sale.customerNameEn = data.customerNameEn;
                this.sale.paymentTypes = data.paymentTypes;
                this.sale.change = data.change;
                this.sale.customerNameAr = data.customerNameAr;
                this.sale.wareHouseName = data.wareHouseName;
                this.sale.wareHouseNameAr = data.wareHouseNameAr;
                this.sale.creditCustomer = data.creditCustomer;
                this.sale.date = moment(data.date).format("LLL");
                if (data.dueDate != null) {
                    this.sale.dueDate = moment(data.dueDate).format("LLL");

                }
                else {
                    this.sale.dueDate = '';
                }
                this.sale.registrationNo = data.registrationNo;
                this.sale.mobile = data.mobile;
                this.sale.code = data.code;
                this.sale.isCredit = data.isCredit;
                this.sale.areaId = data.areaId;
                this.sale.employeeId = data.employeeId;
                this.sale.customerId = data.customerId;
                this.sale.dispatchNote = data.dispatchNote;
                this.sale.saleItems = data.saleItems;
                this.sale.paymentVoucher = data.paymentVoucher;
                this.sale.taxMethod = data.taxMethod;
                this.sale.isButtonDisabled = false;
                this.sale.discount = data.discount;
                this.sale.isDiscountOnTransaction = data.isDiscountOnTransaction;
                this.sale.isFixed = data.isFixed;
                this.sale.isBeforeTax = data.isBeforeTax;
                this.sale.transactionLevelDiscount = data.transactionLevelDiscount;

                this.discountTypeOption = data.isDiscountOnTransaction ? 'At Transaction Level' : 'At Line Item Level'
                this.sale.taxRateId = data.taxRateId;
                this.adjustmentSignProp = data.discount >= 0 ? '+' : '-'

                //if (localStorage.getItem('IsMultiUnit') == 'true' && localStorage.getItem('decimalQuantity') == 'true') {
                //    this.sale.saleItems.forEach(function (x) {

                //        x.highQty = x.serviceItem ? 0 : parseInt(parseFloat(x.quantity) / parseFloat(x.product.unitPerPack));
                //        x.quantity = x.serviceItem ? parseFloat(x.quantity) : parseFloat(parseFloat(x.quantity) % parseFloat(x.product.unitPerPack)).toFixed(3).slice(0, -1);
                //        x.unitPerPack = x.product.unitPerPack;
                //    });
                //}
                //if (localStorage.getItem('IsMultiUnit') == 'true' && localStorage.getItem('decimalQuantity') != 'true') {
                //    this.sale.saleItems.forEach(function (x) {

                //        x.highQty = x.serviceItem ? 0 : parseInt(parseInt(x.quantity) / parseInt(x.product.unitPerPack));
                //        x.quantity = x.serviceItem ? parseInt(x.quantity) : parseInt(parseInt(x.quantity) % parseInt(x.product.unitPerPack));
                //        x.unitPerPack = x.product.unitPerPack;
                //    });
                //}
                this.GetHeaderDetail();
            }
        },

        mounted: function () {

            this.language = this.$i18n.locale;
            var IsDayStart = localStorage.getItem('DayStart');
            var IsExpenseDay = localStorage.getItem('IsExpenseDay');
            var IsDayStartOn = localStorage.getItem('IsDayStart');
            if (IsDayStart != 'true') {
                this.isDayAlreadyStart = true;
                this.language = this.$i18n.locale;
                this.isArea = localStorage.getItem('IsArea');
                this.currency = localStorage.getItem('currency');
                this.isMultiUnit = 'false';
                this.isRaw = localStorage.getItem('IsProduction');

            }
            else {
                if (IsExpenseDay == 'true') {
                    this.isDayAlreadyStart = false;
                }
                else {
                    this.CompanyID = localStorage.getItem('CompanyID');
                    this.UserID = localStorage.getItem('UserID');
                    this.employeeId = localStorage.getItem('EmployeeId');
                    if (IsDayStartOn == 'true') {
                        var root = this;
                        this.isDayAlreadyStart = true;
                        root.isArea = localStorage.getItem('IsArea');
                        root.currency = localStorage.getItem('currency');
                        root.isMultiUnit = 'false';
                        root.isRaw = localStorage.getItem('IsProduction');

                    }

                }

            }

        },
    };
</script>
