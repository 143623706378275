<template>

    <div class="row">
        <div class="col-xm-12 col-sm-12 col-md-12 col-lg-12 ml-auto mr-auto">
            <div class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="card-body">
                    <div class="modal-header">
                        <h5 class="modal-title DayHeading" id="myModalLabel">{{ $t('AutoPurchaseSetting') }} </h5>
                    </div>

                    <div class="row ">
                        <div class="col-xm-12 col-sm-12 col-md-12 col-lg-6">
                            <div class="form-group has-label">
                                <label class="text  font-weight-bolder">   Re Order Day: </label>
                                <input class="form-control" v-model="autoPurchaseSetting.day" @focus="$event.target.select()" type="number" />
                            </div>
                        </div>

                        <div class="col-xm-12 col-sm-12 col-md-12 col-lg-6 form-group" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <label>{{ $t('PurchaseOrder.WareHouse') }} :<span class="text-danger"> *</span></label>
                            <warehouse-dropdown v-model="$v.autoPurchaseSetting.wareHouseId.$model" />
                        </div>

                        <div class="col-xm-12 col-sm-12 col-md-12 col-lg-6 form-group" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <label>Start Date:</label>
                            <datepicker v-model="autoPurchaseSetting.invoiceDate" />
                        </div>

                        <!--<div class="col-xm-12 col-sm-12 col-md-12 col-lg-6 form-group" v-bind:class=" ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <label>{{ $t('Product.TaxMethod') }} :<span class="text-danger"> *</span></label>
                            <multiselect :options="options" v-model="autoPurchaseSetting.taxMethod" :show-labels="false" v-bind:placeholder="$t('SelectMethod')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            </multiselect>
                        </div>

                        <div class="col-xm-12 col-sm-12 col-md-12 col-lg-6 form-group" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <label>{{ $t('PurchaseOrder.VAT%') }} :<span class="text-danger"> *</span></label>
                            <taxratedropdown v-model="autoPurchaseSetting.taxRateId" v-bind:values="autoPurchaseSetting.taxRateId" />
                        </div>-->
                    </div>


                    <div class="modal-footer justify-content-right" v-if="autoPurchaseSetting.id=='00000000-0000-0000-0000-000000000000'">
                        <button type="button" class="btn btn-primary  " v-on:click="SaveAutoPurchase" v-bind:disabled="$v.autoPurchaseSetting.$invalid"> {{ $t('btnSave') }}</button>
                        <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('btnClear') }}</button>
                    </div>

                    <div class="modal-footer justify-content-right" v-else>
                        <button type="button" class="btn btn-primary  " v-on:click="SaveAutoPurchase" v-bind:disabled="$v.autoPurchaseSetting.$invalid"> {{ $t('btnUpdate') }}</button>
                        <button type="button" class="btn btn-outline-danger  mr-3 " v-on:click="close()">{{ $t('btnClear') }}</button>
                    </div>

                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { required } from "vuelidate/lib/validators"
    import moment from "moment";
    //import Multiselect from 'vue-multiselect'
    export default {
        mixins: [clickMixin],
        //components: {
        //    Multiselect
        //},
        data: function () {
            return {
                autoPurchaseSetting: {
                    id: '00000000-0000-0000-0000-000000000000',
                    day: 0,
                    taxMethod: '',
                    taxRateId: '',
                    wareHouseId: '',
                    invoiceDate: '',
                },
                options: [],
            }
        },
        validations: {
            autoPurchaseSetting: {
                day: {
                    required,
                },
                taxMethod: {
                    required,
                },
                taxRateId: {
                    required,
                },
                wareHouseId: {
                    required,
                },
            }
        },
        methods: {

            close: function () {
                this.$router.push('/ProductManagement');
            },
            SaveAutoPurchase: function () {

                var root = this;
                var token = '';

                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                this.$https.post('/Purchase/AutoPurchaseOrderSetting', this.autoPurchaseSetting, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data == true) {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully!' : '!حفظ بنجاح',
                            type: 'success',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });


                    }
                    else {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: "Your printSetting Name  Already Exist!",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                });
            },

            EditAutoPurchase: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('/Purchase/AutoPurchaseOrderSettingDetail', { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data != null && response.data != '') {
                            root.autoPurchaseSetting.id = response.data.id;
                            root.autoPurchaseSetting.day = response.data.day;
                            root.autoPurchaseSetting.taxMethod = response.data.taxMethod;
                            root.autoPurchaseSetting.taxRateId = response.data.taxRateId;
                            root.autoPurchaseSetting.wareHouseId = response.data.wareHouseId;

                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            }
        },
        created() {
            this.$emit('input', this.$route.name);
            this.EditAutoPurchase();
            this.autoPurchaseSetting.invoiceDate = moment().isoWeekday(7);
        },
        mounted: function () {
            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.options = ['Inclusive', 'Exclusive'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
            }
        }
    }
</script>