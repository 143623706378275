<template>
    <div class="row" v-if="isValid('CanViewPurchaseDetail')">
        <div class="col-lg-12">
            <div class="row">
                <div class="col">
                    <h5 class="page_title">{{ $t('PurchaseView.PurchaseInvoice') }}</h5>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('PurchaseView.Home') }}</a></li>
                        <li class="breadcrumb-item active">
                            {{ $t('PurchaseView.PurchaseInvoice') }}
                        </li>
                    </ol>
                </div>

                <div class="col-auto align-self-center">

                    <a v-on:click="goToPurchase" href="javascript:void(0);"
                       class="btn btn-sm btn-outline-danger mx-1">
                        <i class="fas fa-arrow-circle-left fa-lg"></i>

                    </a>
                    <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                       class="btn btn-sm btn-outline-danger">
                        {{ $t('SaleOrder.Close') }}
                    </a>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-9" >
            <div class="card" >
                <div class="card-body">
                    <div class="row" >                                          
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
                            <h5>{{ $t('PurchaseView.From') }}</h5>
                            <div class="card" style="border: 1px #dddddd solid;">
                                <div class="card-body">
                                    <label>{{($i18n.locale == 'en' ||isLeftToRight())?headerFooter.company.nameEnglish:headerFooter.company.companyNameArabic}}</label>
                                    <br />
                                    <label>{{($i18n.locale == 'en' ||isLeftToRight())?headerFooter.company.addressEnglish:headerFooter.company.addressArabic}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
                            <h5>{{ $t('PurchaseView.InvoiceTo') }}</h5>
                            <div class="card" style="border: 1px #dddddd solid;">
                                <div class="card-body">
                                    <label>{{purchase.supplierName}}</label>
                                    <br />
                                    <!--<label v-if="!sale.isCredit">{{sale.customerAddress}}</label>-->
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="row">


                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mb-2">
                            <label class="fw-bold">{{ $t('PurchaseView.TaxMethod') }} :</label>
                            <div>{{purchase.taxMethod}}</div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mb-2">
                            <label class="fw-bold">{{ $t('PurchaseView.VAT%') }} :</label>
                            <div>{{purchase.taxRatesName}}</div>
                        </div>

                    </div> -->
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <purchase-view-item :purchase="purchase" :taxMethod="purchase.taxMethod" :taxRateId="purchase.taxRateId" :raw="purchase.isRaw" :po="false" :key="randerLineItem" :purchaseOrderId="purchase.purchaseOrderId" @discountChanging="updateDiscountChanging" :adjustmentProp="purchase.discount" :adjustmentSignProp="adjustmentSignProp" :isDiscountOnTransaction="purchase.isDiscountOnTransaction" :transactionLevelDiscountProp="purchase.transactionLevelDiscount" :isFixed="purchase.isFixed" :isBeforeTax="purchase.isBeforeTax" />
                            <div class="row">
                                <div class="col-lg-12 mt-4 mb-5">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-6" style="border-right: 1px solid #eee;">

                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group ps-3">
                                                        <div class="font-xs mb-1">{{ $t('PurchaseView.AttachFiles') }}</div>
                                                        <button v-on:click="Attachment()" type="button" class="btn btn-light btn-square btn-outline-dashed mb-1"><i class="fas fa-cloud-upload-alt"></i> {{ $t('QuotationView.Attachment') }} </button>
                                                        <div>
                                                            <small class="text-muted">
                                                                {{ $t('PurchaseView.FileSize') }}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div class="accordion mb-5" id="accordionExample" v-if="purchase.id != '00000000-0000-0000-0000-000000000000' && internationalPurchase=='true'">
                                <div class="accordion-item"  v-if="isValid('CanViewPIAttachment')">
                                    <h5 class="accordion-header m-0" id="headingOne">
                                        <button class="accordion-button fw-semibold " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            {{ $t('PurchaseView.Attachment') }}
                                        </button>
                                    </h5>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class=" table-responsive">
                                                <table class="table ">
                                                    <thead class="thead-light table-hover">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>{{ $t('PurchaseView.Date') }} </th>
                                                            <th>{{ $t('PurchaseView.Description') }} </th>
                                                            <th v-if="isValid('CanDownloadPIAttachment')">{{ $t('PurchaseView.Attachment') }}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(contact,index) in purchase.purchaseInvoiceAttachments" v-bind:key="index">
                                                            <td>
                                                                {{index+1}}
                                                            </td>
                                                            <th>{{getDate(contact.date)}}</th>
                                                            <th>{{contact.description}}</th>

                                                            <td v-if="isValid('CanDownloadPIAttachment')">
                                                                <button class="btn btn-primary  btn-icon mr-2"
                                                                        v-if="contact.path != ''"
                                                                        v-on:click="DownloadAttachment(contact.path)">
                                                                    <i class="fa fa-download"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item"  v-if="isValid('CanViewPIExpenses')">
                                    <h5 class="accordion-header m-0" id="headingTwo">
                                        <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            {{ $t('PurchaseView.Expense') }}
                                        </button>
                                    </h5>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <purchaseorder-expense :show="expense" v-if="expense" @close="expenseSave" :isPurchase="false" :isPurchasePostExpense="true" :purchaseOrderId="purchase.id" :formName="'AdvanceExpense'" />
                                            <purchaseorder-expensebill :show="bill" v-if="bill" @close="billSave" :isInvoice="true" :purchaseId="purchase.id" />
                                            <div>
                                                <div class="row" v-if="!purchase.isCost && purchase.isPurchasePost && purchase.id != '00000000-0000-0000-0000-000000000000'">
                                                    <div class="col-md-12 text-right">
                                                        <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="bill=true" v-if="isValid('CanAddOrderExpense')"> {{ $t('PurchaseView.AddBill') }}</a>
                                                        <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="expense=true" v-if="isValid('CanAddOrderExpense')"> {{ $t('PurchaseView.AddExpense') }}</a>
                                                    </div>
                                                </div>
                                                <div class=" table-responsive">
                                                    <table class="table ">
                                                        <thead class="thead-light table-hover">
                                                            <tr>
                                                                <th>#</th>
                                                                <th style="width:20%;">{{ $t('PurchaseView.Date') }} </th>
                                                                <th style="width:20%;">{{ $t('PurchaseView.VoucherNumber') }} </th>
                                                                <th class="text-right">{{ $t('PurchaseView.Amount') }} </th>
                                                                <th class="text-center">{{ $t('PurchaseView.PaymentMode') }} </th>
                                                                <th>{{ $t('PurchaseView.Description') }} </th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(payment,index) in purchase.purchasePostExpenses" v-bind:key="index">
                                                                <td>
                                                                    {{index+1}}
                                                                </td>
                                                                <th>{{getDate(payment.date)}}</th>
                                                                <th>{{payment.voucherNumber}}</th>
                                                                <th class="text-right">{{currency}} {{parseFloat(payment.amount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</th>
                                                                <th class="text-center"><span v-if="payment.paymentMode==0">{{ $t('PurchaseView.Cash') }}</span><span v-if="payment.paymentMode==1">{{ $t('PurchaseView.Bank') }}</span></th>
                                                                <th>{{payment.narration}}</th>
                                                                <th>
                                                                    <a href="javascript:void(0)" title="Payment View" class="btn  btn-icon btn-soft-primary btn-sm" v-on:click="ViewPaymentVoucher(payment.id, true)" v-if="isValid('CanViewDetailOrderExpense')"><i class=" fas fa-eye"></i></a>
                                                                </th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item" v-if="isValid('CanViewPIActions')">
                                    <h5 class="accordion-header m-0" id="headingThree">
                                        <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            {{ $t('PurchaseView.Actions') }}
                                        </button>
                                    </h5>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class=" table-responsive">
                                                <table class="table ">
                                                    <thead class="thead-light table-hover">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>{{ $t('PurchaseView.Status') }}</th>
                                                            <th>{{ $t('PurchaseView.Date') }} </th>
                                                            <th>{{ $t('PurchaseView.Description/Reason') }}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(process,index) in purchase.purchaseInvoiceActions" v-bind:key="process.id">
                                                            <td>
                                                                {{index+1}}
                                                            </td>
                                                            <th><span class="badge badge-primary">{{process.processName}}</span></th>
                                                            <th>{{getDate(process.date)}}</th>
                                                            <th>{{process.description}}</th>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12 ">
                            <button class="btn btn-sm btn-outline-danger" v-on:click="goToPurchase">
                                {{ $t('QuotationView.Cancel') }}
                            </button>
                        </div>
                    </div>


                </div>
            </div>
            <purchaseInvoice :printDetails="printDetails" v-if="printDetails.length != 0" v-bind:key="printRender"></purchaseInvoice>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" >
            <div class="card">
                <div class="card-body">
                    <div class="row">

                        <div class=" col-lg-12">
                            <h5 class="view_page_title">{{ $t('PurchaseView.BasicInfo') }}</h5>

                        </div>
                        <div class="col-lg-12 text-center">
                            <vue-qrcode v-bind:value="qrValue" style="width:120px;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="invoice_lbl">{{ $t('PurchaseView.PurchaseInvoice') }}#</label>
                            <hr style="margin-top: 0.3rem; margin-bottom: 0.1rem;" />
                            <label>{{purchase.registrationNo}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="invoice_lbl">{{ $t('PurchaseView.PurchaseOrder') }}#</label>
                            <hr style="margin-top: 0.3rem; margin-bottom: 0.1rem;" />
                            <label>{{purchase.purchaseOrderNo}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" v-if="isValid('CanAddGoodsReceiveasPost') || isValid('CanViewGoodsReceiveasPost')">
                            <label class="invoice_lbl">{{ $t('GoodReceive.GoodReceive') }} #</label>
                            <hr style="margin-top: 0.3rem; margin-bottom: 0.1rem;" />
                            <label>{{purchase.goodReceiveNo}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('PurchaseView.WareHouse') }} :</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{ purchase.wareHouseName }}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('PurchaseView.Date') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{purchase.date}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('PurchaseView.SupplierInvoiceNumber') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{ purchase.invoiceNo }}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('PurchaseView.SupplierInvoiceDate') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{ purchase.invoiceDate }}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>





                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('PurchaseView.SendCopyTo') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{ $t('PurchaseView.Email') }}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <!--<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <button class="btn btn-primary btn-block">Send Invoice</button>
                        </div>-->
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-2">
                            <button class="btn btn-light btn-block text-left">PDF <i class="fas fa-file-pdf float-right" style="color:#EB5757;"></i></button>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-2">
                            <button class="btn btn-light btn-block text-left">Sheets <i class="fas fa-file-excel float-right" style="color:#198754;"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <bulk-attachment :documentid="purchase.id" :show="show" v-if="show" @close="attachmentSave" />

    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    import { required } from "vuelidate/lib/validators";
    import VueQrcode from 'vue-qrcode'

    export default {
        components: {
            VueQrcode,
        },
        mixins: [clickMixin],
        name: "PurchaseView",
       
        data: function () {
            return {
                discountTypeOption: 'At Line Item Level',
                adjustmentSignProp: '+',

                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    purchaseOrderId: "",
                    registrationNo: "",
                    supplierId: "",
                    invoiceNo: "",
                    isPurchaseReturn: false,
                    invoiceDate: "",
                    purchaseOrder: "",
                    wareHouseId: "",
                    purchaseItems: [],
                    isRaw: false,
                    taxMethod: '',
                    taxRateId: "00000000-0000-0000-0000-000000000000",

                    discount: 0,
                    isDiscountOnTransaction: false,
                    isFixed: false,
                    isBeforeTax: true,
                    transactionLevelDiscount: 0

                },

                printId: '00000000-0000-0000-0000-000000000000',
                printDetails: [],
                options: [],
                loading: false,
                rander: 0,
                qrValue: '',

                raw: '',
                printRender: 0,
                randerLineItem: 0,
                autoNumber: '',
                language: 'Nothing',
                supplierRender: 0,
                internationalPurchase: '',
                versionAllow: '',
                currency: '',
                bill: false,
                expense: false,
                show: false,
                headerFooter: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
            };
        },
        validations: {
            purchase: {
                date: { required },
                registrationNo: { required },
                supplierId: { required },
                invoiceNo: {},
                invoiceDate: {},
                wareHouseId: {},
                purchaseItems: { required },
            },
        },
        computed: {
            calulateDiscountAmount: function () {
                return this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number(c.discountAmount || 0) }, 0)
            },
            calulateTotalQty: function () {
                return this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number((c.quantity) || 0) }, 0)
            },
            calulateNetTotal: function () {
                return this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number((c.total + c.includingVat) || 0) }, 0)
            },
            calulateTotalExclVAT: function () {
                return this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number((c.total) || 0) }, 0)
            },
            calulateTotalVAT: function () {

                return this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number((c.includingVat + c.inclusiveVat) || 0) }, 0)
            }
        },
        methods: {
            GotoPage: function (link) {
                this.$router.push({ path: link });
            },
            ViewPaymentVoucher: function (id, expense) {
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Purchase/PurchaseOrderPaymentDetail?Id=' + id + '&expense=' + expense, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$https.get('/PaymentVoucher/PaymentVoucherDetails?Id=' + response.data.paymentVoucherId, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                            if (response.data != null) {
                                root.paymentview = response.data;
                                if (expense) {
                                    root.isExpenseview = true;
                                }
                                else {
                                    root.isPaymentview = true;
                                }
                            }
                        });
                    }
                });
            },
            GetTLVForValue: function (tagNumber, tagValue) {
                var tagBuf = Buffer.from([tagNumber], 'utf-8')
                var tagValueLenBuf = Buffer.from([tagValue.length], 'utf-8')
                var tagValueBuf = Buffer.from(tagValue, 'utf-8')
                var bufsArray = [tagBuf, tagValueLenBuf, tagValueBuf]
                return Buffer.concat(bufsArray)
            },
            calulateDiscountAmount1: function () {
                return this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number(c.discountAmount || 0) }, 0)
            },
            calulateBundleAmount1: function () {
                return this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0)
            },
            calulateNetTotalWithVAT: function () {
                var total = this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number((c.total + c.includingVat) || 0) }, 0);
                var grandTotal = parseFloat(total) - (this.calulateDiscountAmount1() + this.calulateBundleAmount1())
                return (parseFloat(grandTotal).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },
            calulateTotalVATofInvoice: function () {
                var total = this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number((c.includingVat + c.inclusiveVat) || 0) }, 0);
                return (parseFloat(total).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },
            Attachment: function () {
                this.show = true;
            },

            attachmentSave: function () {
                this.show = false;
            },

            GetExpenseVoucher: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.get('PurchasePost/PurchasePostExpensePaymentList?id=' + this.purchase.id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {
                            root.purchase.purchasePostExpenses = response.data;
                        }
                    });
            },
            expenseSave: function () {
                this.expense = false;
                this.GetExpenseVoucher();
            },
            billSave: function () {
                this.bill = false;
                this.GetExpenseVoucher();
            },
            getDate: function (date) {
                if (date == null || date == undefined) {
                    return "";
                }
                else {
                    return moment(date).format('LLL');
                }
            },
            DownloadAttachment(path) {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var ext = path.split('.')[1];
                root.$https.get('/Contact/DownloadFile?filePath=' + path, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'file.' + ext);
                        document.body.appendChild(link);
                        link.click();
                    });
            },

            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.purchase.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addproduct');
                    }
                    else {
                        this.$swal({
                            title: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text:(this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'You cannot Change language during update, otherwise your current page data will be lose!' : 'لا يمكنك تغيير اللغة أثناء التحديث ، وإلا ستفقد بيانات صفحتك الحالية!',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },
          
           
            updateDiscountChanging: function (isFixed, isBeforeTax) {
                this.purchase.isFixed = isFixed
                this.purchase.isBeforeTax = isBeforeTax
            },
            GetHeaderDetail: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.get("/Company/GetCompanyDetail?id=" + localStorage.getItem('CompanyID'), { headers: { Authorization: `Bearer ${token}` }, })
                    .then(function (response) {
                        if (response.data != null) {
                            
                            root.headerFooter.company = response.data;
                            var sellerNameBuff = root.GetTLVForValue('1', root.headerFooter.company.nameEnglish)
                            var vatRegistrationNoBuff = root.GetTLVForValue('2', root.headerFooter.company.vatRegistrationNo)
                            var timeStampBuff = root.GetTLVForValue('3', root.purchase.date)
                            var totalWithVat = root.GetTLVForValue('4', root.calulateNetTotalWithVAT())
                            var totalVat = root.GetTLVForValue('5', root.calulateTotalVATofInvoice())
                            var tagArray = [sellerNameBuff, vatRegistrationNoBuff, timeStampBuff, totalWithVat, totalVat]
                            var qrCodeBuff = Buffer.concat(tagArray)
                            root.qrValue = qrCodeBuff.toString('base64')
                        }
                    });
            },
            goToPurchase: function () {
                this.$router.push({
                    path: '/purchase',
                    query: {
                        data: 'Addpurchase'
                    }
                })
            },

            setDefaultWareHouse: function (id) {
                this.purchase.wareHouseId = id;
                this.render++;
            }
        },
        created() {
            if (this.$route.query.data != undefined) {
                this.purchase = this.$route.query.data;
                this.purchase.date = moment(this.purchase.date).format("LLL");
                //  this.warehouse = this.$route.query.data;
                this.rander++;
            }
            else {
                this.rander++;
            }
            
        },

        mounted: function () {

            this.language = this.$i18n.locale;
            this.internationalPurchase = localStorage.getItem('InternationalPurchase');
            this.versionAllow = localStorage.getItem('VersionAllow');
            this.currency = localStorage.getItem('currency');

            this.GetHeaderDetail();
            if (this.$session.exists()) {
                this.raw = localStorage.getItem('IsProduction');
            }
            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.options = ['Inclusive', 'Exclusive'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
            }

            
        },
    };
</script>
