<template>
    <div>
        <div hidden id='inventoryDetailReport' class="col-md-12">
            <div class="border border-dark col-md-12 mb-1 " style="height:40mm;" v-if="isHeaderFooter == 'true'">
                <div class="row">
                    <div class="col-md-4 text-center">
                        <table class="m-auto">
                            <tr>
                                <td>
                                    <p>
                                        <span style="font-size:25px;color:black !important;font-weight:bold;">
                                            {{ headerFooters.company.nameEnglish }}
                                        </span><br />
                                        <span style="font-size:17px;color:black !important;font-weight:bold;">
                                            {{ headerFooters.company.categoryEnglish }}
                                        </span>
                                        <br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">VAT No.:
                                            {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                            No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                        <span style="font-size:15px;color:black !important;font-weight:bold;">
                                            Tel: {{ headerFooters.company.phoneNo }}
                                        </span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-4 text-center my-5" style="padding:0px !important; margin:0 !important">
                        <img :src="headerFooters.company.logoPath"
                            style="width:auto;max-width:300px; height:auto; max-height:120px; padding:5px !important; margin:0 !important">
                        <p
                            style="text-align: center; margin: 0px; padding: 0px; font-size: 10px; line-height: 1; font-family:'Times New Roman', Times;">
                            <span style="font-size:14px;">Product Ledger Report</span>
                        </p>
                    </div>
                    <div class="col-md-4 " style="height:40mm;">
                        <table class="text-right" v-if="arabic == 'true'">
                            <tr>
                                <td>
                                    <p>
                                        <span style="font-size:25px;color:black !important;font-weight:bold;">
                                            {{ headerFooters.company.nameArabic }}.
                                        </span><br />
                                        <span style="font-size:17px;color:black !important;font-weight:bold;">
                                            {{ headerFooters.company.categoryArabic }}
                                        </span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">رقم ضريبة
                                            القيمة المضافة: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">رقم السجل
                                            التجاري :{{ headerFooters.company.companyRegNo }}</span><br />
                                        <span style="font-size:15px;color:black !important;font-weight:bold;">
                                            هاتف: {{ headerFooters.company.phoneNo }}:
                                        </span>
                                    </p>
                                </td>

                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div style="height:45mm;" v-else></div>

            <div class="border border-dark" style="height:15mm;margin-top:1mm;">
                <div class="row">
                    <div class="col-md-12 ml-2 mr-2" style="height:10mm;font-size:12px;">
                        <div class="row">
                            <div class="col-md-2 text-center font-weight-bold">From Date:</div>
                            <div class="col-md-2 text-center font-weight-bold">{{ list.fromDate }}</div>
                            <div class="col-md-2 text-center font-weight-bold" v-if="arabic == 'true'">:من التاريخ</div>

                            <div class="col-md-2 text-center font-weight-bold">To Date:</div>
                            <div class="col-md-2 text-center font-weight-bold">{{ list.toDate }}</div>
                            <div class="col-md-2 text-center font-weight-bold" v-if="arabic == 'true'">:حتى الآن</div>
                        </div>
                    </div>
                </div>
            </div>
            <!--INFORMATION-->
            <div class="col-md-12">
                <div class="row" v-for="(pro) in list.productComparisonReport" v-bind:key="pro.id">
                    <h4>{{ pro.productName }} : <span>{{ pro.code }}</span> </h4>
                    <div class="row">
                        <div class="table-responsive col-md-4">
                            <table class="table mb-0">
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>Inventory</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <!-- <th>#</th> -->
                                        <th>
                                            Opening Balance
                                        </th>
                                        <th>
                                            Closing Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ pro.iOpeningBalance > 0 ? 'Dr' : 'Cr' }}: {{ nonNegative(pro.iOpeningBalance)
}}
                                        </td>
                                        <td>
                                            {{ pro.iClosingingBalance > 0 ? 'Dr' : 'Cr' }}: {{
        nonNegative(pro.iClosingingBalance)
}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive col-md-4">
                            <table class="table mb-0">
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>COGS</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>
                                            Opening Balance
                                        </th>
                                        <th>
                                            Closing Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ pro.gsOpeningBalance > 0 ? 'Dr' : 'Cr' }}: {{
        nonNegative(pro.gsOpeningBalance)
}}
                                        </td>
                                        <td>
                                            {{ pro.gsClosingingBalance > 0 ? 'Dr' : 'Cr' }}: {{
        nonNegative(pro.gsClosingingBalance)
}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive col-md-4">
                            <table class="table mb-0">
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>Sale</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>
                                            Opening Balance
                                        </th>
                                        <th>
                                            Closing Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ pro.sOpeningBalance > 0 ? 'Dr' : 'Cr' }}: {{
        nonNegative(pro.sOpeningBalance)
}}
                                        </td>
                                        <td>
                                            {{ pro.sClosingingBalance > 0 ? 'Dr' : 'Cr' }}: {{
        nonNegative(pro.sClosingingBalance)
}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>
<script>

import clickMixin from '@/Mixins/clickMixin'
export default {
    mixins: [clickMixin],
    props: ['printDetails', 'isPrint', 'isShown', 'headerFooter', 'fromDate','toDate'],
    data: function () {
        return {
            arabic: '',
            english: '',
            language: '',
            isMultiUnit: '',
            isHeaderFooter: '',
            list: {
                toDate: '',
                fromDate: '',
                productComparisonReport:
                    [

                    ]
            },
            Print: false,
            render: 0,
            headerFooters: {
                footerEn: '',
                footerAr: '',
                company: ''
            }
        }
    },

    mounted: function () {
        this.english = localStorage.getItem('English');
        this.arabic = localStorage.getItem('Arabic');

        var root = this;
        this.language = this.$i18n.locale;
        this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
        root.Print = root.isPrint;

        if (this.printDetails.length > 0) {
            this.headerFooters = this.headerFooter;
            this.list.productComparisonReport = this.printDetails;
            this.list.fromDate = this.fromDate;
            this.list.toDate = this.toDate;

            setTimeout(function () {
                root.printInvoice();
            }, 125)
        }
    },
    methods: {
        nonNegative: function (value) {
            return parseFloat(Math.abs(value)).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
        },
        printInvoice: function () {
            this.$htmlToPaper('inventoryDetailReport');
        }
    }
}
</script>