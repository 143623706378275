import { render, staticRenderFns } from "./ImportStockIn.vue?vue&type=template&id=22ee9bfe&scoped=true&"
import script from "./ImportStockIn.vue?vue&type=script&lang=js&"
export * from "./ImportStockIn.vue?vue&type=script&lang=js&"
import style0 from "./ImportStockIn.vue?vue&type=style&index=0&id=22ee9bfe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22ee9bfe",
  null
  
)

export default component.exports