<template>
    <div>
        <div hidden id='purchaseInvoice' class="col-md-7">
            <!--HEADER-->
            <div class="border border-dark col-md-12 mb-1 " style="height:40mm;">
                <div class="row">
                    <div class="col-md-4 text-center " style="height:40mm;">
                        <table class="m-auto">
                            <tr>
                                <td>
                                    <p class="text-center">
                                        <span style="font-size:19px;">{{headerFooters.company.nameEnglish}}</span><br />
                                        <span style="font-size:13px;">{{headerFooters.company.categoryEnglish}}</span><br />
                                        <span style="font-size:13px;">VAT No.: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                        <span style="font-size:12px;">{{headerFooters.company.companyNameEnglish}}</span><br />
                                        <span style="font-size:11px;">
                                            {{headerFooters.company.addressEnglish}}<br />
                                            Tel: {{headerFooters.company.phoneNo}}
                                        </span>
                                    </p>
                                </td>

                            </tr>
                        </table>
                    </div>
                    <div class="col-md-4 text-center my-5" style="height:40mm;">

                        <img src="noblepos-retail.png" style="width:100%; max-width:300px;">
                        <p style="text-align: center; margin: 0px; padding: 0px; font-size: 10px; line-height: 1; font-family: 'Times New Roman', Times;">
                            <span style="font-size:14px;">{{ $t('InventoryBlindPrint') }}</span>
                        </p>

                    </div>
                    <div class="col-md-4 " style="height:40mm;">
                        <table class="m-auto">
                            <tr>
                                <td>
                                    <p class="text-center">
                                        <span style="font-size:19px;">{{headerFooters.company.nameArabic}}.</span><br />
                                        <span style="font-size:13px;">{{headerFooters.company.categoryArabic}}</span><br />
                                        <span style="font-size:13px;">رقم ضريبة القيمة المضافة: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                        <span style="font-size:12px;">{{headerFooters.company.companyNameArabic}}</span><br />
                                        <span style="font-size:11px;">
                                            {{headerFooters.company.addressArabic}}<br />
                                            هاتف: {{headerFooters.company.phoneNo}}:
                                        </span>
                                    </p>
                                </td>

                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div class="border border-dark col-md-12 my-1 " style="height:20mm;">
                <div class="col-md-12" style="height:6mm;">
                    <div class="row">
                        <div class="col-md-2">Date:</div>
                        <div class="col-md-2">{{headerFooters.date}}</div>
                        <div class="col-md-2">:تاريخ</div>
                        <!--<div class="col-md-1"></div>-->
                        <div class="col-md-2 text-center">Inventory. No.:</div>
                        <div class="col-md-2 text-center">{{headerFooters.documentId}}</div>
                        <div class="col-md-2">:المخزون. لا</div>
                    </div>
                </div>
                <div class="col-md-12 " style="height:6mm;">
                    <div class="row">

                        <div class="col-md-2">Warehouse Name:</div>
                        <div class="col-md-8">{{headerFooters.wareHouseName}}</div>
                        <div class="col-md-2">:اسم المستودع</div>
                    </div>
                </div>
            </div>

            <!--INFORMATION-->
            <div class="border border-dark col-md-12 my-1 ">
                <div class="row ">
                    <table class="table col-md-11 m-auto">
                        <tr class="heading" style="font-size:14px;">
                            <th class="text-left">#</th>
                            <th class="text-left">{{ $t('InventoryBlindPrint.ProductName') }}</th>
                            <th class="text-left">{{ $t('InventoryBlindPrint.CategoryName') }}</th>
                            <th class="text-left">{{ $t('InventoryBlindPrint.Shelf/Location') }}</th>
                            <th class="text-center">{{ $t('InventoryBlindPrint.CurrentQuantity') }}</th>
                            <th v-if="headerFooters.isQuantityRemarks" class="text-center">{{ $t('InventoryBlindPrint.PhysicalQuantity') }}</th>
                            <th v-if="headerFooters.isQuantityRemarks" class="text-center">{{ $t('InventoryBlindPrint.RemarksBI') }}</th>

                        </tr>

                        <tr style="font-size:13px;" v-for="(item, index) in list" v-bind:key="item.id">
                            <td class="text-left">{{index+1}}</td>
                            <td v-if="english=='true' && arabic=='false'" class="text-left">{{item.productName}}</td>
                            <td v-else-if="isOtherLang() && english=='false'" class="text-left">{{item.arabicName}}</td>
                            <td v-else class="text-left">{{item.productName}} <br /> {{item.arabicName}}</td>

                            <td v-if="english=='true' && arabic=='false'" class="text-left">{{item.category.name}}</td>
                            <td v-else-if="isOtherLang() && english=='false'" class="text-left">{{item.category.nameArabic}}</td>
                            <td v-else class="text-left">{{item.category.name}} <br /> {{item.category.nameArabic}}</td>
                            <td  class="text-left">{{item.shelf}}</td>



                            <td v-if="headerFooters.isQuantity" class="text-center">{{item.inventory==null? 0: item.inventory.currentQuantity}}</td>
                            <td v-else class="text-left"></td>

                            <td v-if="headerFooters.isQuantityRemarks" class="text-center">{{item.inventory.physicalQuantity}}</td>
                            <td v-if="headerFooters.isQuantityRemarks" class="text-center">{{item.remarks}}</td>

                        </tr>
                    </table>
                </div>
            </div>


            <!--<div class="col-md-12  " style="height:30mm;">
                <div class="row">
                    <table class="table text-center">
                        <tr>
                            <td>
                                {{ $t('AllReports.ApprovedBy') }}:
                            </td>
                            <td>
                                {{ $t('AllReports.PreparedBy') }}:
                            </td>
                        </tr>

                    </table>
                </div>
            </div>-->

        </div>
    </div>

</template>

<script>
    export default {
        props: ['printDetails', 'headerFooter'],
        data: function () {
            return {
                arabic: '',
                english: '',
                list: {
                    number: 0,
                    registrationNo: '',
                    date: '',
                    companyName: '',
                    companyPhoneNo: '',
                    companyAddress: '',
                    invocieType: '',
                    productList: []
                },
                render: 0,
                headerFooters: {
                    company: '',
                    date: '',
                    decumentId: '',
                    wareHouseName: '',
                    isQuantity: false,
                    isQuantityRemarks:false
                }
            }
        },
        
        methods: {

            printInvoice: function () {
                this.$htmlToPaper('purchaseInvoice');
            }
        },
        mounted: function () {
            
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            var root = this;
            if (this.printDetails.length > 0) {
                this.list = this.printDetails;
                this.headerFooters = this.headerFooter;
                
                setTimeout(function () {
                    root.printInvoice();
                }, 375)
            }
        },

    }
</script>


<style scoped>
    #font11 {
        font-size: 11px;
        line-height: 0;
    }

    #font16 {
        font-size: 16px;
    }
</style>