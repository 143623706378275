<template>
    <div hidden style="color:black !important;background-color:white !important;width:100%;height:100%" class="your-background">
        <div ref="mychildcomponent" id='purchaseInvoice' class="col-md-12" style="color:black !important;background-color:white !important;width:100%;height:100%">

            <!--Invoice with less than 10 Items-->
            <div v-if="list.saleItems.length <= 10">
                <div>
                    <!--HEADER-->
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>
                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder'  && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>


                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in list.saleItems" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>

                                <tr style="font-size:12px;font-weight:bold; height:50px;" v-for="(item, index) in emptyListCount" v-bind:key="index">
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-left" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 10px; color: white !important"><br /> <span style="font-size: 13px; font-weight: bold"></span></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateTotalExclVAT).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateDiscountAmount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(list.discount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateTotalVAT).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateNetTotal + list.discount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr v-if="termAndConditionLength">
                                    <td colspan="3" style="border: 0 !important; color: black !important; font-size: 14px; font-weight: bold; text-align: left;">Sold By  الـبائـع</td>
                                    <td colspan="3" style="border: 0 !important; color: black !important; font-size: 14px; font-weight: bold; text-align: right;">Received By  المستلم</td>
                                </tr>



                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color: white !important;" v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width: 100%; max-height: 30mm; background-color: white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-if="!termAndConditionLength" class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <div v-if="!termAndConditionLength">
                    <div class="row">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div class="row" v-if="list.termAndCondition != null && list.termAndCondition != '' && list.termAndConditionAr != null && list.termAndConditionAr != ''" style="height:290mm !important; color:black !important;padding-top:20px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" style="width: 50% !important;">
                                    <p class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                        Terms & conditions

                                    </p>
                                    <p style="padding:0px !important;margin:0px !important; ">
                                        <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 50% !important;">
                                    <p class="text-right" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                        الأحكام والشروط

                                    </p>
                                    <p style="padding:0px !important;margin:0px !important; ">
                                        <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-else class="row" style="height:290mm !important; color:black !important;padding-top:20px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;">

                        <div class="col-12" v-if="list.termAndCondition != null && list.termAndCondition != ''">
                            <p class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                Terms & conditions

                            </p>
                            <p style="padding:0px !important;margin:0px !important; ">
                                <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                            </p>
                        </div>
                        <div class="col-12" v-if="list.termAndConditionAr != null && list.termAndConditionAr != ''">
                            <p class="text-right" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                الأحكام والشروط

                            </p>
                            <p style="padding:0px !important;margin:0px !important; ">
                                <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                            </p>
                        </div>
                    </div>
                    <div class="row" style="height:10mm;">
                        <div class="col-md-12">
                            <table class="table">
                                <tr>
                                    <td style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;text-align:left; padding-left:10px;">
                                        Sold By  الـبائـع
                                    </td>
                                    <td style="color: black !important; font-size: 17px; font-weight: bold; border-top: 0 !important; text-align: right; padding-right: 10px;">
                                        <p>Received By  المستلم</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row footer " style="background-color:white !important" v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:15px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width: 100%; max-height:30mm; background-color: white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
            </div>

            <!--Invoice with less than 20 Items-->

            <div v-else-if="list.saleItems.length <= 20">
                <!-- Page 1 -->
                <div v-if="headerFooter.continueWithPage">
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' ">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>
                                <template v-for="(item, index) in listItemP1" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold; height:60mm;">
                                    <td colspan="2"></td>
                                    <td v-if="isMultiUnit=='true'"></td>
                                    <td colspan="3"></td>
                                    <td class="text-center" style="color: black !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important;"> <span style="float:right;">Page 1 / 2</span></td>
                                </tr>
                                
                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-else>
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style="width:17% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder To
                                        </span>

                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع ل
                                        </span>

                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            الرجوع الى
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الفاتورةإلى
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            VAT #
                                        </span>
                                        <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                            الرقم الضربی
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Address
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            العنوان
                                        </span>
                                        <br />
                                        <br v-if="list.customerAddressWalkIn != null && list.customerAddressWalkIn.length > 51" />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                            Contact No
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                            رقم الاتصال
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Email
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            البريد الإلكتروني
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice for
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            العودة ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الــفـاتــورة لـ
                                        </span>

                                    </p>

                                </td>
                                <td style="width:34% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                            {{list.cashCustomer}}
                                        </span>
                                        <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                            {{list.customerNameEn}} {{ list.customerNameAr}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.customerVat}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.customerAddressWalkIn}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.mobile}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.email}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.description}}
                                        </span>

                                    </p>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' ">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>
                                <template v-for="(item, index) in listItemP1" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:right;">-</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                
                <!-- Page 1 -->
                <div>
                    <!--HEADER-->
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>
                                <template v-for="(item, index) in listItemP2" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>

                                <tr style="font-size:12px;font-weight:bold; height:50px;" v-for="(item, index) in emptyListCount" v-bind:key="index">
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-left" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 10px; color: white !important"><br /> <span style="font-size: 13px; font-weight: bold"></span></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateTotalExclVAT).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateDiscountAmount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(list.discount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateTotalVAT).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateNetTotal + list.discount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>

                                <tr v-if="termAndConditionLength">
                                    <td colspan="3" style="border: 0 !important; color: black !important; font-size: 14px; font-weight: bold; text-align: left;">Sold By  الـبائـع</td>
                                    <td colspan="3" style="border: 0 !important; color: black !important; font-size: 14px; font-weight: bold; text-align: right;">Received By  المستلم</td>
                                </tr>

                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important" v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-if="!termAndConditionLength" class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <div v-if="!termAndConditionLength">
                    <div class="row">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div class="row" v-if="list.termAndCondition != null && list.termAndCondition != '' && list.termAndConditionAr != null && list.termAndConditionAr != ''" style="height:290mm !important; color:black !important;padding-top:20px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" style="width: 50% !important;">
                                    <p class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                        Terms & conditions

                                    </p>
                                    <p style="padding:0px !important;margin:0px !important; ">
                                        <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 50% !important;">
                                    <p class="text-right" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                        الأحكام والشروط

                                    </p>
                                    <p style="padding:0px !important;margin:0px !important; ">
                                        <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-else class="row" style="height:290mm !important; color:black !important;padding-top:20px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;">

                        <div class="col-12" v-if="list.termAndCondition != null && list.termAndCondition != ''">
                            <p class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                Terms & conditions

                            </p>
                            <p style="padding:0px !important;margin:0px !important; ">
                                <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                            </p>
                        </div>
                        <div class="col-12" v-if="list.termAndConditionAr != null && list.termAndConditionAr != ''">
                            <p class="text-right" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                الأحكام والشروط

                            </p>
                            <p style="padding:0px !important;margin:0px !important; ">
                                <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                            </p>
                        </div>
                    </div>
                    <div class="row" style="height:10mm;">
                        <div class="col-md-12">
                            <table class="table">
                                <tr>
                                    <td style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;text-align:left; padding-left:10px;">
                                        Sold By  الـبائـع
                                    </td>
                                    <td style="color: black !important; font-size: 17px; font-weight: bold; border-top: 0 !important; text-align: right; padding-right: 10px;">
                                        <p>Received By  المستلم</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row footer " style="background-color:white !important" v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:15px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width: 100%; max-height:30mm; background-color: white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

            </div>
            <!--Invoice with less than 30 Items-->

            <div v-else-if="list.saleItems.length <= 30">
                <!-- Page 1 -->
                <div v-if="headerFooter.continueWithPage">
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>


                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP1" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold; height:60mm;">
                                    <td colspan="2"></td>
                                    <td v-if="isMultiUnit=='true'"></td>
                                    <td colspan="3"></td>
                                    <td class="text-center" style="color: black !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important;"> <span style="float:right;">Page 1 / 3</span></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-else>
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>


                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style="width:17% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder To
                                        </span>

                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع ل
                                        </span>

                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            الرجوع الى
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الفاتورةإلى
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            VAT #
                                        </span>
                                        <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                            الرقم الضربی
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Address
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            العنوان
                                        </span>
                                        <br />
                                        <br v-if="list.customerAddressWalkIn != null && list.customerAddressWalkIn.length > 51" />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                            Contact No
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                            رقم الاتصال
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Email
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            البريد الإلكتروني
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice for
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            العودة ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الــفـاتــورة لـ
                                        </span>

                                    </p>

                                </td>
                                <td style="width:34% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                            {{list.cashCustomer}}
                                        </span>
                                        <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                            {{list.customerNameEn}} {{ list.customerNameAr}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.customerVat}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.customerAddressWalkIn}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.mobile}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.email}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.description}}
                                        </span>

                                    </p>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP1" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:right;">-</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <!-- Page 2 -->
                <div v-if="headerFooter.continueWithPage">
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP2" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold; height:60mm;">
                                    <td colspan="2"></td>
                                    <td v-if="isMultiUnit=='true'"></td>
                                    <td colspan="3"></td>
                                    <td class="text-center" style="color: black !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important;"> <span style="float:right;">Page 2 / 3</span></td>
                                </tr>


                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-else>
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style="width:17% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder To
                                        </span>

                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            الرجوع الى
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الفاتورةإلى
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            VAT #
                                        </span>
                                        <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                            الرقم الضربی
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Address
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            العنوان
                                        </span>
                                        <br />
                                        <br v-if="list.customerAddressWalkIn != null && list.customerAddressWalkIn.length > 51" />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                            Contact No
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                            رقم الاتصال
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Email
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            البريد الإلكتروني
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice for
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            العودة ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الــفـاتــورة لـ
                                        </span>

                                    </p>

                                </td>
                                <td style="width:34% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                            {{list.cashCustomer}}
                                        </span>
                                        <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                            {{list.customerNameEn}} {{ list.customerNameAr}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.customerVat}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.customerAddressWalkIn}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.mobile}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.email}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.description}}
                                        </span>

                                    </p>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP2" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:right;">-</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <!-- Page 3 -->
                <div>
                    <!--HEADER-->
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>
                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>


                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP3" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>

                                <tr style="font-size:12px;font-weight:bold; height:50px;" v-for="(item, index) in emptyListCount" v-bind:key="index">
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-left" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 10px; color: white !important"><br /> <span style="font-size: 13px; font-weight: bold"></span></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateTotalExclVAT).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateDiscountAmount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(list.discount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateTotalVAT).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateNetTotal + list.discount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>

                                <tr v-if="termAndConditionLength">
                                    <td colspan="3" style="border: 0 !important; color: black !important; font-size: 14px; font-weight: bold; text-align: left;">Sold By  الـبائـع</td>
                                    <td colspan="3" style="border: 0 !important; color: black !important; font-size: 14px; font-weight: bold; text-align: right;">Received By  المستلم</td>
                                </tr>


                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important" v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div v-if="!termAndConditionLength" class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <div v-if="!termAndConditionLength">
                    <div class="row">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div class="row" v-if="list.termAndCondition != null && list.termAndCondition != '' && list.termAndConditionAr != null && list.termAndConditionAr != ''" style="height:290mm !important; color:black !important;padding-top:20px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" style="width: 50% !important;">
                                    <p class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                        Terms & conditions

                                    </p>
                                    <p style="padding:0px !important;margin:0px !important; ">
                                        <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 50% !important;">
                                    <p class="text-right" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                        الأحكام والشروط

                                    </p>
                                    <p style="padding:0px !important;margin:0px !important; ">
                                        <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-else class="row" style="height:290mm !important; color:black !important;padding-top:20px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;">

                        <div class="col-12" v-if="list.termAndCondition != null && list.termAndCondition != ''">
                            <p class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                Terms & conditions

                            </p>
                            <p style="padding:0px !important;margin:0px !important; ">
                                <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                            </p>
                        </div>
                        <div class="col-12" v-if="list.termAndConditionAr != null && list.termAndConditionAr != ''">
                            <p class="text-right" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                الأحكام والشروط

                            </p>
                            <p style="padding:0px !important;margin:0px !important; ">
                                <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                            </p>
                        </div>
                    </div>
                    <div class="row" style="height:10mm;">
                        <div class="col-md-12">
                            <table class="table">
                                <tr>
                                    <td style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;text-align:left; padding-left:10px;">
                                        Sold By  الـبائـع
                                    </td>
                                    <td style="color: black !important; font-size: 17px; font-weight: bold; border-top: 0 !important; text-align: right; padding-right: 10px;">
                                        <p>Received By  المستلم</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row footer " style="background-color:white !important" v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:15px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width: 100%; max-height:30mm; background-color: white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
            </div>


            <!--Invoice with less than 40 Items-->

            <div v-else-if="list.saleItems.length <= 40">
                <!-- Page 1 -->
                <div v-if="headerFooter.continueWithPage">
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>


                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style="width:17% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder To
                                        </span>

                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            الرجوع الى
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الفاتورةإلى
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            VAT #
                                        </span>
                                        <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                            الرقم الضربی
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Address
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            العنوان
                                        </span>
                                        <br />
                                        <br v-if="list.customerAddressWalkIn != null && list.customerAddressWalkIn.length > 51" />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                            Contact No
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                            رقم الاتصال
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Email
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            البريد الإلكتروني
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice for
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            العودة ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الــفـاتــورة لـ
                                        </span>

                                    </p>

                                </td>
                                <td style="width:34% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                            {{list.cashCustomer}}
                                        </span>
                                        <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                            {{list.customerNameEn}} {{ list.customerNameAr}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.customerVat}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.customerAddressWalkIn}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.mobile}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.email}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.description}}
                                        </span>

                                    </p>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>
                                <template v-for="(item, index) in listItemP1" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold; height:60mm;">
                                    <td colspan="2"></td>
                                    <td v-if="isMultiUnit=='true'"></td>
                                    <td colspan="3"></td>
                                    <td class="text-center" style="color: black !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important;"> <span style="float:right;">Page 1 / 4</span></td>
                                </tr>



                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-else>
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>


                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>
                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>
                                <template v-for="(item, index) in listItemP1" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:right;">-</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <!-- Page 2 -->
                <div v-if="headerFooter.continueWithPage">
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style="width:17% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder To
                                        </span>

                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            الرجوع الى
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الفاتورةإلى
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            VAT #
                                        </span>
                                        <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                            الرقم الضربی
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Address
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            العنوان
                                        </span>
                                        <br />
                                        <br v-if="list.customerAddressWalkIn != null && list.customerAddressWalkIn.length > 51" />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                            Contact No
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                            رقم الاتصال
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Email
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            البريد الإلكتروني
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice for
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            العودة ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الــفـاتــورة لـ
                                        </span>

                                    </p>

                                </td>
                                <td style="width:34% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                            {{list.cashCustomer}}
                                        </span>
                                        <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                            {{list.customerNameEn}} {{ list.customerNameAr}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.customerVat}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.customerAddressWalkIn}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.mobile}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.email}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.description}}
                                        </span>

                                    </p>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP2" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>

                                <tr style="font-weight:bold; height:60mm;">
                                    <td colspan="2"></td>
                                    <td v-if="isMultiUnit=='true'"></td>
                                    <td colspan="3"></td>
                                    <td class="text-center" style="color: black !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important;"> <span style="float:right;">Page 2 / 4</span></td>
                                </tr>



                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-else>
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP2" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:right;">-</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <!-- Page 3 -->
                <div v-if="headerFooter.continueWithPage">
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>


                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style="width:17% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder To
                                        </span>

                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            الرجوع الى
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الفاتورةإلى
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            VAT #
                                        </span>
                                        <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                            الرقم الضربی
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Address
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            العنوان
                                        </span>
                                        <br />
                                        <br v-if="list.customerAddressWalkIn != null && list.customerAddressWalkIn.length > 51" />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                            Contact No
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                            رقم الاتصال
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Email
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            البريد الإلكتروني
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice for
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            العودة ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الــفـاتــورة لـ
                                        </span>

                                    </p>

                                </td>
                                <td style="width:34% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                            {{list.cashCustomer}}
                                        </span>
                                        <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                            {{list.customerNameEn}} {{ list.customerNameAr}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.customerVat}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.customerAddressWalkIn}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.mobile}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.email}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.description}}
                                        </span>

                                    </p>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>
                                <template v-for="(item, index) in listItemP3" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>

                                <tr style="font-weight:bold; height:60mm;">
                                    <td colspan="2"></td>
                                    <td v-if="isMultiUnit=='true'"></td>
                                    <td colspan="3"></td>
                                    <td class="text-center" style="color: black !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important;"> <span style="float:right;">Page 3 / 4</span></td>
                                </tr>

                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-else>
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>


                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>
                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>
                                <template v-for="(item, index) in listItemP3" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:right;">-</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <!-- Page 4 -->
                <div>
                    <!--HEADER-->
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP4" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>

                                <tr style="font-size:12px;font-weight:bold; height:50px;" v-for="(item, index) in emptyListCount" v-bind:key="index">
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-left" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 10px; color: white !important"><br /> <span style="font-size: 13px; font-weight: bold"></span></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateTotalExclVAT).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateDiscountAmount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(list.discount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateTotalVAT).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateNetTotal + list.discount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>

                                <tr v-if="termAndConditionLength">
                                    <td colspan="3" style="border: 0 !important; color: black !important; font-size: 14px; font-weight: bold; text-align: left;">Sold By  الـبائـع</td>
                                    <td colspan="3" style="border: 0 !important; color: black !important; font-size: 14px; font-weight: bold; text-align: right;">Received By  المستلم</td>
                                </tr>

                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important" v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div v-if="!termAndConditionLength" class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <div v-if="!termAndConditionLength">
                    <div class="row">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div class="row" v-if="list.termAndCondition != null && list.termAndCondition != '' && list.termAndConditionAr != null && list.termAndConditionAr != ''" style="height:290mm !important; color:black !important;padding-top:20px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" style="width: 50% !important;">
                                    <p class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                        Terms & conditions

                                    </p>
                                    <p style="padding:0px !important;margin:0px !important; ">
                                        <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 50% !important;">
                                    <p class="text-right" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                        الأحكام والشروط

                                    </p>
                                    <p style="padding:0px !important;margin:0px !important; ">
                                        <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-else class="row" style="height:290mm !important; color:black !important;padding-top:20px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;">

                        <div class="col-12" v-if="list.termAndCondition != null && list.termAndCondition != ''">
                            <p class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                Terms & conditions

                            </p>
                            <p style="padding:0px !important;margin:0px !important; ">
                                <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                            </p>
                        </div>
                        <div class="col-12" v-if="list.termAndConditionAr != null && list.termAndConditionAr != ''">
                            <p class="text-right" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                الأحكام والشروط

                            </p>
                            <p style="padding:0px !important;margin:0px !important; ">
                                <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                            </p>
                        </div>
                    </div>
                    <div class="row" style="height:10mm;">
                        <div class="col-md-12">
                            <table class="table">
                                <tr>
                                    <td style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;text-align:left; padding-left:10px;">
                                        Sold By  الـبائـع
                                    </td>
                                    <td style="color: black !important; font-size: 17px; font-weight: bold; border-top: 0 !important; text-align: right; padding-right: 10px;">
                                        <p>Received By  المستلم</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row footer " style="background-color:white !important" v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:15px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width: 100%; max-height:30mm; background-color: white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
            </div>


            <!--Invoice with less than 50 Items-->

            <div v-else-if="list.saleItems.length <= 50">
                <!-- Page 1 -->
                <div v-if="headerFooter.continueWithPage">
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style="width:17% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder To
                                        </span>

                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            الرجوع الى
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الفاتورةإلى
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            VAT #
                                        </span>
                                        <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                            الرقم الضربی
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Address
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            العنوان
                                        </span>
                                        <br />
                                        <br v-if="list.customerAddressWalkIn != null && list.customerAddressWalkIn.length > 51" />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                            Contact No
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                            رقم الاتصال
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Email
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            البريد الإلكتروني
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice for
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            العودة ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الــفـاتــورة لـ
                                        </span>

                                    </p>

                                </td>
                                <td style="width:34% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                            {{list.cashCustomer}}
                                        </span>
                                        <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                            {{list.customerNameEn}} {{ list.customerNameAr}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.customerVat}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.customerAddressWalkIn}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.mobile}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.email}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.description}}
                                        </span>

                                    </p>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP1" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>

                                <tr style="font-weight:bold; height:60mm;">
                                    <td colspan="2"></td>
                                    <td v-if="isMultiUnit=='true'"></td>
                                    <td colspan="3"></td>
                                    <td class="text-center" style="color: black !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important;"> <span style="float:right;">Page 1 / 5</span></td>
                                </tr>

                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-else>
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>
                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP1" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:right;">-</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <!-- Page 2 -->
                <div v-if="headerFooter.continueWithPage">
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style="width:17% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder To
                                        </span>

                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            الرجوع الى
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الفاتورةإلى
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            VAT #
                                        </span>
                                        <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                            الرقم الضربی
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Address
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            العنوان
                                        </span>
                                        <br />
                                        <br v-if="list.customerAddressWalkIn != null && list.customerAddressWalkIn.length > 51" />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                            Contact No
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                            رقم الاتصال
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Email
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            البريد الإلكتروني
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice for
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            العودة ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الــفـاتــورة لـ
                                        </span>

                                    </p>

                                </td>
                                <td style="width:34% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                            {{list.cashCustomer}}
                                        </span>
                                        <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                            {{list.customerNameEn}} {{ list.customerNameAr}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.customerVat}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.customerAddressWalkIn}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.mobile}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.email}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.description}}
                                        </span>

                                    </p>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP2" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>

                                <tr style="font-weight:bold; height:60mm;">
                                    <td colspan="2"></td>
                                    <td v-if="isMultiUnit=='true'"></td>
                                    <td colspan="3"></td>
                                    <td class="text-center" style="color: black !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important;"> <span style="float:right;">Page 2 / 5</span></td>
                                </tr>


                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-else>
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP2" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:right;">-</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <!-- Page 3 -->
                <div v-if="headerFooter.continueWithPage">
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP3" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>

                                <tr style="font-weight:bold; height:60mm;">
                                    <td colspan="2"></td>
                                    <td v-if="isMultiUnit=='true'"></td>
                                    <td colspan="3"></td>
                                    <td class="text-center" style="color: black !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important;"> <span style="float:right;">Page 3 / 5</span></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-else>
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style="width:17% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder To
                                        </span>

                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            الرجوع الى
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الفاتورةإلى
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            VAT #
                                        </span>
                                        <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                            الرقم الضربی
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Address
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            العنوان
                                        </span>
                                        <br />
                                        <br v-if="list.customerAddressWalkIn != null && list.customerAddressWalkIn.length > 51" />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                            Contact No
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                            رقم الاتصال
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Email
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            البريد الإلكتروني
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice for
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            العودة ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الــفـاتــورة لـ
                                        </span>

                                    </p>

                                </td>
                                <td style="width:34% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                            {{list.cashCustomer}}
                                        </span>
                                        <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                            {{list.customerNameEn}} {{ list.customerNameAr}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.customerVat}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.customerAddressWalkIn}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.mobile}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.email}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.description}}
                                        </span>

                                    </p>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP3" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:right;">-</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <!-- Page 4 -->
                <div v-if="headerFooter.continueWithPage">
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>
                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP4" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>

                                <tr style="font-weight:bold; height:60mm;">
                                    <td colspan="2"></td>
                                    <td v-if="isMultiUnit=='true'"></td>
                                    <td colspan="3"></td>
                                    <td class="text-center" style="color: black !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important;"> <span style="float:right;">Page 4 / 5</span></td>
                                </tr>


                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-else>
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style="width:17% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder To
                                        </span>

                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            الرجوع الى
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الفاتورةإلى
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            VAT #
                                        </span>
                                        <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                            الرقم الضربی
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Address
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            العنوان
                                        </span>
                                        <br />
                                        <br v-if="list.customerAddressWalkIn != null && list.customerAddressWalkIn.length > 51" />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                            Contact No
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                            رقم الاتصال
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Email
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            البريد الإلكتروني
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice for
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            العودة ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الــفـاتــورة لـ
                                        </span>

                                    </p>

                                </td>
                                <td style="width:34% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                            {{list.cashCustomer}}
                                        </span>
                                        <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                            {{list.customerNameEn}} {{ list.customerNameAr}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.customerVat}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.customerAddressWalkIn}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.mobile}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.email}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.description}}
                                        </span>

                                    </p>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP4" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:right;">-</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <!-- Page 5 -->
                <div >
                    <!--HEADER-->
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>
                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP5" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>

                                <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-for="(item, index) in emptyListCount" v-bind:key="index">
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-left" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 10px; color: white !important"><br /> <span style="font-size: 13px; font-weight: bold"></span></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateTotalExclVAT).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateDiscountAmount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(list.discount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateTotalVAT).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateNetTotal + list.discount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr v-if="termAndConditionLength">
                                    <td colspan="3" style="border: 0 !important; color: black !important; font-size: 14px; font-weight: bold; text-align: left;">Sold By  الـبائـع</td>
                                    <td colspan="3" style="border: 0 !important; color: black !important; font-size: 14px; font-weight: bold; text-align: right;">Received By  المستلم</td>
                                </tr>


                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important" v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div v-if="!termAndConditionLength" class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <div v-if="!termAndConditionLength">
                    <div class="row">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div class="row" v-if="list.termAndCondition != null && list.termAndCondition != '' && list.termAndConditionAr != null && list.termAndConditionAr != ''" style="height:290mm !important; color:black !important;padding-top:20px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" style="width: 50% !important;">
                                    <p class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                        Terms & conditions

                                    </p>
                                    <p style="padding:0px !important;margin:0px !important; ">
                                        <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 50% !important;">
                                    <p class="text-right" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                        الأحكام والشروط

                                    </p>
                                    <p style="padding:0px !important;margin:0px !important; ">
                                        <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-else class="row" style="height:290mm !important; color:black !important;padding-top:20px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;">

                        <div class="col-12" v-if="list.termAndCondition != null && list.termAndCondition != ''">
                            <p class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                Terms & conditions

                            </p>
                            <p style="padding:0px !important;margin:0px !important; ">
                                <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                            </p>
                        </div>
                        <div class="col-12" v-if="list.termAndConditionAr != null && list.termAndConditionAr != ''">
                            <p class="text-right" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                الأحكام والشروط

                            </p>
                            <p style="padding:0px !important;margin:0px !important; ">
                                <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                            </p>
                        </div>
                    </div>
                    <div class="row" style="height:10mm;">
                        <div class="col-md-12">
                            <table class="table">
                                <tr>
                                    <td style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;text-align:left; padding-left:10px;">
                                        Sold By  الـبائـع
                                    </td>
                                    <td style="color: black !important; font-size: 17px; font-weight: bold; border-top: 0 !important; text-align: right; padding-right: 10px;">
                                        <p>Received By  المستلم</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row footer " style="background-color:white !important" v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:15px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width: 100%; max-height:30mm; background-color: white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
            </div>


            <!--Invoice with less than 60 Items-->

            <div v-else-if="list.saleItems.length <= 60">
                <!-- Page 1 -->
                <div v-if="headerFooter.continueWithPage">
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>
                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP1" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>

                                <tr style="font-weight:bold; height:60mm;">
                                    <td colspan="2"></td>
                                    <td v-if="isMultiUnit=='true'"></td>
                                    <td colspan="3"></td>
                                    <td class="text-center" style="color: black !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important;"> <span style="float:right;">Page 1 / 6</span></td>
                                </tr>



                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-else>
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style="width:17% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder To
                                        </span>

                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            الرجوع الى
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الفاتورةإلى
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            VAT #
                                        </span>
                                        <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                            الرقم الضربی
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Address
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            العنوان
                                        </span>
                                        <br />
                                        <br v-if="list.customerAddressWalkIn != null && list.customerAddressWalkIn.length > 51" />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                            Contact No
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                            رقم الاتصال
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Email
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            البريد الإلكتروني
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice for
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            العودة ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الــفـاتــورة لـ
                                        </span>

                                    </p>

                                </td>
                                <td style="width:34% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                            {{list.cashCustomer}}
                                        </span>
                                        <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                            {{list.customerNameEn}} {{ list.customerNameAr}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.customerVat}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.customerAddressWalkIn}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.mobile}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.email}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.description}}
                                        </span>

                                    </p>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP1" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:right;">-</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <!-- Page 2 -->
                <div v-if="headerFooter.continueWithPage">
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style="width:17% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder To
                                        </span>

                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            الرجوع الى
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الفاتورةإلى
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            VAT #
                                        </span>
                                        <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                            الرقم الضربی
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Address
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            العنوان
                                        </span>
                                        <br />
                                        <br v-if="list.customerAddressWalkIn != null && list.customerAddressWalkIn.length > 51" />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                            Contact No
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                            رقم الاتصال
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Email
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            البريد الإلكتروني
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice for
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            العودة ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الــفـاتــورة لـ
                                        </span>

                                    </p>

                                </td>
                                <td style="width:34% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                            {{list.cashCustomer}}
                                        </span>
                                        <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                            {{list.customerNameEn}} {{ list.customerNameAr}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.customerVat}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.customerAddressWalkIn}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.mobile}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.email}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.description}}
                                        </span>

                                    </p>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP2" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>

                                <tr style="font-weight:bold; height:60mm;">
                                    <td colspan="2"></td>
                                    <td v-if="isMultiUnit=='true'"></td>
                                    <td colspan="3"></td>
                                    <td class="text-center" style="color: black !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important;"> <span style="float:right;">Page 2 / 6</span></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-else>
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>
                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP2" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:right;">-</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <!-- Page 3 -->
                <div v-if="headerFooter.continueWithPage">
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style="width:17% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder To
                                        </span>

                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            الرجوع الى
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الفاتورةإلى
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            VAT #
                                        </span>
                                        <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                            الرقم الضربی
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Address
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            العنوان
                                        </span>
                                        <br />
                                        <br v-if="list.customerAddressWalkIn != null && list.customerAddressWalkIn.length > 51" />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                            Contact No
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                            رقم الاتصال
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Email
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            البريد الإلكتروني
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice for
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            العودة ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الــفـاتــورة لـ
                                        </span>

                                    </p>

                                </td>
                                <td style="width:34% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                            {{list.cashCustomer}}
                                        </span>
                                        <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                            {{list.customerNameEn}} {{ list.customerNameAr}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.customerVat}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.customerAddressWalkIn}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.mobile}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.email}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.description}}
                                        </span>

                                    </p>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP3" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold; height:60mm;">
                                    <td colspan="2"></td>
                                    <td v-if="isMultiUnit=='true'"></td>
                                    <td colspan="3"></td>
                                    <td class="text-center" style="color: black !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important;"> <span style="float:right;">Page 3 / 6</span></td>
                                </tr>



                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-else>
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP3" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:right;">-</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <!-- Page 4 -->
                <div v-if="headerFooter.continueWithPage">
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style="width:17% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder To
                                        </span>

                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            الرجوع الى
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الفاتورةإلى
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            VAT #
                                        </span>
                                        <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                            الرقم الضربی
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Address
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            العنوان
                                        </span>
                                        <br />
                                        <br v-if="list.customerAddressWalkIn != null && list.customerAddressWalkIn.length > 51" />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                            Contact No
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                            رقم الاتصال
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Email
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            البريد الإلكتروني
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice for
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            العودة ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الــفـاتــورة لـ
                                        </span>

                                    </p>

                                </td>
                                <td style="width:34% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                            {{list.cashCustomer}}
                                        </span>
                                        <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                            {{list.customerNameEn}} {{ list.customerNameAr}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.customerVat}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.customerAddressWalkIn}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.mobile}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.email}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.description}}
                                        </span>

                                    </p>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>
                                <template v-for="(item, index) in listItemP4" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold; height:60mm;">
                                    <td colspan="2"></td>
                                    <td v-if="isMultiUnit=='true'"></td>
                                    <td colspan="3"></td>
                                    <td class="text-center" style="color: black !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important;"> <span style="float:right;">Page 4 / 6</span></td>
                                </tr>



                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-else>
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>
                                <template v-for="(item, index) in listItemP4" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:right;">-</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <!-- Page 5 -->
                <div v-if="headerFooter.continueWithPage">
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style="width:17% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder To
                                        </span>

                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice To
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            الرجوع الى
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الفاتورةإلى
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            VAT #
                                        </span>
                                        <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                            الرقم الضربی
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Address
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            العنوان
                                        </span>
                                        <br />
                                        <br v-if="list.customerAddressWalkIn != null && list.customerAddressWalkIn.length > 51" />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                            Contact No
                                        </span>
                                        <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                            رقم الاتصال
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                            Email
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                            البريد الإلكتروني
                                        </span>
                                        <br />
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                            Quotation for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                            SaleOrder for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                            Return for
                                        </span>
                                        <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                            Invoice for
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                            اقتباس لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                            أمر بيع لـ
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                            العودة ل
                                        </span>
                                        <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                            الــفـاتــورة لـ
                                        </span>

                                    </p>

                                </td>
                                <td style="width:34% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <p style="margin:20px 0 0 0 !important">
                                        <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                            {{list.cashCustomer}}
                                        </span>
                                        <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                            {{list.customerNameEn}} {{ list.customerNameAr}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.customerVat}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.customerAddressWalkIn}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.mobile}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                            {{list.email}}
                                        </span>
                                        <br />
                                        <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                            {{list.description}}
                                        </span>

                                    </p>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP5" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold; height:60mm;">
                                    <td colspan="2"></td>
                                    <td v-if="isMultiUnit=='true'"></td>
                                    <td colspan="3"></td>
                                    <td class="text-center" style="color: black !important; font-size: 16px; padding-top: 4px !important; padding-bottom: 4px !important;"> <span style="float:right;">Page 5 / 6</span></td>
                                </tr>


                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
                <div v-else>
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder'">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP5" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>


                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:right;">-</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "> <span style="float:right;">-</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>




                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important; " v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <!-- Page 6 -->
                <div>
                    <!--HEADER-->
                    <div class="row" style="background-color:white !important" v-if="headerFooters.headerImage!='' && headerFooters.headerImage!=null && headerFooters.headerImage!=undefined">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div style="height:40mm;background-color:white !important" v-else></div>

                    <div class="row m-0" style="background-color:white !important; padding:2px 0 0 0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" colspan="3" style="width: 67% !important;">
                                    <p style="margin:0 !important">
                                        <span style="font-size:16px;color:black !important;font-weight:bold;margin-bottom:0!important;text-transform:capitalize;margin-bottom:5px;background-color:white !important">  WE ARE IMPROVING YOUR BUSINESS</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;padding:0 !important; background-color:white !important">  {{headerFooters.company.addressArabic}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.addressEnglish}}</span><br />
                                        <span style="font-size:11px;color:black !important;margin:0!important;background-color:white !important">  {{headerFooters.company.phoneNo}} &nbsp;&nbsp; Email: {{headerFooters.company.companyEmail}}</span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 33% !important;">
                                    <p style="text-align:right; margin:0 !important">
                                        <span style="font-size:15px;color:black !important;">
                                            VAT No. <span> {{headerFooters.company.vatRegistrationNo}}</span> <span>الرقم الضريبي</span>
                                        </span>



                                    </p>
                                    <div class="text-right">
                                        <barcode :width="1.5" :height="40" :displayValue="false" v-bind:value="list.barCode"></barcode>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:51% !important;" class="text-left pt-0 pb-0 pl-0 pr-0 m-0">
                                    <table class="table mt-3">
                                        <tr>
                                            <td class="p-0" style="width: 35% !important;">
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice To
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    الرجوع الى
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الفاتورةإلى
                                                </span>
                                            </td>
                                            <td class="p-0" style="width: 65% !important;">
                                                <span v-if="list.cashCustomer != null" style="padding: 0px !important; font-weight: bold;">
                                                    {{list.cashCustomer}}
                                                </span>
                                                <span v-else style="padding: 0px !important; font-size:12px; font-weight: bold;">
                                                    {{list.customerNameEn}} {{ list.customerNameAr}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    VAT #
                                                </span>
                                                <span style="font-weight: normal;padding-left: 2px; font-size: 11px">
                                                    الرقم الضربی
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.customerVat}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 12px">
                                                    Contact No
                                                </span>
                                                <span style="font-weight: normal; padding-left: 0px; font-size: 10px">
                                                    رقم الاتصال
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.mobile}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Address
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    العنوان
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.customerAddressWalkIn}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px">
                                                    Email
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px">
                                                    البريد الإلكتروني
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 12px !important; font-weight: bold;">
                                                    {{list.email}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-if="formName=='Quotation'">
                                                    Quotation for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleOrder'">
                                                    SaleOrder for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else-if="formName=='SaleReturn'">
                                                    Return for
                                                </span>
                                                <span style="font-weight: normal;text-align:left; padding-left: 0px; font-size: 13px" v-else>
                                                    Invoice for
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-if="formName=='Quotation'">
                                                    اقتباس لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleOrder'">
                                                    أمر بيع لـ
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else-if="formName=='SaleReturn'">
                                                    العودة ل
                                                </span>
                                                <span style="font-weight: normal; padding-left: 2px; font-size: 11px" v-else>
                                                    الــفـاتــورة لـ
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span style="padding: 0px !important;  font-size: 10px !important; font-weight: bold;">
                                                    {{list.description}}
                                                </span>
                                            </td>
                                        </tr>

                                    </table>

                                </td>
                                <td style="width: 16% !important; " class="text-left pt-0 pb-0 pl-0 pr-0">
                                    <div class=" text-center " style="border: 1px solid black;margin-top:20px;" v-if="list.poNumber!='' && list.poNumber!=null">
                                        <p>
                                            <span>PO No.</span> <span style="font-size:11px !important;">الرقم الطلب</span><br />
                                            <span>{{list.poNumber}}</span><br />
                                            <span>Date</span><span>التاريخ </span><br />
                                            <span>{{getDate(list.poDate)}}</span><br />
                                        </p>
                                    </div>

                                </td>

                                <td style="width: 33% !important; " class="text-right pt-0 pb-0 pl-0 pr-0">
                                    <div style="margin:0px 0 0 0 !important">
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==2">Credit Invoice&nbsp;&nbsp; فاتورة أجلة </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==1">Cash Invoice&nbsp;&nbsp; الفاتورة النقدية  </span>
                                        <span style="font-size: 14px; color: black !important; margin-bottom: 0; margin-top: 0px !important;font-weight: bold;" v-if="list.invoiceType==0">Hold Invoice&nbsp;&nbsp;  عقد الفاتورة </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'" />
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn'">
                                            <span v-if="b2b && b2c" style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold">
                                                <span v-if="list.customerCategory=='B2B – Business to Business'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else-if="list.customerCategory=='B2C – Business to Client'">
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                                <span v-else>
                                                    <span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span>
                                                    <span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span>
                                                </span>
                                            </span>

                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2b"><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{taxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else-if="b2c"><span>&nbsp;&nbsp;&nbsp;B2C TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>
                                            <span style="font-size:14px;color:black !important;margin-bottom:0;font-weight:bold" v-else><span>&nbsp;&nbsp;&nbsp;B2B TAX Invoice-</span><span>&nbsp;&nbsp;  {{simplifyTaxInvoiceLabelAr}}</span></span>

                                        </span>
                                        <br v-if="formName !='Quotation' && formName !='SaleOrder' && formName !='SaleReturn' && formName !='SaleReturn'" />

                                        <p v-if="formName=='Quotation' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Quotation No.  الرقم  عرض الأسعار</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleOrder' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding:0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">SaleOrder No.  الرقم  طلب المبيعات</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <p v-else-if="formName=='SaleReturn' " style="color: #FFFFFF !important; background-color: #E5A72E !important; margin: 0 !important; padding: 0 !important;">

                                            <span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">Date </span><span style="font-size: 16px; color: black !important; margin-bottom: 0 !important; ">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin: 0 !important; padding: 0 !important; font-weight: bold;">Return Inv. NO.  الرقم الفاتورة المسترجع</span> <span style="font-size: 16px; color: black !important; margin: 0 !important; ">{{list.registrationNo}}</span>

                                        </p>
                                        <span v-else>

                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Date </span><span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.date}}</span> <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">التاريخ  </span>

                                            <br />
                                            <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">Invoice No.&nbsp;&nbsp;&nbsp;  الرقم الفاتورة</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size: 14px; color: black !important; margin-bottom: 0 !important; font-weight: bold;">{{list.registrationNo}}</span>

                                        </span>
                                        <br />
                                        <span>
                                            <img :src="'data:image/png;base64,' + list.warrantyLogoPath" style="width: 150px; max-width: 150px; height: 100px; margin: 5px 0 0 0 !important">
                                        </span>
                                        <span v-if="formName !='Quotation' && formName !='SaleOrder' ">
                                            <vue-qrcode v-bind:value="qrValue" style="width: 120px; height:100px; margin: 5px 0 0 0 !important;" />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="row mt-2 " style="background-color:white !important">
                        <div class="col-md-12 " style="background-color:white !important">
                            <table class="table " style="border-left: 0 !important;border-bottom: 0 !important; height:60mm;background-color:white !important;margin-bottom:0px !important">
                                <tr class="heading" style="font-size:13px !important;">
                                    <th class="text-center" style="width: 3%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">#</th>
                                    <th class="text-center" style="width: 45%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Product Name <br /> اسم الصنف</th>
                                    <th class="text-center" style="width: 12%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Model/Style <br /> رقم الموديل</th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Qty <br /> الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; " v-if="isMultiUnit=='true'">Total Qty <br /> إجمالي الكمية </th>
                                    <th class="text-center" style="width: 8%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">U.Price <br /> سعرالوحدة</th>
                                    <th class="text-center" style="width: 17%; color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; color: #FFFFFF !important; background-color: #F46E0B !important; border: 0.5px solid #000000; ">Total Price <br /> الاجمالي </th>
                                </tr>

                                <template v-for="(item, index) in listItemP6" style="background-color: white !important; ">
                                    <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-if="index< 10" v-bind:key="item.id">
                                        <td class="text-center" style="color:black !important;padding-top:2px !important; padding-bottom:4px !important;border:0.5px solid #63666A;">{{index+1}}</td>
                                        <td class="text-left" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 9px;">{{item.productName}} <br /> <span style="font-size: 9px; font-weight:normal">{{item.description == null?'':item.description.slice(0,250)}}</span></td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.styleNumber!=null? item.styleNumber : ''}}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-else>{{item.isFree? (item.quantity >0? saleReturnInvoice(item.quantity) : '-') :  saleReturnInvoice(item.quantity) }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important" v-if="isMultiUnit=='true'">{{item.isFree? (item.quantity >0? item.quantity : '-') :  item.quantity }}</td>
                                        <td class="text-center" style="color: black !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-weight: normal !important">{{item.isFree? (item.unitPrice>0? item.unitPrice.toFixed(3).slice(0,-1) : '-') : item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                        <td class="text-center" style="color: black !important; background-color: #dfdfdd !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; " v-if="item.isFree"><span style="float:left;"></span> <span style="float:right;">{{'-'}}</span></td>
                                        <td class="text-center" style="color: black !important; background-color: white !important; padding-top: 2px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-else><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(item.total.toFixed(3)).slice(0,-1)}}</span></td>
                                    </tr>
                                </template>

                                <tr style="font-size: 12px; font-weight: bold; height: 55px !important;" v-for="(item, index) in emptyListCount" v-bind:key="index">
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-left" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A; font-size: 10px; color: white !important"><br /> <span style="font-size: 13px; font-weight: bold"></span></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                    <td class="text-center" style="color: black !important; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #63666A;"></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td class="text-center" style="color: #FFFFFF !important; background-color: #F46E0B !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0.5px solid #000000; font-weight: bolder !important;" colspan="2">{{headerFooters.bankIcon1}} Bank Details / {{headerFooters.bankAccount1}}</td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-left" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3"> <span class="float-right">Total Amount/المبلغ الإجمالي</span> </td>
                                    <td class="text-center" style="color:black !important;background-color: #F5E2A5 !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0.5px solid #000000;"><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateTotalExclVAT).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-if="termAndConditionLength">Terms & conditions   الأحكام والشروط</td>
                                    <td class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:13px!important;border:0!important;padding-left:0 !important;" colspan="2" v-else></td>
                                    <td class="text-center" style="color: black !important; padding-top: 8px !important; padding-bottom: 8px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                                    <td class="text-right" style="color:black !important;font-size:15px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Discount/الخصم</td>
                                    <td class="text-center" style="color: black !important; background-color: #FFDE16 !important; font-size: 15px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateDiscountAmount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>

                                <tr style="font-weight:bold;">
                                    <td v-if="termAndConditionLength" class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:11px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                            <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                        </p>
                                    </td>
                                    <td v-else class="text-left" style=" color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;" colspan="2" rowspan="5">
                                        <p style="padding:0px !important;margin:0px !important; ">
                                            <span style="padding:0px !important;margin:0px !important;">Terms And Conditions On last Page</span><br />
                                            <span style="padding:0px !important;margin:0px !important;">الشروط والأحكام في الصفحة الأخيرة</span>
                                        </p>
                                    </td>

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Adjustment / تعديل</td>
                                    <td class="text-center" style="color: black !important; background-color: #E5A72E !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(list.discount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">

                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">VAT  /ضريبة القيمة المضاف</td>
                                    <td class="text-center" style="color: black !important; background-color: #D67728 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateTotalVAT).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>
                                <tr style="font-weight:bold;">
                                    <td class="text-right" style="color:black !important;font-size:12px;padding-top:4px !important; padding-bottom:4px !important;border:0!important" colspan="3">Grand Total / المبلخ الاجمالي</td>
                                    <td class="text-center" style="color: black !important; background-color: #969798 !important; font-size: 14px; padding-top: 4px !important; padding-bottom: 4px !important; border: 0.5px solid #000000; "><span style="float:left;">{{currency}}</span> <span style="float:right;">{{saleReturnInvoice(parseFloat(calulateNetTotal + list.discount).toFixed(3).slice(0,-1)).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                                </tr>

                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border:0 !important"></td>
                                    <td style="border:0 !important"></td>
                                </tr>
                                <tr v-if="termAndConditionLength">
                                    <td colspan="3" style="border: 0 !important; color: black !important; font-size: 14px; font-weight: bold; text-align: left;">Sold By  الـبائـع</td>
                                    <td colspan="3" style="border: 0 !important; color: black !important; font-size: 14px; font-weight: bold; text-align: right;">Received By  المستلم</td>
                                </tr>


                            </table>
                        </div>
                    </div>

                    <div class="row " style="background-color:white !important" v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:5px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width:100%; max-height:30mm;background-color:white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>

                <div v-if="!termAndConditionLength" class="pagebreak" style="margin-bottom: 0; margin-top: 0;"></div>
                <div v-if="!termAndConditionLength">
                    <div class="row">
                        <div class="col-sm-12 " style="background-color:white !important">
                            <img :src="'data:image/png;base64,' + headerFooters.headerImage" style="width:100%; max-height:200px;">
                        </div>
                    </div>
                    <div class="row" v-if="list.termAndCondition != null && list.termAndCondition != '' && list.termAndConditionAr != null && list.termAndConditionAr != ''" style="height:290mm !important; color:black !important;padding-top:20px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;">
                        <table class="table table-borderless pb-0 m-0">
                            <tr class="p-0 m-0">
                                <td class="p-0 m-0" style="width: 50% !important;">
                                    <p class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                        Terms & conditions

                                    </p>
                                    <p style="padding:0px !important;margin:0px !important; ">
                                        <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                                    </p>
                                </td>
                                <td class="p-0 m-0" style="width: 50% !important;">
                                    <p class="text-right" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                        الأحكام والشروط

                                    </p>
                                    <p style="padding:0px !important;margin:0px !important; ">
                                        <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-else class="row" style="height:290mm !important; color:black !important;padding-top:20px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;">

                        <div class="col-12" v-if="list.termAndCondition != null && list.termAndCondition != ''">
                            <p class="text-left" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                Terms & conditions

                            </p>
                            <p style="padding:0px !important;margin:0px !important; ">
                                <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndCondition"></span>
                            </p>
                        </div>
                        <div class="col-12" v-if="list.termAndConditionAr != null && list.termAndConditionAr != ''">
                            <p class="text-right" style="color:black !important;padding-top:4px !important; padding-bottom:4px !important;font-size:14px!important;border:0!important;padding-left:0 !important;font-weight:bold;">
                                الأحكام والشروط

                            </p>
                            <p style="padding:0px !important;margin:0px !important; ">
                                <span style="padding:0px !important;margin:0px !important;" v-html="list.termAndConditionAr"></span>
                            </p>
                        </div>
                    </div>
                    <div class="row" style="height:10mm;">
                        <div class="col-md-12">
                            <table class="table">
                                <tr>
                                    <td style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;text-align:left; padding-left:10px;">
                                        Sold By  الـبائـع
                                    </td>
                                    <td style="color: black !important; font-size: 17px; font-weight: bold; border-top: 0 !important; text-align: right; padding-right: 10px;">
                                        <p>Received By  المستلم</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row footer " style="background-color:white !important" v-if="headerFooters.footerImage!='' && headerFooters.footerImage!=null && headerFooters.footerImage!=undefined">
                        <div class="col-sm-12  " style="background-color: white !important;margin-top:15px !important;">
                            <img :src="'data:image/png;base64,' + headerFooters.footerImage" style="width: 100%; max-height:30mm; background-color: white !important">
                        </div>
                    </div>
                    <div style="height:30mm;background-color:white !important" v-else></div>
                </div>
            </div>


        </div>

    </div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    import VueQrcode from 'vue-qrcode'
    import VueBarcode from 'vue-barcode';

    export default {
        components: {
            VueQrcode,
            'barcode': VueBarcode,
        },
        props: ['printDetails', 'headerFooter', 'isTouchScreen', 'formName'],
        mixins: [clickMixin],
        data: function () {
            return {
                b2b: false,
                b2c: false,
                termAndConditionLength: false,
                taxInvoiceLabel: "",
                taxInvoiceLabelAr: '',
                simplifyTaxInvoiceLabel: '',
                simplifyTaxInvoiceLabelAr: '',

                currency: "",
                qrValue: "",
                isHeaderFooter: '',
                invoicePrint: '',
                IsDeliveryNote: '',
                arabic: '',
                english: '',
                userName: '',
                emptyListCount: 0,
                indexCount: 0,
                page: 0,
                isMultiUnit: '',
                list: {
                    number: 0,
                    listItemTotal: [],
                    registrationNo: '',
                    date: '',
                    dueDate: '',
                    companyName: '',
                    companyPhoneNo: '',
                    companyAddress: '',
                    discountAmount: '',
                    cashCustomer: '',
                    creditCustomer: '',
                    customerPhoneNo: '',
                    customerAddress: '',
                    paymentMethod: '',
                    paymentMethodNo: '',
                    invocieType: '',
                    saleItems:
                        [

                        ]
                },

                listItemP1Summary: {
                    calulateTotalQty: 0,
                    calulateNetTotal: 0,
                    calulateTotalExclVAT: 0,
                    calulateTotalVAT: 0,
                    calulateTotalInclusiveVAT: 0,
                    calulateDiscountAmount: 0,
                    calulateBundleAmount: 0,
                },
                listItemP2Summary: {
                    calulateTotalQty: 0,
                    calulateNetTotal: 0,
                    calulateTotalExclVAT: 0,
                    calulateTotalVAT: 0,
                    calulateTotalInclusiveVAT: 0,
                    calulateDiscountAmount: 0,
                    calulateBundleAmount: 0,
                },
                listItemP3Summary: {
                    calulateTotalQty: 0,
                    calulateNetTotal: 0,
                    calulateTotalExclVAT: 0,
                    calulateTotalVAT: 0,
                    calulateTotalInclusiveVAT: 0,
                    calulateDiscountAmount: 0,
                    calulateBundleAmount: 0,
                },
                listItemP4Summary: {
                    calulateTotalQty: 0,
                    calulateNetTotal: 0,
                    calulateTotalExclVAT: 0,
                    calulateTotalVAT: 0,
                    calulateTotalInclusiveVAT: 0,
                    calulateDiscountAmount: 0,
                    calulateBundleAmount: 0,
                },
                listItemP5Summary: {
                    calulateTotalQty: 0,
                    calulateNetTotal: 0,
                    calulateTotalExclVAT: 0,
                    calulateTotalVAT: 0,
                    calulateTotalInclusiveVAT: 0,
                    calulateDiscountAmount: 0,
                    calulateBundleAmount: 0,
                },
                listItemP1: [],
                listItemP2: [],
                listItemP3: [],
                listItemP4: [],
                listItemP5: [],
                listItemP6: [],
                itemTotal: 0,
                render: 0,
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
                htmlData: {
                    htmlString: ''
                }
            }
        },
        filters: {
            toWords: function (value) {
                var converter = require('number-to-words');
                if (!value) return ''
                return converter.toWords(value);
            }
        },
        computed: {
            calulateTotalQty: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (Number((c.quantity) || 0) > 0 ? Number((c.quantity) || 0) : 0) }, 0)
            },
            calulateNetTotal: function () {

                var withDisc = this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : c.total - c.discountAmount) }, 0)
                var totalIncDisc = (this.list.isBeforeTax && this.list.isDiscountOnTransaction && this.list.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(this.list.transactionLevelDiscount) * (withDisc) / 100) : parseFloat(this.calulateDiscountAmount)
                var val = this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total + c.includingVat) || 0)) }, 0) + parseFloat((this.list.taxMethod == ("Inclusive" || "شامل") ? 0 : this.calulateTotalVAT)) - totalIncDisc
                return val;
            },
            calulateTotalExclVAT: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total - c.discountAmount) || 0)) }, 0)
            },
            calulateTotalVAT: function () {
                var totalQtyWithotFree = this.list.saleItems.reduce((qty, prod) => qty + (prod.isFree ? 0 : parseInt(prod.quantity == '' ? 0 : prod.quantity)), 0);
                var paidVat = this.list.saleItems
                    .reduce((vat, prod) => (vat + (prod.isFree ? 0 : ((this.list.taxMethod == ("Inclusive" || "شامل")) ? ((parseFloat(prod.total - prod.discountAmount) - (this.list.isBeforeTax ? (((prod.quantity * prod.unitPrice) * this.list.transactionLevelDiscount) / 100) : 0)) * prod.taxRate) / (100 + prod.taxRate) : ((parseFloat(prod.total - prod.discountAmount) - (this.list.isBeforeTax && !this.list.isFixed && this.list.isDiscountOnTransaction ? (((prod.quantity * prod.unitPrice) * this.list.transactionLevelDiscount) / 100) : (this.list.isBeforeTax && this.list.isFixed && this.list.isDiscountOnTransaction ? (this.list.transactionLevelDiscount / parseFloat(totalQtyWithotFree) * prod.quantity) : 0))) * prod.taxRate) / 100))), 0).toFixed(3).slice(0, -1)

                return paidVat;
            },
            calulateDiscountAmount: function () {
                var totalIncDisc = 0;
                if (this.list.isDiscountOnTransaction) {
                    var withDisc = this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : c.total - c.discountAmount) }, 0)

                    var discountForInclusiveVat = parseFloat(this.list.saleItems
                        .reduce((vat, prod) => (vat + (prod.isFree ? 0 : ((this.list.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(prod.total) * prod.taxRate) / (100 + prod.taxRate) : 0))), 0).toFixed(3).slice(0, -1))

                    totalIncDisc = ((this.list.isBeforeTax && this.list.isDiscountOnTransaction) ? (this.list.taxMethod == ("Inclusive" || "شامل") ? (parseFloat(this.list.transactionLevelDiscount) * (withDisc - discountForInclusiveVat) / 100) : (this.list.isFixed ? parseFloat(this.list.transactionLevelDiscount) : parseFloat(this.list.transactionLevelDiscount) * withDisc / 100)) : (this.list.isFixed ? parseFloat(this.list.transactionLevelDiscount) : (parseFloat(withDisc) + (this.list.taxMethod == ("Inclusive" || "شامل") ? 0 : parseFloat(this.calulateTotalVAT))) * parseFloat(this.list.transactionLevelDiscount) / 100)).toFixed(3).slice(0, -1)

                }
                else {
                    totalIncDisc = this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : c.discountAmount) }, 0)
                }

                return totalIncDisc;
            }
        },
        methods: {
            calulateNetTotalForQr: function () {
                var withDisc = this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : c.total - c.discountAmount) }, 0)
                var totalIncDisc = (this.list.isBeforeTax && this.list.isDiscountOnTransaction && this.list.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(this.list.transactionLevelDiscount) * (withDisc) / 100) : parseFloat(this.calulateDiscountAmount)

                return (this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total + c.includingVat) || 0)) }, 0) + parseFloat((this.list.taxMethod == ("Inclusive" || "شامل") ? 0 : this.calulateTotalVAT)) - totalIncDisc).toFixed(3);
            },
            calulateTotalVATForQr: function () {
                var totalQtyWithotFree = this.list.saleItems.reduce((qty, prod) => qty + (prod.isFree ? 0 : parseInt(prod.quantity == '' ? 0 : prod.quantity)), 0);
                var paidVat = this.list.saleItems
                    .reduce((vat, prod) => (vat + (prod.isFree ? 0 : ((this.list.taxMethod == ("Inclusive" || "شامل")) ? ((parseFloat(prod.total - prod.discountAmount) - (this.list.isBeforeTax ? (((prod.quantity * prod.unitPrice) * this.list.transactionLevelDiscount) / 100) : 0)) * prod.taxRate) / (100 + prod.taxRate) : ((parseFloat(prod.total - prod.discountAmount) - (this.list.isBeforeTax && !this.list.isFixed && this.list.isDiscountOnTransaction ? (((prod.quantity * prod.unitPrice) * this.list.transactionLevelDiscount) / 100) : (this.list.isBeforeTax && this.list.isFixed && this.list.isDiscountOnTransaction ? (this.list.transactionLevelDiscount / parseFloat(totalQtyWithotFree) * prod.quantity) : 0))) * prod.taxRate) / 100))), 0).toFixed(3).slice(0, -1)

                return parseFloat(paidVat).toFixed(3);
            },

            saleReturnInvoice: function (data) {
                if (this.formName == 'SaleReturn')
                    return '-' + (data)
                else
                    return data
            },
            getDate: function (x) {
                if (x == null || x == undefined)
                    return '';
                else
                    return moment(x).format('l');
            },
            calulateTotalQtySub: function (val) {
                return val.reduce(function (a, c) { return a + (Number((c.quantity) || 0) > 0 ? Number((c.quantity) || 0) : 0) }, 0)
            },
            calulateNetTotalSub: function (val) {
                var withDisc = val.reduce(function (a, c) { return a + (c.isFree ? 0 : c.total - c.discountAmount) }, 0)
                var totalIncDisc = (this.list.isBeforeTax && this.list.isDiscountOnTransaction && this.list.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(this.list.transactionLevelDiscount) * (withDisc) / 100) : parseFloat(this.calulateDiscountAmount)

                return val.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total + c.includingVat) || 0)) }, 0) + parseFloat((this.list.taxMethod == ("Inclusive" || "شامل") ? 0 : this.calulateTotalVAT)) - totalIncDisc
            },
            calulateTotalExclVATSub: function (val) {
                return val.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total - c.discountAmount) || 0)) }, 0)
            },
            calulateTotalVATSub: function (val) {
                var totalQtyWithotFree = val.reduce((qty, prod) => qty + (prod.isFree ? 0 : parseInt(prod.quantity == '' ? 0 : prod.quantity)), 0);
                var paidVat = val
                    .reduce((vat, prod) => (vat + (prod.isFree ? 0 : ((this.list.taxMethod == ("Inclusive" || "شامل")) ? ((parseFloat(prod.total - prod.discountAmount) - (this.list.isBeforeTax ? (((prod.quantity * prod.unitPrice) * this.list.transactionLevelDiscount) / 100) : 0)) * prod.taxRate) / (100 + prod.taxRate) : ((parseFloat(prod.total - prod.discountAmount) - (this.list.isBeforeTax && !this.list.isFixed && this.list.isDiscountOnTransaction ? (((prod.quantity * prod.unitPrice) * this.list.transactionLevelDiscount) / 100) : (this.list.isBeforeTax && this.list.isFixed && this.list.isDiscountOnTransaction ? (this.list.transactionLevelDiscount / parseFloat(totalQtyWithotFree) * prod.quantity) : 0))) * prod.taxRate) / 100))), 0).toFixed(3).slice(0, -1)

                return paidVat;
            },
            calulateDiscountAmountSub: function (val) {
                var totalIncDisc = 0;
                if (this.list.isDiscountOnTransaction) {
                    var withDisc = val.reduce(function (a, c) { return a + (c.isFree ? 0 : c.total - c.discountAmount) }, 0)

                    var discountForInclusiveVat = parseFloat(val
                        .reduce((vat, prod) => (vat + (prod.isFree ? 0 : ((this.list.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(prod.total) * prod.taxRate) / (100 + prod.taxRate) : 0))), 0).toFixed(3).slice(0, -1))

                    totalIncDisc = ((this.list.isBeforeTax && this.list.isDiscountOnTransaction) ? (this.list.taxMethod == ("Inclusive" || "شامل") ? (parseFloat(this.list.transactionLevelDiscount) * (withDisc - discountForInclusiveVat) / 100) : (this.list.isFixed ? parseFloat(this.list.transactionLevelDiscount) : parseFloat(this.list.transactionLevelDiscount) * withDisc / 100)) : (this.list.isFixed ? parseFloat(this.list.transactionLevelDiscount) : (parseFloat(withDisc) + (this.list.taxMethod == ("Inclusive" || "شامل") ? 0 : parseFloat(this.calulateTotalVAT))) * parseFloat(this.list.transactionLevelDiscount) / 100)).toFixed(3).slice(0, -1)

                }
                else {
                    totalIncDisc = val.reduce(function (a, c) { return a + (c.isFree ? 0 : c.discountAmount) }, 0)
                }

                return totalIncDisc;
            },
            // calulateDiscountAmount1: function () {
            //     return this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number(c.discountAmount || 0)) }, 0)
            // },
            // calulateBundleAmount1: function () {
            //     return this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number(c.bundleAmount || 0)) }, 0)
            // },
            // calulateNetTotalWithVAT: function () {
            //     var total = this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total + c.includingVat) || 0)) }, 0);
            //     var grandTotal = parseFloat(total) - (this.calulateDiscountAmount1() + this.calulateBundleAmount1())
            //     return (parseFloat(grandTotal).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            // },
            // calulateTotalVATofInvoice: function () {
            //     var total = this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.includingVat + c.inclusiveVat) || 0)) }, 0);
            //     return (parseFloat(total).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            // },

            printInvoice: function () {
                this.htmlData.htmlString = this.$refs.mychildcomponent.innerHTML;
                var form = new FormData();
                form.append('htmlString', this.htmlData.htmlString);
                var root = this;

                root.$https.post('/Report/PrintPdf', form, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', root.headerFooters.company.nameEnglish + ' ' + root.list.registrationNo + '.pdf');
                        document.body.appendChild(link);
                        link.click();
                        root.$emit("close", true);
                    });
            },

            GetTLVForValue: function (tagNumber, tagValue) {
                var tagBuf = Buffer.from([tagNumber], 'utf-8')
                var tagValueLenBuf = Buffer.from([tagValue.length], 'utf-8')
                var tagValueBuf = Buffer.from(tagValue, 'utf-8')
                var bufsArray = [tagBuf, tagValueLenBuf, tagValueBuf]
                return Buffer.concat(bufsArray)
            }

        },
        created() {

            this.termAndConditionLength = localStorage.getItem('TermAndConditionLength') == 'true' ? true : false;
        },
        mounted: function () {
            

            this.b2b = localStorage.getItem('b2b') == 'true' ? true : false;
            this.b2c = localStorage.getItem('b2c') == 'true' ? true : false;

            this.isMultiUnit = localStorage.getItem('IsMultiUnit');
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.invoicePrint = localStorage.getItem('InvoicePrint');
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            this.IsDeliveryNote = localStorage.getItem('IsDeliveryNote');
            this.userName = localStorage.getItem('FullName');
            this.currency = localStorage.getItem('currency');
            this.taxInvoiceLabel = localStorage.getItem('taxInvoiceLabel');
            this.taxInvoiceLabelAr = localStorage.getItem('taxInvoiceLabelAr');
            this.simplifyTaxInvoiceLabel = localStorage.getItem('simplifyTaxInvoiceLabel');
            this.simplifyTaxInvoiceLabelAr = localStorage.getItem('simplifyTaxInvoiceLabelAr');
            var root = this;
            if (this.printDetails.saleItems.length > 0) {

                this.list = this.printDetails;
                 if(this.list.customerAddressWalkIn=='' || this.list.customerAddressWalkIn==null )
                {
                    if(this.list.customerAddress=='' || this.list.customerAddress==null)
                    {
                        this.list.customerAddressWalkIn=this.list.customerCity==null || this.list.customerCity==''?'':this.list.customerCity
                    }
                    else
                    {
                        this.list.customerAddressWalkIn=this.list.customerAddress 
                    }

                }
                this.headerFooters = this.headerFooter;

                if (this.headerFooters.blindPrint) {
                    this.printBlindInvoice();
                }
                else {
                    var sellerNameBuff = root.GetTLVForValue('1', this.headerFooters.company.nameEnglish)
                    var vatRegistrationNoBuff = root.GetTLVForValue('2', this.headerFooters.company.vatRegistrationNo)
                    var timeStampBuff = root.GetTLVForValue('3', this.list.date)
                    var totalWithVat = root.GetTLVForValue('4', this.calulateNetTotalForQr())
                    var totalVat = root.GetTLVForValue('5', this.calulateTotalVATForQr())
                    var tagArray = [sellerNameBuff, vatRegistrationNoBuff, timeStampBuff, totalWithVat, totalVat]
                    var qrCodeBuff = Buffer.concat(tagArray)
                    root.qrValue = qrCodeBuff.toString('base64')

                    var count = this.printDetails.saleItems.length;
                    this.page = Math.ceil(count / 2);
                    if (count <= 10) {
                        this.emptyListCount = 10 - count;
                        this.indexCount = 10 - this.emptyListCount;

                    }
                    else if (count > 10 && count <= 20) {
                        this.listItemP1 = this.list.saleItems.slice(0, 10)
                        this.listItemP2 = this.list.saleItems.slice(10, 20)
                        this.emptyListCount = 20 - count;
                        this.indexCount = 20 - this.emptyListCount;
                    }
                    else if (count > 20 && count <= 30) {
                        this.listItemP1 = this.list.saleItems.slice(0, 10)
                        this.listItemP2 = this.list.saleItems.slice(10, 20)
                        this.listItemP3 = this.list.saleItems.slice(20, 30)
                        this.emptyListCount = 30 - count;
                        this.indexCount = 30 - this.emptyListCount;
                    }
                    else if (count > 30 && count <= 40) {
                        this.listItemP1 = this.list.saleItems.slice(0, 10)
                        this.listItemP2 = this.list.saleItems.slice(10, 20)
                        this.listItemP3 = this.list.saleItems.slice(20, 30)
                        this.listItemP4 = this.list.saleItems.slice(30, 40)
                        this.emptyListCount = 40 - count;
                        this.indexCount = 40 - this.emptyListCount;
                    }
                    else if (count > 40 && count <= 50) {
                        this.listItemP1 = this.list.saleItems.slice(0, 10)
                        this.listItemP2 = this.list.saleItems.slice(10, 20)
                        this.listItemP3 = this.list.saleItems.slice(20, 30)
                        this.listItemP4 = this.list.saleItems.slice(30, 40)
                        this.listItemP5 = this.list.saleItems.slice(40, 50)
                        this.emptyListCount = 50 - count;
                        this.indexCount = 50 - this.emptyListCount;
                    }
                    else if (count > 50 && count <= 60) {
                        this.listItemP1 = this.list.saleItems.slice(0, 10)
                        this.listItemP2 = this.list.saleItems.slice(10, 20)
                        this.listItemP3 = this.list.saleItems.slice(20, 30)
                        this.listItemP4 = this.list.saleItems.slice(30, 40)
                        this.listItemP5 = this.list.saleItems.slice(40, 50)
                        this.listItemP6 = this.list.saleItems.slice(50, 60)
                        this.emptyListCount = 60 - count;
                        this.indexCount = 60 - this.emptyListCount;
                    }
                    /*summary calculate listItemP1Summary*/
                    root.listItemP1Summary.calulateTotalQty = this.calulateTotalQtySub(root.listItemP1);

                    root.listItemP1Summary.calulateNetTotal = this.calulateNetTotalSub(root.listItemP1);

                    root.listItemP1Summary.calulateTotalExclVAT = this.calulateTotalExclVATSub(root.listItemP1);

                    root.listItemP1Summary.calulateTotalVAT = this.calulateTotalVATSub(root.listItemP1);

                    //root.listItemP1Summary.calulateTotalInclusiveVAT = root.listItemP1.reduce(function (a, c) { return a + Number((c.inclusiveVat) || 0) }, 0);

                    root.listItemP1Summary.calulateDiscountAmount = this.calulateDiscountAmountSub(root.listItemP1);

                    //root.listItemP1Summary.calulateBundleAmount = root.listItemP1.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0);

                    /*summary calculate listItemP2Summary*/
                    root.listItemP2Summary.calulateTotalQty = this.calulateTotalQtySub(root.listItemP2);

                    root.listItemP2Summary.calulateNetTotal = this.calulateNetTotalSub(root.listItemP2);

                    root.listItemP2Summary.calulateTotalExclVAT = this.calulateTotalExclVATSub(root.listItemP2);

                    root.listItemP2Summary.calulateTotalVAT = this.calulateTotalVATSub(root.listItemP2);

                    //root.listItemP2Summary.calulateTotalInclusiveVAT = root.listItemP2.reduce(function (a, c) { return a + Number((c.inclusiveVat) || 0) }, 0);

                    root.listItemP2Summary.calulateDiscountAmount = this.calulateDiscountAmountSub(root.listItemP2);

                    //root.listItemP2Summary.calulateBundleAmount = root.listItemP2.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0);

                    /*summary calculate listItemP3Summary*/
                    root.listItemP3Summary.calulateTotalQty = this.calulateTotalQtySub(root.listItemP3);

                    root.listItemP3Summary.calulateNetTotal = this.calulateNetTotalSub(root.listItemP3);

                    root.listItemP3Summary.calulateTotalExclVAT = this.calulateTotalExclVATSub(root.listItemP3);

                    root.listItemP3Summary.calulateTotalVAT = this.calulateTotalVATSub(root.listItemP3);

                    root.listItemP3Summary.calulateDiscountAmount = this.calulateDiscountAmountSub(root.listItemP3);

                    //root.listItemP3Summary.calulateTotalInclusiveVAT = root.listItemP3.reduce(function (a, c) { return a + Number((c.inclusiveVat) || 0) }, 0);

                    //root.listItemP3Summary.calulateBundleAmount = root.listItemP3.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0);



                    /*summary calculate listItemP4Summary*/
                    root.listItemP4Summary.calulateTotalQty = this.calulateTotalQtySub(root.listItemP4);

                    root.listItemP4Summary.calulateNetTotal = this.calulateNetTotalSub(root.listItemP4);

                    root.listItemP4Summary.calulateTotalExclVAT = this.calulateTotalExclVATSub(root.listItemP4);

                    root.listItemP4Summary.calulateTotalVAT = this.calulateTotalVATSub(root.listItemP4);

                    root.listItemP4Summary.calulateDiscountAmount = this.calulateDiscountAmountSub(root.listItemP4);

                    //root.listItemP4Summary.calulateTotalInclusiveVAT = root.listItemP4.reduce(function (a, c) { return a + Number((c.inclusiveVat) || 0) }, 0);

                    //root.listItemP4Summary.calulateBundleAmount = root.listItemP4.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0);

                    /*summary calculate listItemP5Summary*/
                    root.listItemP5Summary.calulateTotalQty = this.calulateTotalQtySub(root.listItemP5);

                    root.listItemP5Summary.calulateNetTotal = this.calulateNetTotalSub(root.listItemP5);

                    root.listItemP5Summary.calulateTotalExclVAT = this.calulateTotalExclVATSub(root.listItemP5);

                    root.listItemP5Summary.calulateTotalVAT = this.calulateTotalVATSub(root.listItemP5);

                    root.listItemP5Summary.calulateDiscountAmount = this.calulateDiscountAmountSub(root.listItemP5);

                    //root.listItemP5Summary.calulateTotalInclusiveVAT = root.listItemP5.reduce(function (a, c) { return a + Number((c.inclusiveVat) || 0) }, 0);

                    //root.listItemP5Summary.calulateBundleAmount = root.listItemP5.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0);

                    root.list.date = moment(root.list.date).format('DD MMM YYYY');

                    console.log(root.list)
                    console.log(root.listItemP1)
                    setTimeout(function () {
                        root.printInvoice();
                    }, 125)
                }

            }
        },

    }
</script>


<style scoped>
    .page {
        background-color: white !important;
    }

    .your-background:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: -1;
        border-bottom: 1000px solid #eee; /* Make it fit your needs */
    }

    .footer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: red;
        color: white;
        text-align: center;
    }
</style>