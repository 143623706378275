<template>
    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">

        <!--<label>{{ $t('PurchaseOrder.RawProduct') }}</label>-->
        <rawProductDropdown @input="addProduct"
                            width="100%" />
        <div class=" table-responsive mt-3">

            <table class="table add_table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <thead style="background-color: #F4F6FC;">
                    <tr>
                        <th width="10%">
                            #
                        </th>
                        <th width="80%">
                            {{ $t('AddProcess.RawProduct') }}
                        </th>


                        <th width="10%"></th>
                    </tr>
                </thead>
                <tbody id="purchase-item">
                    <template v-for="(prod , index) in purchaseProducts.slice().reverse()">

                        <tr :key="prod.productId + index" style="background:#EAF1FE;">
                            <td>
                                {{index+1}}
                            </td>
                            <td>
                                <!--<product-dropdown v-model="prod.productId"
                                @input="changeProduct($event, prod.rowId, prod.productId)" />-->
                                <span v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                    {{products.find(x => x.id == prod.productId).englishName}}
                                </span>
                                <span v-else>
                                    {{products.find(x => x.id == prod.productId).arabicName}}
                                </span>
                            </td>

                            <td class="pt-0">
                                <button @click="removeProduct(prod.rowId)"
                                        title="Remove Item"
                                        class="btn btn-secondary btn-neutral btn-sm btn-round  btn-icon">
                                    <i class="nc-icon nc-simple-remove"></i>
                                </button>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

    </div>
</template>


<script>
    //import Loading from 'vue-loading-overlay';
    //import "vue-loading-overlay/dist/vue-loading.css";
    //import moment from "moment";
    //import VueBarcode from 'vue-barcode';
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        name: "PurchaseItem",
        props: ['purchase', 'purchaseItems'],

        data: function () {
            return {
                rendered: 0,
                product: {
                    id: "",
                },
                products: [],
                purchaseProducts: [],
                loading: false,

                summary: {
                    item: 0,
                    qty: 0,
                    total: 0,
                    totalWithWaste: 0,
                },
                productList: [],
                searchTerm: '',
                isMultiUnit: ''
            };
        },
        validations: {},
        filters: {

        },
        methods: {
            GetProductList: function () {

                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }


                //search = search == undefined ? '' : search;
                // var url = this.wareHouseId != undefined ? "/Product/GetProductInformation?searchTerm=" + search + '&wareHouseId=' + this.wareHouseId + "&isDropdown=true" + '&isRaw=' + root.isRaw : "/Product/GetProductInformation?searchTerm=" + search + '&status=' + root.status + "&isDropdown=true" + '&isRaw=' + root.isRaw;

                this.$https
                    .get("/Product/GetProductBarcode?isRaw=" + true, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.productList = response.data.results.products;

                        }
                    });


            },
            onBarcodeScanned(barcode) {

                if (localStorage.getItem("BarcodeScan") != 'Recipe')
                    return
                var root = this;
                if (root.productList.length > 0) {
                    var product = this.productList.find(x => x.barCode == barcode)
                    if (product != null) {
                        root.addProduct(product.id, product)
                    }
                }


            },
            changeProduct: function (NewProdId, rowId) {
                this.purchaseProducts = this.purchaseProducts.filter(x => x.rowId != rowId);
                this.addProduct(NewProdId);

            },
            calcuateSummary: function () {

                this.summary.item = this.purchaseProducts.length;
                this.summary.qty = this.purchaseProducts.reduce(
                    (qty, prod) => qty + parseInt(prod.quantity == '' ? 0 : prod.quantity), 0);
                this.summary.total = this.purchaseProducts
                    .reduce((total, prod) => total + (prod.highQuantity == '' ? 0 : prod.highQuantity) * prod.unitPerPack + parseFloat(prod.quantity == '' ? 0 : prod.quantity), 0)
                    .toFixed(3).slice(0, -1);
                this.summary.totalWithWaste = this.purchaseProducts
                    .reduce((total, prod) => total + (((prod.highQuantity == '' ? 0 : prod.highQuantity) * prod.unitPerPack) + parseFloat(prod.quantity == '' ? 0 : prod.quantity)) - parseFloat(((((prod.highQuantity == '' ? 0 : prod.highQuantity) * prod.unitPerPack) + parseFloat(prod.quantity == '' ? 0 : prod.quantity)) * prod.waste) / 100), 0)
                    .toFixed(3).slice(0, -1);

                this.$emit("input", this.purchaseProducts);
            },

            updateLineTotal: function (product) {

                






                this.$set(this.purchaseProducts, product, product);


                this.$emit("input", this.purchaseProducts);

            },

            addProduct: function (productId, newProduct) {

                
                if (this.products.find(x => x.id == newProduct.id) == undefined || this.products.length <= 0) {
                    this.products.push(newProduct);
                }

                var prod = this.products.find((x) => x.id == productId);



                this.purchaseProducts.push({
                    rowId: this.createUUID(),
                    productId: prod.id,
                });





                this.product.id = "";
                this.rendered++;
                this.updateLineTotal(this.purchaseProducts);
            },
            createUUID: function () {
                var dt = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (dt + Math.random() * 16) % 16 | 0;
                    dt = Math.floor(dt / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },


            removeProduct: function (id) {

                this.purchaseProducts = this.purchaseProducts.filter((prod) => {
                    return prod.rowId != id;
                });

            },

            getData: function () {

                var root = this;
                
                if (root.$route.query.data != undefined) {
                    //Recipt No Edit
                    if (root.$route.query.data.processItems != undefined) {
                        root.$route.query.data.processItems.forEach(function (item) {
                            root.purchaseProducts.push({
                                rowId: item.id,
                                id: item.id,
                                product: item.product,
                                productId: item.productId,
                            });
                        });

                        for (var k = 0; k < root.purchaseProducts.length; k++) {
                            root.products.push(root.purchaseProducts[k].product);

                        }
                    }

                }

            },
        },
        created: function () {
            //this.$barcodeScanner.init(this.onBarcodeScanned);
            //For Scanner Code
            var root = this;
            var barcode = '';
            var interval;
            document.addEventListener('keydown', function (evt) {
                if (interval)
                    clearInterval(interval);
                if (evt.code === 'Enter') {
                    if (barcode) {
                        root.onBarcodeScanned(barcode);
                    }
                    barcode = '';
                    return;

                }
                if (evt.key !== 'Shift')
                    barcode += evt.key;
            });
            localStorage.setItem("BarcodeScan", 'Recipe')
            //End
            this.getData();
        },
        mounted: function () {
            this.GetProductList();
            this.isMultiUnit = localStorage.getItem('IsMultiUnit');
        },
        destroyed() {
            // Remove listener when component is destroyed
            //this.$barcodeScanner.destroy()
        },
    };
</script>

<style scoped>
    #sale-item td {
        padding-bottom: 0px;
        padding-top: 0px;
    }

    .input-border {
        border: transparent;
        background-color: transparent !important;
    }

        .input-border:focus {
            outline: none !important;
            border: none !important;
        }

    .multiselect__tags {
        background-color: transparent !important;
    }

    .tableHoverOn {
        background-color: #F4F6FC !important;
    }

    .multiselect__input, .multiselect__single {
        background-color: transparent !important;
    }

    #purchase-item tr td {
        vertical-align: baseline;
    }
</style>
