<template>
    <div hidden>
        <div ref="mychildcomponent" id='purchaseInvoice' style="width:400px;z-index:-9999">
            <!--INFORMATION-->
            <div style="width:400px;margin-left:20px;">
                <!--<div style="width:100%;text-align:center;margin-bottom:2.5rem;margin-top:1rem;">
        <img :src="headerFooters.company.logoPath" style="width: auto; max-width: 300px; height: auto; max-height: 120px;">
    </div>-->
                <!--<div style="width:100%;text-align:center;">
        <vue-qrcode v-bind:value="qrValue" style="width:200px" v-bind:errorCorrectionLevel="correctionLevel" />
    </div>-->
                <div style="width:100%;">
                    <div style="text-align: center;">
                        <span style="font-size:30px;font-weight:bold;color:black;">{{headerFooters.company.nameEnglish}} {{headerFooters.company.nameArabic}}</span><br />
                        <span style="font-size:18px;font-weight:bold;color:black;">{{headerFooters.company.categoryEnglish}} {{headerFooters.company.categoryArabic}}</span><br />
                        <p style="border:1px solid; width:350px;font-weight:bold;color:black;margin-left:auto;margin-right:auto;margin-top:1.3rem;margin-bottom:1.3rem;">
                            VAT No. {{headerFooters.company.vatRegistrationNo}} الرقم الضريبي <br />
                            <span style="font-size:18px;color:black;">{{headerFooters.company.companyNameArabic}}</span><br />
                            <span style="font-size:17px;color:black;">{{headerFooters.company.companyNameEnglish}}</span>
                        </p>
                        <span style="font-size:18px;color:black;">{{headerFooters.company.addressArabic}}</span><br />
                        <span style="font-size:16px;color:black;">{{headerFooters.company.addressEnglish}}</span><br />
                        <span style="font-size:15px;color:black;">Mobile No. {{headerFooters.company.phoneNo}} رقم الجوال</span><br />
                    </div>
                </div>


                <div style="width:100%;text-align:center;margin-top:2rem;">
                    <p style="font-size: 15px; font-weight: bold; color: black; margin-bottom: 2px; padding-bottom: 2px; font-size: 25px; font-weight: bold"> Expenses   نفقات</p>
                    <p style="font-size: 15px; font-weight: bold;color:black;margin-bottom:2px;padding-bottom:2px;">Expense No: <span style="border:4px solid;font-size:25px;font-weight:bold;padding-left:2px;padding-right:2px;color:black;"> {{list.voucherNo}}</span>: رقم المصاريف</p>

                    <p style="font-size:15px;font-weight:bold;color:black;">Spent Date: <span style="margin-right:20px;margin-left:10px;padding-left:20px;color:black;">{{list.date}}</span>  التاريخ</p>

                </div>
                <table class="table table-borderless " style="width:400px;" v-if="IsExpenseAccount">
                    <tr style="font-size:16px;">
                        <td class="" style="border-top: 0;padding-left:1px;padding-right:1px;color:black;"><span style="font-weight:bold;color:black;">Payment Mode</span><br> {{paymentMode(list.paymentMode)}}</td>
                        <td style="text-align:right; border-top: 0;padding-left:1px;padding-right:1px;color:black;"><span style="font-weight:bold;color:black;">Bank/Cash Account</span>  <br>{{ ($i18n.locale == 'en' ||isLeftToRight()) ? (list.accountName != '' && list.accountName != null) ? list.accountName : list.nameArabic : (list.nameArabic != '' && list.nameArabic != null) ? list.nameArabic : list.accountName}}</td>
                    </tr>
                    <tr style="font-size:16px;">
                        <td class="" style="border-top: 0;padding-left:1px;padding-right:1px;color:black;"><span style="font-weight:bold;color:black;">Reference No:/</span>رقم المرجع<br>{{list.referenceNo}}</td>
                        <td style="text-align:right; border-top: 0;padding-left:1px;padding-right:1px;color:black;"><span style="font-weight:bold;color:black;">TaxId/</span>الرقم الضريبي<br>{{list.taxId}}</td>
                    </tr>
                    <tr style="font-size:16px;">
                        <td class="" style="border-top: 0;padding-left:1px;padding-right:1px;color:black;"><span style="font-weight:bold;color:black;">Name:/</span>اسم<br>{{list.name}}</td>
                    </tr>
                </table>
                <div style="width:100%;">
                    <table class="table report-tbl" style="width:400px;">
                        <tr class="heading" style="font-size:15px;border-bottom:1px solid black;border-top:1px solid;border-color:black !important;">
                            <th style="text-align: left; width: 5%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">رقم <br />No.</th>
                            <th style="text-align: center; width: 50%;  border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;" v-if="!IsExpenseAccount">وصف <br />Description</th>
                            <th style="text-align: center; width: 20%;  border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;" v-if="IsExpenseAccount">وصف <br />Description</th>
                            <th style="text-align: center; width: 20%;  border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;" v-if="IsExpenseAccount">حساب المصاريف <br />Expense Account</th>
                            <th style="text-align: center; width: 10%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">مقدار<br />Amount</th>
                            <th style="text-align: center; width: 10%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;" v-if="IsExpenseAccount">قيمة الضريبة<br />Total Vat</th>
                            <th style="text-align: center; width: 10%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;" v-if="IsExpenseAccount">مجموع<br />Total</th>
                        </tr>
                        <tr style="font-size:15px;" v-for="(item, index) in list.dailyExpenseDetails" v-bind:key="item.id">
                            <td style="text-align: left; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black;">{{index+1}}</td>
                            <td style="text-align: center; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black; font-weight: 600;"> {{item.description}}</td>
                            <td style="text-align: center; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black; font-weight: 600;" v-if="IsExpenseAccount"> {{ ($i18n.locale == 'en' ||isLeftToRight()) ? (item.accountName != '' && item.accountName != null) ? item.accountName : item.nameArabic : (item.nameArabic != '' && item.nameArabic != null) ? item.nameArabic : item.accountName}}  </td>
                            <td style="text-align: center; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black;">{{item.amount}}</td>
                            <td style="text-align: center; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black;" v-if="IsExpenseAccount">{{(item.exclusiveTotalVat+item.inclusiveTotalVat).toFixed(3).slice(0,-1)}}</td>
                            <td style="text-align: center; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black;" v-if="IsExpenseAccount">{{(item.exclusiveTotalVat+item.amount).toFixed(3).slice(0,-1)}}</td>
                        </tr>
                        <!--<tr style="font-size: 16px; ">-->
                        <!--<th style="   color: black;border-bottom: 1px solid #000000;border-top: 1px solid #000000;">Total Amount المبلغ الإجمالي <br /> {{currency}} {{totalAmount | roundOffFilter}}</th>-->
                        <!--<td style="text-align: center; padding-left: 1px; padding-right: 0px; border-top: 0; padding-top: 0; padding-bottom: 3px; color: black;" colspan="3">Total Amount المبلغ الإجمالي:</td>
                <td colspan="2" style="padding-right: 3rem; text-align: center; padding-left: 10px; padding-right: 1px; border-top: 0; padding-top: 0; padding-bottom: 3px; color: black; ">{{currency}} {{totalAmount | roundOffFilter}}</td>
            </tr>-->
                        <!--<tr class="heading" style="font-size:16px;">
                <th style="text-align:center; padding-left:1px;padding-right:1px;color:black;border-bottom: 1px solid #000000;border-top: 1px solid #000000;" colspan="2">T.Items عدد العنف <br /> 546</th>
                <th style="text-align: center; padding-left: 1px; padding-right: 1px; color: black;border-bottom: 1px solid #000000;border-top: 1px solid #000000;" colspan="2">T.Pieces عدد القطع <br /> 456</th>
                <th style="text-align: center; padding-left: 1px; padding-right: 1px; color: black;border-bottom: 1px solid #000000;border-top: 1px solid #000000;">G.Total الإجمالي <br /> 456</th>
            </tr>-->
                    </table>
                </div>
                <div style="border-bottom: 1px solid black; border-top: 1px solid black; color: black; width: 100%; text-align: center; margin-top: 2rem;">
                    <p style="font-size:15px;font-weight:bold;color:black;">Total Amount: <span style="margin-right:20px;margin-left:10px;padding-left:20px;color:black;">{{currency}} {{totalAmount | roundOffFilter}}</span> المبلغ الإجمالي</p>
                    <p style="font-size:15px;font-weight:bold;color:black;" v-if="IsExpenseAccount">Total Tax: <span style="margin-right:20px;margin-left:10px;padding-left:20px;color:black;">{{currency}} {{totalVat | roundOffFilter}}</span>قيمة الضريبة</p>
                    <p style="font-size:15px;font-weight:bold;color:black;" v-if="IsExpenseAccount">Grand Total: <span style="margin-right:20px;margin-left:10px;padding-left:20px;color:black;"> {{currency}} {{grandTotal | roundOffFilter}}</span>المجموع الإجمالي</p>

                </div>
            </div>


        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import axios from 'axios'
    const options = {
        name: '',
        specs: [
            'fullscreen=no',
            'titlebar=yes',
            'scrollbars=yes'
        ],
        styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css'
        ],
        timeout: 700,
        autoClose: true,
        windowTitle: window.document.title,

    }
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        props: ['printDetails', 'headerFooter', 'isTouchScreen', 'formName'],
        data: function () {
            return {
                currency: '',

                qrValue: "",
                isHeaderFooter: '',
                IsExpenseAccount: false,
                invoicePrint: '',
                IsDeliveryNote: '',
                arabic: '',
                english: '',
                userName: '',
                list: {
                    number: 0,
                    listItemTotal: [],
                    registrationNo: '',
                    date: '',
                    dueDate: '',
                    companyName: '',
                    companyPhoneNo: '',
                    companyAddress: '',
                    discountAmount: '',
                    cashCustomer: '',
                    creditCustomer: '',
                    customerPhoneNo: '',
                    customerAddress: '',
                    paymentMethod: '',
                    paymentMethodNo: '',
                    invocieType: '',
                    saleItems:
                        [

                        ]
                },
                render: 0,
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
                htmlData: {
                    htmlString: ''
                }
            }
        },
        filters: {
            roundOffFilter: function (value) {

                return parseFloat(value).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
            }
        },
        computed: {
            totalAmount: function () {
                var total = 0;
                if (this.list.dailyExpenseDetails !== null && this.list.dailyExpenseDetails.length > 0) {
                    this.list.dailyExpenseDetails.forEach(function (dailyExpenses) {

                        total = parseFloat(total) + parseFloat(dailyExpenses.amount);

                    })
                }

                return total;
            },
            totalVat: function () {
                return this.list.dailyExpenseDetails.reduce(function (a, c) { return a + Number((c.inclusiveTotalVat + c.exclusiveTotalVat) || 0) }, 0)
            },
            grandTotal: function () {
                return this.list.dailyExpenseDetails.reduce(function (a, c) { return a + Number((c.amount + c.exclusiveTotalVat) || 0) }, 0)
            },
        },
        methods: {
            paymentMode: function (x) {
                if (this.invoicePrint == 'العربية') {

                    if (x == 0) {
                        return 'السيولة النقدية';
                    }
                    if (x == 1) {
                        return 'مصرف';
                    }
                    if (x == 5) {
                        return 'يتقدم';
                    }



                }
                else {

                    if (x == 0) {
                        return 'Cash';
                    }
                    if (x == 1) {
                        return 'Bank';
                    } if (x == 5) {
                        return 'Advance';
                    }

                }
            },

            printBlindInvoice: function () {
                this.htmlData.htmlString = this.$refs.mychildcomponent.innerHTML;
                var printerName = localStorage.getItem('PrinterName')
                var form = new FormData();
                form.append('htmlString', this.htmlData.htmlString);
                form.append('NoOfPagesPrint', 0);
                form.append('PrintType', 'invoice');
                form.append('PrinterName', printerName);
                


                if (!this.$ServerIp.includes('localhost')) {
                    axios.post('http://127.0.0.1:7014/print/from-pdf', form).then(function () {

                    });
                }
                else {
                    this.$htmlToPaper('purchaseInvoice', options, () => {
                    });

                }
            },

        },
        created: function () {
            if (this.printDetails.dailyExpenseDetails.length > 0) {
                this.list = this.printDetails;
                this.headerFooters = this.headerFooter;

            }
        },
        mounted: function () {
            
            if (this.formName == 'dailyexpense') {

                this.IsExpenseAccount = false;
            }
            else {
                this.IsExpenseAccount = localStorage.getItem('IsExpenseAccount') == 'true' ? true : false;
            }

            this.currency = localStorage.getItem('currency');
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.invoicePrint = localStorage.getItem('InvoicePrint');
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            this.userName = localStorage.getItem('FullName');
            var root = this;
            if (this.printDetails.dailyExpenseDetails.length > 0) {
                this.list.date = moment().format('DD MMM YYYY');
                setTimeout(function () {
                    root.printBlindInvoice();
                }, 125)

            }
        },

    }
</script>

