<template>
    <div>
        <div hidden id='purchaseInvoice' style="width:400px;">
            <!--INFORMATION-->
            <div class="row ">
                <div class="col-md-12 text-center mb-4">
                    <img src="noblepos-retail.png" style="width:100%; max-width:300px;">
                </div>
                <div class="col-md-12 text-center">
                    <vue-qrcode v-bind:value="qrValue" v-bind:errorCorrectionLevel="correctionLevel" />
                </div>
                <div class="col-md-12">
                    <div class="text-center">
                        <span style="font-size:35px;font-weight:bold;">IDEAL CENTER ???? ???????</span><br />
                        <span style="font-size:18px;font-weight:bold;">For Readymade Garments ???? ??????? ???????</span><br />
                        <p style="border:1px solid; width:350px;" class="ml-auto mr-auto mt-2 mb-2">
                            VAT No. 3109838279000003 ????? ??????? <br />
                            <span style="font-size:18px;font-weight:bold;">????? ????? ????? ???? ??????? ???????</span>
                        </p>
                        <span style="font-size:13px;">?? ???????? ? ???? ??????? ? ????? ???? ????? ???? ? ??? ? ??????? ??????? ????????</span><br />
                        <span style="font-size:12px;">Azizia District, Sahafa Street, Opposit Super Market Aleen, Jeddah, KSA</span><br />
                        <span style="font-size:13px;">Mobile No. 0555582872 ??? ??????</span><br />
                    </div>
                </div>


                <div class="col-md-12 text-center mt-3">
                    <p style="font-size: 13px; font-weight: bold">Invoice No. <span style="border:4px solid;font-size:25px;font-weight:bold;padding-left:2px;padding-right:2px;"> {{list.registrationNo}}</span> ??? ????????</p>
                    <p style="font-size:13px;font-weight:bold">Date: <span style="margin-right:20px;margin-left:10px;padding-left:20px;">{{list.date}}</span>  ???????</p>

                    <table class="table table-borderless m-auto" style="width:400px;">
                        <tr style="font-size:15px;">
                            <td class="" style="border-top: 0;"><span style="font-weight:bold">Counter#</span> <br />??? ????????</td>
                            <td class="" style="border-top: 0;"> </td>
                            <td class="text-right" style="border-top: 0;"></td>
                            <td class="text-right" style="border-top: 0;"> {{employeeName}}</td>
                            <td class="text-right" style="border-top: 0;"><span style="font-weight:bold">User</span> <br />??? ????????</td>
                        </tr>
                    </table>
                </div>

                <div class="col-md-12">
                    <table class="table m-auto report-tbl" style="width:400px;">
                        <tr class="heading" style="font-size:13px;">
                            <th class="text-left" style="width:5%;border-bottom:1px solid #dee2e6;padding-left:1px;padding-right:1px;">??? <br />No.</th>
                            <th class="text-left" style="width:35%;border-bottom:1px solid #dee2e6;padding-left:1px;padding-right:1px;">?????<br />Item</th>
                            <th class="text-center" style="width:15%;border-bottom:1px solid #dee2e6;padding-left:1px;padding-right:1px;">??????<br />Qty</th>
                            <th class="text-right" style="width:20%;border-bottom:1px solid #dee2e6;padding-left:1px;padding-right:1px;">?????<br />Price</th>
                            <th class="text-right" style="width:25%;border-bottom:1px solid #dee2e6;padding-left:1px;padding-right:1px;">?????<br />Total</th>
                        </tr>
                        <span v-for="(item, index) in list.saleItems" v-bind:key="item.id">
                            <tr style="font-size:14px;">
                                <td class="text-left" style="border-top:0;padding-top:0;padding-left:1px;padding-right:1px;">{{index+1}}</td>
                                <td class="text-left" style="border-top:0;padding-top:0;padding-left:1px;padding-right:1px;">{{item.productName }}</td>
                                <td class="text-center" style="border-top:0;padding-top:0;padding-left:1px;padding-right:1px;">{{item.quantity }}</td>
                                <td class="text-right" style="border-top:0;padding-top:0;padding-left:1px;padding-right:1px;">{{item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                <td class="text-right" style="border-top:0;padding-top:0;padding-left:1px;padding-right:1px;">{{(item.total).toFixed(3).slice(0,-1)}}</td>
                            </tr>
                        </span>
                        <tr class="heading" style="font-size:13px;">
                            <th class="text-center" style="padding-left:1px;padding-right:1px;" colspan="2">T.Items ??? ????? <br /> {{list.saleItems.length}}</th>
                            <th class="text-center" style="padding-left:1px;padding-right:1px;" colspan="2">T.Pieces ??? ????? <br /> {{calulateTotalQty.toFixed(3).slice(0,-1)}}</th>
                            <th class="text-center" style="padding-left:1px;padding-right:1px;">G.Total ???????? <br /> {{calulateTotalExclVAT.toFixed(3).slice(0,-1)}}</th>
                        </tr>
                        <tr style="font-size:13px;">
                            <td class="text-right" colspan="3" style="border-bottom:0;padding-bottom:1px;padding-left:1px;padding-right:7px;">Total wihout VAT ???????? ??? ?????:</td>
                            <td class="text-left" colspan="2" style="border-bottom:0;padding-bottom:1px;padding-left:10px;padding-right:1px;">{{calulateTotalExclVAT.toFixed(3).slice(0,-1)}}</td>
                        </tr>
                        <tr style="font-size:13px;">
                            <td class="text-right" colspan="3" style="padding-left: 1px; padding-right: 7px; border-top: 0; padding-top: 0; border-bottom: 0; padding-bottom: 1px; ">Discount ???? ?????:</td>
                            <td class="text-left" colspan="2" style="padding-left: 10px; padding-right: 1px; border-top: 0; padding-top: 0; border-bottom: 0; padding-bottom: 1px; ">{{ calulateDiscountAmount.toFixed(3).slice(0,-1) }}</td>
                        </tr>
                        <tr style="font-size:13px;">
                            <td class="text-right" style="padding-left: 1px; padding-right: 7px; border-top: 0; padding-top: 0; border-bottom: 0; padding-bottom: 1px;" colspan="3">Total after Discount ???????? ??? ?????:</td>
                            <td class="text-left" colspan="2" style="padding-left: 10px; padding-right: 1px; border-top: 0; padding-top: 0; border-bottom: 0; padding-bottom: 1px;">{{ (calulateNetTotal - calulateDiscountAmount).toFixed(3).slice(0,-1) }}</td>
                        </tr>

                        <tr style="font-size:13px;">
                            <td class="text-right" style="padding-left: 1px; padding-right: 7px; border-top: 0; padding-top: 0; padding-bottom: 3px;" colspan="3">VAT Amount ???? ???????:</td>
                            <td class="text-left" colspan="2" style="padding-left: 10px; padding-right: 1px; border-top: 0; padding-top: 0; padding-bottom: 3px;">{{ calulateTotalVAT.toFixed(3).slice(0,-1) }}</td>
                        </tr>
                        <tr style="font-size:14px;font-weight:bold;">
                            <td class="text-right" style="padding-left: 1px; padding-right: 7px; border-top: 0; padding-top: 0;" colspan="3">Payable Amount ???????? ???????:</td>
                            <td class="text-left" colspan="2" style="padding-left: 10px; padding-right: 1px; border-top: 0; padding-top: 0;">{{ (calulateNetTotal - calulateDiscountAmount).toFixed(3).slice(0,-1) }}</td>
                        </tr>

                        <tr style="font-size:18px;font-weight:bold;">
                            <td class="text-center" colspan="5">Payment</td>
                        </tr>
                        <tr style="font-size:15px;">
                            <td class="text-left" colspan="3">Cash:</td>
                            <td class="text-right" colspan="2">{{ list.paymentAmount }}</td>
                        </tr>
                        <tr style="font-size:15px;">
                            <th class="text-left" style="border-top:0;padding-top:0;" colspan="3">Change Due:</th>
                            <th class="text-right" colspan="2" style="border-top:0;padding-top:0;">{{ list.change }}</th>
                        </tr>

                        <tr style="font-size:15px;">
                            <td class="text-center" colspan="5">Thanks</td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
    import moment from "moment";
    import VueQrcode from 'vue-qrcode'
    export default {
        props: ['printDetail'],
        components: {
            VueQrcode
        },
        data: function () {
            return {
                qrValue: "https://www.techqode.com/",
                correctionLevel: "H",
                list: {
                    registrationNo: '',
                    date: '',
                    dueDate: '',
                    companyName: '',
                    companyPhoneNo: '',
                    companyAddress: '',
                    discountAmount: '',
                    cashCustomer: '',
                    creditCustomer: '',
                    customerPhoneNo: '',
                    customerAddress: '',
                    paymentMethod: '',
                    paymentMethodNo: '',
                    invocieType: '',
                    saleItems:
                        [

                        ]
                },
                render: 0,
                CompanyID: '',
                UserID: '',
                employeeId: '',
                employeeName:''
            }
        },
        filters: {
            toWords: function (value) {
                var converter = require('number-to-words');
                if (!value) return ''
                return converter.toWords(value);
            }
        },
        computed: {
            calulateTotalQty: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number((c.quantity) || 0) }, 0)
            },
            calulateNetTotal: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number((c.total + c.includingVat) || 0) }, 0)
            },
            calulateTotalExclVAT: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number((c.total) || 0) }, 0)
            },
            calulateTotalVAT: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number((c.includingVat + c.inclusiveVat) || 0) }, 0)
            },
            calulateDiscountAmount: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number((c.discountAmount) || 0) }, 0)
            },
            calulateBundleAmount: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0)
            }
        },
        methods: {
            printInvoice: function () {
                this.$htmlToPaper('purchaseInvoice');
            },
            EmployeeData: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/EmployeeRegistration/EmployeeDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            root.employeeName = response.data.englishName;
                        }
                    },
                        function (error) {
                            console.log(error);
                        });
            },


        },
        mounted: function () {
            
            var root = this;
            if (this.printDetail.saleItems.length > 0) {
                this.list = this.printDetail;
                this.list.date = moment().format('DD/MM/YYYY hh:mm:ss A');
                if (root.$session.exists()) {

                    root.CompanyID = localStorage.getItem('CompanyID');
                    root.UserID = localStorage.getItem('UserID');
                    root.employeeId = localStorage.getItem('EmployeeId');
                    root.EmployeeData(root.employeeId);
                }
                setTimeout(function () {
                    root.printInvoice();
                }, 125)
            }

        },

    }
</script>


<style scoped>
    #font11 {
        font-size: 11px;
        line-height: 0;
    }

    #font16 {
        font-size: 16px;
    }
</style>