<template>
    <div>
        <div hidden id='viewStockInvoice' class="col-md-12">

            <!--HEADER-->
            <div class="col-md-12" style="height:45mm;border:2px solid #000000;" v-if="isHeaderFooter=='true'">
                <div class="row" style="height:35mm">
                    <div class="col-md-4 ">
                        <table class="text-left">
                            <tr>
                                <td>
                                    <p>
                                        <span style="font-size:25px;color:black !important;font-weight:bold;">{{headerFooters.company.nameEnglish}}</span><br />
                                        <span style="font-size:17px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryEnglish}}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">VAT No.: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">Cr No.:{{headerFooters.company.companyRegNo}}</span><br />
                                        <span style="font-size:15px;color:black !important;font-weight:bold;">
                                            Tel: {{headerFooters.company.phoneNo}}
                                        </span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-4 text-center my-5" style="padding:0px !important; margin:0 !important">
                        <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                    </div>
                    <div class="col-md-4 ">
                        <table class="text-right">
                            <tr>
                                <td>
                                    <p>
                                        <span style="font-size:25px;color:black !important;font-weight:bold;">{{headerFooters.company.nameArabic}}.</span><br />
                                        <span style="font-size:17px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryArabic}}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">رقم ضريبة القيمة المضافة: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">رقم السجل التجاري :{{headerFooters.company.companyRegNo}}</span><br />
                                        <span style="font-size:15px;color:black !important;font-weight:bold;">
                                            هاتف: {{headerFooters.company.phoneNo}}:
                                        </span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="row " style="margin-bottom:10px !important;height:10mm">
                    <div class="col-md-12">
                        <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                            <span style="font-size:20px; padding-top:3px; font-weight:bolder" v-if="headerFooters.isStockIn">{{ $t('ViewStock.StockIn') }}</span>
                            <span style="font-size:20px; padding-top:3px; font-weight:bolder" v-else>{{ $t('ViewStock.StockOut') }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div style="height:60mm;" v-else></div>

            <div style="height:30mm;margin-top:1mm; border:2px solid #000000;">
                <div class="row">
                    <div class="col-md-12 ml-2 mr-2">
                        <div>
                            <!--Row 1-->
                            <div class="row">
                                <div class="col-md-6" style="display:flex;">
                                    <div style="width:32%;font-weight:bolder;text-align:right;color:black !important;">Date:</div>
                                    <div style="width:42%; text-align:center;color:black !important;font-weight:bold;">{{productDetail.date}}</div>
                                    <div style="width:26%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">:تاريخ</div>
                                </div>
                                <div class="col-md-6" style="display:flex;">
                                    <div style="width:32%; font-weight:bolder;text-align:right;color:black !important;">Stock Adj. No.:</div>
                                    <div style="width:42%; text-align:center;color:black !important;font-weight:bold;">{{productDetail.code}}</div>
                                    <div style="width:26%;font-weight:bolder;font-size:15px !important;color:black !important;">:رقم تسوية المخزون</div>
                                </div>
                            </div>

                            <!--Row 2-->
                            <div class="row" v-if="headerFooters.isStockIn">
                                <div class="col-md-12" style="display:flex;">
                                    <div style="width:16%;font-weight:bolder;text-align:right;color:black !important;">Warehouse Name:</div>
                                    <div style="width:71%;text-align:center;font-weight:bold;color:black !important;">{{productDetail.warehouseName}}</div>
                                    <div style="width:13%;font-weight:bolder;color:black !important; padding-right:20px;font-size:15px !important">
                                        :اسم المستودع
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-else>
                                <div class="col-md-6" style="display:flex;">
                                    <div style="width:32%;font-weight:bolder;text-align:right;color:black !important;">Warehouse Name:</div>
                                    <div style="width:42%; text-align:center;color:black !important;font-weight:bold;">{{productDetail.warehouseName}}</div>
                                    <div style="width:26%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">:اسم المستودع</div>
                                </div>
                                <div class="col-md-6" style="display:flex;">
                                    <div style="width:32%; font-weight:bolder;text-align:right;color:black !important;">{{ $t('ViewStockInPrint.TaxMethod') }}:</div>
                                    <div style="width:42%; text-align:center;color:black !important;font-weight:bold;">{{ productDetail.taxMethod }}</div>
                                    <div style="width:26%;font-weight:bolder;font-size:15px !important;color:black !important;">:{{ $t('ViewStockInPrint.VAT%') }}</div>
                                </div>
                            </div>


                            <!--Row 3-->
                            <div class="row">
                                <div class="col-md-12" style="display:flex;">
                                    <div style="width:16%;font-weight:bolder;text-align:right;color:black !important;">Remarks:</div>
                                    <div style="width:71%;text-align:center;font-weight:bold;color:black !important;">{{productDetail.narration}}</div>
                                    <div style="width:13%;font-weight:bolder;color:black !important; padding-right:20px;font-size:15px !important">
                                        :ملاحظات
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!--INFORMATION-->
            <div style="border:2px solid !important; padding:5px;">
                <div class="row ">
                    <div class="col-lg-12 mt-5">
                        <table class="table table-borderless">
                            <thead style="background-color: #F4F6FC;">
                                <tr>
                                    <th style="width: 20px;">
                                        #
                                    </th>
                                    <th style="width: 150px;">
                                        {{ $t('ViewStockInPrint.ProductName') }}
                                    </th>
                                    <th style="width: 100px;" class="text-center">
                                        {{ $t('ViewStockInPrint.UnitPrice') }}
                                    </th>
                                    <th style="width: 70px;" class="text-center">
                                        {{ $t('ViewStockInPrint.salePrice') }}
                                    </th>
                                    <th style="width: 100px;" class="text-center">
                                        {{ $t('ViewStockInPrint.Quantity') }}
                                    </th>
                                    <!--<th style="width: 100px;" v-if="isFifo">
                                        {{ $t('ViewStockItem.BatchNo') }}
                                    </th>
                                    <th style="width: 100px;" v-if="isFifo">
                                        {{ $t('ViewStockItem.ExpDate') }}
                                    </th>-->
                                    <th style="width: 110px;" class="text-center" v-if="isMultiUnit=='true'">
                                        {{ $t('ViewStockInPrint.TotalQty') }}
                                    </th>
                                    <th style="width: 100px;" class="text-right">
                                        {{ $t('ViewStockInPrint.Total') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody id="purchase-item">
                                <template v-for="(prod , index) in productDetail.stockAdjustmentDetails">

                                    <tr :key="prod.productId + index">
                                        <td class="border-top-0">
                                            {{index+1}}
                                        </td>
                                        <td class="border-top-0">
                                            <span v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                                {{prod.product.englishName}}
                                            </span>
                                            <span v-else>
                                                {{prod.product.arabicName}}
                                            </span>
                                        </td>

                                        <td class="border-top-0 text-center">
                                            {{currency}} {{parseFloat(prod.price).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                        </td>
                                        <td class="border-top-0 text-center">
                                            {{currency}} {{parseFloat(prod.salePrice).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                        </td>
                                        <td class="border-top-0 text-center" v-if="isMultiUnit=='true'">
                                            {{prod.highQty}} - {{prod.quantity}}
                                        </td>
                                        <td class="border-top-0 text-center" v-else>
                                            {{prod.quantity}}
                                        </td>

                                        <!--<td class="border-top-0 text-center" v-if="isFifo">
                                            <span> {{prod.batchNo}}</span>
                                        </td>

                                        <td class="border-top-0 text-center" v-if=" isFifo">
                                            <span>{{getDate(prod.batchExpiry)}}</span>

                                        </td>-->
                                        <td class="border-top-0 text-center" v-if="isMultiUnit=='true'">
                                            {{prod.totalQty}}
                                        </td>
                                        <td class="border-top-0 text-right">
                                            {{currency}}  {{parseFloat(prod.total).toFixed(3).slice(0,-1) | formatAmount}}
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>


                        <table class="table table-borderless">
                            <thead class="m-0">
                                <tr class="arabicLanguage">
                                    <th class="text-center" style="width:30%">
                                        {{ $t('ViewStockInPrint.NoItem') }}
                                    </th>
                                    <th class="text-center" style="width:35%;">
                                        {{ $t('ViewStockInPrint.TotalQty') }}
                                    </th>
                                    <th class="text-center" style="width:35%;">
                                        {{ $t('ViewStockInPrint.Total') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="arabicLanguage">
                                    <td class="text-center">
                                        {{ summary.item }}
                                    </td>
                                    <td class="text-center">
                                        {{ summary.qty }}
                                    </td>
                                    <td class="text-center">
                                        {{currency}}   {{ summary.total}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    import { required } from "vuelidate/lib/validators";
    import moment from "moment";
    export default {
        mixins: [clickMixin],
        name: "ViewStockInPrint",
        props: ['printDetails', 'headerFooter'],
        data: function () {
            return {
                isMultiUnit: '',
                isFifo: false,
                stockAdjustmentDetails: [],
                render: 0,
                productDetail: {
                    id: '00000000-0000-0000-0000-000000000000',
                    date: '',
                    narration: '',
                    code: '',
                    warehouseId: '',
                    isDraft: true,
                    stockAdjustmentType: '',
                    stockAdjustmentDetails: [],
                    taxMethod: '',
                    taxRateId: "",
                    reason: '',
                },
                loading: false,
                language: 'Nothing',
                options: [],
                reasonOptions: [],
                summary: {
                    item: 0,
                    qty: 0,
                    total: 0,
                    vat: 0,
                },
                currency: '',
                headerFooters: {
                    company: '',
                    isStockIn: ''
                }

            }
        },
        validations: {
            productDetail: {
                date: {
                    required
                },
                code: {
                    required
                },
                warehouseId: {
                    required
                }
            }
        },
        methods: {
            getDate: function (x) {
                if (x == null) {
                    return '-'
                }
                else {
                    return moment(x).format("DD/MM/yyyy");
                }

            },
            calcuateSummary: function () {


                var isMultiUnit = localStorage.getItem('IsMultiUnit');
                if (isMultiUnit == 'true') {
                    this.productDetail.stockAdjustmentDetails.forEach(function (x) {

                        x.totalQty = x.quantity;
                        x.total = parseFloat(x.price) * x.quantity;
                        x.highQty = parseInt(parseInt(x.quantity) / parseInt(x.product.unitPerPack));
                        x.quantity = parseInt(parseInt(x.quantity) % parseInt(x.product.unitPerPack));

                    });
                    this.summary.item = this.productDetail.stockAdjustmentDetails.length;
                    this.summary.qty = this.productDetail.stockAdjustmentDetails.reduce(
                        (qty, prod) => qty + parseInt(parseInt(prod.quantity) + (parseInt(prod.highQty) * parseInt(prod.product.unitPerPack))), 0);
                    this.summary.total = this.productDetail.stockAdjustmentDetails
                        .reduce((total, prod) => total + parseFloat(prod.total), 0)
                        .toFixed(3).slice(0, -1);
                }
                else {
                    this.productDetail.stockAdjustmentDetails.forEach(function (x) {

                        x.total = parseFloat(x.price) * x.quantity;

                    });
                    this.summary.item = this.productDetail.stockAdjustmentDetails.length;
                    this.summary.qty = this.productDetail.stockAdjustmentDetails.reduce(
                        (qty, prod) => qty + parseInt(parseInt(prod.quantity)), 0);
                    this.summary.total = this.productDetail.stockAdjustmentDetails
                        .reduce((total, prod) => total + parseFloat(prod.price) * prod.quantity, 0)
                        .toFixed(3).slice(0, -1);
                }






            },
            PrintInvoice: function () {
                this.$htmlToPaper('viewStockInvoice');
            },
            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.productDetail.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addSale');
                    }
                    else {
                        this.$swal({
                            title: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text:(this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'You cannot Change language during update, otherwise your current page data will be lose!' : 'لا يمكنك تغيير اللغة أثناء التحديث ، وإلا ستفقد بيانات صفحتك الحالية!',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },

            onCancel: function () {
                this.$router.push('/stockValue?formName=' + this.formName);
            },

        },
        created() {
            this.isFifo = localStorage.getItem('fIFO') == 'true' ? true : false;
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            if (this.printDetails != undefined) {

                this.productDetail = this.printDetails;
                this.headerFooters = this.headerFooter;
                this.productDetail.date = moment(this.printDetails.date).format("DD/MM/YYYY");
                this.calcuateSummary();
            }
        },
        mounted: function () {
            this.isMultiUnit = localStorage.getItem('IsMultiUnit');
            var root = this;
            this.currency = localStorage.getItem('currency');
            this.language = this.$i18n.locale;
            setTimeout(function () {
                root.PrintInvoice();
            }, 125)





        }
    }
</script>
