<template>
    <div>

        <multiselect v-if="isMultiple == true" v-model="DisplayValue" :options="options" :disabled="disabled"
            v-bind:placeholder="$t('OriginDropdown.SelectOrigin')" :multiple="true" track-by="name"
            :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true"
            v-bind:class="($i18n.locale == 'en' || isLeftToRight()) ? 'text-left ' : 'arabicLanguage '">
            <!--<p slot="noResult" class="text-danger"> Oops! No Origin found.</p>-->

            <a slot="noResult" class="btn btn-primary " v-on:click="AddOrigin('Add')"
                v-if="isValid('CanAddOrigin')">{{ $t('OriginDropdown.AddProductOrigin') }}</a><br />

        </multiselect>
        <multiselect v-else v-model="DisplayValue" :options="options" :disabled="disabled"
            v-bind:placeholder="$t('OriginDropdown.SelectOrigin')" :multiple="false" track-by="name"
            :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true"
            v-bind:class="$i18n.locale == 'en' ? 'text-left ' : 'arabicLanguage '">
            <!--<p slot="noResult" class="text-danger"> Oops! No Origin found.</p>-->

            <a slot="noResult" class="btn btn-primary " v-on:click="AddOrigin('Add')"
                v-if="isValid('CanAddOrigin')">{{ $t('OriginDropdown.AddProductOrigin') }}</a><br />

        </multiselect>

        <modal :show="show" v-if="show">
            <div class="modal-content">
                <div class="modal-header" v-if="type == 'Edit'">
                    <h5 class="modal-title" id="myModalLabel">{{ $t('OriginDropdown.UpdateOrigion') }}</h5>
                </div>
                <div class="modal-header" v-else>
                    <h5 class="modal-title" id="myModalLabel"> {{ $t('OriginDropdown.AddProductOrigin') }}</h5>
                </div>
                <div class="modal-body">
                    <div class="row ">
                        <div :key="render" class="form-group has-label col-sm-12 "
                            v-bind:class="{ 'has-danger': $v.origin.code.$error }">
                            <label class="text  font-weight-bolder"> {{ $t('OriginDropdown.Code') }}:<span
                                    class="text-danger"> *</span></label>
                            <input disabled class="form-control"
                                
                                v-model="$v.origin.code.$model" type="text" />
                            <span v-if="$v.origin.code.$error" class="error">
                                <span v-if="!$v.origin.code.maxLength"> {{ $t('OriginDropdown.CodeLength') }}</span>
                            </span>
                        </div>
                        <div v-if="english == 'true'" class="form-group has-label col-sm-12 "
                            v-bind:class="{ 'has-danger': $v.origin.name.$error }">
                            <label class="text  font-weight-bolder"> {{ $t('OriginDropdown.OriginName')
                                    | englishLanguage
                            }}: <span class="text-danger"> *</span></label>
                            <input class="form-control" v-model="$v.origin.name.$model"
                                
                                type="text" />
                            <span v-if="$v.origin.name.$error" class="error">
                                <span v-if="!$v.origin.name.required"> {{ $t('OriginDropdown.NameRequired') }}</span>
                                <span v-if="!$v.origin.name.maxLength"> {{ $t('OriginDropdown.NameLength') }}</span>
                            </span>
                        </div>
                        <div v-if="isOtherLang()" class="form-group has-label col-sm-12 "
                            v-bind:class="{ 'has-danger': $v.origin.nameArabic.$error }">
                            <label class="text  font-weight-bolder"> {{ $t('OriginDropdown.OriginNameAr')
                                    | arabicLanguage
                            }}: <span class="text-danger"> *</span></label>
                            <input class="form-control  " v-model="$v.origin.nameArabic.$model"
                                v-bind:class="isLeftToRight() ? 'text-left' : 'arabicLanguage'" type="text" />
                            <span v-if="$v.origin.nameArabic.$error" class="error">
                                <span v-if="!$v.origin.nameArabic.required"> {{ $t('OriginDropdown.NameRequired')
                                }}</span>
                                <span v-if="!$v.origin.nameArabic.maxLength">{{ $t('OriginDropdown.NameLength')
                                }}</span>
                            </span>
                        </div>

                        <div class="form-group has-label col-sm-12 "
                            v-bind:class="{ 'has-danger': $v.origin.description.$error }">
                            <label class="text  font-weight-bolder"> {{ $t('OriginDropdown.Description') }}: </label>
                            <textarea rows="3" class="form-control"
                                v-bind:class="($i18n.locale == 'en' || isLeftToRight()) ? 'text-left' : 'text-right'"
                                v-model="$v.origin.description.$model" type="text" />
                            <span v-if="$v.origin.description.$error" class="error"> {{ $t('OriginDropdown.descriptionLength')}}</span>
                        </div>

                        <div class="form-group col-md-4">
                            <div class="checkbox form-check-inline mx-2">
                                <input type="checkbox" id="inlineCheckbox1" v-model="origin.isActive">
                                <label for="inlineCheckbox1"> {{ $t('OriginDropdown.Active') }} </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!loading">
                    <div class="modal-footer">
                        <button type="button" class="btn btn-soft-primary btn-sm" v-on:click="SaveOrigin"
                            v-bind:disabled="$v.origin.$invalid" v-if="type != 'Edit'">{{$t('OriginDropdown.btnSave')}}</button>
                        <button type="button" class="btn btn-soft-primary btn-sm" v-on:click="SaveOrigin"
                            v-bind:disabled="$v.origin.$invalid" v-if="type == 'Edit'">{{ Update}}</button>
                        <button type="button" class="btn btn-soft-secondary btn-sm" v-on:click="close()">{{ $t('OriginDropdown.btnClear')}}</button>
                    </div>
                </div>
                <div v-else>
                    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import clickMixin from '@/Mixins/clickMixin'
import { requiredIf, maxLength } from "vuelidate/lib/validators"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    name: 'origindropdown',
    props: ["values", 'disabled', 'isMultiple'],
    mixins: [clickMixin],
    components: {
        Multiselect,
        Loading
    },
    data: function () {
        return {
            arabic: '',
            english: '',
            options: [],
            value: '',
            show: false,
            type: '',
            origin: {
                id: '00000000-0000-0000-0000-000000000000',
                code: '',
                name: '',
                nameArabic: '',
                description: '',
                isActive: true
            },
            render: 0,
            loading: false,
        }
    },
    validations: {
        origin: {
            name: {
                maxLength: maxLength(50)
            },
            nameArabic: {
                required: requiredIf((x) => {
                    if (x.name == '' || x.name == null)
                        return true;
                    return false;
                }),
                maxLength: maxLength(50)
            },
            code: {
                maxLength: maxLength(30)
            },
            description: {
                maxLength: maxLength(200)
            }
        }
    },
    methods: {
        getData: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            this.$https.get('/Product/OriginList?isActive=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {


                if (response.data != null) {
                    response.data.results.origins.forEach(function (cat) {

                        root.options.push({
                            id: cat.id,
                            name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (cat.name != '' && cat.name != null) ? cat.code + ' ' + cat.name : cat.code + ' ' + cat.nameArabic : (cat.nameArabic != '' && cat.nameArabic != null) ? cat.code + ' ' + cat.nameArabic : cat.code + ' ' + cat.name
                        })
                    })
                }
            }).then(function () {
                root.value = root.options.find(function (x) {
                    return x.id == root.values;
                })
            });
        },
        AddOrigin: function (type) {
            this.$v.$reset();
            this.GetAutoCodeGenerator();
            this.origin = {
                id: '00000000-0000-0000-0000-000000000000',
                code: '',
                name: '',
                nameArabic: '',
                description: '',
                isActive: true
            }

            this.show = !this.show;
            this.type = type;
        },
        close: function () {
            this.show = false;
        },
        GetAutoCodeGenerator: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.get('/Product/OriginCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                if (response.data != null) {
                    root.origin.code = response.data;
                    root.render++;
                }
            });
        },
        SaveOrigin: function () {
            var root = this;
            this.loading = true;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.post('/Product/SaveOrigin', this.origin, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data.isSuccess == true) {
                        if (root.type != "Edit") {
                            root.$store.state.originList.push({
                                id: response.data.origin.id,
                                name: response.data.origin.name,
                                nameArabic: response.data.origin.nameArabic,
                                description: response.data.origin.description,
                                code: response.data.origin.code,
                            })
                            root.loading = false
                            if (response.data.origin.isActive == true) {
                                root.options.push({
                                    id: response.data.origin.id,
                                    code: root.origin.code,
                                    name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (response.data.origin.name != '' && response.data.origin.name != null) ? response.data.origin.code + ' ' + response.data.origin.name : response.data.origin.code + ' ' + response.data.origin.nameArabic : (response.data.origin.nameArabic != '' && response.data.origin.nameArabic != null) ? response.data.origin.code + ' ' + response.data.origin.nameArabic : response.data.origin.code + ' ' + response.data.origin.name,
                                    nameArabic: root.origin.nameArabic,
                                    description: root.origin.description
                                })
                            }
                            root.show = false;
                            root.$swal({
                                icon: 'success',
                                title: 'Saved Successfully!',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            var data = root.$store.state.originList.find(function (x) {
                                return x.id == response.data.origin.id;
                            });
                            data.id = response.data.origin.id;
                            data.name = (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (response.data.origin.name != '' && response.data.origin.name != null) ? response.data.origin.code + ' ' + response.data.origin.name : response.data.origin.code + ' ' + response.data.origin.nameArabic : (response.data.origin.nameArabic != '' && response.data.origin.nameArabic != null) ? response.data.origin.code + ' ' + response.data.origin.nameArabic : response.data.origin.code + ' ' + response.data.origin.name,
                                data.nameArabic = response.data.origin.nameArabic;
                            data.description = response.data.origin.description;
                            data.code = response.data.origin.code;
                            root.$swal({
                               title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Update!' : 'تم التحديث!',
                                text: "Your Origin " + response.data.origin.name + " has been updated!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 800,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                    }
                    else {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: "Your Origin Name  Already Exist!",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                            text: error.response.data,

                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    root.loading = false
                })
                .finally(() => root.loading = false)
        }
    },
    computed: {
        DisplayValue: {
            get: function () {
                if (this.value != "" || this.value != undefined) {
                    return this.value;
                }
                return this.values;
            },
            set: function (value) {
                this.value = value;
                if (value == null || value == undefined) {
                    this.$emit('input', value);

                }
                else {
                    if (this.isMultiple == true) {
                        this.$emit('input', value);
                    }
                    else {
                        this.$emit('input', value.id);
                    }


                }
            }
        }
    },
    mounted: function () {
        this.english = localStorage.getItem('English');
        this.arabic = localStorage.getItem('Arabic');
        this.getData();
    },
}
</script>