<template>
    <div>
        <div ref="mychildcomponent" hidden id='purchaseInvoice' class="col-md-12" style="color:black !important">

            <div >
                <!--page1-->
                <div v-if="itemTotal <= 24">
                    <!--HEADER-->
                    <div class="col-md-12"
                        style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                        v-if="isHeaderFooter == 'true'">
                        <div class="row" style="height:35mm">
                            <div class="col-md-4 ">
                                <table class="text-left">
                                    <tr>
                                        <td>
                                            <p>
                                                <span
                                                    style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                <span
                                                    style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                <span
                                                    style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                    No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                <span style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                    No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                <span style="font-size:15px;color:black !important;font-weight:bold;">
                                                    Tel: {{ headerFooters.company.phoneNo }}
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-md-4 text-center my-5" style="padding:0px !important; margin:0 !important">
                                <img :src="headerFooters.company.logoPath"
                                    style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                            </div>
                            <div class="col-md-4 ">
                                <table class="text-right">
                                    <tr>
                                        <td>
                                            <p>
                                                <span
                                                    style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                <span
                                                    style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                <span
                                                    style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                    ضريبة القيمة المضافة:
                                                    {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                <span
                                                    style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                    السجل التجاري :{{ headerFooters.company.companyRegNo }}</span><br />
                                                <span style="font-size:15px;color:black !important;font-weight:bold;">
                                                    هاتف: {{ headerFooters.company.phoneNo }}:
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="row " style="margin-bottom:10px !important;height:10mm">
                            <div class="col-md-12">
                                <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                    <span
                                        style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                        ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                        }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en' || isLeftToRight()) ?
                                        '(Hold)' : '(معلق)' }} </span></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style="height:60mm;" v-else></div>

                    <div
                        style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                        <div class="row">
                            <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                <div>
                                    <!--Row 1-->
                                    <div class="row">
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Date:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.date }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :تاريخ
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                Delivery No:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.registrationNo }}</div>
                                            <div
                                                style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                :رقم التسجيل</div>
                                        </div>
                                    </div>
                                    <!--Row 2-->
                                    <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                    <!--Row 3-->
                                    <div class="row">
                                        <div class="col-md-12" style="display:flex;">
                                            <div
                                                style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                <span>Address</span>:
                                            </div>
                                            <div
                                                style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                {{ list.customerAddress }}
                                            </div>
                                            <div
                                                style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                : عنوان
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--INFORMATION-->
                    <div
                        style="height:205mm; background-color: #ffffff !important; border:2px solid !important; background-color: #ffffff !important;">
                        <div class="row ">
                            <div class="col-md-12 ">
                                <table class="table">
                                    <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                        v-if="invoicePrint == 'العربية'">
                                        <th
                                            style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                            #</th>
                                        <th class="text-center"
                                            style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                            v-if="headerFooters.styleNo">رقم الموديل</th>
                                        <th class="text-right"
                                            style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                            v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف</th>
                                        <th class="text-right"
                                            style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                            v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم الصنف
                                        </th>
                                        <th class="text-right"
                                            style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                            v-else>اسم الصنف</th>
                                        <th class="text-center"
                                            style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                            v-if="headerFooters.itemPieceSize">حجم </th>
                                        <th class="text-center"
                                            style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                            الكمية </th>
                                        <th class="text-center"
                                            style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                            v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                    </tr>
                                    <tr class="heading" style="font-size:16px;" v-else>
                                        <th
                                            style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                            #</th>
                                        <th class="text-center"
                                            style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                            v-if="headerFooters.styleNo">Model/Style</th>
                                        <th class="text-left"
                                            style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                            v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product Name
                                        </th>
                                        <th class="text-left"
                                            style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                            v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">Product
                                            Name</th>
                                        <th class="text-left"
                                            style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                            v-else>Product Name</th>
                                        <th class="text-center"
                                            style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                            v-if="headerFooters.itemPieceSize">Package size</th>
                                        <th class="text-center"
                                            style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                            Qty</th>
                                        <th class="text-center"
                                            style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                            v-if="isMultiUnit == 'true'">Total Qty</th>
                                    </tr>


                                    <tr style="font-size:15px;font-weight:bold;"
                                        v-for="(item, index) in list.deliveryChallanItems" v-bind:key="item.id">
                                        <td class="text-left"
                                            style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                            {{ index+ 1}}</td>
                                        <td class="text-center"
                                            style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                            v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                        <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                            class="text-left"
                                            style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                            {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span><span style="font-size: 13px; ">1{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                        <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                            class="text-left"
                                            style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                            <span
                                              style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">2{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                        </td>
                                        <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                            class="text-left"
                                            style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                           3{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                        <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                            class="text-left"
                                            style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                            <span
                                                style="float:right !important;color:black !important;">4{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                        </td>
                                        <td v-else
                                            style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                            <span
                                                style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            <span style="float:right !important;color:black !important;">
                                                {{ item.arabicName }}
                                            </span>
                                        </td>
                                        <td class="text-center"
                                            style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                            v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                        <td class="text-center"
                                            style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                            v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}</td>
                                        <td class="text-center"
                                            style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                            v-else>{{ item.quantity }}</td>
                                        <td class="text-center"
                                            style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                            v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                        <div class="row" style="background-color: #ffffff !important;">
                            <div class="col-md-12">
                                <table class="table ">
                                    <tr>
                                        <td
                                            style="padding: 3px 7px; font-size: 16px; font-weight: bold; border-left: 2px solid ; border-top: 2px solid; border-right: 0; border-bottom: 2px solid ; width: 60%; color: black !important; ">
                                        </td>
                                        <td style="padding: 3px 7px; font-size: 16px; font-weight: bold; border-right: 2px solid ; border-top: 2px solid; border-left: 0; border-bottom: 2px solid ; width: 40%; color: black !important; text-align: right; "
                                            v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{ calulateTotalQty }}
                                        </td>
                                        <td style="padding: 3px 7px; font-size: 16px; font-weight: bold; border-right: 2px solid ; border-top: 2px solid; border-left: 0; border-bottom: 2px solid ; width: 40%; color: black !important; text-align: center; "
                                            v-else>Total Quantity : {{ calulateTotalQty }} </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12" style="height:69mm; background-color: #ffffff !important;"
                        v-if="isHeaderFooter == 'true'">
                        <div class="row" style="background-color: #ffffff !important;">
                            <table class="table text-center">
                                <tr>
                                    <td
                                        style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                        {{ $t('AllReport.ReceivedBy') }}::
                                    </td>
                                    <td
                                        style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                        {{ $t('AllReport.ApprovedBy') }}::
                                    </td>
                                    <td
                                        style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                        {{ $t('AllReport.PreparedBy') }}::
                                    </td>
                                </tr>

                            </table>
                        </div>
                    </div>
                </div>

                <!--page2-->
                <div v-else-if="itemTotal > 24 && itemTotal <= 48">
                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="height:35mm; background-color: #ffffff !important;">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>

                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>


                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP1"
                                            v-bind:key="item.id">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 1}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table ">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px; font-size: 16px; font-weight: bold; border-left: 2px solid ; border-top: 2px solid; border-right: 0; border-bottom: 2px solid ; width: 60%; color: black !important; ">
                                                Page: 1 - 2</td>
                                            <td style="padding: 3px 7px; font-size: 16px; font-weight: bold; border-right: 2px solid ; border-top: 2px solid; border-left: 0; border-bottom: 2px solid ; width: 40%; color: black !important; text-align: right; "
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    listItemP1Summary.calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px; font-size: 16px; font-weight: bold; border-right: 2px solid ; border-top: 2px solid; border-left: 0; border-bottom: 2px solid ; width: 40%; color: black !important; text-align: center; "
                                                v-else>Total Quantity : {{ listItemP1Summary.calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>

                    </div>


                    <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
                    <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>


                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>


                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP2"
                                            v-bind:key="index">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 25}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table ">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px; font-size: 16px; font-weight: bold; border-left: 2px solid ; border-top: 2px solid; border-right: 0; border-bottom: 2px solid ; width: 60%; color: black !important; ">
                                                Page: 2 - 2</td>
                                            <td style="padding: 3px 7px; font-size: 16px; font-weight: bold; border-right: 2px solid ; border-top: 2px solid; border-left: 0; border-bottom: 2px solid ; width: 40%; color: black !important; text-align: right; "
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px; font-size: 16px; font-weight: bold; border-right: 2px solid ; border-top: 2px solid; border-left: 0; border-bottom: 2px solid ; width: 40%; color: black !important; text-align: center; "
                                                v-else>Total Quantity : {{ calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>

                    </div>
                </div>

                <!--page3-->
                <div v-else-if="itemTotal > 48 && itemTotal <= 72">
                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>


                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP1"
                                            v-bind:key="item.id">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 1}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 1 - 3</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    listItemP1Summary.calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ listItemP1Summary.calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>

                    </div>


                    <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
                    <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>

                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important; height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>


                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP2"
                                            v-bind:key="item.id">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 25}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}
                                                    </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 2 - 3</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    listItemP2Summary.calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ listItemP2Summary.calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>


                    <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
                    <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>


                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important; height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>


                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP3"
                                            v-bind:key="index">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 49}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 3 - 3</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!--page4-->
                <div v-else-if="itemTotal > 72 && itemTotal <= 96">
                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>

                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP1"
                                            v-bind:key="item.id">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 1}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 1 - 4</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    listItemP1Summary.calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ listItemP1Summary.calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>


                    <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
                    <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>

                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>

                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP2"
                                            v-bind:key="item.id">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 25}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 2 - 4</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    listItemP2Summary.calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ listItemP2Summary.calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>

                    <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
                    <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>

                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>

                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP3"
                                            v-bind:key="item.id">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 49}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 3 - 4</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    listItemP3Summary.calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ listItemP3Summary.calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>

                    <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
                    <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>

                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>


                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP4"
                                            v-bind:key="index">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 73}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 4 - 4</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!--page5-->
                <div v-else-if="itemTotal > 96 && itemTotal <= 120">
                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>

                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP1"
                                            v-bind:key="item.id">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 1}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 1 - 5</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    listItemP1Summary.calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ listItemP1Summary.calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>


                    <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
                    <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>

                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>

                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP2"
                                            v-bind:key="item.id">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 25}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 2 - 5</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    listItemP2Summary.calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ listItemP2Summary.calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('AllReport.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>

                    <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
                    <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>

                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>

                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP3"
                                            v-bind:key="item.id">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 49}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 3 - 5</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    listItemP3Summary.calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ listItemP3Summary.calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>
                    <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
                    <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>

                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>

                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP4"
                                            v-bind:key="item.id">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 49}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 4 - 5</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    listItemP4Summary.calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ listItemP4Summary.calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>

                    <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
                    <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>

                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>


                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP5"
                                            v-bind:key="index">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 73}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 5 - 5</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!--page6-->
                <div v-else-if="itemTotal > 120 && itemTotal <= 144">
                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>

                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP1"
                                            v-bind:key="item.id">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 1}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 1 - 6</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    listItemP1Summary.calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ listItemP1Summary.calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>


                    <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
                    <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>

                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>

                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP2"
                                            v-bind:key="item.id">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 25}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 2 - 6</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    listItemP2Summary.calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ listItemP2Summary.calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>

                    <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
                    <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>

                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>

                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP3"
                                            v-bind:key="item.id">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 49}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 3 - 6</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    listItemP3Summary.calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ listItemP3Summary.calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>

                    <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
                    <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>

                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>


                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP4"
                                            v-bind:key="index">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 73}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 4 - 6</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    listItemP4Summary.calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ listItemP4Summary.calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>
                    <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
                    <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>
                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>


                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP5"
                                            v-bind:key="index">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 97}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 5 - 6</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    listItemP5Summary.calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ listItemP5Summary.calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>
                    <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
                    <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>


                    <div>
                        <!--HEADER-->
                        <div class="col-md-12"
                            style="height:45mm; background-color: #ffffff !important; border:2px solid #000000;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;height:35mm">
                                <div class="col-md-4 ">
                                    <table class="text-left">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameEnglish }}</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryEnglish }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">VAT
                                                        No.: {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">Cr
                                                        No.:{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        Tel: {{ headerFooters.company.phoneNo }}
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4 text-center my-5"
                                    style="padding:0px !important; margin:0 !important">
                                    <img :src="headerFooters.company.logoPath"
                                        style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                                </div>
                                <div class="col-md-4 ">
                                    <table class="text-right">
                                        <tr>
                                            <td>
                                                <p>
                                                    <span
                                                        style="font-size:25px;color:black !important;font-weight:bold;">{{ headerFooters.company.nameArabic }}.</span><br />
                                                    <span
                                                        style="font-size:17px;color:black !important;font-weight:bold;">{{ headerFooters.company.categoryArabic }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        ضريبة القيمة المضافة:
                                                        {{ headerFooters.company.vatRegistrationNo }}</span><br />
                                                    <span
                                                        style="font-size:16px;color:black !important;font-weight:bold;">رقم
                                                        السجل التجاري
                                                        :{{ headerFooters.company.companyRegNo }}</span><br />
                                                    <span
                                                        style="font-size:15px;color:black !important;font-weight:bold;">
                                                        هاتف: {{ headerFooters.company.phoneNo }}:
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row " style="margin-bottom:10px !important;height:10mm">
                                <div class="col-md-12">
                                    <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                        <span
                                            style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{
                                            ($i18n.locale == 'en' || isLeftToRight()) ? 'Delivery Note' : 'مذكرة تسليم'
                                            }}<span v-if="list.invoiceType == 0">{{ ($i18n.locale == 'en'
                                            || isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="height:60mm;" v-else></div>

                        <div
                            style="height:45mm; background-color: #ffffff !important; margin-top:1mm; border:2px solid #000000;">
                            <div class="row">
                                <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                                    <div>
                                        <!--Row 1-->
                                        <div class="row">
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                    Date:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.date }}
                                                </div>
                                                <div
                                                    style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                    :تاريخ
                                                </div>
                                            </div>
                                            <div class="col-md-6" style="display:flex;">
                                                <div
                                                    style="width:28%; font-weight:bolder;text-align:right;color:black !important;">
                                                    Delivery No:</div>
                                                <div
                                                    style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                    {{ list.registrationNo }}</div>
                                                <div
                                                    style="width:22%;font-weight:bolder;font-size:15px !important;color:black !important;">
                                                    :رقم التسجيل</div>
                                            </div>
                                        </div>
                                        <!--Row 2-->
                                        <div class="row">
                                        <div class="col-md-6" style="display:flex;" v-if="list.saleInvoiceRegistrationNumber == null">
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Order:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleOrderRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم أمر البيع
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;" v-else>
                                            <div 
                                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;">
                                                Sale Invoice:</div>
                                            <div
                                                style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                                {{ list.saleInvoiceRegistrationNumber }}
                                            </div>
                                            <div
                                                style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;">
                                                :رقم فاتورة المبيعات
                                            </div>
                                        </div>
                                        <div class="col-md-6" style="display:flex;">
                                            <div
                                                style="width:28%; font-weight:bolder;text-align:right;color:black !important; ">
                                                <span>Customer Name</span>:
                                            </div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-if="list.cashCustomer != null">{{ list.cashCustomer }}</div>
                                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"
                                                v-else>{{ invoicePrint == 'العربية' ? list.customerNameAr :
                                                list.customerNameEn}}</div>
                                            <div
                                                style="width:22%; font-weight:bolder;color:black !important; padding-right:6px;font-size:15px !important">
                                                : اسم العميل </div>
                                        </div>
                                    </div>
                                        <!--Row 3-->
                                        <div class="row">
                                            <div class="col-md-12" style="display:flex;">
                                                <div
                                                    style="width:13%; font-weight:bolder;text-align:right; color:black !important;">
                                                    <span>Address</span>:
                                                </div>
                                                <div
                                                    style="width:76%;text-align:center;font-weight:bold;color:black !important;">
                                                    {{ list.customerAddress }}
                                                </div>
                                                <div
                                                    style="width:11%; font-weight:bolder; font-size:15px !important;color:black !important;">
                                                    : عنوان
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--INFORMATION-->
                        <div style="height:205mm; background-color: #ffffff !important; border:2px solid !important;">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <table class="table">
                                        <tr class="heading" style="font-size:16px !important;padding-top:5px;"
                                            v-if="invoicePrint == 'العربية'">
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">رقم الموديل</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">اسم الصنف
                                            </th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">اسم
                                                الصنف</th>
                                            <th class="text-right"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-else>اسم الصنف</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.itemPieceSize">حجم </th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;">
                                                الكمية </th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="isMultiUnit == 'true'">إجمالي الكمية </th>
                                        </tr>
                                        <tr class="heading" style="font-size:16px;" v-else>
                                            <th
                                                style="width:3%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">
                                                #</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"
                                                v-if="headerFooters.styleNo">Model/Style</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="!headerFooters.styleNo && headerFooters.itemPieceSize">Product
                                                Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else-if="headerFooters.styleNo && !headerFooters.itemPieceSize">
                                                Product Name</th>
                                            <th class="text-left"
                                                style="width:50%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-else>Product Name</th>
                                            <th class="text-center"
                                                style="width:10%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="headerFooters.itemPieceSize">Package size</th>
                                            <th class="text-center"
                                                style="width:13%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">
                                                Qty</th>
                                            <th class="text-center"
                                                style="width:14%;padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;"
                                                v-if="isMultiUnit == 'true'">Total Qty</th>
                                        </tr>


                                        <tr style="font-size:15px;font-weight:bold;" v-for="(item, index) in listItemP6"
                                            v-bind:key="index">
                                            <td class="text-left"
                                                style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">
                                                {{ index+ 97}}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.styleNo">{{ item.product.styleNumber }}</td>
                                            <td v-if="english == 'true' && arabic == 'false' && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'false' && headerFooters.arabicName"
                                                class="text-left"
                                                style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else-if="isOtherLang() && english == 'true' && !headerFooters.arabicName && headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
                                                {{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></td>
                                            <td v-else-if="isOtherLang() && english == 'true' && headerFooters.arabicName && !headerFooters.englishName"
                                                class="text-left"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:right !important;color:black !important;">{{ item.arabicName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                            </td>
                                            <td v-else
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">
                                                <span
                                                    style="float:left !important;color:black !important;">{{ item.productName }}<span style="font-size: 13px; ">{{item.productId != null?'':item.description.slice(0,250)}}</span></span>
                                                <span style="float:right !important;color:black !important;">
                                                    {{ item.arabicName }}
                                                </span>
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="headerFooters.itemPieceSize">{{ item.product.width }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.highQty }} - {{ item.newQuantity }}
                                            </td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-else>{{ item.quantity }}</td>
                                            <td class="text-center"
                                                style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;"
                                                v-if="isMultiUnit == 'true'">{{ item.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style="height:41mm; background-color: #ffffff !important; margin-top:1mm;">
                            <div class="row" style="background-color: #ffffff !important;">
                                <div class="col-md-12">
                                    <table class="table table-bordered tbl_padding">
                                        <tr>
                                            <td
                                                style="padding: 3px 7px;font-size:16px;font-weight:bold; width:25%;border:2px solid black !important;color:black !important;">
                                                Page: 6 - 6</td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important; text-align:right;"
                                                v-if="invoicePrint == 'العربية'">الكمية الإجمالية : {{
                                                    calulateTotalQty
                                                }} </td>
                                            <td style="padding: 3px 7px;font-size:16px;font-weight:bold;width:25%;border:2px solid black !important;color:black !important;"
                                                v-else>Total Quantity : {{ calulateTotalQty }} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12  " style="height:69mm; background-color: #ffffff !important;"
                            v-if="isHeaderFooter == 'true'">
                            <div class="row" style="background-color: #ffffff !important;">
                                <table class="table text-center">

                                    <tr>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ReceivedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.ApprovedBy') }}::
                                        </td>
                                        <td
                                            style="color: black !important;font-size:17px; font-weight:bold;border-top:0 !important;">
                                            {{ $t('SaleInvoice.PreparedBy') }}::
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
const options = {
    name: '',
    specs: [
        'fullscreen=no',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css',

    ],
    timeout: 1000,
    autoClose: false,
    windowTitle: window.document.title,

}
import clickMixin from '@/Mixins/clickMixin'
export default {
    mixins: [clickMixin], props: ['printDetails', 'headerFooter', 'isTouchScreen', 'formName'],
    components: {
    },
    data: function () {
        return {
            b2b: false,
            b2c: false,
            taxInvoiceLabel: "",
            taxInvoiceLabelAr: '',
            simplifyTaxInvoiceLabel: '',
            simplifyTaxInvoiceLabelAr: '',
            qrValue: "",
            listItemP1Summary: {
                calulateTotalQty: 0,
                calulateNetTotal: 0,
                calulateTotalExclVAT: 0,
                calulateTotalVAT: 0,
                calulateTotalInclusiveVAT: 0,
                calulateDiscountAmount: 0,
                calulateBundleAmount: 0,
            },
            listItemP2Summary: {
                calulateTotalQty: 0,
                calulateNetTotal: 0,
                calulateTotalExclVAT: 0,
                calulateTotalVAT: 0,
                calulateTotalInclusiveVAT: 0,
                calulateDiscountAmount: 0,
                calulateBundleAmount: 0,
            },
            listItemP3Summary: {
                calulateTotalQty: 0,
                calulateNetTotal: 0,
                calulateTotalExclVAT: 0,
                calulateTotalVAT: 0,
                calulateTotalInclusiveVAT: 0,
                calulateDiscountAmount: 0,
                calulateBundleAmount: 0,
            },
            listItemP4Summary: {
                calulateTotalQty: 0,
                calulateNetTotal: 0,
                calulateTotalExclVAT: 0,
                calulateTotalVAT: 0,
                calulateTotalInclusiveVAT: 0,
                calulateDiscountAmount: 0,
                calulateBundleAmount: 0,
            },
            listItemP5Summary: {
                calulateTotalQty: 0,
                calulateNetTotal: 0,
                calulateTotalExclVAT: 0,
                calulateTotalVAT: 0,
                calulateTotalInclusiveVAT: 0,
                calulateDiscountAmount: 0,
                calulateBundleAmount: 0,
            },
            listItemP1: [],
            listItemP2: [],
            listItemP3: [],
            listItemP4: [],
            listItemP5: [],
            listItemP6: [],
            itemTotal: 0,
            isHeaderFooter: '',
            invoicePrint: '',
            IsDeliveryNote: 'true',
            arabic: '',
            english: '',
            userName: '',
            isMultiUnit: '',
            list: {
                number: 0,

                listItemTotal: [],
                registrationNo: '',
                date: '',
                dueDate: '',
                companyName: '',
                companyPhoneNo: '',
                companyAddress: '',
                discountAmount: '',
                cashCustomer: '',
                creditCustomer: '',
                customerPhoneNo: '',
                customerAddress: '',
                paymentMethod: '',
                paymentMethodNo: '',
                invocieType: '',
                deliveryChallanItems:
                    [

                    ]
            },
            render: 0,
            headerFooters: {
                footerEn: '',
                footerAr: '',
                company: ''
            },
            htmlData: {
                htmlString: ''
            }
        }
    },
    filters: {
        toWords: function (value) {
            var converter = require('number-to-words');
            if (!value) return ''
            return converter.toWords(value);
        }
    },
    computed: {
        calulateTotalQty: function () {
            return this.list.deliveryChallanItems.reduce(function (a, c) { return a + (Number((c.quantity) || 0) > 0 ? Number((c.quantity) || 0) : 0) }, 0)
        },

    },
    methods: {
        calulateTotalQtySub: function (val) {
            return val.reduce(function (a, c) { return a + (Number((c.quantity) || 0) > 0 ? Number((c.quantity) || 0) : 0) }, 0)
        },


        PrintOption: function (x) {

            if (x == undefined || !x.value) {

                return false;
            }
            return true;
        },




        printInvoice: function () {

            var root = this;
            this.$htmlToPaper('purchaseInvoice', options, () => {
                if (root.isTouchScreen === 'TouchInvoice') {

                    root.$router.go('/TouchInvoice')
                }
                else if (root.isTouchScreen === 'addSale') {
                    root.$router.push({
                        query: {
                            data: undefined
                        }
                    });
                    root.$router.go('/addSale')
                }
                else if (root.isTouchScreen === 'sale') {

                    root.$router.push('/sale');
                }
                else {

                    console.log('No Rander the Page');
                }

            });
        },


        GetTLVForValue: function (tagNumber, tagValue) {
            var tagBuf = Buffer.from([tagNumber], 'utf-8')
            var tagValueLenBuf = Buffer.from([tagValue.length], 'utf-8')
            var tagValueBuf = Buffer.from(tagValue, 'utf-8')
            var bufsArray = [tagBuf, tagValueLenBuf, tagValueBuf]
            return Buffer.concat(bufsArray)
        }

    },
    mounted: function () {

        this.b2b = localStorage.getItem('b2b') == 'true' ? true : false;
        this.b2c = localStorage.getItem('b2c') == 'true' ? true : false;
        this.taxInvoiceLabel = localStorage.getItem('taxInvoiceLabel');
        this.taxInvoiceLabelAr = localStorage.getItem('taxInvoiceLabelAr');
        this.simplifyTaxInvoiceLabel = localStorage.getItem('simplifyTaxInvoiceLabel');
        this.simplifyTaxInvoiceLabelAr = localStorage.getItem('simplifyTaxInvoiceLabelAr');

        this.isMultiUnit = localStorage.getItem('IsMultiUnit');
        this.english = localStorage.getItem('English');
        this.arabic = localStorage.getItem('Arabic');
        this.invoicePrint = localStorage.getItem('InvoicePrint');
        this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
        this.userName = localStorage.getItem('FullName');
        var root = this;
        if (this.printDetails.deliveryChallanItems.length > 0) {
            this.list = this.printDetails;
            this.headerFooters = this.headerFooter;


            {
                var sellerNameBuff = root.GetTLVForValue('1', this.headerFooters.company.nameEnglish)
                var vatRegistrationNoBuff = root.GetTLVForValue('2', this.headerFooters.company.vatRegistrationNo)
                var timeStampBuff = root.GetTLVForValue('3', this.list.date)
                var tagArray = [sellerNameBuff, vatRegistrationNoBuff, timeStampBuff]
                var qrCodeBuff = Buffer.concat(tagArray)
                root.qrValue = qrCodeBuff.toString('base64')

                var totalItem = this.printDetails.deliveryChallanItems.length;

                this.itemTotal = totalItem;

                if (totalItem <= 24) {
                    for (var i = 0; i < totalItem; i++) {
                        root.listItemP1.push(root.printDetails.deliveryChallanItems[i]);
                    }
                }
                else if (totalItem > 24 && totalItem <= 48) {
                    for (var k = 0; k < totalItem; k++) {
                        if (k <= 23) {
                            root.listItemP1.push(root.printDetails.deliveryChallanItems[k]);
                        }
                        else {
                            root.listItemP2.push(root.printDetails.deliveryChallanItems[k]);
                        }
                    }
                }
                else if (totalItem > 48 && totalItem <= 72) {
                    for (var j = 0; j < totalItem; j++) {
                        if (j <= 23) {
                            root.listItemP1.push(root.printDetails.deliveryChallanItems[j]);
                        }
                        else if (j > 23 && j <= 47) {
                            root.listItemP2.push(root.printDetails.deliveryChallanItems[j]);
                        }
                        else {
                            root.listItemP3.push(root.printDetails.deliveryChallanItems[j]);
                        }
                    }
                }

                else if (totalItem > 72 && totalItem <= 96) {
                    for (var l = 0; l < totalItem; l++) {
                        if (l <= 23) {
                            root.listItemP1.push(root.printDetails.deliveryChallanItems[l]);
                        }
                        else if (l > 23 && l <= 47) {
                            root.listItemP2.push(root.printDetails.deliveryChallanItems[l]);
                        }
                        else if (l > 47 && l <= 71) {
                            root.listItemP3.push(root.printDetails.deliveryChallanItems[l]);
                        }

                        else {
                            root.listItemP4.push(root.printDetails.deliveryChallanItems[l]);
                        }
                    }
                }

                else if (totalItem > 96 && totalItem <= 120) {
                    for (var m = 0; m < totalItem; m++) {
                        if (m <= 23) {
                            root.listItemP1.push(root.printDetails.deliveryChallanItems[m]);
                        }
                        else if (m > 23 && m <= 47) {
                            root.listItemP2.push(root.printDetails.deliveryChallanItems[m]);
                        }
                        else if (m > 47 && m <= 71) {
                            root.listItemP3.push(root.printDetails.deliveryChallanItems[m]);
                        }
                        else if (m > 71 && m <= 96) {
                            root.listItemP4.push(root.printDetails.deliveryChallanItems[m]);
                        }
                        else {
                            root.listItemP5.push(root.printDetails.deliveryChallanItems[m]);
                        }
                    }
                }
                else if (totalItem > 120 && totalItem <= 144) {
                    for (var n = 0; m < totalItem; n++) {
                        if (n <= 23) {

                            root.listItemP1.push(root.printDetails.deliveryChallanItems[n]);
                        }
                        else if (n > 23 && n <= 47) {
                            root.listItemP2.push(root.printDetails.deliveryChallanItems[n]);
                        }
                        else if (n > 47 && n <= 71) {
                            root.listItemP3.push(root.printDetails.deliveryChallanItems[n]);
                        }
                        else if (n > 71 && n <= 96) {
                            root.listItemP4.push(root.printDetails.deliveryChallanItems[n]);
                        }
                        else if (n > 96 && n <= 120) {
                            root.listItemP5.push(root.printDetails.deliveryChallanItems[n]);
                        }
                        else {
                            root.listItemP6.push(root.printDetails.deliveryChallanItems[n]);
                        }
                    }
                }
                /*summary calculate listItemP1Summary*/
                root.listItemP1Summary.calulateTotalQty = this.calulateTotalQtySub(root.listItemP1);


                root.listItemP2Summary.calulateTotalQty = this.calulateTotalQtySub(root.listItemP2);



                root.listItemP3Summary.calulateTotalQty = this.calulateTotalQtySub(root.listItemP3);


                root.listItemP4Summary.calulateTotalQty = this.calulateTotalQtySub(root.listItemP4);


                root.listItemP5Summary.calulateTotalQty = this.calulateTotalQtySub(root.listItemP5);




                this.list.date = moment(this.list.date).format('DD MMM YYYY');
                setTimeout(function () {
                    root.printInvoice();
                }, 125)
            }

        }
    },

}
</script>


