<template>
    <modal :show="show" :modalLarge="true" v-if="isValid('Day Close')">

        <div style="margin-bottom:0px" class="card" v-bind:key="counterRander">
            <div class="card-body">
                <div class="col-lg-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                    <div class="tab-content" id="nav-tabContent">

                        <div class="modal-header">

                            <h5 class="modal-title DayHeading" id="myModalLabel">{{ $t('DayEnd') }}</h5>

                        </div>
                        <div class="card-body">

                            <!-- Row 1 -->

                            <div class="row">
                                <div class="col-sm-12 mb-2">
                                    <h6 class="modal-title DayHeading" id="myModalLabel" style="background-color:#B4C6E7;padding-top:3px; padding-bottom:3px;padding-left:3px;font-size:14px">{{ $t('UserAndTerminalDetails') }}</h6>
                                </div>

                                <div class="form-group has-label col-sm-3 ">
                                    <span class="text" style="font-size:14px;">{{ $t('User') }}: </span>


                                </div> <div class="form-group has-label col-sm-3 ">
                                    <label style="font-size:14px;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'pl-4 font-weight-bolder  ' : 'pr-4 font-weight-bolder '">  {{UserName}}</label>


                                </div>
                                <div class="form-group has-label col-sm-3 ">
                                    <span class="text" style="font-size:14px;">{{ $t('DayStarts.Counter') }}: </span>


                                </div>  <div class="form-group has-label col-sm-3 ">
                                    <label style="font-size:14px;  " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'pl-4 font-weight-bolder  ' : 'pr-4 font-weight-bolder '">  {{dayEnd.counterCode}}</label>


                                </div>
                                <div class="form-group has-label col-sm-3 ">
                                    <span class="text" style="font-size:14px;">{{ $t('DayStarts.Date') }}: </span>


                                </div>
                                <div class="form-group has-label col-sm-3 ">
                                    <label style="font-size:14px;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'pl-4 font-weight-bolder ' : 'pr-4 font-weight-bolder'">  {{date}}</label>


                                </div>
                                <div class="form-group has-label col-sm-3 ">
                                    <span class="text" style="font-size:14px;">{{ $t('Time') }}: </span>


                                </div>
                                <div class="form-group has-label col-sm-3 ">
                                    <label style="font-size:14px;  " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'pl-4 font-weight-bolder ' : 'pr-4 font-weight-bolder'">  {{getTimeOnly(startTime)}}</label>


                                </div>
                                <div class="form-group has-label col-sm-3 ">
                                    <span class="text" style="font-size:14px;">OutStanding Balance: </span>


                                </div>
                                <div class="form-group has-label col-sm-3 ">
                                    <label style="font-size:14px;  " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'pl-4 font-weight-bolder ' : 'pr-4 font-weight-bolder'">  {{outStandingBalance}}</label>


                                </div>
                            </div>
                            <!-- Row 2 -->
                            <div class="row">
                                <div class="col-sm-12 mb-2">
                                    <h6 class="modal-title DayHeading" id="myModalLabel" style="background-color:#9CC083;padding-top:3px; padding-bottom:3px;padding-left:3px;font-size:14px">{{ $t('CashSaleExpenseDetail') }}</h6>

                                </div>
                                <table class="table border ml-3 mr-3 table-bordered ">

                                    <tr style="font-size:12px;padding-bottom:4px; ">
                                        <td style="text-align:center;font-weight:bold;color:black !important"> <span>{{ $t('DayStarts.OpeningCash') }}</span></td>
                                        <td style="text-align:center;color:black !important"> <span>{{dayEnd.openingCash}}</span></td>
                                        <td style="text-align:center;font-weight:bold;color:black !important"> <span>{{ $t('CashSale') }}</span></td>
                                        <td style="text-align:center;color:black !important"> <span>{{dayEnd.cashInHand}}</span></td>
                                        <td style="text-align:center;font-weight:bold;color:black !important"> <span>{{ $t('TotalCash') }}</span></td>
                                        <td style="text-align:center;color:black !important"> <span>{{TotalCash()}}</span></td>
                                    </tr>
                                    <tr style="font-size:12px;padding-bottom:4px; ">
                                        <td style="text-align:center;font-weight:bold;color:black !important"> <span>{{ $t('DayStarts.Expense') }}</span></td>
                                        <td style="text-align:center;color:black !important"> <span> {{dayEnd.expenseCash}}</span></td>
                                        <td style="text-align:center;font-weight:bold;color:black !important"> <span>{{ $t('UserDashboard.CashInHand') }}</span></td>
                                        <td style="text-align:center;color:black !important" colspan="3"> <span> {{total}}</span></td>

                                    </tr>
                                    <!--<tr style="font-size:12px;padding-bottom:4px;">
                                        <td style="text-align:center;font-weight:bold;color:black !important"> <span>{{dayEnd.openingCash}}</span></td>
                                        <td style="text-align:center;font-weight:bold;color:black !important"> <span>{{dayEnd.cashInHand}}</span></td>
                                        <td style="text-align:center;font-weight:bold;color:black !important"> <span> {{TotalCash()}}</span></td>
                                    </tr>-->

                                </table>

                                <!--<div class="form-group has-label col-sm-2 ">
                                    <span class="text" style="font-size:14px;">{{ $t('DayStarts.OpeningCash') }}: </span>


                                </div> <div class="form-group has-label col-sm-2 ">
                                    <label style="font-size:14px;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'pl-4 font-weight-bolder  ' : 'pr-4 font-weight-bolder '">  {{dayEnd.openingCash}}</label>


                                </div>
                                <div class="form-group has-label col-sm-2 ">
                                    <span class="text" style="font-size:14px;">{{ $t('CashSale') }}: </span>


                                </div>  <div class="form-group has-label col-sm-2 ">
                                    <label style="font-size:14px;  " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'pl-4 font-weight-bolder  ' : 'pr-4 font-weight-bolder '">  {{dayEnd.cashInHand}}</label>


                                </div>
                                <div class="form-group has-label col-sm-2 ">
                                    <span class="text" style="font-size:14px;">{{ $t('TotalCash') }}: </span>


                                </div>
                                <div class="form-group has-label col-sm-2 ">
                                    <label style="font-size:14px;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'pl-4 font-weight-bolder ' : 'pr-4 font-weight-bolder'">  {{TotalCash()}}</label>


                                </div>-->
                                <!--<div class="form-group has-label col-sm-3 ">
                                    <span class="text" style="font-size:14px;">{{ $t('DayStarts.Expense') }}: </span>


                                </div>
                                <div class="form-group has-label col-sm-3 ">
                                    <label style="font-size:14px;  " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'pl-4 font-weight-bolder ' : 'pr-4 font-weight-bolder'">  {{dayEnd.expenseCash}}</label>


                                </div>
                                <div class="form-group has-label col-sm-3 ">
                                    <span class="text" style="font-size:14px;">{{ $t('UserDashboard.CashInHand') }}: </span>


                                </div>
                                <div class="form-group has-label col-sm-3 ">
                                    <label style="font-size:14px;  " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'pl-4 font-weight-bolder ' : 'pr-4 font-weight-bolder'">  {{total}}</label>


                                </div>-->
                            </div>
                            <!-- Row 3 -->
                            <!--<div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'pl-3 ' : 'pr-3'">
                                <div class="col-sm-4" style="background-color:#FFD966">
                                    <h6 class="modal-title DayHeading" id="myModalLabel" style="color: #FF0002;padding-top:2px;">{{ $t('UserDashboard.CashInHand') }}</h6>
                                </div>
                                <div class="col-sm-4" style=" background-color:#FFD966; ">
                                    <label style="font-size:14px;  " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'pl-4 font-weight-bolder ' : 'pr-4 font-weight-bolder'">  {{total}}</label>
                                </div>


                            </div>-->
                            <!-- Row 4 -->
                            <div class="row mt-2">
                                <div class="col-sm-12">
                                    <h6 class="modal-title DayHeading" id="myModalLabel" style="background-color:#F4B084;padding-top:3px; padding-bottom:3px;padding-left:3px;font-size:14px">{{ $t('BankSaleDetail') }}</h6>
                                    <div class="row">
                                        <div class="col-sm-6 mt-2">
                                            <table class="table ">

                                                <tr style="font-size:12px;padding-bottom:4px;border-color:black !important" v-for="bank in bankDetails" v-bind:key="bank.bankName">
                                                    <td v-bind:style="        ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-align:left;border-top:1px solid;color: black !important;font-weight:bold' : 'text-align:right;border-top:1px solid;color: black !important;font-weight:bold'"> <span>{{bank.bankName}}</span>:</td>
                                                    <td v-bind:style="        ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-align:right;border-top:1px solid;color: black !important;font-weight:bold' : 'text-align:left;border-top:1px solid;color: black !important;font-weight:bold'">{{parseFloat(bank.totalAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                                </tr>
                                                <tr>
                                                    <td style="color: black;font-size:13px; background-color:#FFD966;padding-top:0px !important; padding-bottom:0px !important; font-weight:bold" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-align:left' : 'text-align:right'"> <span>{{ $t('BankTotal') }}</span>:</td>
                                                    <td style="color: black;font-size:13px;background-color:#FFD966;padding-top:0px !important; padding-bottom:0px !important;font-weight:bold" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-align:right' : 'text-align:left'">{{parseFloat(dayEnd.bankAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                                </tr>

                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- Row 5 -->
                            <div class="row mt-2">
                                <div class="col-sm-12 ">
                                    <div class="row border border-dark ml-1 mr-1" style="border:1px solid !important">

                                        <div class="col-sm-4 ml-auto mr-auto" style="font-size :14px; font-weight:bold">
                                            <span>{{ $t('TotalSale') }} :</span><span>{{getTotalSale()}}</span>
                                        </div>

                                    </div>

                                </div>
                            </div>


                            <div class="row">


                                <div class="form-group has-label col-sm-6 ">
                                    <label class="text  font-weight-bolder" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'font-size:15px'"> {{ $t('TotalCash') }} </label>
                                    <decimal-to-fixed  v-bind:salePriceCheck="false" v-model="total" :disabled="true" />
                                </div>
                                <div class="form-group has-label col-sm-6 ">
                                    <label class="text  font-weight-bolder" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'font-size:15px'"> {{ $t('DayStarts.VerifyCash') }}:<span class="text-danger"> *</span> </label>
                                    <decimal-to-fixed  v-bind:salePriceCheck="false" v-model="$v.dayEnd.verifyCash.$model" v-on:input="calculateGrandTotal()" />
                                </div>
                                <div class="form-group has-label col-sm-6 ">
                                    <label class="text  font-weight-bolder" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'font-size:15px'"> {{ $t('CashTransfer') }}:<span class="text-danger"> *</span> </label>
                                    <decimal-to-fixed  v-bind:salePriceCheck="false" v-model="$v.dayEnd.carryCash.$model" v-on:input="calculateGrandTotal()" />
                                </div>
                                <div class="form-group has-label col-sm-6 ">
                                    <label class="text  font-weight-bolder" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'font-size:15px'"> {{ $t('ClosingCash') }}: </label>
                                    <decimal-to-fixed  v-bind:salePriceCheck="false" v-model="grandTotal" :disabled="true" />
                                </div>

                                <div class="form-group has-label col-sm-12 " v-if="parseFloat(total).toFixed(0) != parseFloat(dayEnd.verifyCash).toFixed(0)">
                                    <label class="text  font-weight-bolder" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'font-size:15px'"> {{ $t('DayStarts.Reason') }}:<span class="text-danger"> *</span> </label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.dayEnd.creditReason.$model" type="text" @click="$event.target.select()" />
                                </div>
                                <div class="form-group has-label col-sm-6 " v-if="dayEnd.carryCash>0 && dayEnd.isSupervisor == false">
                                    <label class="text  font-weight-bolder" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'font-size:15px'"> {{$t('CashReceivedBy')}}: <span class="text-danger"> *</span></label>
                                    <usersDropdown :isSupervisor="true" v-model="dayEnd.supervisorName"></usersDropdown>
                                </div>
                                <div class="form-group has-label col-sm-6 " v-if="dayEnd.carryCash>0 && dayEnd.isSupervisor == false">
                                    <label class="text  font-weight-bolder" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'font-size:15px'"> {{$t('Password')}}: <span class="text-danger"> *</span></label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="dayEnd.supervisorPassword" type="password" />
                                </div>
                                <div class="form-group has-label col-sm-6 ">
                                    <label class="text  font-weight-bolder" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'font-size:15px'"> {{$t('UserName')}}:<span class="text-danger"> *</span> </label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.dayEnd.user.$model" type="text" />
                                </div>
                                <div class="form-group has-label col-sm-6 ">
                                    <label class="text  font-weight-bolder" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'font-size:15px'">{{$t('Password')}}:<span class="text-danger"> *</span> </label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.dayEnd.password.$model" type="password" />
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer justify-content-right" v-if="type=='Edit'">

                            <button type="button" class="btn btn-primary  " v-on:click="SaveDayEnd" v-bind:disabled="$v.dayEnd.$invalid "> {{ $t('btnUpdate') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('Close') }}</button>

                        </div>
                        <div class="modal-footer justify-content-right" v-else>

                            <button type="button" class="btn btn-primary  " v-on:click="SaveDayEnd" v-bind:disabled="$v.dayEnd.$invalid "> {{ $t('btnSave') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('Close') }}</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <dayEndReportPrint :headerFooter="headerFooter" :printDetail="printDetail" v-if="printReport" v-bind:key="printRender"></dayEndReportPrint>
        <dayEndA4ReportPrint :headerFooter="headerFooter" :printDetail="printDetail" v-if="printA4Report" v-bind:key="printRender"></dayEndA4ReportPrint>
    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';

    import moment from "moment";
    import 'vue2-timepicker/dist/VueTimepicker.css'

    import { required } from "vuelidate/lib/validators"
    export default {
        mixins: [clickMixin],

        props: ['show', 'cashInHand', 'openingCash', 'bank', 'expense',
            'type', 'counterCode', 'counterId', 'dayStartId', 'outStandingBalance','dayWiseList'],
        data: function () {
            return {
                printReport: false,
                printA4Report: false,
                printRender: 0,
                render: 0,
                time: 0,
                dateRander: 0,
                UserName: '',
                date: '',
                InvoiceSetting: '',
                startTime: '',
                total: 0,
                grandTotal: 0,
                transactions: 0,
                isSupervisor: false,
                counterRander: 0,
                bankDetails: [],
                dayEnd: {
                    id: '',
                    date: '',
                    toTime: '',
                    isActive: true,
                    locationId: '',
                    openingCash: '',
                    cashInHand: '',
                    verifyCash: '',
                    user: '',
                    password: '',
                    creditReason: '',
                    carryCash: 0,
                    counterId: '00000000-0000-0000-0000-000000000000',
                    expenseCash: 0,
                    bankAmount: 0,
                    endTerminalBy: '',
                    supervisorName: '',
                    supervisorPassword: '',
                    isSupervisor: false,
                },
                headerFooter: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
                filePath: null,
            }
        },
        validations: {
            dayEnd: {
                date: {
                },
                toTime: {

                },
                verifyCash: {
                    required
                },
                carryCash: {
                    required
                },
                user: {
                    required
                },
                password: {
                    required
                },
                creditReason: {
                    //required: requiredIf((x) => {

                    //    if (x.openingCash - x.cashInHand - x.bankAmount - x.expense - x.expenseCash > parseFloat(x.verifyCash))
                    //        return true;

                    //    return false;
                    //})
                }

            }
        },

        methods: {
            getTimeOnly: function (x) {
                return moment(x).format('HH:mm ');
            },
            TotalCash: function () {
                return parseFloat(parseFloat(this.dayEnd.openingCash) + parseFloat(this.dayEnd.cashInHand)).toFixed(3).slice(0, -1);
            },
            getTotalSale: function () {
                return parseFloat(parseFloat(this.total) + parseFloat(this.dayEnd.bankAmount)).toFixed(3).slice(0, -1);
            },

            calculateGrandTotal: function () {
                this.grandTotal = parseFloat(parseFloat(this.dayEnd.verifyCash) - parseFloat(this.dayEnd.carryCash)).toFixed(3).slice(0, -1);
            },
            getOpeningBalance: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                //this.dayStart.counterId = id;
                this.$https.get('/Product/OpeningBalance?counterId=' + localStorage.getItem('CounterId') + '&isOpeningCash=' + false, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        
                        root.dayEnd.openingCash = response.data.openingBalance < 0 ? response.data.openingBalance * (-1) : parseFloat(response.data.openingBalance).toFixed(3).slice(0, -1)

                        var cashInHand = parseFloat(parseFloat(response.data.cashInHand) - parseFloat(response.data.openingBalance) - parseFloat(response.data.draftExpense)).toFixed(3).slice(0, -1) 
                        root.dayEnd.cashInHand = cashInHand < 0 ? cashInHand.toFixed(3).slice(0, -1) * (-1) : parseFloat(cashInHand).toFixed(3).slice(0, -1)
                        root.dayEnd.bankAmount = parseFloat(response.data.bank).toFixed(3).slice(0, -1)
                        root.dayEnd.expenseCash = parseFloat(parseFloat(response.data.draftExpense) + parseFloat(response.data.postExpense)).toFixed(3).slice(0, -1)
                        root.dayEnd.counterCode = response.data.terminalCode
                        root.total = parseFloat(parseFloat(response.data.cashInHand) - parseFloat(response.data.draftExpense)).toFixed(3).slice(0, -1)
                        root.transactions = response.data.noOfTransaction
                        root.startTime = response.data.startTime;
                        root.date = response.data.date;
                        root.bankDetails = response.data.bankDetails;
                        /*  root.total = total < 0 ? total * (-1) : parseFloat(total).toFixed(3).slice(0, -1) ;*/
                        root.grandTotal = parseFloat(root.total).toFixed(3).slice(0, -1);
                        //root.total = 1;
                        root.counterRander++
                    }
                });
            },

            close: function () {
                this.$emit('close');
            },

            GetHeaderDetail: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get("/Company/GetCompanyDetail?id=" + localStorage.getItem('CompanyID'), { headers: { Authorization: `Bearer ${token}` }, })
                    .then(function (response) {
                        if (response.data != null) {
                            root.headerFooter.company = response.data;

                        }
                    });
            },
            PrintDayEnd: function () {

                this.GetHeaderDetail();
                this.printRender++;
                if (this.InvoiceSetting == 'A4') {
                    this.printA4Report = true;
                }
                else {
                    this.printReport = true;

                }

            },
            SaveDayEnd: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (this.dayEnd.carryCash == null && this.dayEnd.carryCash == '') {
                    this.dayEnd.carryCash = 0;
                    if (this.dayEnd.carryCash == 0) {
                        this.dayEnd.supervisorName = '';
                        this.dayEnd.supervisorPassword = '';
                    }
                }
                this.dayEnd.isSupervisorLogin = this.SupervisorLogin;

                this.dayEnd.saleId = localStorage.getItem('UserID');

                this.dayEnd.locationId = localStorage.getItem('CompanyID');
                this.printDetail = this.dayEnd;
                this.printDetail.grandTotal = this.grandTotal;
                this.printDetail.total = this.total;
                this.printDetail.startTime = this.startTime;
                this.printDetail.startDate = this.date;
                this.printDetail.totalCash = this.TotalCash();
                this.printDetail.getTotalSale = this.getTotalSale();
                this.printDetail.bankDetails = this.bankDetails;
                this.printDetail.transactions = this.transactions;
                this.printDetail.dayWiseList = this.dayWiseList;
                this.printDetail.outStandingBalance = this.outStandingBalance;

                this.$https.post('/Product/SaveDayStart?isDayStart=' + false, this.dayEnd, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data.isSuccess) {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully' : 'حفظ بنجاح',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved' : 'تم الحفظ',
                            type: 'success',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        });

                        localStorage.setItem('IsDayStart', false);
                        localStorage.setItem('isExpenseDay', false);

                        root.PrintDayEnd();


                    }
                    else {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Your day Start End  Already Exist!' : 'يومك يبدأ ينتهي بالفعل',
                            type: 'error',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        });
                    }
                }).catch(error => {

                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: error.response.data,

                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    root.loading = false
                })
            }
        },
        mounted: function () {
            this.getOpeningBalance();
            if (this.dayEnd.id == '00000000-0000-0000-0000-000000000000' || this.dayEnd.id == undefined || this.dayEnd.id == '') {
                this.dayEnd.toTime = moment().format('HH:mm');
                this.dayEnd.date = moment().format('llll');
                this.dateRander++;
                this.time++;
                if (this.$session.exists()) {
                    this.UserName = localStorage.getItem('LoginUserName');
                    this.dayEnd.user = localStorage.getItem('LoginUserName');
                    this.InvoiceSetting = localStorage.getItem('PrintSizeA4');

                    var IsSupervisor = localStorage.getItem('IsSupervisor');
                    if (IsSupervisor == 'true') {
                        this.isSupervisor = true
                    }
                    else {
                        this.isSupervisor = false
                    }
                    this.dayEnd.endTerminalBy = localStorage.getItem('UserName');

                    this.role = localStorage.getItem('RoleName');
                }
                //this.dayEnd.counterCode = localStorage.getItem('CounterCode');
                this.dayEnd.counterId = this.counterId;
                this.dayEnd.openingCash = this.openingCash.toFixed(3).slice(0, -1);
                this.dayEnd.cashInHand = this.cashInHand.toFixed(3).slice(0, -1);
                this.total = (this.cashInHand + this.openingCash).toFixed(3).slice(0, -1) - this.expense.toFixed(3).slice(0, -1);
                this.dayEnd.expense = this.expense.toFixed(3).slice(0, -1);
            }
            this.SupervisorLogin = localStorage.getItem('IsSupervisor') == 'true' ? true : false;

            var isOpenDay = localStorage.getItem('IsOpenDay') == 'true' ? true : false;
            this.dayEnd.isSupervisor = this.isSupervisor;
            this.dayEnd.isOpenDay = isOpenDay;
            this.dayEnd.id = this.dayStartId;

        }
    }
</script>
