<template>
    <div class="col-md-12 ml-auto mr-auto mt-3">

        <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">

                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <div class="card">
                            <div class="card-header">
                                <div>
                                    <span class="pull-left mr-5 pull-left">
                                        <h5>   {{ $t('SalePaymentDetail.SaleInvoice') }} - {{sale.registrationNo}}</h5>
                                    </span>
                                    <span class="pull-right">
                                        {{sale.date}}
                                    </span>
                                </div>

                                <span class="pull-left mt-1">
                                    <div class="form-check-radio form-check-inline">
                                        <label class="form-check-label mt-1">
                                            <input class="form-check-input" type="radio" name="isCredit" :value="sale.isCredit" @click="sale.isCredit = false" :checked="!sale.type" disabled>
                                            {{ $t('SalePaymentDetail.Cash') }}
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check-radio form-check-inline">
                                        <label class="form-check-label mt-1">
                                            <input class="form-check-input" type="radio" name="isCredit" :value="sale.isCredit" @click="sale.isCredit = true" :checked="sale.type" disabled>
                                            {{ $t('SalePaymentDetail.Credit') }}
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                </span>

                            </div>
                            <div class="card-body mt-0 pt-0">
                                <div class="tab-content" id="nav-tabContent">

                                    <div class="row form-group">
                                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                                            <label>  {{ $t('SalePaymentDetail.Customer') }} :</label>
                                            <div>
                                                <input v-if="sale.isCredit" type="text" class="form-control"
                                                       v-model="sale.customerName" disabled />
                                                <input v-else type="text" class="form-control"
                                                       v-model="sale.cashCustomer" disabled />
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-if="!sale.isCredit">
                                            <label>{{ $t('SalePaymentDetail.Mobile') }} :</label>
                                            <div>
                                                <input type="text" class="form-control" v-model="sale.mobile" disabled />
                                            </div>
                                        </div>

                                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-if="sale.isCredit">
                                            <label>{{ $t('SalePaymentDetail.DueDate') }} :</label>
                                            <div>
                                                <datepicker v-model="sale.dueDate" disabled />
                                            </div>
                                        </div>

                                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                            <label>{{ $t('SalePaymentDetail.WareHouse') }}:</label>
                                            <div>
                                                <input type="text" v-model="sale.wareHouseName"
                                                       disabled class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <sale-item :saleItems="sale.saleItems" @input="SaveSaleItems" @summary="updateSummary" v-show="false" @details="sale.saleItems = $event" />
                                    <div class="table-responsive" v-bind:key="rendered">

                                        <table class="table table-striped table-hover table_list_bg" v-if="sale.saleItems.length > 0 && products.length > 0 && vats.length > 0">

                                            <thead class="m-0">
                                                <tr>
                                                    <th>
                                                        {{ $t('SalePaymentDetail.Product') }}
                                                    </th>
                                                    <th class="text-right">
                                                        {{ $t('SalePaymentDetail.UnitPrice') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('SalePaymentDetail.Quantity') }}
                                                    </th>
                                                    <th v-if="sale.saleItems.filter(x=> x.isBundleOffer).length > 0">
                                                        {{ $t('SalePaymentDetail.Bundle') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('SalePaymentDetail.Disc%') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('SalePaymentDetail.FixDiscount') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('SalePaymentDetail.Vat%') }}
                                                    </th>
                                                    <th class="text-right">
                                                        {{ $t('SalePaymentDetail.LineTotal') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody :key="rendered">

                                                <template v-for="(prod, index) in sale.saleItems.slice().reverse()">
                                                    <tr :key="index">
                                                        <td>

                                                            {{ products.find(x => x.id == prod.productId).barCode+ " : " +products.find(x => x.id == prod.productId).englishName +"-"+ products.find(x => x.id == prod.productId).code}}
                                                        </td>

                                                        <td class="text-right">
                                                            {{prod.unitPrice}}
                                                        </td>
                                                        <td>
                                                            {{prod.quantity}}
                                                        </td>
                                                        <td class="text-center" v-if="sale.saleItems.filter(x=> x.isBundleOffer).length > 0">
                                                            <span class="badge badge-pill badge-info">{{prod.bundleOffer}}</span>
                                                        </td>
                                                        <td class="text-center" v-else-if="sale.saleItems.filter(x=> x.isBundleOffer).length > 0">
                                                            <span>-</span>
                                                        </td>
                                                        <td>
                                                            {{prod.discount}}
                                                        </td>
                                                        <td>
                                                            {{prod.fixDiscount}}
                                                        </td>
                                                        <td>

                                                            {{vats.find(x => x.id == prod.taxRateId).name}}
                                                        </td>
                                                        <td class="text-right">

                                                            {{currency}} {{parseFloat(prod.lineTotal).toFixed(3).slice(0,-1) | formatAmount}}
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>

                                    <hr />
                                    <div>
                                        <h5>{{ $t('SalePaymentDetail.SalePaymentDetail') }}</h5>
                                        <div class="row form-group">

                                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mt-3 border-right">
                                                <h6>{{ $t('SalePaymentDetail.PaymentDetails') }}</h6>
                                                <p class="ml-4">
                                                    {{ $t('SalePaymentDetail.DueAmount') }} :{{currency}} {{sale.salePayment.dueAmount}}
                                                </p>
                                                <p class="ml-4">
                                                    {{ $t('SalePaymentDetail.Balance') }} : {{currency}} {{sale.salePayment.balance}}
                                                </p>
                                                <p class="ml-4">
                                                    {{ $t('SalePaymentDetail.Change') }} : {{currency}} {{sale.salePayment.change}}
                                                </p>
                                                <div class="ml-4" v-for="(payment, index) in sale.salePayment.paymentTypes" :key="index">
                                                    <p>
                                                        {{payment.paymentType == 0 ? 'Cash' : "Bank"}} <span v-if="payment.paymentOptionName != null">({{payment.paymentOptionName}})</span> : {{payment.amount | formatAmount}}
                                                    </p>
                                                </div>

                                            </div>
                                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mt-3">
                                                <h6>  {{ $t('SalePaymentDetail.SummaryDetails') }}</h6>
                                                <div class="ml-4">
                                                    <p>{{ $t('SalePaymentDetail.InvoiceTotal') }} : {{currency}} {{summary.total | formatAmount}}</p>
                                                    <p>{{ $t('SalePaymentDetail.Discount') }}: {{currency}} {{summary.discount | formatAmount}}</p>
                                                    <p>{{ $t('SalePaymentDetail.TotalAfterDiscount') }} : {{currency}} {{summary.withDisc | formatAmount}}</p>
                                                    <p>Vat : {{currency}} {{summary.vat | formatAmount}}</p>
                                                    <p v-if="summary.bundleAmount > 0">{{ $t('SalePaymentDetail.BundleAmount') }}: <span class="badge badge-info">{{ summary.bundleAmount | formatAmount }}</span></p>
                                                    <p>{{ $t('SalePaymentDetail.TotalWithVat') }} : {{currency}}{{summary.withVat | formatAmount}}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div v-if="!loading" class="col-md-12 text-right">
                                        <button class="btn btn-reset  mr-2"
                                                v-on:click="goToSale">
                                            {{ $t('SalePaymentDetail.Cancel') }}
                                        </button>
                                    </div>
                                    <div class="card-footer col-md-3" v-else>
                                        <loading :name="loaging0" :active.sync="loading"
                                                 :can-cancel="true"
                                                 :on-cancel="goToSale"
                                                 :is-full-page="true"></loading>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">

                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <h5>{{ $t('SalePaymentDetail.SalePaymentDetail') }}</h5>
                        <div class="row form-group">

                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mt-3 border-right">
                                <h6>{{ $t('SalePaymentDetail.PaymentDetails') }}</h6>
                                <p class="ml-4">
                                    {{ $t('SalePaymentDetail.DueAmount') }} : {{currency}} {{sale.salePayment.dueAmount}}
                                </p>
                                <p class="ml-4">
                                    {{ $t('SalePaymentDetail.Balance') }}  : {{currency}} {{sale.salePayment.balance}}
                                </p>
                                <p class="ml-4">
                                    {{ $t('SalePaymentDetail.Change') }}  : {{currency}} {{sale.salePayment.change}}
                                </p>
                                <div class="ml-4" v-for="(payment, index) in sale.salePayment.paymentTypes" :key="index">
                                    <p>
                                        {{payment.paymentType == 0 ? 'Cash' : "Bank"}} <span v-if="payment.paymentOptionName != null">{{payment.paymentOptionName}}</span> : {{payment.amount | formatAmount}}
                                    </p>
                                </div>

                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mt-3">
                                <h6>{{ $t('SalePaymentDetail.SummaryDetail') }} </h6>
                                <div class="ml-4">
                                    <p>{{ $t('SalePaymentDetail.InvoiceTotal') }} :  {{currency}} {{summary.total | formatAmount}}</p>
                                    <p>{{ $t('SalePaymentDetail.Discount') }} : {{currency}} {{summary.discount | formatAmount}}</p>
                                    <p>{{ $t('SalePaymentDetail.TotalAfterDiscount') }}  :{{currency}} {{summary.withDisc | formatAmount}}</p>
                                    <p>{{ $t('SalePaymentDetail.Vat') }}  : {{currency}} {{summary.vat | formatAmount}}</p>
                                    <p v-if="summary.bundleAmount > 0">{{ $t('SalePaymentDetail.BundleAmount') }} : <span class="badge badge-info">{{currency}} {{ summary.bundleAmount | formatAmount }}</span></p>
                                    <p>  {{ $t('SalePaymentDetail.TotalWithVat') }}  :  {{currency}} {{summary.withVat | formatAmount}}</p>
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading" class="col-md-12 text-right">
                            <button class="btn btn-reset  mr-2 float-left"
                                    block v-b-toggle.accordion-1 variant="info">
                                {{ $t('SalePaymentDetail.EditInvoice') }}
                            </button>
                            <button class="btn btn-primary  mr-2"
                                    v-on:click="saveSale('Paid')"
                                    :disabled="!isPaymentValid">
                                {{ $t('SalePaymentDetail.Submit') }}
                            </button>
                            <button class="btn btn-reset  mr-2"
                                    v-on:click="goToSale">
                                {{ $t('SalePaymentDetail.Cancel') }}
                            </button>
                        </div>
                        <div class="card-footer col-md-3" v-else>
                            <loading :name="loaging" :active.sync="loading"
                                     :can-cancel="true"
                                     :on-cancel="goToSale"
                                     :is-full-page="true"></loading>
                        </div>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";

    //import VueBarcode from 'vue-barcode';
    export default {
        name: "AddSale",

        data: function () {
            return {
                rendered: 0,
                sale: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    registrationNo: "",
                    customerId: "00000000-0000-0000-0000-000000000000",
                    dueDate: "",
                    wareHouseId: "",
                    currency: "",
                    saleItems: [],
                    isCredit: false,
                    cashCustomer: "Walk-In",
                    mobile: "",
                    code: "",
                    invoiceType: "",
                    wareHouseName: null,
                    customerName: null,
                    salePayment: {
                        dueAmount: 0,
                        received: 0,
                        balance: 0,
                        change: 0,
                        paymentTypes: [],
                        paymentType: 'Cash',
                        paymentOptionId: null,
                    }
                },
                loading: false,
                summary: Object,
                products: [],
                vats: [],
                isDashboard:''
            };
        },
        computed: {
            isPaymentValid: function () {
                var paymentTypesAmount;
                if (this.sale.salePayment.paymentTypes.length > 0) {
                    paymentTypesAmount = this.sale.salePayment.paymentTypes.reduce((total, payment) =>
                        total + payment.amount, 0);
                } else {
                    paymentTypesAmount = 0;
                }

                if (this.sale.salePayment.paymentType == "Bank") {
                    if (this.sale.salePayment.paymentOptionId == "00000000-0000-0000-0000-000000000000" ||
                        this.sale.salePayment.paymentOptionId == null) {
                        return false;
                    }
                }

                if ((parseFloat(this.sale.salePayment.received) <= parseFloat(this.sale.salePayment.dueAmount))) {
                    if (((parseFloat(this.sale.salePayment.received) + paymentTypesAmount) + this.sale.salePayment.balance) === this.sale.salePayment.dueAmount) {
                        return true
                    }
                } else {
                    if (((parseFloat(this.sale.salePayment.received) + paymentTypesAmount) - this.sale.salePayment.balance) === this.sale.salePayment.dueAmount) {
                        return true
                    }
                }
                return false;
            },
        },
        methods: {


            calculatBalance: function (received) {

                if (received == '') {
                    received = 0;
                }
                var paymentTypesAmount;
                if (this.sale.salePayment.paymentTypes.length > 0) {
                    paymentTypesAmount = this.sale.salePayment.paymentTypes.reduce((total, payment) =>
                        total + payment.amount, 0);
                } else {
                    paymentTypesAmount = 0;
                }

                this.sale.salePayment.balance = (parseFloat(received) + paymentTypesAmount) - this.sale.salePayment.dueAmount;
                this.sale.salePayment.change = this.sale.salePayment.balance;
            },

            addPayment: function (amount, paymentType) {
                this.sale.salePayment.received = 0;
                var payment = this.sale.salePayment.paymentTypes.find((x) => x.paymentType == paymentType)
                if (payment != undefined) {
                    payment.amount += parseFloat(amount);
                } else {
                    this.sale.salePayment.paymentTypes.push({ paymentType: paymentType, amount: parseFloat(amount) });
                }

                this.calculatBalance(0);
            },

            updateSummary: function (summary) {
                this.summary = summary;
                this.sale.salePayment.dueAmount = parseFloat(this.summary.withVat)
            },
            AutoIncrementCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/Sale/SaleAutoGenerateNo", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.sale.registrationNo = response.data;
                        }
                    });
            },
            SaveSaleItems: function (saleItems) {
                this.sale.saleItems = saleItems;
            },
            saveSale: function (invoiceType) {
                this.loading = true;
                var root = this;
                root.sale.invoiceType = invoiceType;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                if (this.sale.salePayment.received != 0) {
                    this.addPayment(this.sale.salePayment.received, this.sale.salePayment.paymentType);
                }

                this.$https
                    .post('/Sale/SaveSaleInformation', root.sale, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        root.loading = false
                        root.info = response.data.bpi

                        root.$swal.fire({
                            icon: 'success',
                            title: 'Saved Successfully',
                            showConfirmButton: false,

                            timer: 800,
                            timerProgressBar: true,

                        });
                        //root.$router.push('/product');
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                                text: error,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)

            },

            goToSale: function () {
                if (this.isDashboard) {
                    this.$router.push('/Dashboard1');

                }
                else {
                    this.$router.push('/sale');
                }
            },

            getData: function () {
                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                this.$https
                    .get("/Product/GetProductInformation", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.products = response.data.products;
                        }
                    })
                    .then(function () {
                        root.$https
                            .get("/Product/TaxRateList", {
                                headers: { Authorization: `Bearer ${token}` },
                            })
                            .then(function (response) {
                                if (response.data != null) {
                                    root.vats = response.data.taxRates;
                                }
                            });
                    });
            },

        },
        created: function () {
            if (this.$route.query.data != undefined) {
                var data = this.$route.query.data;
                this.sale.invoiceType = data.invoiceType;
                this.sale.isCredit = data.isCredit;
                this.sale.cashCustomer = data.cashCustomer;
                this.sale.date = moment(data.date).format("DD MMM YYYY");
                this.sale.dueDate = moment(data.dueDate).format("DD MMM YYYY");
                this.sale.registrationNo = data.registrationNo;
                this.sale.mobile = data.mobile;
                this.sale.code = data.code;
                this.sale.customerId = data.customerId;
                this.sale.saleItems = data.saleItems;
                this.sale.salePayment = data.salePayment;
                this.sale.wareHouseName = data.wareHouseName;
                this.sale.customerName = data.customerName;
            }
        },

        mounted: function () {
            this.currency = localStorage.getItem('currency');
            this.getData();
            if (this.$route.query.data == undefined) {

                this.sale.date = moment().format("DD MMM YYYY");
                this.sale.dueDate = moment().format("DD MMM YYYY");
            }
            if (this.$route.query.data == undefined) {
                this.AutoIncrementCode();
            }
            if (this.$route.query.data != undefined) {
                this.warehouse = this.$route.query.data;
            }
            if (this.$route.query.dashboard != undefined) {
                
                this.isDashboard = this.$route.query.dashboard;
            }
        },
    };
</script>
