<template>
    <div  >
        <div class="col-md-12  " style="color:black !important;background-color:white !important;height: 210mm !important">
            <div class="row" style="background-color:white !important">
                <div class="col-4" style="background-color:white !important">

                </div>
                <div class="col-4 text-center" style="background-color:white !important">

                </div>
                <div class="col-4 text-end" style="background-color:white !important">
                    <button class="btn btn-outline-danger  mr-2"
                            v-on:click="goToSale">
                        <i class="fas fa-arrow-circle-left fa-lg"></i>
                    </button>
                </div>
            </div>
            <div style="background-color:white !important;padding-left:40px !important;padding-right:40px !important">
                <!--HEADER-->
                <div class="row" style="background-color:white !important">
                    <div class="col-4" style="background-color:white !important">
                    </div>
                    <div class="col-4 text-center" style="background-color:white !important">
                        <p style="font-size: 10px; color: #222234 !important; font-weight: bold;padding-top:19px">Original</p>
                    </div>
                    <div class="col-4 text-center" style="background-color:white !important">
                    </div>
                </div>
                <div class="row" style="background-color:white !important">
                    <div class="col-4" style="background-color:white !important">
                        <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px;">
                    </div>
                    <div class="col-4 text-center" style="background-color:white !important">
                        <p style="font-size: 25px; color: #222234 !important; font-weight: bold;padding-top:19px">{{headerFooters.company.nameEnglish}}</p>
                    </div>
                    <div class="col-4 text-center" style="background-color:white !important">
                        <barcode :width="1.9" :height="50" :displayValue="false" v-bind:value="list.barCode"></barcode>
                    </div>
                </div>
                <div class="row" style="background-color:white !important">
                    <div class="col-8" style="background-color:white !important">
                        <div class="row" style="background-color:white !important;font-size: 15px; color: black !important;font-weight:bold;font-style:italic">
                            <div class="col-2" style="margin-bottom:0px !important;padding-bottom:0px !important">Address:</div>
                            <div class="col-10" style="margin-bottom:0px !important;padding-bottom:0px !important">
                                <span>{{headerFooters.company.addressEnglish}}</span>

                            </div>
                            <div class="col-2" style="margin-bottom:0px !important;padding-bottom:0px !important">STN/NTN:</div>
                            <div class="col-10" style="margin-bottom:0px !important;padding-bottom:0px !important">
                                <span>{{headerFooters.company.vatRegistrationNo}}</span>

                            </div>

                            <div class="col-2" style="padding-top:0px !important;margin-top:0px !important;margin-bottom:0px !important;padding-bottom:0px !important">Phone:</div>
                            <div class="col-10" style="padding-top:0px !important;margin-top:0px !important;margin-bottom:0px !important;padding-bottom:0px !important">
                                <span>{{headerFooters.company.phoneNo}}</span>

                            </div>
                            <div class="col-2" style="padding-top:0px !important;margin-top:0px !important;margin-bottom:0px !important;padding-bottom:0px !important">E-Mail:</div>
                            <div class="col-10" style="padding-top:0px !important;margin-top:0px !important;margin-bottom:0px !important;padding-bottom:0px !important">
                                <span>{{headerFooters.company.companyEmail}}</span>

                            </div>
                            <!--<div class="col-12">
                        <p style="font-size: 16px; color: #222234 !important; "><span style="padding-right:6px !important"><img src="Group 144.png" style="width:auto;max-width:15px; max-height:15px;"></span><span>{{headerFooters.company.companyEmail}}</span></p>

                    </div>
                    <div class="col-12">
                        <p style="font-size: 16px; color: #222234 !important; "><span style="padding-right:6px !important"><img src="/images/phone.png" style="width:auto;max-width:15px; max-height:15px;"></span><span>{{headerFooters.company.phoneNo}}</span></p>

                    </div>-->


                        </div>
                        <!--<p style="font-size: 14px; color: #222234 !important; font-weight: bold;">{{headerFooters.company.addressEnglish}}</p>
                <p style="font-size: 14px; color: #222234 !important; font-weight: bold;"><span><img src="Group 144.png" /></span><span>{{headerFooters.company.email}}</span></p>
                <p style="font-size: 14px; color: #222234 !important; font-weight: bold;"><span><img src="Phone45435.png" /></span><span>{{headerFooters.company.phoneNo}}</span></p>-->
                    </div>

                    <div class="col-4 ">
                        <div class="row" style="background-color:white !important;font-size: 16px; color: black !important">
                            <div class="col-6 text-right" style="margin-bottom:0px !important;padding-bottom:0px !important;font-weight:bold;">Date:</div>
                            <div class="col-6 text-left" style="margin-bottom:0px !important;padding-bottom:0px !important;color:black">
                                <span>{{list.date}}</span>

                            </div>
                            <div class="col-6 text-right" style="margin-bottom:0px !important;padding-bottom:0px !important;font-weight:bold;">Time:</div>
                            <div class="col-6 text-left" style="margin-bottom:0px !important;padding-bottom:0px !important;color:black">
                                <span>{{list.time}}</span>

                            </div>
                            <div class="col-6 text-right" style="margin-bottom:0px !important;margin-top:20px !important;padding-bottom:0px !important;font-weight:bold;">Refrence No:</div>
                            <div class="col-6 text-left" style="margin-bottom: 0px !important; margin-top: 20px !important; padding-bottom: 0px !important; color: black; font-weight: bold; ">
                                <span>{{list.registrationNo}}</span>

                            </div>




                        </div>

                    </div>
                </div>
                <div class="row" style="background-color: white !important; font-size: 15px; color: black !important; font-style: italic">
                    <div class="col-1" style="font-weight:bold;padding-top:15px">Customer:</div>
                    <div class="col-11" style="padding-top:15px">
                        <span v-if="list.cashCustomer != null">{{list.cashCustomer}}</span> <span v-else>{{list.customerNameEn}}</span><br />
                        <!--<span>{{list.customerAddressWalkIn}} Lahore Pakistan</span><br />-->


                    </div>
                    <div class="col-1" style="font-weight:bold;">Address:</div>
                    <div class="col-11" style="">
                        <span v-if="list.customerAddres=='' && list.customerAddres==null"> Lahore Pakistan</span>
                        <span v-else>{{list.customerAddress}}</span>


                    </div>
                    <div class="col-1" style="font-weight:bold;">GST:</div>
                    <div class="col-11" style="">
                        <span>{{list.customerVat}}</span><br />


                    </div>
                    <!--<div class="col-1" style="font-weight:bold;">CRN:</div>
    <div class="col-11" style="">
        <span v-if="list.customerCRN != null && list.customerCRN != '' && list.customerCRN !=undefined">{{list.customerCRN}}</span><br />


    </div>-->
                    <div class="col-1" style="font-weight:bold;">Tel :</div>
                    <div class="col-11" style="">
                        <span>{{list.mobile}}</span>


                    </div>

                </div>

                <div class="row" style="font-size:16px">
                    <div class="col-md-12 ">
                        <table class="table ">
                            <tr class="heading" style="font-size:16px !important;background-color:#F2F2F2 !important">
                                <th class="text-left" style="width:33%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Order Date</th>
                                <th class="text-center" style="width:33%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Delivery Date</th>
                                <th class="text-right" style="width:33%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important;padding-right:25px !important">Mode</th>
                            </tr>
                            <tr class="heading" style="font-size:16px !important;background-color:#F2F2F2">
                                <th class="text-left" style="width:33%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important">{{list.date}}</th>
                                <th class="text-center" style="width:33%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important">{{list.date}}</th>
                                <th class="text-right" style="width:33%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;padding-right:25px !important"><span v-if="!list.isCredit">Cash</span><span v-else>Credit</span></th>
                            </tr>
                            <tr>
                            </tr>
                        </table>
                    </div>


                </div>


                <div class="row " style="background-color:white !important;">
                    <div class="col-md-12 " style="background-color:white;height:200mm">
                        <table class="table " style="border-left: 0 !important;border-bottom: 0 !important;">
                            <tr class="heading" style="font-size:16px !important;background-color:#F2F2F2 !important;color:black !important">
                                <th class="text-center" style="width:3%;padding-top:5px !important; padding-bottom:5px !important;background-color:#F2F2F2 !important;"> No</th>
                                <th class="text-left" style="width:35%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Product Name</th>
                                <th class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">QTY</th>
                                <th class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Unit Price</th>
                                <th class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important"  v-if="!list.isDiscountOnTransaction">Discount </th>
                                <th class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Exc GST </th>
                                <th class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Gst%</th>
                                <!--<th class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Gst Amt</th>-->
                                <th class="text-right" style="width:15%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Total</th>
                            </tr>


                            <template v-for="(item, index) in list.saleItems">
                                <tr style="font-size:16px;border-bottom:0.01px  solid grey;color:black !important " v-bind:key="item.id">
                                    <td class="text-center" style="width:3%;padding-top:5px !important; padding-bottom:5px !important;background-color:white !important;border-bottom:0.01px  solid grey;color:black !important">{{index+1}}</td>
                                    <td class="text-left" style="width:35%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-bottom:0.01px  solid grey;color:black !important">{{item.product.styleNumber}}{{item.productName}} </td>
                                    <td class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-bottom:0.01px  solid grey;color:black !important">{{item.quantity }}</td>
                                    <td class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-bottom:0.01px  solid grey;color:black !important">Rs.{{item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-bottom:0.01px  solid grey;color:black !important"  v-if="!list.isDiscountOnTransaction">{{item.discountAmount.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-bottom:0.01px  solid grey;color:black !important">{{(item.total - item.discountAmount).toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-bottom:0.01px  solid grey;color:black !important">{{item.taxRate}} %</td>
                                    <!--<td class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-bottom:0.01px  solid grey">{{(item.includingVat+item.inclusiveVat).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>-->
                                    <td class="text-right" style="width:15%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-bottom:0.01px  solid grey;color:black !important">Rs.{{(((item.total - (item.discountAmount+item.bundleAmount)) + item.includingVat)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                </tr>
                            </template>
                        </table>
                           <table class="table " style="border: 0 !important">

                            <tr>
                                <td class="text-center" style="color: white !important; padding-top: 30px !important; padding-bottom: 10px !important;border:0!important;background-color:white " colspan="5">
                                </td>
                                <td style="color:black !important;background-color: white !important;font-size:15px;padding-top:10px !important; padding-bottom:10px !important;border:0!important;" colspan="4">

                                    <span style="float:left;font-weight:bolder">{{list.taxRateName}}:</span> <span style="float:right;font-weight:bolder">{{currency}} {{parseFloat(calulateTotalVAT).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center" style="color: white !important; padding-top: 10px !important; padding-bottom: 10px !important;border:0!important;background-color:white " colspan="5" rowspan="3">
                                   
                                    <div style="display:flex">

                                        <div style="border:0.5px solid black;width:60%;color:black;font-size:16px ;margin-left:20px;margin-right:15px; margin-top:15px;margin-bottom:15px;">
                                            <p style="font-weight:bold">Total Payment Details</p>
                                            <template v-for="(sale,index) in list.paymentTypes">
                                                <div v-bind:key="index" v-if="sale.name == 'Cash'">
                                                    <span style="font-weight:bold;float:left;padding-left:5px">{{sale.name}}:</span><span style="float:left;padding-left:10px">{{currency}}: {{sale.amount.toFixed(0)}}</span><br />
                                                </div>
                                                <div v-bind:key="index" v-if=" sale.name == 'Bank'">
                                                    <span style="font-weight:bold;float:left;padding-left:5px">{{sale.name}}  ({{sale.bankAccountName}})</span><span style="float:left;padding-left:10px">{{currency}}: {{sale.amount.toFixed(0)}}</span><br />
                                                </div>
                                            </template>


                                        </div>

                                    </div>
                                </td>
                                <td style="color:black !important;background-color: white !important;font-size:15px;padding-top:10px !important; padding-bottom:10px !important;border-bottom:0.01px solid grey;border-top:0.01px solid grey" colspan="4"><span style="float:left;font-weight:bolder">Discount:</span> <span style="float:right;font-weight:bolder">{{currency}}   {{calulateDiscountAmount}}</span></td>

                            </tr>
                           
                            <tr>
                                <td style="color:black !important;background-color: white !important;font-size:15px;padding-top:10px !important; padding-bottom:10px !important;border-bottom:0.01px solid grey" colspan="4"><span style="float:left;font-weight:bolder">Adj/Old Battery:</span> <span style="float:right;font-weight:bolder">{{currency}}   {{parseFloat(list.discount ).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                            </tr>


                            <tr>
                                <td style="color:black !important;background-color: white !important;font-size:15px;padding-top:10px !important; padding-bottom:10px !important;border-bottom:0.01px solid grey" colspan="4"><span style="float:left;font-weight:bolder">Grand Total :</span> <span style="float:right;">{{currency}}   {{parseFloat(calulateNetTotal  + list.discount  ).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                            </tr>

                            <tr>

                                <td style="color: black !important; border: none; background-color: white !important; font-size: 17px; padding-top: 10px !important; padding-bottom: 10px !important; font-weight: bold" colspan="5">
                                    <span style="font-weight:bold;color:black;">Upto Pending Amount:</span><span style="font-weight:bold;color:black;">&nbsp; &nbsp; Rs  {{parseFloat(list.openingBalance).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span>
                                </td>
                                <td style="color:black !important;background-color: white !important;font-size:17px;padding-top:10px !important; padding-bottom:10px !important;border-bottom:0.01px solid grey;font-weight:bold" colspan="4"><span style="float:left;font-weight:bolder">Total Payments :</span> <span style="float:right;">{{currency}}   {{parseFloat(calulateNetTotal+ list.discount -dueAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                            </tr>
                            <tr>
                                <td style="color:black !important; border:none;background-color: white !important;font-size:17px;padding-top:10px !important; padding-bottom:10px !important;font-weight:bold" colspan="5">
                                    <span style="font-weight:bold">Baterry Ups Card Given to Customer:</span> <span v-if="list.isWarranty">Yes</span><span v-else>No</span>
                                </td>
                                <td style="color:black !important;background-color: white !important;font-size:17px;padding-top:10px !important; padding-bottom:10px !important;border-bottom:0.01px solid grey;font-weight:bold" colspan="4"><span style="float:left;font-weight:bolder">Balance Due :</span> <span style="float: right; font-weight: bolder">{{currency}}   {{parseFloat(dueAmount ).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                            </tr>


                        </table>
                    </div>
                    <div class="col-md-12 ">
                        <div class="row" style="background-color:white !important;padding-top:20px;">

                            <div class="col-10 " style="font-size: 16px; color: black !important;padding-top:8px !important">
                                <p style="font-size: 16px; color: black !important; font-weight: bold;border-top:0.5px solid;display:inline-block">
                                    &nbsp;&nbsp;(Signature) &nbsp; &nbsp;
                                </p><br />
                                <!--<span style="font-size: 18px; color: black !important;padding-top:8px !important">
                                    <span style="font-weight:bold;color:black;">Running Balance:</span><span style="font-weight:bold;color:black;">&nbsp; &nbsp; Rs  {{parseFloat(list.openingBalance).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span>
                                </span><br />-->

                            </div>

                            <!--<div class="col-3" style="font-size: 16px; color: black !important">
                                <span style="font-weight:bold">Warranty:</span> <span v-if="list.isWarranty">Yes</span><span v-else>No</span>-->
                                <!--<span>Company Warr
                        <input v-bind:key="randerWarranty" name="check" v-model="list.isWarranty"
                                :value="list.isWarranty"
                                type="checkbox" /></span>-->
                            <!--</div>-->


                        </div>

                    </div>

                    <div class="col-md-12 ">
                        <div class="row" style="padding-top: 20px !important; line-height: 1; background-color: white">
                            <div class="col-8 ">
                                <span style="font-size: 16px; color: black !important; font-weight: bold;border-bottom:0.5px solid;display:inline-block">
                                    Terms & Conditions:

                                </span><br />
                                <!--<p style="font-size: 16px; color: black !important; font-weight: bold;border-bottom:0.01px solid black">
                        </p>-->
                                <p style="font-size: 16px; color: #222234 !important; " v-html="headerFooters.footerEn">
                                </p>
                            </div>
                            <div class="col-4">
                                <span style="font-size: 16px; color: black !important; font-weight: bold;border-top:0.5px solid;">
                                    ({{userName}} Signature)
                                </span><br />
                                <span>I have read and accept all Terms and Conditions.</span>
                            </div>
                            <div class="col-12 text-center " style="font-size:14px;font-weight:bold">
                                <p>User Name: &nbsp;&nbsp;{{userName}}</p>
                            </div>


                        </div>
                    </div>

                </div>


            </div>
        </div>
    </div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    import VueBarcode from 'vue-barcode';

    export default {
        components: {
            'barcode': VueBarcode,

        },
        props: ['printDetails', 'headerFooter', 'isTouchScreen'],
        mixins: [clickMixin],
        data: function () {
            return {
                currency: "",
                customer: true,
                qrValue: "",
                isHeaderFooter: '',
                invoicePrint: '',
                IsDeliveryNote: '',
                arabic: '',
                english: '',
                userName: '',
                emptyListCount: 0,
                indexCount: 0,
                page: 0,
                isMultiUnit: '',
                list: {
                    number: 0,
                    listItemTotal: [],
                    registrationNo: '',
                    date: '',
                    time: '',
                    dueDate: '',
                    companyName: '',
                    companyPhoneNo: '',
                    companyAddress: '',
                    discountAmount: '',
                    cashCustomer: '',
                    creditCustomer: '',
                    customerPhoneNo: '',
                    customerAddress: '',
                    paymentMethod: '',
                    paymentMethodNo: '',
                    invocieType: '',
                    saleItems:
                        [

                        ]
                },
                render: 0,
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
                htmlData: {
                    htmlString: ''
                },
                otherAmount: 0,
                dueAmount: 0
            }
        },
        filters: {
            toWords: function (value) {
                var converter = require('number-to-words');
                if (!value) return ''
                return converter.toWords(value);
            }
        },
        computed: {
            calulateTotalQty: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (Number((c.quantity) || 0) > 0 ? Number((c.quantity) || 0) : 0) }, 0)
            },
            calulateNetTotal: function () {
                var withDisc = this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : c.total - c.discountAmount) }, 0)
                var totalIncDisc = (this.list.isBeforeTax && this.list.isDiscountOnTransaction && this.list.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(this.list.transactionLevelDiscount) * (withDisc) / 100) : parseFloat(this.calulateDiscountAmount)

                return this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total + c.includingVat) || 0)) }, 0) + parseFloat((this.list.taxMethod == ("Inclusive" || "شامل") ? 0 : this.calulateTotalVAT)) - totalIncDisc
            },
            calulateTotalExclVAT: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total - c.discountAmount) || 0)) }, 0)
            },
            calulateTotalVAT: function () {
                var totalQtyWithotFree = this.list.saleItems.reduce((qty, prod) => qty + (prod.isFree ? 0 : parseInt(prod.quantity == '' ? 0 : prod.quantity)), 0);
                var paidVat = this.list.saleItems
                    .reduce((vat, prod) => (vat + (prod.isFree ? 0 : ((this.list.taxMethod == ("Inclusive" || "شامل")) ? ((parseFloat(prod.total - prod.discountAmount) - (this.list.isBeforeTax ? (((prod.quantity * prod.unitPrice) * this.list.transactionLevelDiscount) / 100) : 0)) * prod.taxRate) / (100 + prod.taxRate) : ((parseFloat(prod.total - prod.discountAmount) - (this.list.isBeforeTax && !this.list.isFixed && this.list.isDiscountOnTransaction ? (((prod.quantity * prod.unitPrice) * this.list.transactionLevelDiscount) / 100) : (this.list.isBeforeTax && this.list.isFixed && this.list.isDiscountOnTransaction ? (this.list.transactionLevelDiscount / parseFloat(totalQtyWithotFree) * prod.quantity) : 0))) * prod.taxRate) / 100))), 0).toFixed(3).slice(0, -1)

                return paidVat;
            },
            calulateDiscountAmount: function () {
                var totalIncDisc = 0;
                if (this.list.isDiscountOnTransaction) {
                    var withDisc = this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : c.total - c.discountAmount) }, 0)

                    var discountForInclusiveVat = parseFloat(this.list.saleItems
                        .reduce((vat, prod) => (vat + (prod.isFree ? 0 : ((this.list.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(prod.total) * prod.taxRate) / (100 + prod.taxRate) : 0))), 0).toFixed(3).slice(0, -1))

                    totalIncDisc = ((this.list.isBeforeTax && this.list.isDiscountOnTransaction) ? (this.list.taxMethod == ("Inclusive" || "شامل") ? (parseFloat(this.list.transactionLevelDiscount) * (withDisc - discountForInclusiveVat) / 100) : (this.list.isFixed ? parseFloat(this.list.transactionLevelDiscount) : parseFloat(this.list.transactionLevelDiscount) * withDisc / 100)) : (this.list.isFixed ? parseFloat(this.list.transactionLevelDiscount) : (parseFloat(withDisc) + (this.list.taxMethod == ("Inclusive" || "شامل") ? 0 : parseFloat(this.calulateTotalVAT))) * parseFloat(this.list.transactionLevelDiscount) / 100)).toFixed(3).slice(0, -1)

                }
                else {
                    totalIncDisc = this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : c.discountAmount) }, 0)
                }

                return totalIncDisc;
            }
        },
        methods: {
            goToSale: function () {

                this.$router.push({
                    path: '/sale',
                    query: {
                        data: 'AddSales'
                    }
                });
            },
            calulateTotalQtySub: function (val) {
                return val.reduce(function (a, c) { return a + (Number((c.quantity) || 0) > 0 ? Number((c.quantity) || 0) : 0) }, 0)
            },
            calulateNetTotalSub: function (val) {
                var withDisc = val.reduce(function (a, c) { return a + (c.isFree ? 0 : c.total - c.discountAmount) }, 0)
                var totalIncDisc = (this.list.isBeforeTax && this.list.isDiscountOnTransaction && this.list.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(this.list.transactionLevelDiscount) * (withDisc) / 100) : parseFloat(this.calulateDiscountAmount)

                return val.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total + c.includingVat) || 0)) }, 0) + parseFloat((this.list.taxMethod == ("Inclusive" || "شامل") ? 0 : this.calulateTotalVAT)) - totalIncDisc
            },
            calulateTotalExclVATSub: function (val) {
                return val.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total - c.discountAmount) || 0)) }, 0)
            },
            calulateTotalVATSub: function (val) {
                var totalQtyWithotFree = val.reduce((qty, prod) => qty + (prod.isFree ? 0 : parseInt(prod.quantity == '' ? 0 : prod.quantity)), 0);
                var paidVat = val
                    .reduce((vat, prod) => (vat + (prod.isFree ? 0 : ((this.list.taxMethod == ("Inclusive" || "شامل")) ? ((parseFloat(prod.total - prod.discountAmount) - (this.list.isBeforeTax ? (((prod.quantity * prod.unitPrice) * this.list.transactionLevelDiscount) / 100) : 0)) * prod.taxRate) / (100 + prod.taxRate) : ((parseFloat(prod.total - prod.discountAmount) - (this.list.isBeforeTax && !this.list.isFixed && this.list.isDiscountOnTransaction ? (((prod.quantity * prod.unitPrice) * this.list.transactionLevelDiscount) / 100) : (this.list.isBeforeTax && this.list.isFixed && this.list.isDiscountOnTransaction ? (this.list.transactionLevelDiscount / parseFloat(totalQtyWithotFree) * prod.quantity) : 0))) * prod.taxRate) / 100))), 0).toFixed(3).slice(0, -1)

                return paidVat;
            },
            calulateDiscountAmountSub: function (val) {
                var totalIncDisc = 0;
                if (this.list.isDiscountOnTransaction) {
                    var withDisc = val.reduce(function (a, c) { return a + (c.isFree ? 0 : c.total - c.discountAmount) }, 0)

                    var discountForInclusiveVat = parseFloat(val
                        .reduce((vat, prod) => (vat + (prod.isFree ? 0 : ((this.list.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(prod.total) * prod.taxRate) / (100 + prod.taxRate) : 0))), 0).toFixed(3).slice(0, -1))

                    totalIncDisc = ((this.list.isBeforeTax && this.list.isDiscountOnTransaction) ? (this.list.taxMethod == ("Inclusive" || "شامل") ? (parseFloat(this.list.transactionLevelDiscount) * (withDisc - discountForInclusiveVat) / 100) : (this.list.isFixed ? parseFloat(this.list.transactionLevelDiscount) : parseFloat(this.list.transactionLevelDiscount) * withDisc / 100)) : (this.list.isFixed ? parseFloat(this.list.transactionLevelDiscount) : (parseFloat(withDisc) + (this.list.taxMethod == ("Inclusive" || "شامل") ? 0 : parseFloat(this.calulateTotalVAT))) * parseFloat(this.list.transactionLevelDiscount) / 100)).toFixed(3).slice(0, -1)

                }
                else {
                    totalIncDisc = val.reduce(function (a, c) { return a + (c.isFree ? 0 : c.discountAmount) }, 0)
                }

                return totalIncDisc;
            },
            calulateDiscountAmount1: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number(c.discountAmount || 0) }, 0)
            },
            calulateBundleAmount1: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0)
            },
            calulateNetTotalWithVAT: function () {
                var total = this.list.saleItems.reduce(function (a, c) { return a + Number((c.total + c.includingVat) || 0) }, 0);
                var grandTotal = parseFloat(total) - (this.calulateDiscountAmount1() + this.calulateBundleAmount1())
                return (parseFloat(grandTotal).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },
            calulateTotalVATofInvoice: function () {
                var total = this.list.saleItems.reduce(function (a, c) { return a + Number((c.includingVat + c.inclusiveVat) || 0) }, 0);
                return (parseFloat(total).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },


        },
       
        created: function () {
            
            this.isMultiUnit = localStorage.getItem('IsMultiUnit');
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.invoicePrint = localStorage.getItem('InvoicePrint');
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            this.IsDeliveryNote = localStorage.getItem('IsDeliveryNote');
            this.userName = localStorage.getItem('FullName');
            this.currency = localStorage.getItem('currency');
            var root = this;
            if (this.$route.query.data != undefined) {
                this.list = this.$route.query.data;
                this.headerFooters = this.$route.query.headerFooter;
                if (this.list.paymentTypes != null || this.list.paymentTypes.length > 0) {
                    root.list.paymentTypes.forEach(function (x) {
                        if (x.name == 'Credit') {
                            root.dueAmount = parseFloat(x.amount)
                        }
                    });
                    var count = this.list.saleItems.length;
                    this.page = Math.ceil(count / 2);
                    if (count <= 20) {
                        this.emptyListCount = 12 - count;
                        this.indexCount = 12 - this.emptyListCount;

                    }
                    else {
                        console.log(count, this.page);
                    }
                    this.list.date = moment().format('DD MMM YYYY');
                    this.list.time = moment().format('hh:mm A');

                }
            }
           
        },

    }
</script>
<style scoped>
    body {
        background-color: white !important;
    }

    .page {
        background-color: white !important;
    }

    .your-background:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: -1;
        border-bottom: 1000px solid #eee; /* Make it fit your needs */
    }

    .img {
        text-align: right !important;
        margin-top: 2px;
        padding-left: 2px
    }
</style>


