<template>
    <modal :show="show">

        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">

                            <h5 class="modal-title DayHeading" id="myModalLabel">{{ $t('AddCity.UpdateCity') }} </h5>

                        </div>
                        <div class="modal-header" v-else>

                            <h5 class="modal-title DayHeading" id="myModalLabel">{{ $t('AddCity.AddCity') }} </h5>

                        </div>
                        <div class="">
                            <div class="card-body">
                                <div class="row ">
                                    <div :key="render" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.city.code.$error} && ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <label class="text  font-weight-bolder"> {{ $t('AddCity.Code') }}:<span class="text-danger"> *</span></label>
                                        <input disabled class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.city.code.$model" type="text" />
                                        <span v-if="$v.city.code.$error" class="error">
                                            <span v-if="!$v.city.code.maxLength">{{ $t('AddCity.CodeLength') }}</span>
                                        </span>
                                    </div>
                                    <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.city.name.$error} && ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <label class="text  font-weight-bolder"> {{ $t('AddCity.CityName') }}: <span class="text-danger"> *</span></label>
                                        <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.city.name.$model" type="text" />
                                        <span v-if="$v.city.name.$error" class="error">
                                            <span v-if="!$v.city.name.required">{{ $t('AddCity.NameRequired') }}</span>
                                            <span v-if="!$v.city.name.maxLength">{{ $t('AddCity.NameLength') }}</span>
                                        </span>
                                    </div>


                                    <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.city.description.$error} && ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <label class="text  font-weight-bolder"> {{ $t('AddCity.Description') }}: </label>
                                        <textarea class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.city.description.$model" type="text" />
                                        <span v-if="$v.city.description.$error" class="error">{{ $t('AddCity.descriptionLength') }}</span>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label style="margin: 7px;">{{ $t('AddCity.Active') }}</label> <br />
                                        <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': city.isActive, 'bootstrap-switch-off': !city.isActive}" v-on:click="city.isActive = !city.isActive" style="width: 72px;">
                                            <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-check-2"></i>
                                                </span>
                                                <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                                <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-simple-remove"></i>
                                                </span>
                                                <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-city="success" data-off-city="success">
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-right" v-if="type=='Edit'">

                            <button type="button" class="btn btn-primary  " v-on:click="SaveCity" v-bind:disabled="$v.city.$invalid"> {{ $t('AddCity.btnUpdate') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddCity.btnClear') }}</button>

                        </div>
                        <div class="modal-footer justify-content-right" v-if="type!='Edit'">

                            <button type="button" class="btn btn-primary  " v-on:click="SaveCity" v-bind:disabled="$v.city.$invalid"> {{ $t('AddCity.btnSave') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddCity.btnClear') }}</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';

    import { required, maxLength } from "vuelidate/lib/validators"
    export default {
        props: ['show', 'city', 'type'],
        mixins: [clickMixin],
        data: function () {
            return {
                render: 0
            }
        },
        validations: {
            city: {
                name: {
                    required,
                    maxLength: maxLength(50)
                },
                code: {
                    maxLength: maxLength(30)
                },
                description: {
                    maxLength: maxLength(200)
                }
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            GetAutoCodeGenerator: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Region/CityCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.city.code = response.data;
                        root.render++;
                    }
                });
            },
            SaveCity: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.post('/Region/SaveCity', this.city, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data.isSuccess == true) {

                        if (root.type != "Edit") {
                            root.$store.state.cityList.push({
                                id: response.data.city.id,
                                name: response.data.city.name,
                                description: response.data.city.description,
                                code: response.data.city.code,
                                isActive: response.data.city.isActive,
                            })
                            root.$swal({
                                title: this.$t('AddCity.Saved'),
                                text: this.$t('AddCity.SavedSuccessfully'),
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });

                            root.close();
                        }
                        else {
                            var data = root.$store.state.cityList.find(function (x) {
                                return x.id == response.data.city.id;
                            });
                            data.id = response.data.city.id;
                            data.name = response.data.city.name;
                            data.description = response.data.city.description;
                            data.code = response.data.city.code;
                            data.isActive = response.data.city.isActive;
                            root.$swal({
                                title: this.$t('AddCity.Saved'),
                                text: this.$t('AddCity.UpdateSucessfully'),
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                    }
                    else {
                        root.$swal({
                            title: this.$t('AddCity.Error') ,
                            text: this.$t('AddCity.CityNameExist'),
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                });
            }
        },
        mounted: function () {

            if (this.city.id == '00000000-0000-0000-0000-000000000000' || this.city.id == undefined || this.city.id == '')
                this.GetAutoCodeGenerator();

        }
    }
</script>
