<template>
    <modal :show="show">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myModalLabel">{{ $t('ProductFilterModel.InventoryInformation') }}</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group has-label col-sm-12 ">
                        <label class="text"> {{ $t('ProductFilterModel.SelectItem') }} : </label>
                        <product-dropdown :emptyselect="true" v-model="productId" @input="ProductDetail(productId)" />
                    </div>
                    <div class="col-sm-12" v-for="(inv) in inventory" v-bind:key="inv.id">
                        <div class="row">
                            <div class="form-group has-label col-sm-8 "
                                v-if="($i18n.locale == 'en' || isLeftToRight())">
                                <label class="text"> {{ $t('ProductFilterModel.WareHouseName') }} : </label>
                                <input class="form-control" readonly type="text" :placeholder="inv.warehouseName">
                            </div>
                            <div class="form-group has-label col-sm-8 "
                                v-else-if="$i18n.locale == 'ar' & inv.warehouseNameArabic != ''">
                                <label class="text"> {{ $t('ProductFilterModel.WareHouseName') }} : </label>
                                <input class="form-control" readonly type="text" :placeholder="inv.warehouseNameArabic">
                            </div>
                            <div class="form-group has-label col-sm-8 " v-else>
                                <label class="text"> {{ $t('ProductFilterModel.WareHouseName') }} : </label>
                                <input class="form-control" readonly type="text" :placeholder="inv.warehouseName">
                            </div>
                            <div class="form-group has-label col-sm-4 ">
                                <label class="text"> {{ $t('ProductFilterModel.Quantity') }} : </label>
                                <input class="form-control" readonly type="text" :placeholder="inv.currentQuantity">
                            </div>
                        </div>

                    </div>
                    <div class="form-group has-label col-sm-12 ">
                        <label class="text"> {{ $t('ProductFilterModel.TotalQty') }} : </label>
                        <input class="form-control" readonly type="text" :placeholder="totalQuantity">
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-right">
                <button type="button" class="btn btn-outline-danger" v-on:click="close()"> {{
                        $t('ProductFilterModel.btnClear')
                }}</button>

            </div>
            <div class="card-footer" v-if="loading">
                <loading :active.sync="loading" :can-cancel="true" :is-full-page="true"></loading>
            </div>
        </div>

    </modal>

</template>
<script>
import Loading from 'vue-loading-overlay';

import clickMixin from '@/Mixins/clickMixin'
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    components: {
        Loading
    },
    mixins: [clickMixin],
    props: ['show'],
    data: function () {
        return {

            loading: false,
            productId: '',
            inventory: [],
            totalQuantity: 0,


        }
    },
    methods: {

        close: function () {



            this.$emit('close', false);


        },
        ProductDetail: function (Id) {



            var root = this;

            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.totalQuantity = 0;
            root.$https.get('/Product/ProductInventoryDetailQuery?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {

                    if (response.data) {

                        root.inventory = response.data;

                        root.inventory.forEach(function (x) {
                            root.totalQuantity += x.currentQuantity
                        });


                    } else {
                        console.log("error: something wrong from db.");
                    }
                },
                    function (error) {
                        this.loading = false;
                        console.log(error);
                    });

        },
    },
    mounted: function () {


    }
}
</script>
