import { render, staticRenderFns } from "./PaymentVoucher2.vue?vue&type=template&id=60b33a1c&scoped=true&"
import script from "./PaymentVoucher2.vue?vue&type=script&lang=js&"
export * from "./PaymentVoucher2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60b33a1c",
  null
  
)

export default component.exports