<template>
    <div hidden   style="background-color:white !important;">
        <div v-if="itemTotal<=3" ref="mychildcomponent" id='purchaseInvoice' class="col-md-12  your-background" style="color:black !important;background-color:white !important;">
             <div style="background-color:white !important;padding-left:40px !important;padding-right:40px !important">
                <!--HEADER-->
               
                <div class="row" style="background-color:white !important;">
                    <div class="col-4 text-center" style="background-color:white !important;border-top:1px solid black !important ;border-left:1px solid black !important ;border-right:1px solid black !important ;">
                        <p style="font-size:20px;font-weight: bold;" >SALE INVOICE</p>
                        <div class="row">
                            <div class="col-6 text-right">
                                <p style="font-size:16px;font-weight: bold;">Invoice No.</p>
                                <p style="font-size:16px;font-weight: bold;">Inv. Date:</p>
                                <p style="font-size:16px;font-weight: bold;">V.No:</p>
                            </div>
                            <div class="col-6 text-left">
                                <p style="font-size:16px;font-weight: bold;">{{list.registrationNo}}</p>
                                <p style="font-size:16px;font-weight: bold;">{{list.date}}</p>
                                <p style="font-size:16px;font-weight: bold;">****SV</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 " style="background-color:white !important">
                        <p style="font-size: 40px; color: #222234 !important; font-weight: bold;text-align: center !important;">
                            {{headerFooters.company.nameEnglish}}</p>

                         <div class="row">
                            <div class="col-6">
                                <span style="font-size:20px;padding: 0px !important;">  &nbsp;  &nbsp;  &nbsp;  {{headerFooters.company.companyNameEnglish}}</span> <br />
                                <span style="font-size:16px;padding: 0px !important;"> 0303-6911920/0303-6911920</span> <br />
                                <span style="font-size:16px;padding: 0px !important;"> &nbsp;  &nbsp;&nbsp;0303-6911920</span> <br />
                            </div>
                            <div class="col-6">
                                <p style="font-size:16px;font-weight: bold;text-align: right !important;padding-top: 40px !important;">{{headerFooters.company.addressEnglish}}</p>
                               
                            </div>
                        </div>
                    </div>
                    <div class="col-12 " style="padding-left: 0px !important;padding-right: 0px !important;">
                        <table class="table "  style="font-size:16px;border-top:1px solid black !important;border-left:1px solid black !important;border-right:1px solid black !important ;padding-bottom: 0px !important;margin-bottom: 0px !important;" >
                          
                            <tr style="font-size:16px !important;background-color:white !important">
                                <th class="text-left" style="width:50%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important"><span style="font-weight:normal;border: 0px !important;">909</span>  &nbsp;  &nbsp;&nbsp; <span>Counter Sale</span></th>
                                <th class="text-center" style="width:30%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;text-align: left;font-weight:normal;border: 0px !important;">Order Tracker:&nbsp; &nbsp;   OFFICE</th>
                                <th class="text-right" style="width:20%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;padding-right:25px !important;font-weight:normal;border: 0px !important;text-align: right;"></th>
                            </tr>
                            <tr style="font-size:16px !important;background-color:white !important">
                                <th class="text-left" style="width:50%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;font-weight:normal;border: 0px !important;">{{list.areaName }}</th>
                                <th class="text-center" style="width:30%%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;text-align: left;;font-weight:normal;;border: 0px !important;">Page# &nbsp; 1</th>
                                <th class="text-right" style="width:20%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;padding-right:25px !important;font-weight:normal;border: 0px !important;text-align: right;">{{list.time}}</th>
                            </tr>
                          
                        </table>
                        <table class="table "  >
                            <tr style="font-size:16px !important;color:black !important;border: 1px solid black !important;">
                                <th class="text-center" style="width:2%;padding-top:5px !important; padding-bottom:5px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Sr</th>
                                <th class="text-left" style="width:33%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Particular</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Ctn.</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Pcs.</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Qty.</th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Scheme</th>
                                <th class="text-center" style="width:7%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Rate</th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Scheme Less</th>
                                <th class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;" >Disc. </th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Ex.Disc. </th>
                                <th class="text-right" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Amount</th>
                            </tr>


                            <template v-for="(item, index) in list.saleItems">
                                <tr style="font-size:16px;color:black !important " v-bind:key="item.id">
                                    <td class="text-center" style="width:2%;padding-top:5px !important; padding-bottom:5px !important;background-color:white !important;color:black !important">{{index+1}}</td>
                                    <td class="text-left" style="width:33%padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.product.styleNumber}}{{item.productName}} </td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.highQty }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.newQuantity }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.quantity}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.schemePhysicalQuantity}}</td>
                                    <td class="text-center" style="width:7%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{ item.schemeQuantity }} + {{item.scheme}} </td>
                                    <td class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important"  >{{item.discountAmount.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{(item.total - item.discountAmount).toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-right" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{(((item.total - (item.discountAmount+item.bundleAmount)) + item.includingVat)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                </tr>
                            </template>
                            <tr style="font-size:16px;color:black !important;border: 1px solid black !important; " >
                                    <td class="text-left" style="width:33%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;text-align:right !important;font-weight: bold;" colspan = "2">Total </td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{ Carton }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{ Pieces }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{ calulateTotalQty }}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{ SchemePhysicalQuantity }}</td>
                                    <td class="text-center" style="width:7%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;"></td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{ SchemeQuantity }}</td>
                                    <td class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;"> {{parseFloat(list.discount ).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;"> {{parseFloat(calulateTotalExclVAT).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                    <td class="text-right" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{parseFloat(calulateNetTotal  + list.discount  ).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                </tr>
                           </table>
                        <div class="row">
                            <div class="col-8" style="padding-top: 67px !important;font-size: 16px;font-weight: bold;">
                                <span style="border-top:1px solid black;"> SHOP KEEPER SIGNATURE</span>
                            </div>
                            <div class="col-4">
                                <table class="table "
                                    style="padding-bottom: 0px !important;margin-bottom: 0px !important">
                        
                                    <tr style="background-color:white !important;border: 1px solid black;">
                                        <th class="text-center" style="background-color:white !important;text-align: right;font-weight:bold;border: 1px solid black;padding: 0px !important;">Net Bill</th>
                                        <th class="text-right" style=" background-color:white !important;font-weight:bold;border: 1px solid black;padding: 0px !important;">{{parseFloat(calulateNetTotal  + list.discount  ).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</th>
                                    </tr>
                                    <tr style="background-color:white !important">
                                        <th class="text-center" style="background-color:white !important;text-align: right;font-weight:bold;border: 1px solid black;padding: 0px !important;">
                                            Prv.Balance</th>
                                        <th class="text-right" style=" background-color:white !important;font-weight:bold;border: 1px solid black;padding: 0px !important;">{{parseFloat(list.openingBalance-(calulateNetTotal  + list.discount)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</th>
                                    </tr>
                        
                                    <tr style="background-color:white !important">
                                        <th class="text-center" style="background-color:white !important;text-align: right;font-weight:bold;border: 1px solid black;padding: 0px !important;">
                                           Grand Total</th>
                                        <th class="text-right" style=" background-color:white !important;font-weight:bold;border: 1px solid black;padding: 0px !important;">{{parseFloat((list.openingBalance-(calulateNetTotal  + list.discount)+(calulateNetTotal  + list.discount))  ).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</th>
                                    </tr>
                        
                        
                                </table>
                            </div>
                        
                        </div>
                          
                    </div>
                </div>
              

              
        </div>
    </div>
    <div v-else-if="itemTotal>3 && itemTotal<=11" ref="mychildcomponent" id='purchaseInvoice' class="col-md-12  your-background" style="color:black !important;background-color:white !important;">
             <div style="background-color:white !important;padding-left:40px !important;padding-right:40px !important">
                <!--HEADER-->
               
                <div class="row" style="background-color:white !important;">
                    <div class="col-4 text-center" style="background-color:white !important;border-top:1px solid black !important ;border-left:1px solid black !important ;border-right:1px solid black !important ;">
                        <p style="font-size:20px;font-weight: bold;" >SALE INVOICE</p>
                        <div class="row">
                            <div class="col-6 text-right">
                                <p style="font-size:16px;font-weight: bold;">Invoice No.</p>
                                <p style="font-size:16px;font-weight: bold;">Inv. Date:</p>
                                <p style="font-size:16px;font-weight: bold;">V.No:</p>
                            </div>
                            <div class="col-6 text-left">
                                <p style="font-size:16px;font-weight: bold;">{{list.registrationNo}}</p>
                                <p style="font-size:16px;font-weight: bold;">{{list.date}}</p>
                                <p style="font-size:16px;font-weight: bold;">****SV</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 " style="background-color:white !important">
                        <p style="font-size: 40px; color: #222234 !important; font-weight: bold;text-align: center !important;">
                            {{headerFooters.company.nameEnglish}}</p>

                         <div class="row">
                            <div class="col-6">
                                <span style="font-size:20px;padding: 0px !important;">  &nbsp;  &nbsp;  &nbsp;  {{headerFooters.company.companyNameEnglish}}</span> <br />
                                <span style="font-size:16px;padding: 0px !important;"> 0303-6911920/0303-6911920</span> <br />
                                <span style="font-size:16px;padding: 0px !important;"> &nbsp;  &nbsp;&nbsp;0303-6911920</span> <br />
                            </div>
                            <div class="col-6">
                                <p style="font-size:16px;font-weight: bold;text-align: right !important;padding-top: 40px !important;">{{headerFooters.company.addressEnglish}}</p>
                               
                            </div>
                        </div>
                    </div>
                    <div class="col-12 " style="padding-left: 0px !important;padding-right: 0px !important;">
                        <table class="table "  style="font-size:16px;border-top:1px solid black !important;border-left:1px solid black !important;border-right:1px solid black !important ;padding-bottom: 0px !important;margin-bottom: 0px !important;" >
                          
                            <tr style="font-size:16px !important;background-color:white !important">
                                <th class="text-left" style="width:50%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important"><span style="font-weight:normal;border: 0px !important;">909</span>  &nbsp;  &nbsp;&nbsp; <span>Counter Sale</span></th>
                                <th class="text-center" style="width:30%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;text-align: left;font-weight:normal;border: 0px !important;">Order Tracker:&nbsp; &nbsp;   OFFICE</th>
                                <th class="text-right" style="width:20%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;padding-right:25px !important;font-weight:normal;border: 0px !important;text-align: right;"></th>
                            </tr>
                            <tr style="font-size:16px !important;background-color:white !important">
                                <th class="text-left" style="width:50%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;font-weight:normal;border: 0px !important;">{{list.areaName }}</th>
                                <th class="text-center" style="width:30%%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;text-align: left;;font-weight:normal;;border: 0px !important;">Page# &nbsp; 1</th>
                                <th class="text-right" style="width:20%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;padding-right:25px !important;font-weight:normal;border: 0px !important;text-align: right;">{{list.time}}</th>
                            </tr>
                          
                        </table>
                        <table class="table "  >
                            <tr style="font-size:16px !important;color:black !important;border: 1px solid black !important;">
                                <th class="text-center" style="width:2%;padding-top:5px !important; padding-bottom:5px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Sr</th>
                                <th class="text-left" style="width:33%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Particular</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Ctn.</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Pcs.</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Qty.</th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Scheme</th>
                                <th class="text-center" style="width:7%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Rate</th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Scheme Less</th>
                                <th class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;" >Disc. </th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Ex.Disc. </th>
                                <th class="text-right" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Amount</th>
                            </tr>


                            <template v-for="(item, index) in listItemP1">
                                <tr style="font-size:16px;color:black !important " v-bind:key="item.id">
                                    <td class="text-center" style="width:2%;padding-top:5px !important; padding-bottom:5px !important;background-color:white !important;color:black !important">{{index+1}}</td>
                                    <td class="text-left" style="width:33%padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.product.styleNumber}}{{item.productName}} </td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.highQty }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.newQuantity }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.quantity}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.schemePhysicalQuantity}}</td>
                                    <td class="text-center" style="width:7%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{ item.schemeQuantity }} + {{item.scheme}} </td>
                                    <td class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important"  >{{item.discountAmount.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{(item.total - item.discountAmount).toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-right" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{(((item.total - (item.discountAmount+item.bundleAmount)) + item.includingVat)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                </tr>
                            </template>
                           
                           </table>
                      
                          
                    </div>
                </div>
              

              
        </div>
        <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
        <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>
         <div style="background-color:white !important;padding-left:40px !important;padding-right:40px !important;padding-top:20px ;">
                <!--HEADER-->
               
                <div class="row" style="background-color:white !important;">
                   
                   
                    <div class="col-12 " style="padding-left: 0px !important;padding-right: 0px !important;">
                      
                        <table class="table "  >
                            <tr style="font-size:16px !important;color:black !important;border: 1px solid black !important;">
                                <th class="text-center" style="width:2%;padding-top:5px !important; padding-bottom:5px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Sr</th>
                                <th class="text-left" style="width:33%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Particular</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Ctn.</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Pcs.</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Qty.</th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Scheme</th>
                                <th class="text-center" style="width:7%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Rate</th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Scheme Less</th>
                                <th class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;" >Disc. </th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Ex.Disc. </th>
                                <th class="text-right" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Amount</th>
                            </tr>


                            <template v-for="(item, index) in listItemP2">
                                <tr style="font-size:16px;color:black !important " v-bind:key="item.id">
                                    <td class="text-center" style="width:2%;padding-top:5px !important; padding-bottom:5px !important;background-color:white !important;color:black !important">{{index+5}}</td>
                                    <td class="text-left" style="width:33%padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.product.styleNumber}}{{item.productName}} </td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.highQty }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.newQuantity }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.quantity}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.schemePhysicalQuantity}}</td>
                                    <td class="text-center" style="width:7%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{ item.schemeQuantity }} + {{item.scheme}} </td>
                                    <td class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important"  >{{item.discountAmount.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{(item.total - item.discountAmount).toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-right" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{(((item.total - (item.discountAmount+item.bundleAmount)) + item.includingVat)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                </tr>
                            </template>
                            <tr style="font-size:16px;color:black !important;border: 1px solid black !important; " >
                                    <td class="text-left" style="width:33%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;text-align:right !important;font-weight: bold;" colspan = "2">Total </td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{ Carton }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{ Pieces }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{ calulateTotalQty }}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{ SchemePhysicalQuantity }}</td>
                                    <td class="text-center" style="width:7%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;"></td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{ SchemeQuantity }}</td>
                                    <td class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;"> {{parseFloat(list.discount ).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;"> {{parseFloat(calulateTotalExclVAT).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                    <td class="text-right" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{parseFloat(calulateNetTotal  + list.discount  ).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                </tr>
                           </table>
                        <div class="row">
                            <div class="col-8" style="padding-top: 67px !important;font-size: 16px;font-weight: bold;">
                                <span style="border-top:1px solid black;"> SHOP KEEPER SIGNATURE</span>
                            </div>
                            <div class="col-4">
                                <table class="table "
                                    style="padding-bottom: 0px !important;margin-bottom: 0px !important">
                        
                                    <tr style="background-color:white !important;border: 1px solid black;">
                                        <th class="text-center" style="background-color:white !important;text-align: right;font-weight:bold;border: 1px solid black;padding: 0px !important;">Net Bill</th>
                                        <th class="text-right" style=" background-color:white !important;font-weight:bold;border: 1px solid black;padding: 0px !important;">{{parseFloat(calulateNetTotal  + list.discount  ).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</th>
                                    </tr>
                                    <tr style="background-color:white !important">
                                        <th class="text-center" style="background-color:white !important;text-align: right;font-weight:bold;border: 1px solid black;padding: 0px !important;">
                                            Prv.Balance</th>
                                        <th class="text-right" style=" background-color:white !important;font-weight:bold;border: 1px solid black;padding: 0px !important;">{{parseFloat(list.openingBalance-(calulateNetTotal  + list.discount)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</th>
                                    </tr>
                        
                                    <tr style="background-color:white !important">
                                        <th class="text-center" style="background-color:white !important;text-align: right;font-weight:bold;border: 1px solid black;padding: 0px !important;">
                                           Grand Total</th>
                                        <th class="text-right" style=" background-color:white !important;font-weight:bold;border: 1px solid black;padding: 0px !important;">{{parseFloat((list.openingBalance-(calulateNetTotal  + list.discount)+(calulateNetTotal  + list.discount))  ).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</th>
                                    </tr>
                        
                        
                                </table>
                            </div>
                        
                        </div>
                          
                    </div>
                </div>
              

              
        </div>


    </div>
    <div v-else-if="itemTotal>11 && itemTotal<=21" ref="mychildcomponent" id='purchaseInvoice' class="col-md-12  your-background" style="color:black !important;background-color:white !important;">
             <div style="background-color:white !important;padding-left:40px !important;padding-right:40px !important">
                <!--HEADER-->
               
                <div class="row" style="background-color:white !important;">
                    <div class="col-4 text-center" style="background-color:white !important;border-top:1px solid black !important ;border-left:1px solid black !important ;border-right:1px solid black !important ;">
                        <p style="font-size:20px;font-weight: bold;" >SALE INVOICE</p>
                        <div class="row">
                            <div class="col-6 text-right">
                                <p style="font-size:16px;font-weight: bold;">Invoice No.</p>
                                <p style="font-size:16px;font-weight: bold;">Inv. Date:</p>
                                <p style="font-size:16px;font-weight: bold;">V.No:</p>
                            </div>
                            <div class="col-6 text-left">
                                <p style="font-size:16px;font-weight: bold;">{{list.registrationNo}}</p>
                                <p style="font-size:16px;font-weight: bold;">{{list.date}}</p>
                                <p style="font-size:16px;font-weight: bold;">****SV</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 " style="background-color:white !important">
                        <p style="font-size: 40px; color: #222234 !important; font-weight: bold;text-align: center !important;">
                            {{headerFooters.company.nameEnglish}}</p>

                         <div class="row">
                            <div class="col-6">
                                <span style="font-size:20px;padding: 0px !important;">  &nbsp;  &nbsp;  &nbsp;  {{headerFooters.company.companyNameEnglish}}</span> <br />
                                <span style="font-size:16px;padding: 0px !important;"> 0303-6911920/0303-6911920</span> <br />
                                <span style="font-size:16px;padding: 0px !important;"> &nbsp;  &nbsp;&nbsp;0303-6911920</span> <br />
                            </div>
                            <div class="col-6">
                                <p style="font-size:16px;font-weight: bold;text-align: right !important;padding-top: 40px !important;">{{headerFooters.company.addressEnglish}}</p>
                               
                            </div>
                        </div>
                    </div>
                    <div class="col-12 " style="padding-left: 0px !important;padding-right: 0px !important;">
                        <table class="table "  style="font-size:16px;border-top:1px solid black !important;border-left:1px solid black !important;border-right:1px solid black !important ;padding-bottom: 0px !important;margin-bottom: 0px !important;" >
                          
                            <tr style="font-size:16px !important;background-color:white !important">
                                <th class="text-left" style="width:50%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important"><span style="font-weight:normal;border: 0px !important;">909</span>  &nbsp;  &nbsp;&nbsp; <span>Counter Sale</span></th>
                                <th class="text-center" style="width:30%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;text-align: left;font-weight:normal;border: 0px !important;">Order Tracker:&nbsp; &nbsp;   OFFICE</th>
                                <th class="text-right" style="width:20%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;padding-right:25px !important;font-weight:normal;border: 0px !important;text-align: right;"></th>
                            </tr>
                            <tr style="font-size:16px !important;background-color:white !important">
                                <th class="text-left" style="width:50%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;font-weight:normal;border: 0px !important;">{{list.areaName }}</th>
                                <th class="text-center" style="width:30%%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;text-align: left;;font-weight:normal;;border: 0px !important;">Page# &nbsp; 1</th>
                                <th class="text-right" style="width:20%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;padding-right:25px !important;font-weight:normal;border: 0px !important;text-align: right;">{{list.time}}</th>
                            </tr>
                          
                        </table>
                        <table class="table "  >
                            <tr style="font-size:16px !important;color:black !important;border: 1px solid black !important;">
                                <th class="text-center" style="width:2%;padding-top:5px !important; padding-bottom:5px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Sr</th>
                                <th class="text-left" style="width:33%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Particular</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Ctn.</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Pcs.</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Qty.</th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Scheme</th>
                                <th class="text-center" style="width:7%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Rate</th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Scheme Less</th>
                                <th class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;" >Disc. </th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Ex.Disc. </th>
                                <th class="text-right" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Amount</th>
                            </tr>


                            <template v-for="(item, index) in listItemP1">
                                <tr style="font-size:16px;color:black !important " v-bind:key="item.id">
                                    <td class="text-center" style="width:2%;padding-top:5px !important; padding-bottom:5px !important;background-color:white !important;color:black !important">{{index+1}}</td>
                                    <td class="text-left" style="width:33%padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.product.styleNumber}}{{item.productName}} </td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.highQty }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.newQuantity }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.quantity}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.schemePhysicalQuantity}}</td>
                                    <td class="text-center" style="width:7%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{ item.schemeQuantity }} + {{item.scheme}} </td>
                                    <td class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important"  >{{item.discountAmount.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{(item.total - item.discountAmount).toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-right" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{(((item.total - (item.discountAmount+item.bundleAmount)) + item.includingVat)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                </tr>
                            </template>
                          
                           </table>
                       
                          
                    </div>
                </div>
              

              
        </div>
        <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
        <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>
        <div style="background-color:white !important;padding-left:40px !important;padding-right:40px !important;padding-top:20px ;">
                <!--HEADER-->
               
                <div class="row" style="background-color:white !important;">
                   
                   
                    <div class="col-12 " style="padding-left: 0px !important;padding-right: 0px !important;">
                      
                        <table class="table "  >
                            <tr style="font-size:16px !important;color:black !important;border: 1px solid black !important;">
                                <th class="text-center" style="width:2%;padding-top:5px !important; padding-bottom:5px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Sr</th>
                                <th class="text-left" style="width:33%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Particular</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Ctn.</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Pcs.</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Qty.</th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Scheme</th>
                                <th class="text-center" style="width:7%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Rate</th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Scheme Less</th>
                                <th class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;" >Disc. </th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Ex.Disc. </th>
                                <th class="text-right" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Amount</th>
                            </tr>


                            <template v-for="(item, index) in listItemP2">
                                <tr style="font-size:16px;color:black !important " v-bind:key="item.id">
                                    <td class="text-center" style="width:2%;padding-top:5px !important; padding-bottom:5px !important;background-color:white !important;color:black !important">{{index+5}}</td>
                                    <td class="text-left" style="width:33%padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.product.styleNumber}}{{item.productName}} </td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.highQty }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.newQuantity }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.quantity}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.schemePhysicalQuantity}}</td>
                                    <td class="text-center" style="width:7%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{ item.schemeQuantity }} + {{item.scheme}} </td>
                                    <td class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important"  >{{item.discountAmount.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{(item.total - item.discountAmount).toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-right" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{(((item.total - (item.discountAmount+item.bundleAmount)) + item.includingVat)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                </tr>
                            </template>
                          
                           </table>
                       
                    </div>
                </div>
              

              
        </div>
        <p style="page-break-after: always;margin-bottom:0;margin-top:0;"></p>
        <p style="page-break-before: always;margin-bottom:0;margin-top:0;"></p>
         <div style="background-color:white !important;padding-left:40px !important;padding-right:40px !important;padding-top:20px">
                <!--HEADER-->
               
                <div class="row" style="background-color:white !important;">
                    
                  
                    <div class="col-12 " style="padding-left: 0px !important;padding-right: 0px !important;">
                       
                        <table class="table "  >
                            <tr style="font-size:16px !important;color:black !important;border: 1px solid black !important;">
                                <th class="text-center" style="width:2%;padding-top:5px !important; padding-bottom:5px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Sr</th>
                                <th class="text-left" style="width:33%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Particular</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Ctn.</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Pcs.</th>
                                <th class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Qty.</th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Scheme</th>
                                <th class="text-center" style="width:7%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Rate</th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Scheme Less</th>
                                <th class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;" >Disc. </th>
                                <th class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Ex.Disc. </th>
                                <th class="text-right" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;">Amount</th>
                            </tr>


                            <template v-for="(item, index) in listItemP3">
                                <tr style="font-size:16px;color:black !important " v-bind:key="item.id">
                                    <td class="text-center" style="width:2%;padding-top:5px !important; padding-bottom:5px !important;background-color:white !important;color:black !important">{{index+14}}</td>
                                    <td class="text-left" style="width:33%padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.product.styleNumber}}{{item.productName}} </td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.highQty }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.newQuantity }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.quantity}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.schemePhysicalQuantity}}</td>
                                    <td class="text-center" style="width:7%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{ item.schemeQuantity }} + {{item.scheme}} </td>
                                    <td class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important"  >{{item.discountAmount.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{(item.total - item.discountAmount).toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-right" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;color:black !important">{{(((item.total - (item.discountAmount+item.bundleAmount)) + item.includingVat)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                </tr>
                            </template>
                            <tr style="font-size:16px;color:black !important;border: 1px solid black !important; " >
                                    <td class="text-left" style="width:33%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;text-align:right !important;font-weight: bold;" colspan = "2">Total </td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{ Carton }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{ Pieces }}</td>
                                    <td class="text-center" style="width:6%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{ calulateTotalQty }}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{ SchemePhysicalQuantity }}</td>
                                    <td class="text-center" style="width:7%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;"></td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{ SchemeQuantity }}</td>
                                    <td class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;"> {{parseFloat(list.discount ).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                    <td class="text-center" style="width:9%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;"> {{parseFloat(calulateTotalExclVAT).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                    <td class="text-right" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-top:0.01px  solid black;border-bottom:0.01px  solid black;color:black !important;font-weight: bold;">{{parseFloat(calulateNetTotal  + list.discount  ).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                </tr>
                           </table>
                        <div class="row">
                            <div class="col-8" style="padding-top: 67px !important;font-size: 16px;font-weight: bold;">
                                <span style="border-top:1px solid black;"> SHOP KEEPER SIGNATURE</span>
                            </div>
                            <div class="col-4">
                                <table class="table "
                                    style="padding-bottom: 0px !important;margin-bottom: 0px !important">
                        
                                    <tr style="background-color:white !important;border: 1px solid black;">
                                        <th class="text-center" style="background-color:white !important;text-align: right;font-weight:bold;border: 1px solid black;padding: 0px !important;">Net Bill</th>
                                        <th class="text-right" style=" background-color:white !important;font-weight:bold;border: 1px solid black;padding: 0px !important;">{{parseFloat(calulateNetTotal  + list.discount  ).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</th>
                                    </tr>
                                    <tr style="background-color:white !important">
                                        <th class="text-center" style="background-color:white !important;text-align: right;font-weight:bold;border: 1px solid black;padding: 0px !important;">
                                            Prv.Balance</th>
                                        <th class="text-right" style=" background-color:white !important;font-weight:bold;border: 1px solid black;padding: 0px !important;">{{parseFloat(list.openingBalance-(calulateNetTotal  + list.discount)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</th>
                                    </tr>
                        
                                    <tr style="background-color:white !important">
                                        <th class="text-center" style="background-color:white !important;text-align: right;font-weight:bold;border: 1px solid black;padding: 0px !important;">
                                           Grand Total</th>
                                        <th class="text-right" style=" background-color:white !important;font-weight:bold;border: 1px solid black;padding: 0px !important;">{{parseFloat((list.openingBalance-(calulateNetTotal  + list.discount)+(calulateNetTotal  + list.discount))  ).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</th>
                                    </tr>
                        
                        
                                </table>
                            </div>
                        
                        </div>
                          
                    </div>
                </div>
              

              
        </div>
    </div>
    </div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";

    import axios from 'axios'
    const options = {
        name: '',
        specs: [
            'fullscreen=no',
            'titlebar=yes',
            'scrollbars=yes'
        ],
        styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css',
            './assets/css/A5Print.css' // <- inject here
        ],
        timeout: 700,
        autoClose: true,
        windowTitle: window.document.title,

    }
    export default {
      
        props: ['printDetails', 'headerFooter', 'isTouchScreen'],
        mixins: [clickMixin],
        data: function () {
            return {
                currency: "",
                customer: true,
                qrValue: "",
                isHeaderFooter: '',
                invoicePrint: '',
                IsDeliveryNote: '',
                arabic: '',
                english: '',
                userName: '',
                itemTotal: 0,
                emptyListCount: 0,
                randerWarranty: 0,
                indexCount: 0,
                page: 0,
                isMultiUnit: '',
                listItemP1Summary: {
                calulateTotalQty: 0,
                calulateNetTotal: 0,
                calulateTotalExclVAT: 0,
                calulateTotalVAT: 0,
                calulateTotalInclusiveVAT: 0,
                calulateDiscountAmount: 0,
                calulateBundleAmount: 0,
            },
            listItemP2Summary: {
                calulateTotalQty: 0,
                calulateNetTotal: 0,
                calulateTotalExclVAT: 0,
                calulateTotalVAT: 0,
                calulateTotalInclusiveVAT: 0,
                calulateDiscountAmount: 0,
                calulateBundleAmount: 0,
            },
            listItemP3Summary: {
                calulateTotalQty: 0,
                calulateNetTotal: 0,
                calulateTotalExclVAT: 0,
                calulateTotalVAT: 0,
                calulateTotalInclusiveVAT: 0,
                calulateDiscountAmount: 0,
                calulateBundleAmount: 0,
            },
            listItemP4Summary: {
                calulateTotalQty: 0,
                calulateNetTotal: 0,
                calulateTotalExclVAT: 0,
                calulateTotalVAT: 0,
                calulateTotalInclusiveVAT: 0,
                calulateDiscountAmount: 0,
                calulateBundleAmount: 0,
            },
            listItemP5Summary: {
                calulateTotalQty: 0,
                calulateNetTotal: 0,
                calulateTotalExclVAT: 0,
                calulateTotalVAT: 0,
                calulateTotalInclusiveVAT: 0,
                calulateDiscountAmount: 0,
                calulateBundleAmount: 0,
            },
                listItemP1: [],
            listItemP2: [],
            listItemP3: [],
            listItemP4: [],
            listItemP5: [],
            listItemP6: [],
                list: {
                    number: 0,
                    listItemTotal: [],
                    registrationNo: '',
                    date: '',
                    time: '',
                    dueDate: '',
                    companyName: '',
                    companyPhoneNo: '',
                    companyAddress: '',
                    discountAmount: '',
                    cashCustomer: '',
                    creditCustomer: '',
                    customerPhoneNo: '',
                    customerAddress: '',
                    paymentMethod: '',
                    paymentMethodNo: '',
                    invocieType: '',
                    saleItems:
                        [

                        ]
                },
                render: 0,
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
                htmlData: {
                    htmlString: ''
                },
                otherAmount: 0,
                dueAmount: 0
            }
        },
        filters: {
            toWords: function (value) {
                var converter = require('number-to-words');
                if (!value) return ''
                return converter.toWords(value);
            }
        },
        computed: {
            Carton: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (Number((c.highQty) || 0) > 0 ? Number((c.highQty) || 0) : 0) }, 0)
            },
            Pieces: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (Number((c.newQuantity) || 0) > 0 ? Number((c.newQuantity) || 0) : 0) }, 0)
            },
            calulateTotalQty: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (Number((c.quantity) || 0) > 0 ? Number((c.quantity) || 0) : 0) }, 0)
            },
            SchemePhysicalQuantity: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (Number((c.schemePhysicalQuantity) || 0) > 0 ? Number((c.schemePhysicalQuantity) || 0) : 0) }, 0)
            },
            SchemeQuantity: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (Number((c.SchemeQuantity) || 0) > 0 ? Number((c.SchemeQuantity) || 0) : 0) }, 0)
            },
            calulateNetTotal: function () {
                var withDisc = this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : c.total - c.discountAmount) }, 0)
                var totalIncDisc = (this.list.isBeforeTax && this.list.isDiscountOnTransaction && this.list.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(this.list.transactionLevelDiscount) * (withDisc) / 100) : parseFloat(this.calulateDiscountAmount)

                return this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total + c.includingVat) || 0)) }, 0) + parseFloat((this.list.taxMethod == ("Inclusive" || "شامل") ? 0 : this.calulateTotalVAT)) - totalIncDisc
            },
            calulateTotalExclVAT: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total - c.discountAmount) || 0)) }, 0)
            },
            calulateTotalVAT: function () {
                var totalQtyWithotFree = this.list.saleItems.reduce((qty, prod) => qty + (prod.isFree ? 0 : parseInt(prod.quantity == '' ? 0 : prod.quantity)), 0);
                var paidVat = this.list.saleItems
                    .reduce((vat, prod) => (vat + (prod.isFree ? 0 : ((this.list.taxMethod == ("Inclusive" || "شامل")) ? ((parseFloat(prod.total - prod.discountAmount) - (this.list.isBeforeTax ? (((prod.quantity * prod.unitPrice) * this.list.transactionLevelDiscount) / 100) : 0)) * prod.taxRate) / (100 + prod.taxRate) : ((parseFloat(prod.total - prod.discountAmount) - (this.list.isBeforeTax && !this.list.isFixed && this.list.isDiscountOnTransaction ? (((prod.quantity * prod.unitPrice) * this.list.transactionLevelDiscount) / 100) : (this.list.isBeforeTax && this.list.isFixed && this.list.isDiscountOnTransaction ? (this.list.transactionLevelDiscount / parseFloat(totalQtyWithotFree) * prod.quantity) : 0))) * prod.taxRate) / 100))), 0).toFixed(3).slice(0, -1)

                return paidVat;
            },
            calulateDiscountAmount: function () {
                var totalIncDisc = 0;
                if (this.list.isDiscountOnTransaction) {
                    var withDisc = this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : c.total - c.discountAmount) }, 0)

                    var discountForInclusiveVat = parseFloat(this.list.saleItems
                        .reduce((vat, prod) => (vat + (prod.isFree ? 0 : ((this.list.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(prod.total) * prod.taxRate) / (100 + prod.taxRate) : 0))), 0).toFixed(3).slice(0, -1))

                    totalIncDisc = ((this.list.isBeforeTax && this.list.isDiscountOnTransaction) ? (this.list.taxMethod == ("Inclusive" || "شامل") ? (parseFloat(this.list.transactionLevelDiscount) * (withDisc - discountForInclusiveVat) / 100) : (this.list.isFixed ? parseFloat(this.list.transactionLevelDiscount) : parseFloat(this.list.transactionLevelDiscount) * withDisc / 100)) : (this.list.isFixed ? parseFloat(this.list.transactionLevelDiscount) : (parseFloat(withDisc) + (this.list.taxMethod == ("Inclusive" || "شامل") ? 0 : parseFloat(this.calulateTotalVAT))) * parseFloat(this.list.transactionLevelDiscount) / 100)).toFixed(3).slice(0, -1)

                }
                else {
                    totalIncDisc = this.list.saleItems.reduce(function (a, c) { return a + (c.isFree ? 0 : c.discountAmount) }, 0)
                }

                return totalIncDisc;
            }
        },
        methods: {

            calulateTotalQtySub: function (val) {
                return val.reduce(function (a, c) { return a + (Number((c.quantity) || 0) > 0 ? Number((c.quantity) || 0) : 0) }, 0)
            },
            calulateNetTotalSub: function (val) {
                var withDisc = val.reduce(function (a, c) { return a + (c.isFree ? 0 : c.total - c.discountAmount) }, 0)
                var totalIncDisc = (this.list.isBeforeTax && this.list.isDiscountOnTransaction && this.list.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(this.list.transactionLevelDiscount) * (withDisc) / 100) : parseFloat(this.calulateDiscountAmount)

                return val.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total + c.includingVat) || 0)) }, 0) + parseFloat((this.list.taxMethod == ("Inclusive" || "شامل") ? 0 : this.calulateTotalVAT)) - totalIncDisc
            },
            calulateTotalExclVATSub: function (val) {
                return val.reduce(function (a, c) { return a + (c.isFree ? 0 : Number((c.total - c.discountAmount) || 0)) }, 0)
            },
            calulateTotalVATSub: function (val) {
                var totalQtyWithotFree = val.reduce((qty, prod) => qty + (prod.isFree ? 0 : parseInt(prod.quantity == '' ? 0 : prod.quantity)), 0);
                var paidVat = val
                    .reduce((vat, prod) => (vat + (prod.isFree ? 0 : ((this.list.taxMethod == ("Inclusive" || "شامل")) ? ((parseFloat(prod.total - prod.discountAmount) - (this.list.isBeforeTax ? (((prod.quantity * prod.unitPrice) * this.list.transactionLevelDiscount) / 100) : 0)) * prod.taxRate) / (100 + prod.taxRate) : ((parseFloat(prod.total - prod.discountAmount) - (this.list.isBeforeTax && !this.list.isFixed && this.list.isDiscountOnTransaction ? (((prod.quantity * prod.unitPrice) * this.list.transactionLevelDiscount) / 100) : (this.list.isBeforeTax && this.list.isFixed && this.list.isDiscountOnTransaction ? (this.list.transactionLevelDiscount / parseFloat(totalQtyWithotFree) * prod.quantity) : 0))) * prod.taxRate) / 100))), 0).toFixed(3).slice(0, -1)

                return paidVat;
            },
            calulateDiscountAmountSub: function (val) {
                var totalIncDisc = 0;
                if (this.list.isDiscountOnTransaction) {
                    var withDisc = val.reduce(function (a, c) { return a + (c.isFree ? 0 : c.total - c.discountAmount) }, 0)

                    var discountForInclusiveVat = parseFloat(val
                        .reduce((vat, prod) => (vat + (prod.isFree ? 0 : ((this.list.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(prod.total) * prod.taxRate) / (100 + prod.taxRate) : 0))), 0).toFixed(3).slice(0, -1))

                    totalIncDisc = ((this.list.isBeforeTax && this.list.isDiscountOnTransaction) ? (this.list.taxMethod == ("Inclusive" || "شامل") ? (parseFloat(this.list.transactionLevelDiscount) * (withDisc - discountForInclusiveVat) / 100) : (this.list.isFixed ? parseFloat(this.list.transactionLevelDiscount) : parseFloat(this.list.transactionLevelDiscount) * withDisc / 100)) : (this.list.isFixed ? parseFloat(this.list.transactionLevelDiscount) : (parseFloat(withDisc) + (this.list.taxMethod == ("Inclusive" || "شامل") ? 0 : parseFloat(this.calulateTotalVAT))) * parseFloat(this.list.transactionLevelDiscount) / 100)).toFixed(3).slice(0, -1)

                }
                else {
                    totalIncDisc = val.reduce(function (a, c) { return a + (c.isFree ? 0 : c.discountAmount) }, 0)
                }

                return totalIncDisc;
            },
            calulateDiscountAmount1: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number(c.discountAmount || 0) }, 0)
            },
            calulateBundleAmount1: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0)
            },
            calulateNetTotalWithVAT: function () {
                var total = this.list.saleItems.reduce(function (a, c) { return a + Number((c.total + c.includingVat) || 0) }, 0);
                var grandTotal = parseFloat(total) - (this.calulateDiscountAmount1() + this.calulateBundleAmount1())
                return (parseFloat(grandTotal).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },
            calulateTotalVATofInvoice: function () {
                var total = this.list.saleItems.reduce(function (a, c) { return a + Number((c.includingVat + c.inclusiveVat) || 0) }, 0);
                return (parseFloat(total).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },

            printInvoice: function () {

                var root = this;
                this.$htmlToPaper('purchaseInvoice', options, () => {
                    if (root.isTouchScreen === 'TouchInvoice') {
                        root.$router.go('/TouchInvoice')
                    }
                    else if (root.isTouchScreen === 'addSale') {
                        root.$router.push({
                            query: {
                                data: undefined
                            }
                        });
                        root.$router.go('/addSale')
                    }
                    else if (root.isTouchScreen === 'sale') {
                        root.$router.push('/sale');
                    }
                    else {

                        console.log('No Rander the Page');
                    }

                });
            },
            printBlindInvoice: function () {
                var root = this;
                // this.$htmlToPaper('purchaseInvoice');
                this.htmlData.htmlString = this.$refs.mychildcomponent.innerHTML;
                //  var html1 = this.$refs.mychildcomponent.innerHTML;
                //  var data = { html: html1 }
                //
                var printerName = localStorage.getItem('PrinterName')
                var form = new FormData();
                form.append('htmlString', this.htmlData.htmlString);
                form.append('NoOfPagesPrint', 0);
                form.append('PrintType', 'invoice');
                form.append('PrinterName', printerName);
                //this.$htmlToPaper('purchaseInvoice');
                //axios.post('http://localhos:7013/print/from-pdf', form);
                //axios.post('http://127.0.0.1:7013/print/from-pdf', form);
                //alert();
                //var root = this;



                if (!this.$ServerIp.includes('localhost')) {
                    axios.post('http://127.0.0.1:7014/print/from-pdf', form).then(function (x) {
                        console.log(x);

                    });
                    //if (root.isTouchScreen === true) {
                    //    root.$router.go('/TouchInvoice')
                    //}
                }
                else {
                    this.$htmlToPaper('purchaseInvoice', options, () => {
                        if (root.isTouchScreen === true) {
                            root.$router.go('/TouchInvoice')
                        }
                        else {
                            root.$router.go('/addSale')
                        }
                    });

                }


                //var token = '';
                //if (this.$session.exists()) {
                //    token = localStorage.getItem('token');
                //}
                //root.loading = true;
                //root.$https.post('/EmployeeRegistration/PrintPos', data, { headers: { "Authorization": `Bearer ${token}` } }).then(function (x) {
                //    alert(x.data)
                //});



            },
            GetTLVForValue: function (tagNumber, tagValue) {
                var tagBuf = Buffer.from([tagNumber], 'utf-8')
                var tagValueLenBuf = Buffer.from([tagValue.length], 'utf-8')
                var tagValueBuf = Buffer.from(tagValue, 'utf-8')
                var bufsArray = [tagBuf, tagValueLenBuf, tagValueBuf]
                return Buffer.concat(bufsArray)
            }

        },
        created() {

        },
        mounted: function () {
            this.isMultiUnit = localStorage.getItem('IsMultiUnit');
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.invoicePrint = localStorage.getItem('InvoicePrint');
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            this.IsDeliveryNote = localStorage.getItem('IsDeliveryNote');
            this.userName = localStorage.getItem('FullName');
            this.currency = localStorage.getItem('currency');
            var root = this;
            
            if (this.printDetails.saleItems.length > 0) {
                this.list = this.printDetails;
                this.headerFooters = this.headerFooter;
                
                if (this.list.paymentTypes != null || this.list.paymentTypes.length > 0) {

                    root.list.paymentTypes.forEach(function (x) {
                        if (x.name == 'Credit') {
                            root.dueAmount = parseFloat(x.amount)
                        }
                    })
                }
                if (this.headerFooters.blindPrint) {
                    this.printBlindInvoice();
                }
                else {
                    var sellerNameBuff = root.GetTLVForValue('1', this.headerFooters.company.nameEnglish)
                    var vatRegistrationNoBuff = root.GetTLVForValue('2', this.headerFooters.company.vatRegistrationNo)
                    var timeStampBuff = root.GetTLVForValue('3', this.list.date)
                    var totalWithVat = root.GetTLVForValue('4', this.calulateNetTotalWithVAT())
                    var totalVat = root.GetTLVForValue('5', this.calulateTotalVATofInvoice())
                    var tagArray = [sellerNameBuff, vatRegistrationNoBuff, timeStampBuff, totalWithVat, totalVat]
                    var qrCodeBuff = Buffer.concat(tagArray)
                    root.qrValue = qrCodeBuff.toString('base64')

                    var totalItem = this.printDetails.saleItems.length;
                    this.itemTotal = totalItem;
                    // this.page = Math.ceil(count / 2);
                    if (totalItem <= 3) {
                    for (var i = 0; i < totalItem; i++) {
                        root.listItemP1.push(root.printDetails.saleItems[i]);
                    }
                }
                else if (totalItem > 3 && totalItem <= 11) {
                    for (var k = 0; k < totalItem; k++) {
                        if (k <= 3) {
                            root.listItemP1.push(root.printDetails.saleItems[k]);
                        }
                        else {
                            root.listItemP2.push(root.printDetails.saleItems[k]);
                        }
                    }
                }
                else if (totalItem > 11 && totalItem <= 21) {
                    for (var j = 0; j < totalItem; j++) {
                        if (j <= 3) {
                            root.listItemP1.push(root.printDetails.saleItems[j]);
                        }
                        else if (j > 3 && j <= 12) {
                            root.listItemP2.push(root.printDetails.saleItems[j]);
                        }
                        else {
                            root.listItemP3.push(root.printDetails.saleItems[j]);
                        }
                    }
                }
                    this.list.date = moment().format('DD MMM YYYY');
                    this.list.time = moment().format('hh:mm A');
                    setTimeout(function () {
                        root.printInvoice();
                    }, 125)
                }

            }
        },

    }
</script>
<style scoped>
    body {
        background-color: white !important;
    }

    .page {
        background-color: white !important;
    }

    .your-background:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: -1;
        border-bottom: 1000px solid #eee; /* Make it fit your needs */
    }

    .img {
        text-align: right !important;
        margin-top: 2px;
        padding-left: 2px
    }
</style>


