<template>
    <div>
        <div hidden    id='inventoryDetailReport' class="col-md-7">
            <!--HEADER-->
            <div class="col-md-12" style="height:45mm;border:2px solid #000000;">
                <div class="row" style="height:35mm">
                    <div class="col-md-4 ">
                        <table class="text-left">
                            <tr>
                                <td>
                                    <p>
                                        <span style="font-size:25px;color:black !important;font-weight:bold;">{{headerFooters.company.nameEnglish}}</span><br />
                                        <span style="font-size:17px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryEnglish}}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">VAT No.: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">Cr No.:{{headerFooters.company.companyRegNo}}</span><br />
                                        <span style="font-size:15px;color:black !important;font-weight:bold;">
                                            Tel: {{headerFooters.company.phoneNo}}
                                        </span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-4 text-center my-5" style="padding:0px !important; margin:0 !important">
                        <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                    </div>
                    <div class="col-md-4 ">
                        <table class="text-right" v-if="arabic=='true'">
                            <tr>
                                <td>
                                    <p>
                                        <span style="font-size:25px;color:black !important;font-weight:bold;">{{headerFooters.company.nameArabic}}.</span><br />
                                        <span style="font-size:17px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryArabic}}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">رقم ضريبة القيمة المضافة: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">رقم السجل التجاري :{{headerFooters.company.companyRegNo}}</span><br />
                                        <span style="font-size:15px;color:black !important;font-weight:bold;">
                                            هاتف: {{headerFooters.company.phoneNo}}:
                                        </span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" style="margin-bottom:10px !important;height:10mm">
                        <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                            <span style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{ $t('TrialBalanceDetailReport.TrialBalanceDetailReport') }}</span>
                        </p>
                    </div>
                </div>

            </div>

            <!--INFORMATION-->
            <div class="border border-dark col-md-12  ">
                <div class="row m-3">
                    <div class="col-md-6" style="display:flex;">
                        <div style="width:28%; font-weight:bolder;text-align:right;color:black !important;">From Date:</div>
                        <div style="width:50%; text-align:center;font-weight:bold;color:black !important;">{{fDate}}</div>
                        <div style="width:22%;font-weight:bolder;color:black !important;font-size:15px !important" v-if="arabic=='true'">:من التاريخ</div>
                    </div>
                    <div class="col-md-6" style="display:flex;">
                        <div style="width:28%;font-weight:bolder;text-align:right;color:black !important;">To Date:</div>
                        <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"><span>{{tDate}}</span> </div>
                        <div style="width:22%;font-weight:bolder;color:black !important; padding-right:20px;font-size:15px !important" v-if="arabic=='true'">
                            :حتي اليوم
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 ">
                <div class="row">

                    <table class="table col-md-12">
                        <tr>
                            <th style="width:5%">#</th>

                            <th style="width: 20%; text-align: left">
                                {{ $t('TrialBalanceDetailReport.Name') }}
                            </th>

                            <th style="width: 20%; text-align: center">
                                {{ $t('TrialBalanceDetailReport.NameArabic') }}
                            </th>
                            <th style="width: 20%; text-align: right">
                                {{ $t('TrialBalanceDetailReport.Debit') }}
                            </th>
                            <th style="width: 15%; text-align: right">
                                {{ $t('TrialBalanceDetailReport.Credit') }}
                            </th>
                            <th style="width: 15%; text-align: right">
                                {{ $t('TrialBalanceDetailReport.Total') }}
                            </th>

                        </tr>

                    </table>
                    <div v-for="(trans,index) in transactionDetail" v-bind:key="trans.id" style="width:100%">

                        <div style="width:100% !important"> 
                            <table class="table col-md-12" style="background-color:azure !important;" >
                                <tbody>
                                    <tr style="font-size:17px;font-weight:bold;background-color:azure !important">
                                        <td style="width:5%;padding-top:5px !important; padding-bottom:5px !important;background-color:azure !important">
                                            {{index+1}}
                                        </td>
                                        <td style="width: 20%; text-align:left;padding-top:5px !important; padding-bottom:5px !important;background-color:azure !important">
                                            {{trans.name}}

                                        </td>
                                        <td style="width: 20%; text-align: center;padding-top:5px !important; padding-bottom:5px !important;background-color:azure !important">
                                            {{trans.nameArabic}}
                                        </td>

                                        <td style="width: 20%; text-align: right;padding-top:5px !important; padding-bottom:5px !important;background-color:azure !important">

                                            {{(parseFloat(trans.debit)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}

                                        </td>

                                        <td style="width: 10%; text-align: right;padding-top:5px !important; padding-bottom:5px !important;background-color:azure !important">
                                            {{(parseFloat(trans.credit)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}

                                        </td>
                                        <td style="width: 15%; text-align: right;padding-top:5px !important; padding-bottom:5px !important;background-color:azure !important">
                                            {{trans.total>0?'Dr':'Cr'}} {{nonNegative(trans.total) }}

                                        </td>


                                    </tr>
                                </tbody>
                            </table>
                            <div v-for="(costCenter,busIndex) in trans.costCenterLookUpModel" :key="costCenter.id" style="width: 100%">
                                <div style="width:100%" >

                                    <table class="table col-md-12" style="background-color:lightgray !important;width:100%">
                                        <tbody>
                                            <tr style="font-size:17px;font-weight:bold;background-color:lightgray !important">
                                                <td style="width:9%;padding-left:12px;padding-top:12px !important; padding-bottom:12px !important;text-align:right;background-color:lightgray !important">
                                                    {{(index + 1) + '.' + (busIndex+1)}}
                                                </td>
                                                <td style="width: 23%; text-align: left;padding-top:12px !important; padding-bottom:12px !important;background-color:lightgray !important">
                                                    {{costCenter.accountName}}

                                                </td>
                                                <td style="width: 16%; text-align: right;padding-top:12px !important; padding-bottom:12px !important;background-color:lightgray !important">
                                                    {{costCenter.accountNameArabic}}
                                                </td>

                                                <td style="width: 14%; text-align: right;padding-top:12px !important; padding-bottom:12px !important;background-color:lightgray !important">
                                                    {{(parseFloat(costCenter.debit)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                                </td>

                                                <td style="width: 12%; text-align: center;padding-top:12px !important; padding-bottom:12px !important;background-color:lightgray !important">
                                                    {{(parseFloat(costCenter.credit)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}

                                                </td>
                                                <td style="width: 15%; text-align: right;padding-top:12px !important; padding-bottom:12px !important;background-color:lightgray !important">
                                                    {{trans.total>0?'Dr':'Cr'}} {{nonNegative(costCenter.total) }}
                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>

                                    <div v-for="(account,locIndex) in costCenter.trialBalanceModel" :key="account.id" style="width: 100%" >
                                        <div style="width:100%">


                                            <table class="table col-md-12" style="margin:0;padding-top:12px !important; padding-bottom:12px !important;width:100%">

                                                <tbody>
                                                    <tr style="font-size:17px;font-weight:bold;">
                                                        <td style="width:13%;padding-top:12px !important; padding-bottom:12px !important;text-align:right" >
                                                            {{(index + 1) + '.' + (busIndex+1) + '.' + (locIndex+1)}}
                                                        </td>
                                                        <td style="width: 20%; text-align: left;padding-top:12px !important; padding-bottom:12px !important">

                                                            {{account.accountName}}


                                                        </td>
                                                        <td style="width: 13%; text-align: center;padding-top:12px !important; padding-bottom:12px !important">

                                                            {{account.accountNameArabic}}
                                                        </td>

                                                        <td style="width: 10%; text-align: right;padding-top:12px !important; padding-bottom:12px !important">

                                                            {{(parseFloat(account.debit)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                                        </td>

                                                        <td style="width: 10%; text-align: right;padding-top:12px !important; padding-bottom:12px !important">

                                                            {{(parseFloat(account.credit)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}

                                                        </td>
                                                        <td style="width: 15%; text-align: right;padding-top:12px !important; padding-bottom:12px !important">

                                                            {{trans.total>0?'Dr':'Cr'}} {{nonNegative(account.total) }}
                                                        </td>


                                                    </tr>
                                                </tbody>
                                            </table>





                                        </div>
                                    </div>



                                </div>
                            </div>

                        </div>

                    </div>
                    <table class="table ">

                        <tr>


                            <th style="width: 45%;  text-align: center" colspan="3">
                                Total
                            </th>

                            <th style="width: 20%; text-align: right">
                                {{getDebitTotal}}
                            </th>
                            <th style="width: 15%; text-align: right">
                                {{getCreditTotal}}
                            </th>
                            <th style="width: 15%; text-align: right">
                                {{getLineTotal}}
                            </th>

                        </tr>

                    </table>


                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import moment from "moment";
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        props: ['printDetails', 'isShown', 'formName', 'isPrint', 'dates', 'headerFooter', 'fromDate', 'toDate', ''],
        data: function () {
            return {
                transactionDetail: [],
                fDate: '',
                tDate: '',
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
                Print: false,
                render: 0,
                arabic: '',
                english: '',
            }
        },
        computed: {
            getCreditTotal: function () {
                var sum = 0;
                this.transactionDetail.forEach(function (x) {
                    sum += x.credit;
                })
                return parseFloat(Math.abs(sum)).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
            },
            getDebitTotal: function () {
                var sum = 0;
                this.transactionDetail.forEach(function (x) {
                    sum += x.debit;
                })
                return parseFloat(Math.abs(sum)).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
            },
            getLineTotal: function () {
                var sum = 0;
                this.transactionDetail.forEach(function (x) {
                    sum += x.total;
                })
                return parseFloat(Math.abs(sum)).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
            }
        },

        mounted: function () {

            this.fDate = this.fromDate;
            this.tDate = this.toDate;
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            var root = this;

            this.headerFooters = this.headerFooter
            root.Print = root.isPrint;
            if (this.printDetails.length > 0 && root.Print) {

                this.transactionDetail = this.printDetails;
                setTimeout(function () {
                    root.printInvoice();
                }, 125)
            }
        },
        methods: {
            nonNegative: function (value) {
                return parseFloat(Math.abs(value)).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
            },
            convertDate: function (x) {
                return moment(x).format('DD MMM YYYY');
            },
            printInvoice: function () {
                this.$htmlToPaper('inventoryDetailReport');
            }
        }
    }
</script>


<style scoped>
    #font11 {
        font-size: 11px;
        line-height: 0;
    }

    #font16 {
        font-size: 16px;
    }
</style>