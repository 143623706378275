<template>
    <div class="col-md-11 ml-auto mr-auto mt-3" v-if="isValid('Can Save  Mobile Order')|| isValid('Can Edit  Mobile Order')">
        <div class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="card-header p-0">
                            <h5 v-if="purchase.id === '00000000-0000-0000-0000-000000000000'"> {{ $t('AddMobileOrder.AddMobileOrder') }}</h5>
                            <h5 v-else> {{ $t('AddMobileOrder.UpdateMobileOrder') }}</h5>
                        </div>
                        <div class="text-left">
                            <div class="card-body">
                                <div class="row">
                                    <div v-bind:key="rendered"
                                         class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                                        <label>{{ $t('AddMobileOrder.Date') }} :<span class="text-danger"> *</span></label>
                                        <div v-bind:class="{ 'has-danger': $v.purchase.orderDate.$error }">
                                            <input readonly
                                                   class="form-control"
                                                   :value="purchase.orderDate" />
                                            <span v-if="$v.purchase.orderDate.$error"
                                                  class="error text-danger">
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <br />
                                <mobile-item @input="SavePurchaseItems" :key="rander" />
                            </div>
                        </div>
                        <div v-if="!loading" class="col-md-12 text-right">
                            <button class="btn btn-primary  mr-2"
                                    v-on:click="savePurchase"
                                    :disabled="$v.$invalid"
                                    v-if="purchase.id === '00000000-0000-0000-0000-000000000000'&& isValid('Can Save  Mobile Order')">
                                <i class="far fa-save"></i> {{ $t('AddMobileOrder.Save') }}
                            </button>
                            <button class="btn btn-primary  mr-2"
                                    v-on:click="savePurchase"
                                    :disabled="$v.$invalid"
                                    v-if="purchase.id != '00000000-0000-0000-0000-000000000000' && isValid('Can Edit  Mobile Order')">
                                <i class="far fa-save"></i> {{ $t('AddMobileOrder.Update') }}
                            </button>
                            <button class="btn btn-danger  mr-2"
                                    v-on:click="goToPurchase">
                                {{ $t('AddMobileOrder.Cancel') }}
                            </button>
                        </div>
                        <div class="card-footer col-md-3" v-else>
                            <loading :active.sync="loading"
                                     :can-cancel="true"
                                     :on-cancel="onCancel"
                                     :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
//import Loading from 'vue-loading-overlay';
import moment from "moment";
import "vue-loading-overlay/dist/vue-loading.css";
import { required } from "vuelidate/lib/validators";

//import VueBarcode from 'vue-barcode';
    export default {
        mixins: [clickMixin],

  data: function () {
    return {
      rendered: 0,
        rander: 0,
      purchase: {
        id:"00000000-0000-0000-0000-000000000000",
        customerId: "",
        name: "",
        orderNo: "",
        orderDate: "",
        mobileOrderItemLookupModels: [],
      },
        loading: false,
        UserName: '',
        CompanyID: '',
        UserID: '',
    };
  },
  validations: {
    purchase: {
      orderDate: { required },
      mobileOrderItemLookupModels: { required },
    },
  },
  methods: {
    SavePurchaseItems: function (mobileOrderItemLookupModels) {
      
        this.purchase.mobileOrderItemLookupModels = mobileOrderItemLookupModels;
    },
    savePurchase: function () {
   this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');


        }
        this.purchase.customerId = this.UserID;
        this.purchase.name = this.UserName;
        

        this.$https
                    .post('/Purchase/SaveMobileOrderInformation', root.purchase, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        root.loading = false
                        root.info = response.data

                         root.$swal.fire({
                             icon: 'success',
                             title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully!' : '!حفظ بنجاح',
                             showConfirmButton: false,
                            
                             timer: 800,
                             timerProgressBar: true,

                         });
                        root.$router.push("mobileorder");
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                                text: error,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)

    },

      goToPurchase: function () {
          this.$router.push('/mobileorder');
  },
  },
  mounted: function () {
      if (this.$session.exists()) {
          this.UserName = localStorage.getItem('UserName');
          this.CompanyID = localStorage.getItem('CompanyID');
          this.UserID = localStorage.getItem('UserID');
      }
    if (this.$route.query.data == undefined) {
      
       
      this.purchase.orderDate = moment().format("DD MM YYYY");
    }
    if (this.$route.query.data != undefined) {
      
        this.purchase = this.$route.query.data;
        this.rander++;
       
       

      // this.purchase.orderDate = moment().format("DD MM YYYY");
      // this.purchase.orderDate = moment().format("DD MM YYYY");
      }
      
      this.purchase.orderDate = moment().format("DD MMM YYYY");
      this.rendered++;
    
  },
};
</script>
