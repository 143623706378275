<template>
    <div>
        <multiselect v-model="DisplayValue" :options="options" :multiple="false" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true">
            <p slot="noResult" class="text-danger"> Oops! No Group found.</p>
        </multiselect>
       
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect'
    import clickMixin from '@/Mixins/clickMixin'
    import { required } from "vuelidate/lib/validators"
    import axios from "axios"
    export default {
        name: 'branddropdown',
        props: ["values"],
        mixins: [clickMixin],

        components: {
            Multiselect,
            //Loading
        },
        data: function () {
            return {
                arabic: '',
                english: '',
                options: [],
                value: '',
                show: false,
                type: '',
                group: {
                    id: '00000000-0000-0000-0000-000000000000',
                    groupName: '',
                    groupType: '',
                },
                groupTypeList: ['Basic', 'Advance', 'Premium', 'Customize'],
                render: 0,
                loading: false,
            }
        },
        validations: {
            group: {
                groupName: {
                    required
                },
                groupType: {
                    required
                },
            }
        },
        methods: {
            getData: function () {
                var root = this;
                
                axios.get(root.$PermissionIp +'/NoblePermission/GetNobleGroup').then(function (response) {
                    if (response.data != null) {
                        response.data.forEach(function (result) {
                            root.options.push({
                                id: result.id,
                                name: result.groupName + '-' + result.groupType,
                            })
                        })
                    }
                }).then(function () {
                    root.value = root.options.find(function (x) {
                        return x.id == root.values;
                    })
                    root.$emit('input', root.value.id);
                });
            },
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {
                    this.value = value;
                    if (this.value == null) {
                        this.$emit('input', '');
                    }
                    else {
                        this.$emit('input', value.id);
                    }

                }
            }
        },
        mounted: function () {

            this.getData();
        },
    }
</script>