<template>
    <div>

        <div class=" table-responsive">

            <table class="table add_table_list_bg mt-2" v-if="purchaseProducts.length > 0">
                <thead class="thead-light table-hover">
                    <tr>
                        <th style="width: 40px;" class="text-center">
                            #
                        </th>
                        <th style="width: 200px;" class="text-center">
                            {{ $t('PurchaseViewItem.Product') }}
                        </th>
                        <th style="width: 110px;" class="text-center" v-if="!hide">
                            {{ $t('PurchaseViewItem.UnitPrice') }}
                        </th>
                        <th class="text-center" style="width: 70px;" v-if="isValid('CanViewUnitPerPack')">
                            {{ $t('PurchaseViewItem.UnitPerPack') }}
                        </th>
                        <th style="width: 110px;" class="text-center" v-if="isMultiUnit=='true'">
                            {{ $t('PurchaseViewItem.HighQty') }}
                        </th>

                        <!--<th style="width: 50px;" v-if="isMultiUnit=='true'">

                        </th>-->
                        <th style="width: 110px;" class="text-center">
                            {{ $t('PurchaseViewItem.Qty') }}
                        </th>
                        <th style="width: 110px;" class="text-center" v-if="isMultiUnit=='true'">
                            {{ $t('PurchaseViewItem.TOTALQTY') }}
                        </th>
                        <th style="width:110px;" class="text-center" v-if="(purchaseProducts.filter(x => x.isExpire).length > 0 && isFifo) && !po">
                            {{ $t('PurchaseViewItem.ExpDate') }}
                        </th>
                        <th style="width:110px;" class="text-center" v-if="isFifo && !po">
                            {{ $t('PurchaseViewItem.BatchNo') }}
                        </th>
                        <th style="width: 100px;" v-if="purchaseProducts.filter(x => x.guarantee).length > 0  && isSerial && !po">
                            {{ $t('PurchaseViewItem.Guarantee') }}
                        </th>
                        <th style="width: 100px;text-align:center;" v-if="purchaseProducts.filter(x => x.serial).length > 0  && isSerial && !po">
                            {{ $t('PurchaseViewItem.Serial') }}
                        </th>
                        <th style="width: 70px;" class="text-center" v-if="!isDiscountOnTransaction">
                            {{ $t('PurchaseItem.Disc%') }}
                        </th>
                        <!--<th style="width: 100px;">
                            {{ $t('PurchaseOrder.VAT%') }}
                        </th>-->
                        <th style="width: 100px;" class="text-right" v-if="!hide">
                            {{ $t('PurchaseViewItem.LineTotal') }}
                        </th>
                    </tr>
                </thead>
                <tbody id="purchase-item">
                    <template v-for="(prod , index) in purchaseProducts">

                        <tr :key="prod.productId + index">
                            <td class="">
                                {{index+1}}
                            </td>
                            <td class="">
                                <span v-if="prod.productId==null">
                                    {{prod.description}}
                                </span>
                                <span v-else>
                                    {{($i18n.locale == 'en' ||isLeftToRight())? products.find(x => x.id == prod.productId).englishName!=''? products.find(x => x.id == prod.productId).englishName : products.find(x => x.id == prod.productId).arabicName :    products.find(x => x.id == prod.productId).arabicName!=''? products.find(x => x.id == prod.productId).arabicName : products.find(x => x.id == prod.productId).englishName}}
                                </span>

                            </td>
                            <td class=" text-center" v-if="!hide">
                                {{ parseFloat(prod.unitPrice).toFixed(3).slice(0,-1)}}
                            </td>
                            <td class="text-center" v-if="isValid('CanViewUnitPerPack')">
                                {{prod.unitPerPack}}
                            </td>
                            <td class=" text-center" v-if="isMultiUnit=='true'">
                                {{prod.highQty}}<br />
                                <small style="font-weight: 500;font-size:70%;">
                                    {{prod.levelOneUnit}}
                                </small>

                            </td>

                            <td class=" text-center">
                                {{prod.quantity}}<br />
                                <small v-if="isMultiUnit=='true'" style="font-weight: 500;font-size:70%;">
                                    {{prod.basicUnit}}
                                </small>
                            </td>
                            <td class=" text-center" v-if="isMultiUnit=='true'">
                                {{parseInt(parseFloat(prod.highQty*prod.unitPerPack) + parseFloat(prod.quantity))}}
                            </td>
                            <td class=" text-center" v-if="(purchaseProducts.filter(x => x.isExpire).length > 0 && isFifo) && !po">

                                <span v-if="prod.isExpire || isFifo">{{getDate(prod.expiryDate)}}</span>
                                <span v-else>
                                    -
                                </span>
                            </td>
                            <td class=" text-center" v-if="isFifo && !po">
                                <span>{{prod.batchNo}}</span>

                            </td>
                            <td class="  text-center" v-if="purchaseProducts.filter(x => x.guarantee).length > 0 && isSerial && !po">
                                <span v-if="prod.guarantee">
                                    {{prod.guaranteeDate}}
                                </span>
                                <span v-else>
                                    -
                                </span>
                            </td>
                            <td class=" text-center" v-if="purchaseProducts.filter(x => x.serial).length > 0  && isSerial && !po">
                                <span v-if="prod.serial">
                                    {{prod.serialNo}}
                                </span>
                                <span v-else>
                                    -
                                </span>
                            </td>
                            <td class="text-center" v-if="!isDiscountOnTransaction">
                                {{prod.discount == 0 ? prod.fixDiscount : prod.discount}}{{prod.discountSign}}
                            </td>
                            <td class=" text-right" v-if="!hide">
                                {{currency}}  {{parseFloat(prod.lineTotal).toFixed(3).slice(0,-1) | formatAmount}}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

        <hr style="margin-bottom:0;" />
        <div v-if="hide"></div>
        <div class="row  ">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">

            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 ">
                <div class="mt-4" v-bind:key="rendered + 'g'">
                    <table class="table" style="background-color: #F1F5FA;">
                        <tbody>
                            <tr>
                                <td colspan="2" style="width:65%;">
                                    <span class="fw-bold">  {{ $t('PurchaseViewItem.SubTotal') }} </span>
                                    <span>(Tax {{taxMethod}})</span>
                                </td>
                                <td class="text-end" style="width:35%;">{{summary.withDisc}}</td>
                            </tr>
                            <tr v-if="isDiscountOnTransaction && isBeforeTax">
                                <td style="width:40%;">
                                    <span style="height:33px !important; ">{{ $t('PurchaseViewItem.Discount') }}</span>
                                    <br />
                                    <span v-if="summary.item > 0">
                                        <a href="javascript:void(0)" v-on:click="UpdateDiscountField('beforeTax')">
                                            <small class="fw-bold text-primary">{{ $t('PurchaseViewItem.ApplyAfterTax') }}</small>
                                        </a>
                                    </span>
                                </td>
                                <td style="width:25%;">
                                    <div class="input-group" style="pointer-events:none">
                                        <decimal-to-fixed v-model="transactionLevelDiscount" @input="calcuateSummary" />
                                        <button v-if="taxMethod == ('Inclusive' || 'شامل')" class="btn btn-primary" type="button" id="button-addon2">{{isFixed?'F':'%'}}</button>
                                        <button v-else class="btn btn-primary" v-on:click="UpdateDiscountField('fixed')" type="button" id="button-addon2">{{isFixed?'F':'%'}}</button>
                                    </div>
                                </td>
                                <td class="text-end" style="width:35%;">{{transactionLevelTotalDiscount}}</td>
                            </tr>
                            <tr v-for="(vat,index) in paidVatList" :key="index">
                                <td class="fw-bold" colspan="2" style="width:65%;">{{vat.name}}</td>
                                <td class="text-end" style="width:35%;">{{vat.amount}}</td>
                            </tr>
                            <tr v-if="isDiscountOnTransaction && !isBeforeTax">
                                <td style="width:40%;">
                                    <span style="height:33px !important; ">{{ $t('PurchaseViewItem.Discount') }}</span>
                                    <br />
                                    <span v-if="summary.item > 0">
                                        <a href="javascript:void(0)" v-on:click="UpdateDiscountField('beforeTax')">
                                            <small class="fw-bold text-primary">{{ $t('PurchaseViewItem.ApplyBeforeTax') }}</small>
                                        </a>
                                    </span>
                                </td>
                                <td style="width:25%;">
                                    <div class="row">
                                        <div class="input-group" style="pointer-events:none">
                                            <decimal-to-fixed v-model="transactionLevelDiscount" @input="calcuateSummary" />
                                            <button v-if="taxMethod == ('Inclusive' || 'شامل')" class="btn btn-primary" type="button" id="button-addon2">{{isFixed?'F':'%'}}</button>
                                            <button v-else class="btn btn-primary" v-on:click="UpdateDiscountField('fixed')" type="button" id="button-addon2">{{isFixed?'F':'%'}}</button>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-end" style="width:35%;">{{transactionLevelTotalDiscount}}</td>
                            </tr>
                            <tr>
                                <td style="width:40%;">
                                    <input class="form-control" type="text" readonly :value=" $t('PurchaseViewItem.Adjustment') " style="border: 1px dashed #1761FD;" />
                                </td>
                                <td style="width:25%;">
                                    <div class="input-group">
                                        <decimal-to-fixed v-model="adjustment" disabled @input="calcuateSummary" />
                                        <button v-on:click="OnChangeOveallDiscount" disabled class="btn btn-primary" type="button" id="button-addon2">{{adjustmentSign}}</button>
                                    </div>
                                </td>
                                <td class="text-end" style="width:35%;">{{adjustmentSign == '+'?adjustment:(-1)*adjustment}}</td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:65%;">
                                    <span style="font-weight:bolder; font-size:16px">{{ $t('PurchaseViewItem.TotalDue') }}({{currency}})</span>
                                </td>
                                <td class="text-end" style="width: 35%; font-weight: bolder; font-size: 16px">{{summary.withVat}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    /*import Multiselect from 'vue-multiselect'*/
    export default {
        name: "PurchaseItem",
        props: ['purchase', 'purchaseItems', 'raw', 'taxMethod', 'taxRateId', 'po', 'purchaseid', 'purchaseOrderId', 'adjustmentProp', 'adjustmentSignProp', 'isDiscountOnTransaction', 'transactionLevelDiscountProp', 'isFixed', 'isBeforeTax'],
        mixins: [clickMixin],
        //components: {
        //    Multiselect
        //},
        data: function () {
            return {
                transactionLevelDiscount: 0,
                adjustment: 0,
                adjustmentSign: '+',
                isDiscountBeforeVat: false,
                transactionLevelTotalDiscount: 0,
                paidVatList: [],
                hide: false,
                options: [],
                saleSizeAssortment: [],
                colorList: [],
                newItem: {
                    description: '',
                    unitPrice: 0,
                    highQty: 0,
                    quantity: 0,
                    discount: 0,
                    fixDiscount: 0
                },

                colorVariants: false,
                isSerial: false,
                isFifo: false,
                decimalQuantity: false,
                internationalPurchase: '',
                rendered: 0,
                product: {
                    id: "",
                },
                products: [],
                purchaseProducts: [],
                loading: false,
                vats: [],
                summary: {
                    item: 0,
                    qty: 0,
                    total: 0,
                    discount: 0,
                    withDisc: 0,
                    vat: 0,
                    withVAt: 0,
                    inclusiveVat: 0,
                    totalCarton: 0,
                    totalPieces: 0
                },
                currency: '',
                searchTerm: '',
                isMultiUnit: '',
                wareRendered: 0,
                isRaw: false,
                servicePurchase: false,
                productList: [],
                defaultVat: false,
            };
        },
        validations: {},
        filters: {

        },
        computed: {
            itemDisable() {
                if (this.taxMethod != '' && this.taxMethod != null && this.taxRateId != '00000000-0000-0000-0000-000000000000' && this.taxRateId != undefined)
                    return false;
                return true;
            }
        },

        methods: {
            UpdateDiscountField: function (prop) {
                if (prop === 'fixed')
                    this.isFixed = this.isFixed ? false : true
                if (prop === 'beforeTax')
                    this.isBeforeTax = this.isBeforeTax ? false : true
                this.$emit("discountChanging", this.isFixed, this.isBeforeTax);
                this.calcuateSummary();
            },
            OnChangeOveallDiscount: function () {
                this.adjustmentSign = this.adjustmentSign == '+' ? '-' : '+'
                this.calcuateSummary()
            },
            NewItemChangeDiscount: function (prod) {
                if (prod.discountSign === '%') {
                    prod.discountSign = 'F';
                    prod.fixDiscount = 0
                    prod.discount = 0
                }
                else {
                    prod.discountSign = '%';
                    prod.discount = 0
                    prod.fixDiscount = 0
                }
            },
            OnChangeDiscountType: function (prod) {
                if (prod.discountSign === '%') {
                    prod.discountSign = 'F';
                    prod.fixDiscount = 0
                    prod.discount = 0
                    this.updateLineTotal(prod.fixDiscount, 'fixDiscount', prod)
                }
                else {
                    prod.discountSign = '%';
                    prod.discount = 0
                    prod.fixDiscount = 0
                    this.updateLineTotal(prod.discount, 'discount', prod)
                }
            },
            newItemProduct: function () {
                var rate = 0;
                if (this.taxRateId != "00000000-0000-0000-0000-000000000000" && this.taxRateId != undefined) {
                    rate = this.vats.find((value) => value.id == this.taxRateId).rate;
                }

                var rowId = this.createUUID();
                this.purchaseProducts.push({
                    rowId: rowId,
                    productId: null,
                    description: this.newItem.description,
                    unitPrice: this.newItem.unitPrice,
                    quantity: this.newItem.quantity,
                    receiveQuantity: 0,
                    highQty: this.newItem.highQty,
                    totalPiece: 0,
                    remainingQuantity: 0,
                    levelOneUnit: '',
                    basicUnit: '',
                    unitPerPack: 0,
                    discount: this.newItem.discount,
                    fixDiscount: this.newItem.fixDiscount,
                    taxRateId: this.taxRateId,
                    rate: rate,
                    taxMethod: this.taxMethod,
                    expiryDate: "",
                    isExpire: false,
                    isService: true,
                    batchNo: "",
                    lineTotal: 0,

                    guarantee: false,
                    serial: false,

                    serialNo: '',
                    guaranteeDate: '',
                    warrantyTypeId: '',
                    inventory: null,
                });

                this.newItem.description = '';
                this.newItem.unitPrice = 0;
                this.newItem.highQty = 0;
                this.newItem.quantity = 0;
                this.newItem.discount = 0;
                this.newItem.fixDiscount = 0;


                var product = this.purchaseProducts.find((x) => {
                    return x.rowId == rowId;
                });

                this.updateLineTotal(product.quantity, "quantity", product);
            },



            changeProduct: function (NewProdId, rowId) {

                this.purchaseProducts = this.purchaseProducts.filter(x => x.rowId != rowId);
                this.addProduct(NewProdId);

            },

            GetColorName: function (colorId, product) {
                if (colorId != null && colorId != undefined && colorId != '') {
                    var color = this.colorList.find(x => x.id == colorId);
                    if (color != undefined) {
                        product.colorName = color.name;
                    }
                }
                else {
                    product.colorName = '';
                }
            },

            sizeAllowInput: function (sizeId, product) {
                if (product.productSizes != null && product.productSizes != undefined && product.productSizes.length != 0) {
                    var size = product.productSizes.find(x => x.sizeId == sizeId);
                    if (size != undefined) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return true;
                }
            },

            sizeQtyVerify: function (product) {
                if (product.saleSizeAssortment != null && product.saleSizeAssortment != undefined) {
                    if (parseFloat(product.totalPiece) < product.saleSizeAssortment.reduce(function (a, c) { return a + parseFloat(c.quantity) }, 0)) {
                        this.$set(product, 'outOfStock', true);
                    } else {
                        this.$set(product, 'outOfStock', false);
                    }
                }
                else {
                    this.$set(product, 'outOfStock', true);
                }
            },

            calcuateSummary: function () {
                this.summary.item = this.purchaseProducts.length;
                if (this.decimalQuantity) {
                    this.summary.totalPieces = this.purchaseProducts.reduce((totalQty, prod) => totalQty + parseFloat(prod.quantity), 0);
                }
                else {
                    this.summary.totalPieces = this.purchaseProducts.reduce((totalQty, prod) => totalQty + parseInt(prod.quantity), 0);
                }

                if (this.decimalQuantity) {
                    this.summary.totalCarton = this.purchaseProducts.reduce((totalCarton, prod) => totalCarton + parseFloat(prod.highQty), 0);
                }
                else {
                    this.summary.totalCarton = this.purchaseProducts.reduce((totalCarton, prod) => totalCarton + parseInt(prod.highQty), 0);
                }

                if (this.decimalQuantity) {
                    this.summary.qty = this.purchaseProducts.reduce((qty, prod) => qty + parseFloat(prod.totalPiece == '' ? 0 : prod.totalPiece), 0);
                }
                else {
                    this.summary.qty = this.purchaseProducts.reduce((qty, prod) => qty + parseInt(prod.totalPiece == '' ? 0 : prod.totalPiece), 0);
                }

                this.summary.total = this.purchaseProducts.reduce((total, prod) =>
                    total + (prod.totalPiece) * prod.unitPrice, 0).toFixed(3).slice(0, -1);

                if (!this.isDiscountOnTransaction) {
                    this.transactionLevelDiscount = 0;
                }
                var vatRate = 0;
                var discountOnly = 0;
                var discountForInclusiveVat = 0;
                var root = this;
                const taxIdList = [...new Set(this.purchaseProducts.map(item => item.taxRateId))];
                root.paidVatList = []
                //'isDiscountOnTransaction', 'transactionLevelDiscount'
                taxIdList.forEach(function (taxId) {
                    vatRate = root.vats.find((value) => value.id == taxId);
                    var filteredRecord = root.purchaseProducts
                        .filter((x) => x.taxRateId === taxId);
                    var totalQtyWithotFree = root.purchaseProducts.reduce((qty, prod) => qty + parseInt(prod.totalPiece == '' ? 0 : prod.totalPiece), 0);

                    discountOnly += filteredRecord
                        .filter((x) => x.discount != 0 || x.discount != "" || x.offerQuantity != 0)
                        .reduce((discount, prod) =>
                            discount + (prod.totalPiece ? (prod.offerQuantity ? 0 : (((prod.totalPiece * prod.unitPrice) * prod.discount) / 100)) : 0), 0);

                    discountOnly += filteredRecord
                        .filter((x) => x.fixDiscount != 0 || x.fixDiscount != "" || x.offerQuantity != 0)
                        .reduce((discount, prod) =>
                            discount + (prod.totalPiece ? (prod.offerQuantity ? 0 : (root.taxMethod == ("Inclusive" || "شامل") ? prod.fixDiscount + (prod.fixDiscount * vatRate.rate / 100) : prod.fixDiscount)) : 0), 0);

                    var paidVat = filteredRecord
                        .reduce((vat, prod) => (vat + ((prod.taxMethod == ("Inclusive" || "شامل")) ? ((parseFloat(prod.lineTotal) - (root.isBeforeTax ? (((prod.totalPiece * prod.unitPrice) * root.transactionLevelDiscount) / 100) : 0)) * vatRate.rate) / (100 + vatRate.rate) : ((parseFloat(prod.lineTotal) - (root.isBeforeTax && !root.isFixed && root.isDiscountOnTransaction ? (((prod.totalPiece * prod.unitPrice) * root.transactionLevelDiscount) / 100) : (root.isBeforeTax && root.isFixed && root.isDiscountOnTransaction ? (root.transactionLevelDiscount / parseFloat(totalQtyWithotFree) * prod.totalPiece) : 0))) * vatRate.rate) / 100)), 0).toFixed(3).slice(0, -1)
                    discountForInclusiveVat += parseFloat(filteredRecord
                        .reduce((vat, prod) => (vat + ((prod.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(prod.lineTotal) * vatRate.rate) / (100 + vatRate.rate) : 0)), 0).toFixed(3).slice(0, -1))

                    root.paidVatList.push({
                        name: vatRate.name,
                        amount: paidVat
                    })

                });
                //root.transactionLevelDiscount = root.transactionLevelDiscount;
                this.summary.discount = discountOnly
                this.summary.withDisc = (this.summary.total - this.summary.discount).toFixed(3).slice(0, -1);

                this.summary.vat = this.paidVatList.reduce((vat, paidVat) => (vat + parseFloat(paidVat.amount)), 0).toFixed(3).slice(0, -1);

                var exclusiveVat = this.taxMethod == ("Inclusive" || "شامل") ? 0 : parseFloat(this.summary.vat);
                this.transactionLevelTotalDiscount = ((this.isBeforeTax && this.isDiscountOnTransaction) ? (this.taxMethod == ("Inclusive" || "شامل") ? (parseFloat(this.transactionLevelDiscount) * (this.summary.withDisc - discountForInclusiveVat) / 100) : (this.isFixed ? parseFloat(this.transactionLevelDiscount) : parseFloat(this.transactionLevelDiscount) * this.summary.withDisc / 100)) : (this.isFixed ? parseFloat(this.transactionLevelDiscount) : (parseFloat(this.summary.withDisc) + parseFloat(exclusiveVat)) * parseFloat(this.transactionLevelDiscount) / 100)).toFixed(3).slice(0, -1)

                var totalIncDisc = (this.isBeforeTax && this.isDiscountOnTransaction && this.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(this.transactionLevelDiscount) * (this.summary.withDisc) / 100) : parseFloat(this.transactionLevelTotalDiscount)
                this.adjustment = (this.adjustment == '' || this.adjustment == null) ? 0 : parseFloat(this.adjustment)

                this.summary.withVat = (parseFloat(this.summary.withDisc) + parseFloat(exclusiveVat) + (this.adjustmentSign == '+' ? this.adjustment : (-1) * this.adjustment)).toFixed(3).slice(0, -1);

                this.summary.withVat = (parseFloat(this.summary.withVat) - totalIncDisc).toFixed(3).slice(0, -1);



                //calculate bundle Amount
                if (this.purchaseProducts.filter(x => x.isBundleOffer).length > 0) {

                    //get bundle get quantity
                    var bundle = {
                        item: 0,
                        qty: 0,
                        total: 0,
                        discount: 0,
                        withDisc: 0,
                        vat: 0,
                        withVat: 0,
                        quantityLimit: 0
                    };

                    var bundleProducts = this.purchaseProducts.filter(x => x.isBundleOffer != undefined && x.offerQuantity > 0);

                    bundle.total = bundleProducts.reduce((total, prod) =>
                        total + prod.offerQuantity * prod.unitPrice, 0).toFixed(3).slice(0, -1);

                    //var bundleExclusiveTax = bundleProducts.reduce((total, prod) =>
                    //    total + (prod.taxMethod == "Exclusive" ? (bundle.total * prod.rate/100) : 0), 0);

                    var discountBundle = bundleProducts.filter((x) => x.discount != 0 || x.discount != "")
                        .reduce((discount, prod) =>
                            discount + (prod.offerQuantity * prod.unitPrice * prod.discount) / 100, 0);

                    var fixDiscountBundle = bundleProducts
                        .filter((x) => x.fixDiscount != 0 || x.fixDiscount != "")
                        .reduce((discount, prod) => discount + prod.fixDiscount, 0);

                    bundle.discount = (parseFloat(discountBundle) + parseFloat(fixDiscountBundle)).toFixed(3).slice(0, -1);

                    bundle.withDisc = (bundle.total - bundle.discount).toFixed(3).slice(0, -1);

                    bundle.vat = bundleProducts
                        .reduce((vat, prod) => vat + (((prod.unitPrice * prod.offerQuantity) -
                            ((prod.unitPrice * prod.offerQuantity * prod.discount) / 100)) *
                            parseFloat(prod.rate)) / ((prod.taxMethod == "Exclusive" || prod.taxMethod == "غير شامل") ? 100 : prod.rate + 100), 0).toFixed(3).slice(0, -1);

                    this.summary.bundleAmount = (parseFloat(bundle.withDisc) + parseFloat(exclusiveVat)).toFixed(3).slice(0, -1);
                    this.summary.withVat = (this.summary.withVat - bundle.withDisc);
                } else {
                    this.summary.bundleAmount = 0;
                }
                this.$emit("input", this.purchaseProducts, this.adjustment, this.adjustmentSign, parseFloat(this.transactionLevelDiscount));

                this.$emit("summary", this.summary);
            },

            updateLineTotal: function (e, prop, product) {

                var discount = 0
                if (prop == "unitPrice") {
                    if (e < 0 || e == '' || e == undefined) {
                        e = 0;
                    }
                    product.unitPrice = e;

                }

                if (prop == "quantity") {
                    if (e <= 0 || e == '') {
                        e = '';
                    }
                    if (String(e).split('.').length > 1 && String(e).split('.')[1].length > 2)
                        e = parseFloat(String(e).slice(0, -1))
                    product.quantity = this.decimalQuantity ? e : Math.round(e);
                }

                if (prop == "highQty") {
                    if (e < 0 || e == '' || e == undefined) {
                        e = '';
                    }
                    product.highQty = Math.round(e);
                }

                if (prop == "discount") {
                    if (e == "" || e < 0) {
                        e = 0;
                    }
                    else if (e > 100) {
                        e = 100;
                    }
                    product.discount = e;
                }

                if (prop == "fixDiscount") {
                    if (e == "" || e < 0) {
                        e = 0;
                    }
                    else if (e > product.unitPrice) {
                        e = product.unitPrice;
                    }
                    product.fixDiscount = e;
                }

                if (product.highUnitPrice && product.isService == false) {
                    product.totalPiece = (parseFloat(product.highQty == '' ? 0 : product.highQty)) + (parseFloat(product.quantity == '' ? 0 : product.quantity) / (product.unitPerPack == null ? 0 : product.unitPerPack));

                }
                else {
                    product.totalPiece = (parseFloat(product.highQty == '' ? 0 : product.highQty) * (product.unitPerPack == null ? 0 : product.unitPerPack)) + parseFloat(product.quantity == '' ? 0 : product.quantity);
                }

                var vat = 0;


                vat = this.vats.find((value) => value.id == product.taxRateId);


                discount = product.discount == 0 ? (this.taxMethod == ("Inclusive" || "شامل") ? product.fixDiscount + (product.fixDiscount * vat.rate / 100) : product.fixDiscount) : (product.totalPiece * product.unitPrice * product.discount) / 100;
                product.lineTotal = product.totalPiece * product.unitPrice - discount;


                //discount = product.discount == 0 ? product.totalPiece * product.fixDiscount : (product.totalPiece * product.unitPrice * product.discount) / 100;

                if (this.purchase != undefined && !product.isService) {
                    if (this.purchase != undefined) {
                        if (product.totalPiece > product.remainingQuantity || product.totalPiece > product.inventory.currentQuantity) {
                            this.$set(product, 'outOfStock', true);
                        } else {
                            this.$set(product, 'outOfStock', false);
                        }
                    }
                }



                this.$set(this.purchaseProducts, product, product);

                this.calcuateSummary();
                this.$emit("input", this.purchaseProducts, this.adjustment, this.adjustmentSign, parseFloat(this.transactionLevelDiscount));


            },

            addProduct: function (productId, newProduct, tempItem, isTemp, poVatId, poTaxMethod) {


                var prd = this.purchaseProducts.find(x => x.productId == productId);
                if (prd != undefined && !this.colorVariants) {
                    prd.quantity++;
                    this.updateLineTotal(prd.quantity, "quantity", prd);
                }
                else {

                    this.products.push(newProduct);
                    var prod = this.products.find((x) => x.id == productId);
                    var rate = 0;

                    if (isTemp) {
                        if (poVatId != "00000000-0000-0000-0000-000000000000" && poVatId != undefined) {
                            rate = this.getVatValue(poVatId, prod);
                        }
                        this.purchaseProducts.push({
                            rowId: this.createUUID(),
                            productId: prod.id,
                            unitPrice: tempItem.unitPrice,
                            quantity: tempItem.quantity,
                            receiveQuantity: 0,
                            highQty: tempItem.highQty,
                            totalPiece: 0,
                            remainingQuantity: 0,
                            levelOneUnit: prod.levelOneUnit,
                            basicUnit: prod.unit == null ? '' : prod.unit.name,
                            unitPerPack: tempItem.unitPerPack,
                            discount: tempItem.discount,
                            fixDiscount: tempItem.fixDiscount,
                            taxRateId: poVatId,
                            rate: rate,
                            taxMethod: poTaxMethod,
                            expiryDate: "",
                            isExpire: prod.isExpire,
                            batchNo: "",
                            lineTotal: 0,
                            guarantee: prod.guarantee,
                            serial: prod.serial,
                            isService: prod.serviceItem,
                            serialNo: '',
                            guaranteeDate: '',
                            inventory: prod.inventory != null ? prod.inventory.currentQuantity : 0,
                            highUnitPrice: prod.highUnitPrice,
                            discountSign: '%',
                        });
                    }
                    else {
                        if (this.defaultVat == 'DefaultVat' || this.defaultVat == 'DefaultVatItem') {
                            rate = this.getVatValue(prod.taxRateId, prod);
                        }
                        if (this.defaultVat == 'DefaultVatHead' || this.defaultVat == 'DefaultVatHeadItem') {
                            if (this.taxRateId != "00000000-0000-0000-0000-000000000000" && this.taxRateId != undefined) {
                                rate = this.getVatValue(this.taxRateId, prod);
                            }
                        }

                        var sizeAssortmentList = [];
                        if (this.colorVariants) {
                            prod.saleSizeAssortment.forEach(function (item) {
                                sizeAssortmentList.push({
                                    sizeId: item.sizeId,
                                    name: item.name,
                                    quantity: item.quantity,
                                });
                            });
                        }

                        var taxRateId = '';
                        var taxMethod = '';
                        if (this.defaultVat == 'DefaultVat' || this.defaultVat == 'DefaultVatItem') {
                            taxRateId = prod.taxRateId;
                            taxMethod = prod.taxMethod;
                        }
                        else if (this.defaultVat == 'DefaultVatHead' || this.defaultVat == 'DefaultVatHeadItem') {
                            taxRateId = this.taxRateId;
                            taxMethod = this.taxMethod;
                        }

                        this.purchaseProducts.push({
                            rowId: this.createUUID(),
                            productId: prod.id,
                            description: '',
                            unitPrice: '0',
                            quantity: 0,
                            receiveQuantity: 0,
                            highQty: 0,
                            totalPiece: 0,
                            remainingQuantity: 0,
                            levelOneUnit: prod.levelOneUnit,
                            basicUnit: prod.unit == null ? '' : prod.unit.name,
                            unitPerPack: prod.unitPerPack == null ? 0 : prod.unitPerPack,
                            discount: 0,
                            fixDiscount: 0,
                            taxRateId: taxRateId,
                            rate: rate,
                            taxMethod: taxMethod,
                            expiryDate: "",
                            isExpire: prod.isExpire,
                            isService: prod.serviceItem,
                            batchNo: "",
                            lineTotal: 0,
                            guarantee: prod.guarantee,
                            serial: prod.serial,
                            serialNo: '',
                            guaranteeDate: '',
                            warrantyTypeId: '',
                            inventory: prod.inventory != null ? prod.inventory.currentQuantity : 0,

                            saleSizeAssortment: sizeAssortmentList,
                            productSizes: prod.productSizes,
                            colorId: '',
                            colorName: '',
                            highUnitPrice: prod.highUnitPrice,
                            discountSign: '%',
                        });
                    }


                    var product = this.purchaseProducts.find((x) => {
                        return x.productId == productId;
                    });

                    this.getVatValue(product.taxRateId, product);

                    this.product.id = "";
                    this.rendered++;
                }

            },
            createUUID: function () {
                var dt = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (dt + Math.random() * 16) % 16 | 0;
                    dt = Math.floor(dt / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },
            getVatValue: function (id, prod) {
                var vat = this.vats.find((value) => value.id == id);
                prod.taxRateId = id;
                prod.rate = vat.rate;
                this.updateLineTotal(prod.unitPrice, "unitPrice", prod);
                return vat.rate;
            },

            getTaxMethod: function (method, prod) {
                prod.taxMethod = method;
                this.updateLineTotal(prod.unitPrice, "unitPrice", prod);
            },

            getVatValueForSummary: function (id, prod) {

                var vat = this.vats.find((value) => value.id == id);
                prod.taxRateId = id;
                prod.rate = vat.rate;
                return vat.rate;
            },
            removeProduct: function (id) {

                this.purchaseProducts = this.purchaseProducts.filter((prod) => {
                    return prod.rowId != id;
                });

                this.calcuateSummary();
            },

            getData: function () {
                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }

                root.$https
                    .get("/Product/TaxRateList", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.vats = response.data.taxRates;
                        }
                    }).then(function () {
                        
                        if (root.purchaseItems != undefined && root.purchaseItems.length > 0) {
                            root.purchaseItems.forEach(function (item) {

                                root.purchaseProducts.push({
                                    rowId: item.id,
                                    id: item.id,
                                    description: item.description,
                                    batchNo: item.batchNo,
                                    discount: item.discount,
                                    expiryDate: item.expiryDate,
                                    isExpire: item.productId == null ? '' : item.product.isExpire,
                                    fixDiscount: item.fixDiscount,
                                    levelOneUnit: item.productId == null ? '' : item.product.levelOneUnit,
                                    product: item.product,
                                    productId: item.productId,
                                    purchaseId: item.purchaseId,
                                    quantity: item.quantity,
                                    highQty: item.highQty,
                                    unitPerPack: item.unitPerPack,
                                    taxMethod: item.taxMethod,
                                    taxRateId: item.taxRateId,
                                    serial: item.productId == null ? false : item.product.serial,
                                    serialNo: item.serialNo,
                                    guarantee: item.productId == null ? false : item.product.guarantee,
                                    guaranteeDate: item.guaranteeDate,
                                    basicUnit: item.productId == null ? '' : item.product.basicUnit,
                                    unitPrice: item.unitPrice,
                                    isService: item.isService,
                                    warrantyTypeId: item.warrantyTypeId,
                                    totalPiece: 0,
                                    saleSizeAssortment: item.saleSizeAssortment,
                                    productSizes: item.product.productSizes,
                                    colorId: item.colorId,
                                    colorName: item.colorName,
                                    highUnitPrice: item.product == null ? 0 : item.product.highUnitPrice,
                                    discountSign: item.discount == 0 ? item.fixDiscount == 0 ? '%' : 'F' : '%',
                                });
                            });
                            root.adjustment = (root.adjustmentProp == null || root.adjustmentProp == undefined || root.adjustmentProp == '') ? 0 : (root.adjustmentSignProp == '+' ? root.adjustmentProp : (-1) * root.adjustmentProp)
                            root.adjustmentSign = root.adjustmentSignProp;
                            for (var l = 0; l < root.purchaseProducts.length; l++) {
                                if (root.purchaseProducts[l].productId != null) {
                                    root.products.push(root.purchaseProducts[l].product);
                                }

                                root.updateLineTotal(root.purchaseProducts[l].quantity, "quantity", root.purchaseProducts[l]);
                                if (root.isMultiUnit) {
                                    root.updateLineTotal(root.purchaseProducts[l].highQty, "highQty", root.purchaseProducts[l]);
                                    root.updateLineTotal(root.purchaseProducts[l].unitPerPack, "unitPerPack", root.purchaseProducts[l]);
                                }
                                root.updateLineTotal(root.purchaseProducts[l].unitPrice, "unitPrice", root.purchaseProducts[l]);
                                root.updateLineTotal(root.purchaseProducts[l].discount, "discount", root.purchaseProducts[l]);
                                root.updateLineTotal(root.purchaseProducts[l].fixDiscount, "fixDiscount", root.purchaseProducts[l]);

                            }
                            root.calcuateSummary()
                        }

                        if (root.purchase != undefined) {
                            if (root.purchase.purchasePostItems != undefined) {
                                //Purchase Return Edit
                                root.purchase.purchasePostItems.forEach(function (item) {
                                    root.purchaseProducts.push({
                                        rowId: item.id,
                                        id: item.id,
                                        batchNo: item.batchNo,
                                        description: item.description,
                                        isService: item.isService,
                                        discount: item.discount,
                                        expiryDate: item.expiryDate,
                                        isExpire: item.productId == null ? false : item.product.isExpire,
                                        fixDiscount: item.fixDiscount,
                                        product: item.product,
                                        productId: item.productId,
                                        purchaseId: item.purchaseId,
                                        quantity: item.quantity,
                                        highQty: item.highQty,
                                        remainingQuantity: item.remainingQuantity,
                                        inventory: item.productId == null ? null : item.product.inventory,
                                        unitPerPack: item.unitPerPack,
                                        taxMethod: item.taxMethod,
                                        taxRateId: item.taxRateId,
                                        unitPrice: item.unitPrice == 0 ? '0' : item.unitPrice,
                                        levelOneUnit: item.productId == null ? '' : item.product.levelOneUnit,
                                        basicUnit: item.productId == null ? '' : item.product.basicUnit,
                                        serial: item.productId == null ? false : item.product.serial,
                                        serialNo: item.serialNo,
                                        guarantee: item.productId == null ? false : item.product.guarantee,
                                        guaranteeDate: item.guaranteeDate,
                                        warrantyTypeId: item.warrantyTypeId,
                                        totalPiece: 0,
                                        highUnitPrice: item.product == null ? 0 : item.product.highUnitPrice,
                                        discountSign: item.discount == 0 ? item.fixDiscount == 0 ? '%' : 'F' : '%',
                                    });

                                });

                                root.adjustment = (root.adjustmentProp == null || root.adjustmentProp == undefined || root.adjustmentProp == '') ? 0 : (root.adjustmentSignProp == '+' ? root.adjustmentProp : (-1) * root.adjustmentProp)
                                root.adjustmentSign = root.adjustmentSignProp;
                                for (var j = 0; j < root.purchaseProducts.length; j++) {
                                    if (root.purchaseProducts[j].productId) {
                                        root.products.push(root.purchaseProducts[j].product);
                                    }
                                    root.updateLineTotal(root.purchaseProducts[j].quantity, "quantity", root.purchaseProducts[j]);

                                    if (root.isMultiUnit) {
                                        root.updateLineTotal(root.purchaseProducts[j].highQty, "highQty", root.purchaseProducts[j]);
                                        root.updateLineTotal(root.purchaseProducts[j].unitPerPack, "unitPerPack", root.purchaseProducts[j]);
                                    }
                                    root.updateLineTotal(root.purchaseProducts[j].unitPrice, "unitPrice", root.purchaseProducts[j]);
                                    root.updateLineTotal(root.purchaseProducts[j].discount, "discount", root.purchaseProducts[j]);
                                    root.updateLineTotal(root.purchaseProducts[j].fixDiscount, "fixDiscount", root.purchaseProducts[j]);

                                }

                                root.calcuateSummary()
                            }
                        }

                        else if (root.$route.query.data != undefined) {
                            //Purchase Invoice Edit

                            if (root.$route.query.data.purchaseItems != undefined) {

                                root.purchaseProducts = root.$route.query.data.purchaseItems;
                                root.adjustment = (root.adjustmentProp == null || root.adjustmentProp == undefined || root.adjustmentProp == '') ? 0 : (root.adjustmentSignProp == '+' ? root.adjustmentProp : (-1) * root.adjustmentProp)
                                root.adjustmentSign = root.adjustmentSignProp;

                                for (var i = 0; i < root.purchaseProducts.length; i++) {
                                    root.products.push(root.purchaseProducts[i].product);
                                    root.updateLineTotal(root.purchaseProducts[i].quantity, "quantity", root.purchaseProducts[i]);
                                    root.updateLineTotal(root.purchaseProducts[i].unitPrice, "unitPrice", root.purchaseProducts[i]);
                                    root.updateLineTotal(root.purchaseProducts[i].discount, "discount", root.purchaseProducts[i]);
                                    root.updateLineTotal(root.purchaseProducts[i].fixDiscount, "fixDiscount", root.purchaseProducts[i]);


                                }
                                root.calcuateSummary()
                            }
                            else if (root.$route.query.data.purchaseOrderItems != undefined) {
                                //Purchase Order Edit

                                root.$route.query.data.purchaseOrderItems.forEach(function (item) {
                                    root.purchaseProducts.push({
                                        rowId: item.id,
                                        id: item.id,
                                        description: item.description,
                                        isService: item.isService,
                                        batchNo: item.batchNo,
                                        discount: item.discount,
                                        expiryDate: item.expiryDate,
                                        isExpire: item.productId == null ? false : item.product.isExpire,
                                        fixDiscount: item.fixDiscount,
                                        product: item.product,
                                        productId: item.productId,
                                        purchaseId: item.purchaseId,
                                        levelOneUnit: item.productId == null ? '' : item.product.levelOneUnit,
                                        quantity: item.quantity,
                                        receiveQuantity: item.receiveQuantity,
                                        highQty: item.highQty,
                                        unitPerPack: item.unitPerPack,
                                        taxMethod: item.taxMethod,
                                        taxRateId: item.taxRateId,
                                        serial: item.productId == null ? false : item.product.serial,
                                        serialNo: item.serialNo,
                                        guarantee: item.productId == null ? false : item.product.guarantee,
                                        guaranteeDate: item.guaranteeDate,
                                        unitPrice: item.unitPrice == 0 ? '0' : item.unitPrice,
                                        basicUnit: item.productId == null ? '' : item.product.basicUnit,
                                        warrantyTypeId: item.warrantyTypeId,
                                        totalPiece: 0,
                                        highUnitPrice: item.product == null ? 0 : item.product.highUnitPrice,
                                        discountSign: item.discount == 0 ? item.fixDiscount == 0 ? '%' : 'F' : '%',

                                    });
                                });

                                root.adjustment = (root.adjustmentProp == null || root.adjustmentProp == undefined || root.adjustmentProp == '') ? 0 : (root.adjustmentSignProp == '+' ? root.adjustmentProp : (-1) * root.adjustmentProp)
                                root.adjustmentSign = root.adjustmentSignProp;
                                for (var k = 0; k < root.purchaseProducts.length; k++) {
                                    if (root.purchaseProducts[k].productId != null) {
                                        root.products.push(root.purchaseProducts[k].product);
                                    }

                                    root.updateLineTotal(root.purchaseProducts[k].quantity, "quantity", root.purchaseProducts[k]);
                                    if (root.isMultiUnit) {
                                        root.updateLineTotal(root.purchaseProducts[k].highQty, "highQty", root.purchaseProducts[k]);
                                        root.updateLineTotal(root.purchaseProducts[k].unitPerPack, "unitPerPack", root.purchaseProducts[k]);
                                    }

                                    root.updateLineTotal(root.purchaseProducts[k].unitPrice, "unitPrice", root.purchaseProducts[k]);
                                    root.updateLineTotal(root.purchaseProducts[k].discount, "discount", root.purchaseProducts[k]);
                                    root.updateLineTotal(root.purchaseProducts[k].fixDiscount, "fixDiscount", root.purchaseProducts[k]);

                                }
                                root.calcuateSummary()
                            }
                        }
                    });
            },
            getTotalAmount: function () {
                return this.summary.withVAt;
            },
            clearList: function () {
                this.purchaseProducts = [];
            },

            GetSizeData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Product/SizeList?isActive=true' + '&isVariance=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.saleSizeAssortment = [];
                        response.data.results.sizes.forEach(function (item) {
                            root.saleSizeAssortment.push({
                                sizeId: item.id,
                                name: item.name,
                                quantity: 0,
                            });
                        });
                    }
                });
            },

            GetColorData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Product/ColorList?isActive=true' + '&isVariance=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.colorList = response.data.results.colors;
                    }
                });
            },
        },


        created: function () {
            this.transactionLevelDiscount = this.transactionLevelDiscountProp;
            if (this.$i18n.locale == 'en') {
                this.options = ['Inclusive', 'Exclusive'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
            }
            this.defaultVat = localStorage.getItem('DefaultVat');

            //this.$barcodeScanner.init(this.onBarcodeScanned);
            //For Scanner Code
            var root = this;
            var barcode = '';
            var interval;
            document.addEventListener('keydown', function (evt) {
                if (interval)
                    clearInterval(interval);
                if (evt.code === 'Enter') {
                    if (barcode) {
                        root.onBarcodeScanned(barcode);
                    }
                    barcode = '';
                    return;

                }
                if (evt.key !== 'Shift')
                    barcode += evt.key;
            });

            //End
            localStorage.setItem("BarcodeScan", 'Purchase')
            this.servicePurchase = localStorage.getItem('ServicePurchase') == 'true' ? true : false;
            this.isFifo = localStorage.getItem('fIFO') == 'true' ? true : false;
            //this.$barcodeScanner.init(this.onBarcodeScanned);
            this.getData();

            if (localStorage.getItem('ColorVariants') == 'true') {
                this.GetSizeData();
                this.GetColorData();
            }
        },

        mounted: function () {
            this.colorVariants = localStorage.getItem('ColorVariants') == 'true' ? true : false;
            this.decimalQuantity = localStorage.getItem('decimalQuantity') == 'true' ? true : false;
            this.internationalPurchase = localStorage.getItem('InternationalPurchase');
            this.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
            this.isSerial = localStorage.getItem('IsSerial') == 'true' ? true : false;


            //this.GetProductList();
            if (this.$session.exists()) {
                this.currency = localStorage.getItem('currency');
                this.isMultiUnit = localStorage.getItem('IsMultiUnit');
            }
        },
    };
</script>

<style scoped>

    #purchase-item td {
        padding-bottom: 5px;
        padding-top: 5px;
    }

    #purchase-item tr td {
        border-top: 0 !important;
        border-bottom: 0 !important;
    }

    .input-border {
        border: transparent;
        background-color: transparent !important;
    }

        .input-border:focus {
            outline: none !important;
            border: none !important;
        }

    .samary_tbl_pd td {
        padding: 20px 7px !important;
    }

    .multiselect__tags {
        background-color: transparent !important;
    }

    .tableHoverOn {
        background-color: #ffffff !important;
        height: 32px !important;
        max-height: 32px !important;
    }

    .multiselect__input, .multiselect__single {
        background-color: transparent !important;
    }

    .disableBtn {
        pointer-events: none;
        opacity: 0.5;
    }
</style>
