<template>
    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">

        <table class="table table-striped table-hover table_list_bg">
            <tr>
                <td class="border-top-0">
                    <product-dropdown v-bind:key="rendered"
                                      @input="addProduct"
                                      width="100%" />
                </td>
            </tr>
        </table>
        <div class=" table-responsive">

            <table class="table " v-if="purchaseProducts.length > 0" style="table-layout:fixed;">
                <thead>
                    <tr>
                        <th style="width: 200px;">
                            {{ $t('MobileItem.Product') }}
                        </th>
                        <th style="width: 110px;" class="text-right">
                            {{ $t('MobileItem.UnitPrice') }}
                        </th>
                        <th style="width: 110px;" class="text-center">
                            {{ $t('MobileItem.Qty') }}
                        </th>
                     
                       
                        
                       
                        <th style="width: 100px;" class="text-right">
                            {{ $t('MobileItem.LineTotal') }}
                        </th>
                        <th style="width: 40px"></th>
                    </tr>
                </thead>
                <tbody id="purchase-item">
                    <template v-for="(prod , index) in purchaseProducts.slice().reverse()">

                        <tr :key="prod.productId + index" style="border-bottom: 1px solid #d6d6d6; ">
                            <td class="border-top-0">
                                <!--<product-dropdown v-model="prod.productId"
                                @input="changeProduct($event, prod.rowId, prod.productId)" />-->
                                <span>
                                    {{products.find(x => x.id == prod.productId).code + ':' + products.find(x => x.id == prod.productId).englishName}}
                                </span>
                            </td>

                            <td class="border-top-0">
                                <input type="number" v-model="prod.unitPrice"
                                       @focus="$event.target.select()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                       class="form-control input-border text-center"
                                       @keyup="updateLineTotal($event.target.value, 'unitPrice', prod)" />
                            </td>
                            <td class="border-top-0">
                                <input type="number" v-model="prod.quantity"
                                       @focus="$event.target.select()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                       class="form-control input-border text-center"
                                       @keyup="updateLineTotal($event.target.value, 'quantity', prod)" />
                            </td>
                            
                            
                            
                          
                            <td class="border-top-0 text-right">
                                {{currency}}  {{parseFloat(prod.lineTotal).toFixed(3).slice(0,-1) | formatAmount}}
                            </td>
                            <td class="border-top-0 pt-0">
                                <button @click="removeProduct(prod.rowId)"
                                        title="Remove Item"
                                        class="btn btn-secondary btn-neutral btn-sm  btn-icon">
                                    <i class="nc-icon nc-simple-remove"></i>
                                </button>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

        <hr />
        <div class=" table-responsive"
             v-bind:key="rendered + 'g'"
             v-if="purchaseProducts.length > 0">
            <table class="table " style="table-layout:fixed">
                <thead class="m-0">
                    <tr class="text-right">
                        <th class="text-center" style="width:85px;">
                            {{ $t('MobileItem.NoItem') }}
                        </th>
                        <th class="text-center" style="width:100px;">
                            {{ $t('MobileItem.TotalQty') }}
                        </th>
                        <th style="width:100px;">
                            {{ $t('MobileItem.Total') }}
                        </th>
                       
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-right">
                        <td class="text-center">
                            {{ summary.item }}
                        </td>
                        <td class="text-center">
                            {{ summary.qty }}
                        </td>

                        <td>
                            {{currency}}   {{ summary.total | formatAmount}}
                        </td>
                        
                        
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template><script>
    //import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";

    //import VueBarcode from 'vue-barcode';
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        name: "PurchaseItem",

        data: function () {
            return {
                rendered: 0,
                product: {
                    id: "",
                },
                products: [],
                purchaseProducts: [],
                loading: false,
                vats: [],
                summary: {
                    item: 0,
                    qty: 0,
                    total: 0,
                },
                currency: '',
                searchTerm: ''
            };
        },
        validations: {},
        filter: {},
        methods: {
            changeProduct: function (NewProdId, rowId) {
                this.purchaseProducts = this.purchaseProducts.filter(x => x.rowId != rowId);
                this.addProduct(NewProdId);

            },
            calcuateSummary: function () {

                this.summary.item = this.purchaseProducts.length;
                this.summary.qty = this.purchaseProducts.reduce(
                    (qty, prod) => qty + parseInt(prod.quantity), 0);
                this.summary.total = this.purchaseProducts
                    .reduce((total, prod) => total + parseInt(prod.quantity) * prod.unitPrice, 0)
                    .toFixed(3).slice(0,-1);
              
               
                

                
              


                this.$emit("input", this.purchaseProducts);
            },

            updateLineTotal: function (e, prop, product) {



                if (prop == "unitPrice") {
                    if (e < 0) {
                        e = 0;
                    }
                    product.unitPrice = e;
                }

                if (prop == "quantity") {
                    if (e < 0) {
                        e = 0;
                    }
                    product.quantity = e;
                }

               

                

                 product.lineTotal = product.quantity * product.unitPrice ;
               
                

                this.$set(this.purchaseProducts, product, product);

                this.calcuateSummary();

                this.$emit("input", this.purchaseProducts);

            },

            addProduct: function (productId, newProduct) {


                if (this.products.find(x => x.id == newProduct.id) == undefined || this.products.length <= 0) {
                    this.products.push(newProduct);
                }

                var prod = this.products.find((x) => x.id == productId);

                

                this.purchaseProducts.push({
                    rowId: this.createUUID(),
                    productId: prod.id,
                    unitPrice: 0,
                    quantity: 1,
                    lineTotal: 0,
                });

              
                this.product.id = "";
                this.rendered++;
            },
            createUUID: function () {
                var dt = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (dt + Math.random() * 16) % 16 | 0;
                    dt = Math.floor(dt / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },

            removeProduct: function (id) {

                this.purchaseProducts = this.purchaseProducts.filter((prod) => {
                    return prod.rowId != id;
                });

                this.calcuateSummary();
            },

        },
        created: function () {
            
            var root = this;

            if (root.$route.query.data != undefined) {
                if (root.purchase != root.$route.query.data.mobileOrderItemLookupModels) {

                    root.purchaseProducts = root.$route.query.data.mobileOrderItemLookupModels;
                    for (var j = 0; j < root.purchaseProducts.length; j++) {

                        root.updateLineTotal(root.purchaseProducts[j].quantity, "quantity", root.purchaseProducts[j]);
                        root.updateLineTotal(root.purchaseProducts[j].unitPrice, "unitPrice", root.purchaseProducts[j]);

                    }
                    root.calcuateSummary()
                }
            }
          
        },
        mounted: function () {
          
        },
    };
</script>
