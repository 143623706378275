<template>
    <div>

        <div class="table-responsive">
            <table class="table mb-0" v-if="saleProducts.length > 0">

                <thead class="thead-light table-hover">
                    <tr>
                        <th style="width: 20px;">
                            #
                        </th>
                        <th style="width: 200px;">
                            {{ $t('InvoiceViewItem.Product') }}
                        </th>
                        <th class="text-right" style="width: 100px;">
                            {{ $t('InvoiceViewItem.UnitPrice') }}
                        </th>
                        <th class="text-center" style="width: 60px;" v-if="isValid('CanViewUnitPerPack')">
                            {{ $t('SaleItem.UnitPerPack') }}
                        </th>
                        <th class="text-center" style="width: 80px;" v-if="isFifo">
                            {{ $t('SaleItem.BatchNo') }}
                        </th>
                        <th class="text-center" style="width: 80px;" v-if="isFifo">
                            {{ $t('SaleItem.ExpiryDate') }}
                        </th>
                        <th style="width: 110px;" class="text-center" v-if="isMultiUnit=='true'">
                            {{ $t('InvoiceViewItem.HighQty') }}
                        </th>
                        <th class="text-center" style="width: 100px;">
                            {{ $t('InvoiceViewItem.Quantity') }}
                        </th>
                        <th style="width: 110px;" class="text-center" v-if="isMultiUnit=='true'">
                            {{ $t('InvoiceViewItem.TOTALQTY') }}
                        </th>
                        <th class="text-center" style="width: 100px;" hidden>
                            {{ $t('InvoiceViewItem.ReturnDays') }}
                        </th>
                        <th style="width: 100px;" v-if="saleProducts.filter(x=> x.isBundleOffer).length > 0" hidden>
                            {{ $t('InvoiceViewItem.Bundle') }}
                        </th>

                        <th class="text-center" style="width: 100px;" v-if="!isDiscountOnTransaction">
                            {{ $t('InvoiceViewItem.DISC%') }}
                        </th>

                        <th class="text-end" style="width: 100px;">
                            {{ $t('InvoiceViewItem.LineTotal') }}
                        </th>
                    </tr>
                </thead>
                <tbody id="sale-item">
                    <template v-for="(prod, index) in saleProducts">
                        <tr :key="rendered + index" v-bind:class="{'alert-danger':prod.outOfStock}">
                            <td>{{index+1}}</td>
                            <td>
                                <span>
                                    {{($i18n.locale == 'en' ||isLeftToRight())? products.find(x => x.id == prod.productId).englishName!=''? products.find(x => x.id == prod.productId).englishName : products.find(x => x.id == prod.productId).arabicName :    products.find(x => x.id == prod.productId).arabicName!=''? products.find(x => x.id == prod.productId).arabicName : products.find(x => x.id == prod.productId).englishName}}
                                </span>

                                <span v-if="prod.promotionId != null && prod.promotionId != undefined && prod.promotionId != ''" class="badge rounded-pill badge-soft-primary">
                                    <span v-if="prod.discountSign == 'F'">
                                        {{(products.find(x => x.id == prod.productId).promotionOffer.discount).toFixed(3).slice(0,-1)}}, ({{products.find(x => x.id == prod.productId).promotionOffer.stockLimit - products.find(x => x.id == prod.productId).promotionOffer.quantityOut}})
                                    </span>
                                    <span v-if="prod.discountSign == '%'">
                                        {{(products.find(x => x.id == prod.productId).promotionOffer.discount).toFixed(3).slice(0,-1)}}%, ({{products.find(x => x.id == prod.productId).promotionOffer.stockLimit - products.find(x => x.id == prod.productId).promotionOffer.quantityOut}})
                                    </span>
                                </span>
                                <span v-if="products.find(x => x.id == prod.productId).bundleCategory != undefined" class="badge rounded-pill badge-soft-primary">
                                    {{products.find(x => x.id == prod.productId).bundleCategory.buy}} + {{products.find(x => x.id == prod.productId).bundleCategory.get}}, ({{products.find(x => x.id == prod.productId).bundleCategory.stockLimit - products.find(x => x.id == prod.productId).bundleCategory.quantityOut}})
                                </span>
                            </td>

                            <td class="text-right">
                                {{prod.unitPrice}}
                            </td>
                            <td class="text-center" v-if="isValid('CanViewUnitPerPack')">
                                {{prod.unitPerPack}}
                            </td>
                            <td class="text-center" v-if="isFifo">
                                {{prod.batchNo}}
                            </td>
                            <td class="text-center" v-if="isFifo">
                                {{getDate(prod.batchExpiry)}}
                            </td>
                            <td class="text-center" v-if="isMultiUnit=='true'">
                                {{prod.highQty}}<br />
                                <small style="font-weight: 500;font-size:70%;">
                                    {{prod.levelOneUnit}}
                                </small>
                            </td>
                            <td class="text-center">
                                {{prod.quantity}}<br />
                                <small style="font-weight: 500;font-size:70%;" v-if="isMultiUnit=='true'">
                                    {{prod.basicUnit}}
                                </small>
                            </td>
                            <td class="text-center" v-if="isMultiUnit=='true'">
                                {{parseInt(parseFloat(prod.highQty*prod.unitPerPack) + parseFloat(prod.quantity))}}
                            </td>
                            <td v-if="prod.saleReturnDays > 0" hidden>
                                {{prod.saleReturnDays}}
                            </td>
                            <td class="text-center" v-else hidden>
                                <span>--</span>
                            </td>
                            <td class="text-center" v-if="saleProducts.filter(x=> x.isBundleOffer).length > 0" hidden>
                                <span class="badge badge-pill badge-info">{{prod.bundleOffer}}</span>
                            </td>

                            <td class="text-center" v-if="!isDiscountOnTransaction">
                                {{prod.discount == 0 ? prod.fixDiscount : prod.discount}}{{prod.discountSign}}
                            </td>

                            <td class="text-end">
                                {{currency}} {{parseFloat(prod.lineTotal).toFixed(3).slice(0,-1) | formatAmount}}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

        <div class="row  ">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">

            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
                <div class="mt-4" v-bind:key="rendered + 'g'">
                    <table class="table" style="background-color: #f1f5fa;">
                        <tbody>
                            <tr>
                                <td colspan="2" style="width:65%;">
                                    <span class="fw-bold">{{ $t('InvoiceViewItem.SubTotal') }}</span>
                                    <span>(Tax {{taxMethod}})</span>
                                </td>
                                <td class="text-end" style="width:35%;">{{summary.withDisc}}</td>
                            </tr>
                            <tr v-if="isDiscountOnTransaction && isBeforeTax">
                                <td style="width:40%;">
                                    <span style="height:33px !important; ">{{ $t('InvoiceViewItem.Disc') }}</span>
                                    <br />
                                    <span v-if="summary.item > 0">
                                        <a href="javascript:void(0)" v-on:click="UpdateDiscountField('beforeTax')">
                                            <small class="fw-bold text-primary">{{ $t('InvoiceViewItem.ApplyAfterTax') }}</small>
                                        </a>
                                    </span>
                                </td>
                                <td style="width:25%;">
                                    <div class="input-group" style="pointer-events: none;">
                                        <decimal-to-fixed v-model="transactionLevelDiscount" @input="calcuateSummary" />
                                        <button v-if="taxMethod == ('Inclusive' || 'شامل')" class="btn btn-primary" type="button" id="button-addon2">{{isFixed?'F':'%'}}</button>
                                        <button v-else class="btn btn-primary" v-on:click="UpdateDiscountField('fixed')" type="button" id="button-addon2">{{isFixed?'F':'%'}}</button>
                                    </div>
                                </td>
                                <td class="text-end" style="width:35%;">{{transactionLevelTotalDiscount}}</td>
                            </tr>
                            <tr v-for="(vat,index) in paidVatList" :key="index">
                                <td class="fw-bold" colspan="2" style="width:65%;">{{vat.name}}</td>
                                <td class="text-end" style="width:35%;">{{vat.amount}}</td>
                            </tr>
                            <tr v-if="isDiscountOnTransaction && !isBeforeTax">
                                <td style="width:40%;">
                                    <span style="height:33px !important; ">{{ $t('InvoiceViewItem.Disc') }}</span>
                                    <br />
                                    <span v-if="summary.item > 0">
                                        <a href="javascript:void(0)" v-on:click="UpdateDiscountField('beforeTax')">
                                            <small class="fw-bold text-primary">{{ $t('InvoiceViewItem.ApplyBeforeTax') }}</small>
                                        </a>
                                    </span>
                                </td>
                                <td style="width:25%;">
                                    <div class="row">
                                        <div class="input-group " style="pointer-events: none;">
                                            <decimal-to-fixed v-model="transactionLevelDiscount" @input="calcuateSummary" />
                                            <button v-if="taxMethod == ('Inclusive' || 'شامل')" class="btn btn-primary" type="button" id="button-addon2">{{isFixed?'F':'%'}}</button>
                                            <button v-else class="btn btn-primary" v-on:click="UpdateDiscountField('fixed')" type="button" id="button-addon2">{{isFixed?'F':'%'}}</button>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-end" style="width:35%;">{{transactionLevelTotalDiscount}}</td>
                            </tr>
                            <tr>
                                <td style="width:40%;">
                                    <input class="form-control" type="text" readonly :value="$t('InvoiceViewItem.Adjustment')" style="border: 1px dashed #1761fd;" />
                                </td>
                                <td style="width:25%;">
                                    <div class="input-group">
                                        <decimal-to-fixed v-model="adjustment" disabled @input="calcuateSummary" />
                                        <button v-on:click="OnChangeOveallDiscount" disabled class="btn btn-primary" type="button" id="button-addon2">{{adjustmentSign}}</button>
                                    </div>
                                </td>
                                <td class="text-end" style="width:35%;">{{adjustmentSign == '+'?adjustment:(-1)*adjustment}}</td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width:65%;">
                                    <span style="font-weight:bolder; font-size:16px">{{ $t('InvoiceViewItem.TotalDue') }}({{currency}})</span>
                                </td>
                                <td class="text-end" style="width: 35%; font-weight: bolder; font-size: 16px">{{summary.withVat}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
        <authorize-user-model :authorize="authorize"
                              :show="show"
                              v-if="show"
                              @result="result"
                              @close="show = false" />
    </div>
</template>

<script>
    import moment from "moment";
    import clickMixin from '@/Mixins/clickMixin'
    /*import Multiselect from 'vue-multiselect'*/
    export default {
        name: "SaleItem",
        props: ['saleItems', 'wareHouseId', 'saleOrderId', 'taxMethod', 'wholesale', 'taxRateId', 'adjustmentProp', 'adjustmentSignProp', 'isDiscountOnTransaction', 'transactionLevelDiscountProp', 'isFixed', 'isBeforeTax'],
        mixins: [clickMixin],
        //components: {
        //    Multiselect
        //},
        data: function () {
            return {
                transactionLevelDiscount: 0,
                adjustment: 0,
                adjustmentSign: '+',
                isDiscountBeforeVat: false,
                transactionLevelTotalDiscount: 0,
                saleSizeAssortment: [],
                colorList: [],
                options: [],
                dayStart: '',
                colorVariants: false,
                isFifo: false,
                isSerial: false,
                soInventoryReserve: false,
                decimalQuantity: false,
                invoiceWoInventory: false,
                fixDiscount: '',
                discount: '',
                bundle: '',
                counter: 0,
                isMultiUnit: '',
                isAuthour: {
                    changePriceDuringSale: false,
                    giveDiscountDuringSale: false,
                    column: '',
                },
                changePriceDuringSale: false,
                giveDiscountDuringSale: false,
                useQuantity: false,
                show: false,
                authorize: {
                    column: '',
                    userName: '',
                    password: '',
                },
                rendered: 0,
                product: {
                    id: "",
                },
                products: [],
                saleProducts: [],
                loading: false,
                vats: [],
                summary: {
                    item: 0,
                    qty: 0,
                    total: 0,
                    discount: 0,
                    withDisc: 0,
                    vat: 0,
                    withVat: 0,
                    bundleAmount: 0,
                    totalCarton: 0,
                    totalPieces: 0
                },
                currency: '',
                count: 0,
                productList: [],
                serialItem: '',
                showSerial: false,
                vatSelectOnSale: false,
                wholsalePriceActive: true,
                saleDefaultVat: '',
                paidVatList: []
            };
        },
        validations: {},
        filter: {},
        methods: {
            UpdateDiscountField: function (prop) {
                if (prop === 'fixed')
                    this.isFixed = this.isFixed ? false : true
                if (prop === 'beforeTax')
                    this.isBeforeTax = this.isBeforeTax ? false : true
                /*this.$emit("discountChanging", this.isFixed, this.isBeforeTax);*/
                this.calcuateSummary();
            },
            OnChangeOveallDiscount: function () {
                this.adjustmentSign = this.adjustmentSign == '+' ? '-' : '+'
                this.calcuateSummary()
            },
            NewItemChangeDiscount: function (prod) {
                if (prod.discountSign === '%') {
                    prod.discountSign = 'F';
                    prod.fixDiscount = 0
                    prod.discount = 0
                }
                else {
                    prod.discountSign = '%';
                    prod.discount = 0
                    prod.fixDiscount = 0
                }
            },
            OnChangeDiscountType: function (prod) {
                if (prod.discountSign === '%') {
                    prod.discountSign = 'F';
                    prod.fixDiscount = 0
                    prod.discount = 0
                    this.updateLineTotal(prod.fixDiscount, 'fixDiscount', prod)
                }
                else {
                    prod.discountSign = '%';
                    prod.discount = 0
                    prod.fixDiscount = 0
                    this.updateLineTotal(prod.discount, 'discount', prod)
                }
            },
            AddSerial: function (item) {

                this.serialItem = item;
                this.showSerial = true;
            },

            updateSerial: function (serial, item) {

                var prod = this.saleProducts.find(x => x.rowId == item.rowId);
                if (prod != undefined) {
                    prod.serial = serial;
                }
                this.showSerial = false;
            },

            result: function (x) {
                this.isAuthour = x;
            },
            openmodel: function (column) {

                this.authorize = {
                    userName: '',
                    password: '',
                    column: column
                }
                this.show = !this.show;
            },
            openmodel1: function (column) {

                this.authorize = {
                    userName: '',
                    password: '',
                    column: column
                }
                this.show = !this.show;
            },
            openmodel2: function (column) {

                this.authorize = {
                    userName: '',
                    password: '',
                    column: column
                }
                this.show = !this.show;
            },

            changeProduct: function (NewProdId, rowId) {
                this.saleProducts = this.saleProducts.filter(x => x.rowId != rowId);
                this.addProduct(NewProdId);
            },

            GetColorName: function (colorId, product) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Product/ColorDetail?id=' + colorId + '&productId=' + product.productId + '&isVariance=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        product.saleSizeAssortment.forEach(function (item) {
                            var size = response.data.variationInventories.find(x => x.sizeId == item.sizeId);
                            if (size != undefined) {
                                item.currentQuantity = size.quantity;
                            }
                            else {
                                item.currentQuantity = 0;
                            }
                        });

                        if (colorId != null && colorId != undefined && colorId != '') {
                            product.colorName = response.data.name;
                            product.currentQuantity = response.data.quantity;
                        }
                        else {
                            product.colorName = '';
                        }
                    }
                });
            },

            sizeAllowInput: function (sizeId, product) {

                if (product.productSizes != null && product.productSizes != undefined && product.productSizes.length != 0) {
                    var size = product.productSizes.find(x => x.sizeId == sizeId);
                    if (size != undefined) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return true;
                }
            },

            sizeQtyVerify: function (product) {
                if (product.saleSizeAssortment != null && product.saleSizeAssortment != undefined) {
                    if (parseFloat(product.totalPiece) < product.saleSizeAssortment.reduce(function (a, c) { return a + parseFloat(c.quantity == '' ? 0 : c.quantity) }, 0)) {
                        this.$set(product, 'outOfStock', true);
                    } else {
                        this.$set(product, 'outOfStock', false);
                    }
                }
                else {
                    this.$set(product, 'outOfStock', true);
                }
            },

            calcuateSummary: function () {
                this.summary.item = this.saleProducts.length;
                if (this.decimalQuantity) {
                    this.summary.totalPieces = this.saleProducts.reduce((totalQty, prod) => totalQty + parseFloat(prod.quantity), 0);
                }
                else {
                    this.summary.totalPieces = this.saleProducts.reduce((totalQty, prod) => totalQty + parseInt(prod.quantity), 0);
                }

                if (this.decimalQuantity) {
                    this.summary.totalCarton = this.saleProducts.reduce((totalCarton, prod) => totalCarton + parseFloat(prod.highQty), 0);
                }
                else {
                    this.summary.totalCarton = this.saleProducts.reduce((totalCarton, prod) => totalCarton + parseInt(prod.highQty), 0);
                }

                if (this.decimalQuantity) {
                    this.summary.qty = this.saleProducts.reduce((qty, prod) => qty + parseFloat(prod.totalPiece == '' ? 0 : prod.totalPiece), 0);
                }
                else {
                    this.summary.qty = this.saleProducts.reduce((qty, prod) => qty + parseInt(prod.totalPiece == '' ? 0 : prod.totalPiece), 0);
                }

                this.summary.total = this.saleProducts.reduce((total, prod) =>
                    total + (prod.totalPiece) * prod.unitPrice, 0).toFixed(3).slice(0, -1);

                if (!this.isDiscountOnTransaction) {
                    this.transactionLevelDiscount = 0;
                }
                var vatRate = 0;
                var discountOnly = 0;
                var discountForInclusiveVat = 0;
                var root = this;
                const taxIdList = [...new Set(this.saleProducts.map(item => item.taxRateId))];
                root.paidVatList = []
                //'isDiscountOnTransaction', 'transactionLevelDiscount'
                taxIdList.forEach(function (taxId) {
                    vatRate = root.vats.find((value) => value.id == taxId);
                    var filteredRecord = root.saleProducts
                        .filter((x) => x.taxRateId === taxId);
                    var totalQtyWithotFree = root.saleProducts.reduce((qty, prod) => qty + parseInt(prod.totalPiece == '' ? 0 : prod.totalPiece), 0);

                    discountOnly += filteredRecord
                        .filter((x) => x.discount != 0 || x.discount != "")
                        .reduce((discount, prod) => discount + prod.promotionOfferQuantity > 0 ? (prod.promotionOfferQuantity ? (((prod.promotionOfferQuantity * prod.unitPrice) * prod.discount) / 100) : 0) : (prod.totalPiece ? (((prod.totalPiece * prod.unitPrice) * prod.discount) / 100) : 0), 0);


                    //discountOnly += filteredRecord
                    //    .filter((x) => x.discount != 0 || x.discount != "" || x.offerQuantity != 0)
                    //    .reduce((discount, prod) =>
                    //        discount + (prod.totalPiece ? (prod.offerQuantity ? 0 : (((prod.totalPiece * prod.unitPrice) * prod.discount) / 100)) : 0), 0);

                    discountOnly += filteredRecord
                        .filter((x) => x.fixDiscount != 0 || x.fixDiscount != "" || x.offerQuantity != 0)
                        .reduce((discount, prod) =>
                            discount + (prod.totalPiece ? (prod.offerQuantity ? 0 : (root.taxMethod == ("Inclusive" || "شامل") ? prod.fixDiscount + (prod.fixDiscount * vatRate.rate / 100) : prod.fixDiscount)) : 0), 0);

                    var paidVat = filteredRecord
                        .reduce((vat, prod) => (vat + ((prod.taxMethod == ("Inclusive" || "شامل")) ? ((parseFloat(prod.lineTotal) - (root.isBeforeTax ? (((prod.totalPiece * prod.unitPrice) * root.transactionLevelDiscount) / 100) : 0)) * vatRate.rate) / (100 + vatRate.rate) : ((parseFloat(prod.lineTotal) - (root.isBeforeTax && !root.isFixed && root.isDiscountOnTransaction ? (((prod.totalPiece * prod.unitPrice) * root.transactionLevelDiscount) / 100) : (root.isBeforeTax && root.isFixed && root.isDiscountOnTransaction ? (root.transactionLevelDiscount / parseFloat(totalQtyWithotFree) * prod.totalPiece) : 0))) * vatRate.rate) / 100)), 0).toFixed(3).slice(0, -1)
                    discountForInclusiveVat += parseFloat(filteredRecord
                        .reduce((vat, prod) => (vat + ((prod.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(prod.lineTotal) * vatRate.rate) / (100 + vatRate.rate) : 0)), 0).toFixed(3).slice(0, -1))

                    root.paidVatList.push({
                        name: vatRate.name,
                        amount: paidVat
                    })

                });
                //root.transactionLevelDiscount = root.transactionLevelDiscount;
                this.summary.discount = discountOnly
                this.summary.withDisc = (this.summary.total - this.summary.discount).toFixed(3).slice(0, -1);

                this.summary.vat = this.paidVatList.reduce((vat, paidVat) => (vat + parseFloat(paidVat.amount)), 0).toFixed(3).slice(0, -1);

                var exclusiveVat = this.taxMethod == ("Inclusive" || "شامل") ? 0 : parseFloat(this.summary.vat);
                this.transactionLevelTotalDiscount = ((this.isBeforeTax && this.isDiscountOnTransaction) ? (this.taxMethod == ("Inclusive" || "شامل") ? (parseFloat(this.transactionLevelDiscount) * (this.summary.withDisc - discountForInclusiveVat) / 100) : (this.isFixed ? parseFloat(this.transactionLevelDiscount) : parseFloat(this.transactionLevelDiscount) * this.summary.withDisc / 100)) : (this.isFixed ? parseFloat(this.transactionLevelDiscount) : (parseFloat(this.summary.withDisc) + parseFloat(exclusiveVat)) * parseFloat(this.transactionLevelDiscount) / 100)).toFixed(3).slice(0, -1)

                var totalIncDisc = (this.isBeforeTax && this.isDiscountOnTransaction && this.taxMethod == ("Inclusive" || "شامل")) ? (parseFloat(this.transactionLevelDiscount) * (this.summary.withDisc) / 100) : parseFloat(this.transactionLevelTotalDiscount)
                this.adjustment = (this.adjustment == '' || this.adjustment == null) ? 0 : parseFloat(this.adjustment)

                this.summary.withVat = (parseFloat(this.summary.withDisc) + parseFloat(exclusiveVat) + (this.adjustmentSign == '+' ? this.adjustment : (-1) * this.adjustment)).toFixed(3).slice(0, -1);

                this.summary.withVat = (parseFloat(this.summary.withVat) - totalIncDisc).toFixed(3).slice(0, -1);



                
                this.$emit("input", this.saleProducts, this.adjustment, this.adjustmentSign, parseFloat(this.transactionLevelDiscount));

                this.$emit("summary", this.summary);
            },

            updateLineTotal: function (e, prop, product) {
                var root = this;
                
                if (e != undefined) {
                    var discount = product.discount == 0 || product.discount == "" ? product.fixDiscount == 0 || product.fixDiscount == ""
                        ? 0
                        : product.fixDiscount
                        : product.discount;

                    if (prop == "unitPrice") {
                        product.unitPrice = e;
                    }

                    if (prop == "quantity") {
                        if (e <= 0 || e == '') {
                            e = 0;
                        }
                        if (String(e).split('.').length > 1 && String(e).split('.')[1].length > 2)
                            e = parseFloat(String(e).slice(0, -1))
                        product.quantity = this.decimalQuantity ? e : Math.round(e);
                    }
                    if (prop == "highQty") {
                        if (e < 0 || e == '' || e == undefined) {
                            e = 0;
                        }
                        product.highQty = Math.round(e);
                    }
                    product.totalPiece = (parseFloat(product.highQty == undefined ? 0 : product.highQty) * parseFloat(product.unitPerPack == null ? 0 : product.unitPerPack)) + parseFloat(product.quantity == '' ? 0 : product.quantity);

                    if (product.productId != null) {
                        var prod = root.products.find((x) => x.id == product.productId);

                        if (product.bundleId != null) {
                            if (product.totalPiece > product.buy) {
                                this.$set(product, 'bundleOffer', product.buy.toString() + " + " + product.get.toString());
                                this.$set(product, 'isBundleOffer', true);
                            }
                            else {
                                this.$set(product, 'bundleOffer', "");
                                this.$set(product, 'isBundleOffer', false);
                            }
                            //bundle category calculation
                            if (parseFloat(product.totalPiece) >= (product.get + product.buy)) {
                                var offer = Math.floor(parseFloat(product.totalPiece) / (product.get + product.buy));
                                if ((prod.bundleCategory.quantityOut + offer) <= product.stockLimit) {
                                    if (offer <= product.offerQuantityLimit) {
                                        product.offerQuantity = offer * product.get;
                                    }
                                    else {
                                        product.offerQuantity = product.offerQuantityLimit * product.get;
                                    }
                                }
                                else {
                                    product.offerQuantity = 0;
                                }
                            }
                            else {
                                product.offerQuantity = 0;
                            }
                            //bundle category calculation end
                        }
                    }



                    if (!this.invoiceWoInventory && product.productId != null && this.formName != 'Quotation') {
                        var bundleQuantity = product.bundleOfferQuantity == undefined ? 0 : product.bundleOfferQuantity;
                        if (prod.inventory != null) {
                            if (parseFloat(product.totalPiece) + bundleQuantity > (prod.inventory.currentQuantity + ((this.saleOrderId != null && this.saleOrderId != '' && this.soInventoryReserve) ? parseFloat(product.soQty) : 0))) {
                                this.$set(product, 'outOfStock', true);
                            } else {
                                this.$set(product, 'outOfStock', false);
                            }
                        }
                        else {
                            this.$set(product, 'outOfStock', true);
                        }
                    }


                    //End Calculate offer
                    if (prop == "discount") {
                        if (e == "" || e < 0) {
                            e = 0;
                        }
                        else if (e > 100) {
                            e = 100;
                        }
                        product.discount = e;
                    }

                    if (prop == "fixDiscount") {
                        if (e == "" || e < 0) {
                            e = 0;
                        }
                        else if (e > product.unitPrice) {
                            e = product.unitPrice;
                        }
                        product.fixDiscount = e;
                    }

                    var vat = 0;
                    var total = 0;
                    var calculateVAt = 0;
                    //here we will select quantity after deduct bundle quantity
                    //here we will select quantity after deduct bundle quantity
                    if (product.promotionId != null) {

                        if (prod.promotionOffer.discountType == '%') {
                            product.discountSign = '%';
                            if (prod.promotionOffer.includingBaseQuantity) {
                                if (product.totalPiece > product.offerQuantityLimit) {
                                    if (product.totalPiece <= product.upToQuantity) {
                                        product.promotionOfferQuantity = product.totalPiece;
                                        product.discount = prod.promotionOffer.discount;
                                        discount = product.totalPiece * product.unitPrice * product.discount / 100;
                                    }
                                    else {
                                        product.promotionOfferQuantity = product.upToQuantity;
                                        product.discount = prod.promotionOffer.discount;
                                        discount = product.promotionOfferQuantity * product.unitPrice * product.discount / 100;
                                    }
                                }
                                else {
                                    product.discount = 0;
                                    product.fixDiscount = 0;
                                    discount = 0;
                                }                                
                            }
                            else {
                                if (product.totalPiece > product.offerQuantityLimit) {
                                    if (product.totalPiece <= product.upToQuantity) {
                                        product.promotionOfferQuantity = product.totalPiece - product.offerQuantityLimit;
                                        product.discount = prod.promotionOffer.discount;
                                        discount = product.promotionOfferQuantity * product.unitPrice * product.discount / 100;
                                    }
                                    else {
                                        product.promotionOfferQuantity = product.upToQuantity - product.offerQuantityLimit;
                                        product.discount = prod.promotionOffer.discount;
                                        discount = product.promotionOfferQuantity * product.unitPrice * product.discount / 100;
                                    }

                                }
                                else {
                                    product.discount = 0;
                                    product.fixDiscount = 0;
                                    discount = 0;
                                }
                            }


                        }
                        else {
                            product.discountSign = 'F'
                            if (product.totalPiece >= product.offerQuantityLimit) {
                                product.fixDiscount = prod.promotionOffer.discount;
                                discount = product.fixDiscount;
                                product.discount = 0;
                                product.promotionOfferQuantity = 1;
                            }
                            else {
                                product.discount = 0;
                                product.fixDiscount = 0;
                                discount = 0;
                            }
                        }


                        vat = this.vats.find((value) => value.id == product.taxRateId);
                        total = product.totalPiece * product.unitPrice;
                        if (product.taxMethod == 'Inclusive' || product.taxMethod == 'شامل') {
                            calculateVAt = product.discountSign == 'F' ? (((total * 100) / (100 + vat.rate)) - discount) * vat.rate / 100 : (total - discount) * vat.rate / (vat.rate + 100);
                            product.lineItemVAt = calculateVAt;
                            product.lineTotal = product.discountSign == 'F' ? ((total * 100 / (100 + vat.rate)) - discount) * (100 + vat.rate) / 100 : total - discount;
                        }
                        else {
                            product.lineItemVAt = ((total - discount) * vat.rate) / 100;
                            product.lineTotal = total - discount;
                        }


                        this.$set(this.saleProducts, product, product);
                        this.calcuateSummary();
                        this.$emit("input", this.saleProducts, this.adjustment, this.adjustmentSign, parseFloat(this.transactionLevelDiscount));

                    }
                    else if (product.isBundleOffer) {
                        //isDiscountBeforeVat
                        vat = this.vats.find((value) => value.id == product.taxRateId);
                        discount = product.discount == 0 ? (this.taxMethod == ("Inclusive" || "شامل") ? product.fixDiscount + (product.fixDiscount * vat.rate / 100) : product.fixDiscount) : (product.totalPiece * product.unitPrice * product.discount) / 100;
                        product.lineTotal = (product.totalPiece - product.offerQuantity) * product.unitPrice - discount;

                        this.$set(this.saleProducts, product, product);
                        this.calcuateSummary();
                        this.$emit("input", this.saleProducts, this.adjustment, this.adjustmentSign, parseFloat(this.transactionLevelDiscount));
                    }
                    else {
                        //isDiscountBeforeVat
                        vat = this.vats.find((value) => value.id == product.taxRateId);
                        discount = product.discount == 0 ? (this.taxMethod == ("Inclusive" || "شامل") ? product.fixDiscount + (product.fixDiscount * vat.rate / 100) : product.fixDiscount) : (product.totalPiece * product.unitPrice * product.discount) / 100;
                        product.lineTotal = product.totalPiece * product.unitPrice - discount;

                        this.$set(this.saleProducts, product, product);
                        this.calcuateSummary();
                        this.$emit("input", this.saleProducts, this.adjustment, this.adjustmentSign, parseFloat(this.transactionLevelDiscount));
                    }
                }
            },

            updateBatch: function (productId, batch) {
                
                var prd = this.saleProducts.find(x => x.productId == productId);
                if (prd != undefined) {
                    prd.batchNo = batch.batchNumber;
                    prd.batchExpiry = batch.expiryDate;
                }
                this.updateLineTotal(prd.quantity, "quantity", prd);
            },

            addProduct: function (productId, newProduct, qty, price, so, quantityOut, soItem, batch) {

                var uid = this.createUUID();

                if (this.saleProducts.some(x => x.productId == productId) && !this.isFifo && !this.colorVariants) {
                    var prd = this.saleProducts.find(x => x.productId == productId);
                    prd.quantity++;
                    this.updateLineTotal(prd.quantity, "quantity", prd);
                }
                else if (!so && batch != undefined && this.saleProducts.some(x => x.productId == productId && x.batchNo == batch.batchNumber) && this.isFifo) {
                    var prd1 = this.saleProducts.find(x => x.productId == productId && x.batchNo == newProduct.batchNo);
                    prd1.quantity++;
                    this.updateLineTotal(prd1.quantity, "quantity", prd1);
                }
                else {
                    var prod = ''
                    if (so && this.isFifo) {
                        this.products.push({
                            rowId: soItem.id,
                            arabicName: newProduct.arabicName,
                            assortment: newProduct.assortment,
                            barCode: newProduct.barCode,
                            basicUnit: soItem.product.basicUnit,
                            batchExpiry: soItem.expiryDate,
                            batchNo: soItem.batchNo,
                            brandId: newProduct.brandId,
                            bundleCategory: newProduct.bundleCategory,
                            category: newProduct.category,
                            categoryId: newProduct.categoryId,
                            code: newProduct.code,
                            colorId: newProduct.colorId,
                            colorName: newProduct.colorName,
                            discount: soItem.discount,
                            fixDiscount: soItem.fixDiscount,
                            colorNameArabic: newProduct.colorNameArabic,
                            currentQuantity: soItem.product.inventory.currentQuantity,
                            description: newProduct.description,
                            englishName: newProduct.englishName,
                            guarantee: soItem.product.guarantee,
                            id: newProduct.id,
                            image: newProduct.image,
                            inventory: soItem.product.inventory,
                            inventoryBatch: soItem.product.inventoryBatch,
                            isActive: newProduct.isActive,
                            isExpire: newProduct.isExpire,
                            isRaw: newProduct.isRaw,

                            length: soItem.product.length,
                            levelOneUnit: soItem.product.levelOneUnit,
                            originId: soItem.product.originId,
                            promotionOffer: soItem.product.promotionOffer,
                            purchasePrice: newProduct.purchasePrice,
                            salePrice: newProduct.salePrice,
                            salePriceUnit: newProduct.salePriceUnit,
                            saleReturnDays: soItem.product.saleReturnDays,
                            serial: soItem.product.serial,
                            serviceItem: newProduct.serviceItem,

                            shelf: newProduct.shelf,
                            sizeId: newProduct.sizeId,
                            sizeName: newProduct.sizeName,
                            sizeNameArabic: newProduct.sizeNameArabic,
                            stockLevel: newProduct.stockLevel,
                            styleNumber: newProduct.styleNumber,
                            subCategoryId: newProduct.subCategoryId,
                            taxMethod: soItem.product.taxMethod,
                            taxRate: newProduct.taxRate,
                            taxRateId: soItem.product.taxRateId,
                            taxRateValue: newProduct.taxRateValue,
                            unit: newProduct.unit,
                            unitId: newProduct.unitId,

                            unitPerPack: newProduct.unitPerPack,
                            width: newProduct.width,
                            highUnitPrice: newProduct.highUnitPrice,
                            discountSign: '%',
                        });
                        prod = this.products.find((x) => x.rowId == soItem.id);
                    }
                    else if (this.isFifo && (batch != undefined || batch != null)) {

                        var inventoryData = {
                            autoNumbering: newProduct.inventory.autoNumbering,
                            averagePrice: newProduct.inventory.averagePrice,
                            batchNumber: newProduct.inventory.batchNumber,
                            bundleId: newProduct.inventory.bundleId,
                            buy: newProduct.inventory.buy,
                            currentQuantity: newProduct.inventory.currentQuantity,
                            currentStockValue: newProduct.inventory.currentStockValue,
                            date: newProduct.inventory.date,
                            documentId: newProduct.inventory.documentId,
                            documentNumber: newProduct.inventory.documentNumber,
                            expiryDate: newProduct.inventory.expiryDate,
                            get: newProduct.inventory.get,
                            id: newProduct.inventory.id,
                            isActive: newProduct.inventory.isActive,
                            isOpen: newProduct.inventory.isOpen,
                            price: newProduct.inventory.price,
                            product: newProduct.inventory.product,

                            productId: newProduct.inventory.productId,
                            promotionId: newProduct.inventory.promotionId,
                            quantity: newProduct.inventory.quantity,
                            remainingQuantity: newProduct.inventory.remainingQuantity,
                            salePrice: newProduct.inventory.salePrice,
                            serial: newProduct.inventory.serial,
                            stock: newProduct.inventory.stock,
                            stockId: newProduct.inventory.stockId,
                            transactionType: newProduct.inventory.transactionType,
                            wareHouseId: newProduct.inventory.wareHouseId,
                            warrantyDate: newProduct.inventory.warrantyDate,
                            highUnitPrice: newProduct.highUnitPrice,
                            discountSign: '%',
                        };

                        this.products.push({
                            rowId: uid,
                            arabicName: newProduct.arabicName,
                            assortment: newProduct.assortment,
                            barCode: newProduct.barCode,
                            basicUnit: newProduct.basicUnit,
                            batchExpiry: newProduct.batchExpiry,
                            batchNo: newProduct.batchNo,
                            brandId: newProduct.brandId,
                            bundleCategory: newProduct.bundleCategory,
                            category: newProduct.category,
                            categoryId: newProduct.categoryId,
                            code: newProduct.code,
                            colorId: newProduct.colorId,
                            colorName: newProduct.colorName,

                            colorNameArabic: newProduct.colorNameArabic,
                            currentQuantity: newProduct.currentQuantity,
                            description: newProduct.description,
                            englishName: newProduct.englishName,
                            guarantee: newProduct.guarantee,
                            id: newProduct.id,
                            image: newProduct.image,
                            inventory: inventoryData,
                            inventoryBatch: newProduct.inventoryBatch,
                            isActive: newProduct.isActive,
                            isExpire: newProduct.isExpire,
                            isRaw: newProduct.isRaw,

                            length: newProduct.length,
                            levelOneUnit: newProduct.levelOneUnit,
                            originId: newProduct.originId,
                            promotionOffer: newProduct.promotionOffer,
                            purchasePrice: newProduct.purchasePrice,
                            salePrice: newProduct.salePrice,
                            salePriceUnit: newProduct.salePriceUnit,
                            saleReturnDays: newProduct.saleReturnDays,
                            serial: newProduct.serial,
                            serviceItem: newProduct.serviceItem,

                            shelf: newProduct.shelf,
                            sizeId: newProduct.sizeId,
                            sizeName: newProduct.sizeName,
                            sizeNameArabic: newProduct.sizeNameArabic,
                            stockLevel: newProduct.stockLevel,
                            styleNumber: newProduct.styleNumber,
                            subCategoryId: newProduct.subCategoryId,
                            taxMethod: newProduct.taxMethod,
                            taxRate: newProduct.taxRate,
                            taxRateId: newProduct.taxRateId,
                            taxRateValue: newProduct.taxRateValue,
                            unit: newProduct.unit,
                            unitId: newProduct.unitId,

                            unitPerPack: newProduct.unitPerPack,
                            width: newProduct.width,
                            highUnitPrice: newProduct.highUnitPrice,
                            discountSign: '%',
                        });

                        prod = this.products.find((x) => x.rowId == uid);

                    }
                    else {
                        if (this.products.find(x => x.id == productId) == undefined || this.products.length <= 0) {
                            this.products.push(newProduct);
                        }
                        prod = this.products.find((x) => x.id == productId);
                    }

                    var rate = 0;
                    var taxRateId = '';
                    var taxMethod = '';
                    if (this.saleDefaultVat == 'DefaultVat' || this.saleDefaultVat == 'DefaultVatItem') {
                        if (prod.taxRateId != "00000000-0000-0000-0000-000000000000" && prod.taxRateId != undefined) {
                            rate = this.getVatValue(prod.taxRateId, prod);
                        }
                        taxRateId = prod.taxRateId;
                        taxMethod = prod.taxMethod;
                    }
                    if (this.saleDefaultVat == 'DefaultVatHead' || this.saleDefaultVat == 'DefaultVatHeadItem') {
                        if (this.taxRateId != "00000000-0000-0000-0000-000000000000" && this.taxRateId != undefined) {
                            rate = this.getVatValue(this.taxRateId, prod);
                        }
                        taxRateId = this.taxRateId;
                        taxMethod = this.taxMethod;
                    }


                    if (qty != null && qty != undefined && qty != 0) {
                        this.saleProducts.push({
                            rowId: uid,
                            productId: prod.id,
                            description: '',
                            unitPrice: so ? price : '0',
                            quantity: qty,
                            highQty: soItem.highQty,
                            soQty: qty,
                            discount: so ? soItem.discount : 0,
                            fixDiscount: so ? soItem.fixDiscount : 0,
                            lineItemVAt: 0,
                            batchExpiry: prod.batchExpiry,
                            batchNo: prod.batchNo,
                            promotionId: prod.promotionOffer == null ? null : prod.promotionOffer.id,
                            quantityOut: quantityOut == null ? null : quantityOut,
                            bundleId: prod.bundleCategory == null ? null : prod.bundleCategory.id,
                            taxRateId: soItem.taxRateId,
                            saleReturnDays: prod.saleReturnDays,
                            taxMethod: soItem.taxMethod,
                            rate: rate,
                            serial: soItem.serial,
                            guaranteeDate: soItem.guaranteeDate,
                            isSerial: newProduct.serial,
                            guarantee: newProduct.guarantee,
                            inventoryList: prod.inventoryBatch == null ? null : prod.inventoryBatch,
                            currentQuantity: prod.inventory == null ? 0 : prod.inventory.currentQuantity,
                            lineTotal: prod.salePrice * 1,
                            buy: prod.bundleCategory != null ? prod.bundleCategory.buy : 0,
                            get: prod.bundleCategory != null ? prod.bundleCategory.get : 0,
                            quantityLimit: prod.bundleCategory != null ? prod.bundleCategory.quantityLimit : 0,
                            offerQuantity: 0,
                            unitPerPack: newProduct.unitPerPack,
                            levelOneUnit: prod.levelOneUnit,
                            basicUnit: prod.basicUnit,
                            highUnitPrice: newProduct.highUnitPrice,
                            discountSign: '%',
                        });
                    }
                    else {

                        var sizeAssortmentList = [];
                        if (this.colorVariants) {
                            prod.saleSizeAssortment.forEach(function (item) {
                                sizeAssortmentList.push({
                                    sizeId: item.sizeId,
                                    name: item.name,
                                    quantity: item.quantity,
                                    currentQuantity: 0,
                                });

                            });
                        }


                        this.saleProducts.push({
                            rowId: uid,
                            productId: prod.id,
                            description: prod.englishName,
                            unitPrice: this.wholesale.id == 2 ? newProduct.wholesalePrice : (prod.salePrice == 0 ? '0' : prod.salePrice),
                            quantity: this.isMultiUnit == 'true' ? 0 : 1,
                            soQty: 0,
                            highQty: 0,
                            discount: 0,
                            fixDiscount: 0,
                            lineItemVAt: 0,
                            batchExpiry: prod.batchExpiry,
                            batchNo: prod.batchNo,
                            inventoryList: prod.inventoryBatch == null ? null : prod.inventoryBatch,
                            currentQuantity: prod.inventory == null ? 0 : prod.inventory.currentQuantity,
                            promotionId: prod.promotionOffer == null ? null : prod.promotionOffer.id,
                            bundleId: prod.bundleCategory == null ? null : prod.bundleCategory.id,
                            taxRateId: taxRateId,
                            taxMethod: taxMethod,
                            saleReturnDays: prod.saleReturnDays,
                            rate: rate,
                            serial: '',
                            guaranteeDate: '',
                            isSerial: newProduct.serial,
                            guarantee: newProduct.guarantee,
                            lineTotal: prod.salePrice * 1,
                            buy: prod.bundleCategory != null ? prod.bundleCategory.buy : 0,
                            get: prod.bundleCategory != null ? prod.bundleCategory.get : 0,
                            quantityLimit: prod.bundleCategory != null ? prod.bundleCategory.quantityLimit : 0,
                            offerQuantity: 0,
                            unitPerPack: newProduct.unitPerPack,
                            levelOneUnit: prod.levelOneUnit,
                            basicUnit: prod.basicUnit,
                            saleSizeAssortment: sizeAssortmentList,
                            productSizes: prod.productSizes,
                            colorId: '',
                            colorName: '',
                            highUnitPrice: newProduct.highUnitPrice,
                            discountSign: '%',
                        });
                    }

                }

                var product = this.saleProducts.find((x) => {
                    return x.productId == productId && x.rowId == uid;
                });


                this.getVatValue(product.taxRateId, product);
                this.updateLineTotal(product.quantity, "quantity", product);
                this.updateLineTotal(product.highQty, "highQty", product);

                this.product.id = "";
                this.rendered++;
            },
            EmtySaleProductList: function () {

                this.saleProducts = [];
                this.products = [];
            },
            createUUID: function () {

                var dt = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (dt + Math.random() * 16) % 16 | 0;
                    dt = Math.floor(dt / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },
            getVatValue: function (id, prod) {

                var vat = this.vats.find((value) => value.id == id);
                prod.taxRateId = id;
                prod.rate = vat.rate;
                this.updateLineTotal(prod.unitPrice, "unitPrice", prod);
                return vat.rate;
            },
            getTaxMethod: function (method, prod) {
                prod.taxMethod = method;
                this.updateLineTotal(prod.unitPrice, "unitPrice", prod);
            },
            removeProduct: function (id) {

                this.saleProducts = this.saleProducts.filter((prod) => {
                    return prod.rowId != id;
                });

                this.calcuateSummary();
            },

            getDate: function (x) {
                return moment(x).format("l");
            },

            getData: function () {
                var root = this;

                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/Product/TaxRateList", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.vats = response.data.taxRates;
                        }
                    }).then(function () {


                        if (root.saleItems != undefined) {
                            
                            root.saleItems.forEach(function (item) {

                                if (root.isFifo) {
                                    root.products.push({
                                        rowId: item.id,
                                        arabicName: item.arabicName,
                                        assortment: item.assortment,
                                        barCode: item.barCode,
                                        basicUnit: item.product.basicUnit,
                                        batchExpiry: item.expiryDate,
                                        batchNo: item.batchNo,
                                        brandId: item.brandId,
                                        bundleCategory: item.bundleCategory,
                                        category: item.category,
                                        categoryId: item.categoryId,
                                        code: item.code,
                                        colorId: item.colorId,
                                        colorName: item.colorName,

                                        colorNameArabic: item.colorNameArabic,
                                        currentQuantity: item.currentQuantity,
                                        description: item.description,
                                        englishName: item.productName,
                                        guarantee: item.product.guarantee,
                                        id: item.productId,
                                        image: item.image,
                                        inventory: item.product.inventory,
                                        inventoryBatch: item.product.inventoryBatch,
                                        isActive: item.isActive,
                                        isExpire: item.isExpire,
                                        isRaw: item.isRaw,

                                        length: item.length,
                                        levelOneUnit: item.product.levelOneUnit,
                                        originId: item.originId,
                                        promotionOffer: item.promotionOffer,
                                        purchasePrice: item.purchasePrice,
                                        salePrice: item.salePrice,
                                        salePriceUnit: item.salePriceUnit,
                                        saleReturnDays: item.saleReturnDays,
                                        serial: item.product.serial,
                                        serviceItem: item.serviceItem,

                                        shelf: item.shelf,
                                        sizeId: item.sizeId,
                                        sizeName: item.sizeName,
                                        sizeNameArabic: item.sizeNameArabic,
                                        stockLevel: item.stockLevel,
                                        styleNumber: item.styleNumber,
                                        subCategoryId: item.subCategoryId,
                                        taxMethod: item.taxMethod,
                                        taxRate: item.taxRate,
                                        taxRateId: item.taxRateId,
                                        taxRateValue: item.taxRateValue,
                                        unit: item.unit,
                                        unitId: item.unitId,

                                        unitPerPack: item.unitPerPack,
                                        width: item.width,
                                        saleSizeAssortment: item.saleSizeAssortment,
                                        highUnitPrice: item.product.highUnitPrice,
                                        discountSign: item.discount == 0 ? item.fixDiscount == 0 ? '%' : 'F' : '%',
                                    });


                                }
                                else {
                                    root.products.push(item.product);
                                }


                                var vat = root.vats.find((value) => value.id == item.taxRateId);

                                root.saleProducts.push({
                                    rowId: item.id,
                                    productId: item.productId,
                                    description: item.description,
                                    unitPrice: item.unitPrice == 0 ? '0' : item.unitPrice,
                                    quantity: item.quantity,
                                    highQty: item.highQty,
                                    discount: item.discount,
                                    fixDiscount: item.fixDiscount,
                                    taxRateId: item.taxRateId,
                                    taxMethod: item.taxMethod,
                                    rate: vat.rate,
                                    soQty: item.soQty,
                                    promotionId: item.promotionId,
                                    bundleId: item.bundleId,

                                    offerQuantityLimit: item.product.bundleCategory != null ? item.product.bundleCategory.quantityLimit : (item.product.promotionOffer != null ? item.product.promotionOffer.baseQuantity : 0),
                                    offerQuantity: item.offerQuantity == undefined ? 0 : item.offerQuantity,
                                    promotionOfferQuantity: item.promotionOfferQuantity,
                                    upToQuantity: item.promotionId == null ? 0 : item.product.promotionOffer.upToQuantity,
                                    buy : item.buy,
                                    get: item.get,
                                    totalPiece:0,

                                    batchExpiry: item.batchExpiry,
                                    batchNo: item.batchNo,
                                    inventoryList: item.product.inventoryBatch == null ? null : item.product.inventoryBatch,
                                    currentQuantity: item.product.inventory == null ? 0 : item.product.inventory.currentQuantity,
                                    saleReturnDays: item.saleReturnDays,
                                    lineTotal: item.unitPrice * item.quantity,
                                    unitPerPack: item.unitPerPack,
                                    levelOneUnit: item.product.levelOneUnit,
                                    basicUnit: item.product.basicUnit,
                                    serial: item.serial,
                                    guaranteeDate: item.guaranteeDate,
                                    isSerial: item.product.serial,
                                    guarantee: item.product.guarantee,
                                    saleSizeAssortment: item.saleSizeAssortment,
                                    saleSizeAssortmentInventory: item.saleSizeAssortmentInventory,
                                    productSizes: item.product.productSizes,
                                    colorId: item.colorId,
                                    colorName: item.colorName,
                                    highUnitPrice: item.product.highUnitPrice,
                                    discountSign: item.discount == 0 ? item.fixDiscount == 0 ? '%' : 'F' : '%',
                                });



                                var product = root.saleProducts.find((x) => {
                                    return x.productId == item.productId && x.rowId == item.id;
                                });

                                if (root.colorVariants) {
                                    root.UpdateColorName(item.colorId, product);
                                }

                                root.getVatValue(product.taxRateId, product);
                                root.updateLineTotal(item.quantity, "quantity", product);
                                root.updateLineTotal(item.highQty, "highQty", product);
                                root.adjustment = (root.adjustmentProp == null || root.adjustmentProp == undefined || root.adjustmentProp == '') ? 0 : (root.adjustmentSignProp == '+' ? root.adjustmentProp : (-1) * root.adjustmentProp)
                                root.adjustmentSign = root.adjustmentSignProp;
                                root.product.id = "";
                                root.rendered++;
                            });
                            root.$emit("details", root.saleProducts);
                        }
                    });
            },

            UpdateColorName: function (colorId, product) {
                product.saleSizeAssortment.forEach(function (item) {
                    var size = product.saleSizeAssortmentInventory.variationInventories.find(x => x.sizeId == item.sizeId);
                    if (size != undefined) {
                        item.currentQuantity = size.quantity;
                    }
                    else {
                        item.currentQuantity = 0;
                    }
                });

                if (colorId != null && colorId != undefined && colorId != '') {
                    product.currentQuantity = product.saleSizeAssortmentInventory.quantity;
                }
            },

            GetSizeData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Product/SizeList?isActive=true' + '&isVariance=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.saleSizeAssortment = [];
                        response.data.results.sizes.forEach(function (item) {
                            root.saleSizeAssortment.push({
                                sizeId: item.id,
                                name: item.name,
                                quantity: 0,
                            });
                        });
                    }
                });
            },
        },
        created: function () {
            this.transactionLevelDiscount = this.transactionLevelDiscountProp;
            if (this.$i18n.locale == 'en') {
                this.options = ['Inclusive', 'Exclusive'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
            }

            this.invoiceWoInventory = localStorage.getItem('InvoiceWoInventory') == 'true' ? true : false;
            this.saleDefaultVat = localStorage.getItem('SaleDefaultVat');

            //var barcode = '';
            //var interval;
            //document.addEventListener('keydown', function (evt) {
            //    if (interval)
            //        clearInterval(interval);
            //    if (evt.code === 'Enter') {
            //        if (barcode) {
            //            root.onBarcodeScanned(barcode);
            //        }
            //        barcode = '';
            //        return;

            //    }
            //    if (evt.key !== 'Shift')
            //        barcode += evt.key;
            //});

            //End
            this.getData();
            var root = this;
            localStorage.setItem("BarcodeScan", 'SaleItem')
            if (this.$route.query.mobiledata != undefined) {
                for (var j = 0; j < this.$route.query.mobiledata.mobileOrderItemLookupModels.length; j++) {
                    this.saleProducts.quantity[j] = this.$route.query.mobiledata.mobileOrderItemLookupModels[j].quantity[j];
                }
                root.calcuateSummary();
                this.saleProducts.rowId = this.$route.query.mobiledata.mobileOrderItemLookupModels.rowId;
                this.saleProducts.quantity = this.$route.query.mobiledata.mobileOrderItemLookupModels.quantity;
            }

            if (localStorage.getItem('ColorVariants') == 'true') {
                this.GetSizeData();
            }
        },

        mounted: function () {
            this.colorVariants = localStorage.getItem('ColorVariants') == 'true' ? true : false;
            this.isFifo = localStorage.getItem('fIFO') == 'true' ? true : false;
            this.soInventoryReserve = localStorage.getItem('SoInventoryReserve') == 'true' ? true : false;
            this.decimalQuantity = localStorage.getItem('decimalQuantity') == 'true' ? true : false;
            this.isMultiUnit = localStorage.getItem('IsMultiUnit');
            this.currency = localStorage.getItem('currency');
            this.dayStart = localStorage.getItem('DayStart');

            this.isSerial = localStorage.getItem('IsSerial') == 'true' ? true : false;

            this.changePriceDuringSale = localStorage.getItem('changePriceDuringSale');
            this.changePriceDuringSale == 'true' ? (this.changePriceDuringSale = true) : (this.changePriceDuringSale = false);
            this.giveDiscountDuringSale = localStorage.getItem('giveDicountDuringSale');
            this.giveDiscountDuringSale == 'true' ? (this.giveDiscountDuringSale = true) : (this.giveDiscountDuringSale = false);
        },

    };
</script>

