<template>
    <div>
        <div hidden  id='purchaseInvoice' class="col-md-12">
            <!--page1-->
            <div>
                <!--HEADER-->
                <div class="col-md-12" style="height:45mm;border:2px solid #000000;background-color:white" v-if="IsPaksitanClient">
                    <div class="row" style="height:35mm">
                        <div class="col-md-4  my-5" style="padding:0px !important; margin:0 !important">
                            <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px;  margin:0 !important;padding:5px">
                        </div>
                        <div class="col-md-8 ">
                            <table class="text-center">
                                <tr>
                                    <td>
                                        <p>
                                            <u style="font-size:16px;color:black !important;font-weight:bold;">
                                                Sales Tax Invoice
                                            </u><br />
                                            <span style="font-size:27px;color:black !important;font-weight:bold;">{{headerFooters.company.nameEnglish}}</span><br />
                                            <span style="font-size:16px;color:black !important;font-weight:bold;">{{headerFooters.company.addressEnglish}}</span><br />
                                            <span style="font-size:18px;color:black !important;font-weight:bold;float:left">NTN :&nbsp;&nbsp;&nbsp; {{headerFooters.company.vatRegistrationNo}}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span style="font-size:18px;color:black !important;font-weight:bold;float:right">STR:&nbsp;&nbsp;&nbsp;   {{headerFooters.company.companyRegNo}}</span><br />
                                            <span style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">Vat Report</span>
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <!--<div class="col-md-4 ">
                <table class="text-right" v-if="arabic=='true'">
                    <tr>
                        <td>
                            <p>
                                <span style="font-size:25px;color:black !important;font-weight:bold;">{{headerFooters.company.nameArabic}}.</span><br />
                                <span style="font-size:17px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryArabic}}</span><br />
                                <span style="font-size:16px;color:black !important;font-weight:bold;">رقم ضريبة القيمة المضافة: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                <span style="font-size:16px;color:black !important;font-weight:bold;">رقم السجل التجاري :{{headerFooters.company.companyRegNo}}</span><br />
                                <span style="font-size:15px;color:black !important;font-weight:bold;">
                                    هاتف: {{headerFooters.company.phoneNo}}:
                                </span>
                            </p>
                        </td>
                    </tr>
                </table>
            </div>-->
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="margin-bottom:10px !important;height:10mm">
                            <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">

                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" style="height:45mm;border:2px solid #000000;background-color:white" v-else-if="isHeaderFooter=='true'">
                    <div class="row" style="height:35mm;background-color:white">
                        <div class="col-md-4 ">
                            <table class="text-left">
                                <tr>
                                    <td>
                                        <p>
                                            <span style="font-size:25px;color:black !important;font-weight:bold;">{{headerFooters.company.nameEnglish}}</span><br />
                                            <span style="font-size:17px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryEnglish}}</span><br />
                                            <span style="font-size:16px;color:black !important;font-weight:bold;">VAT No.: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                            <span style="font-size:16px;color:black !important;font-weight:bold;">Cr No.:{{headerFooters.company.companyRegNo}}</span><br />
                                            <span style="font-size:15px;color:black !important;font-weight:bold;">
                                                Tel: {{headerFooters.company.phoneNo}}
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-4 text-center my-5" style="padding:0px !important; margin:0 !important">
                            <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                        </div>
                        <div class="col-md-4 ">
                            <table class="text-right" v-if="arabic=='true'">
                                <tr>
                                    <td>
                                        <p>
                                            <span style="font-size:25px;color:black !important;font-weight:bold;">{{headerFooters.company.nameArabic}}.</span><br />
                                            <span style="font-size:17px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryArabic}}</span><br />
                                            <span style="font-size:16px;color:black !important;font-weight:bold;">رقم ضريبة القيمة المضافة: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                            <span style="font-size:16px;color:black !important;font-weight:bold;">رقم السجل التجاري :{{headerFooters.company.companyRegNo}}</span><br />
                                            <span style="font-size:15px;color:black !important;font-weight:bold;">
                                                هاتف: {{headerFooters.company.phoneNo}}:
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row" style="background-color:white">
                        <div class="col-md-12" style="margin-bottom:10px !important;height:10mm" >
                            <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                <span style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">VAT Report</span>
                            </p>
                        </div>

                    </div>
                </div>
                <div style="height:45mm;" v-else></div>

                <div style="height:20mm;margin-top:1mm;margin-bottom:1mm; border:2px solid #000000;background-color:white">
                    <div class="row mt-3">
                        <div class="col-md-12 ml-2 mr-2" style="height:6mm;font-size:16px;">
                            <div>
                                <!--Row 1-->
                                <!--Row 2-->
                                <div class="row">
                                    <div class="col-md-6" style="display:flex;">
                                        <div style="width:28%;font-weight:bolder;text-align:right;color:black !important;">From Date:</div>
                                        <div style="width:50%; text-align:center;color:black !important;font-weight:bold;">{{list.fromDate}}</div>
                                        <div style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;" v-if="arabic=='true'">:تاريخ</div>
                                    </div>
                                    <div class="col-md-6" style="display:flex;">
                                        <div style="width:28%; font-weight:bolder;text-align:right;color:black !important;">To Date.:</div>
                                        <div style="width:50%; text-align:center;font-weight:bold;color:black !important;">{{list.toDate}}</div>
                                        <div style="width:22%;font-weight:bolder;color:black !important;font-size:15px !important" v-if="arabic=='true'">
                                            : تاريخ الاستحقاق
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <!--INFORMATION-->
                <div style="height:310mm;border:2px solid !important;background-color:white">
                    <div class="row ">
                        <div class="col-lg-12 " style="height:260mm">
                            <table class="table">

                                <tr><th colspan="4" style="padding-left:30px;padding-top:10px;font-size:20px;border:1px solid !important;color:black !important">VAT Paid </th></tr>
                                <tr>
                                    <th style="width:15%;border-top:1px solid !important;color:black !important;border-bottom:1px solid !important;color:black !important">#</th>
                                    <th style="border-top:1px solid !important;color:black !important;border-bottom:1px solid !important;color:black !important">{{ $t('VatReportPrint.Code') }}</th>
                                    <th style="border-top:1px solid !important;color:black !important;border-bottom:1px solid !important;color:black !important">{{ $t('VatReportPrint.Name') }}</th>
                                    <th style="border-top:1px solid !important;color:black !important;border-bottom:1px solid !important;color:black !important">{{$t('VatReportPrint.Amount')}}</th>
                                </tr>
                                <tr v-for="(account,index) in list.paidaccounts" v-bind:key="account.code">
                                    <td>{{index+1}}</td>
                                    <td>{{account.code}}</td>
                                    <td>
                                        <span>{{account.name}}</span>
                                    </td>
                                    <td>{{Number(Math.abs(parseFloat(account.amount).toFixed(2))).toLocaleString()}}</td>

                                </tr>
                                <tr style="border:1px solid !important;color:black !important">
                                    <td></td>
                                    <td></td>
                                    <td><b>{{ $t('VatReportPrint.Total') }}</b></td>
                                    <td><b>{{(parseFloat(list.totalvatpaid)).toFixed(2)}}</b></td>
                                </tr>
                                <tr>
                                    <th colspan="4" style="padding-left:30px;padding-top:10px;font-size:20px;border:1px solid !important;color:black !important">VAT Payable </th>
                                </tr>
                                <tr v-for="(account,index) in list.payableaccounts" v-bind:key="account.code">
                                    <td>{{index+1}}</td>
                                    <td>{{account.code}}</td>
                                    <td>
                                        <span>{{account.name}}</span>
                                    </td>
                                    <td>{{Number(Math.abs(parseFloat(account.amount).toFixed(2))).toLocaleString()}}</td>

                                </tr>

                                <tr style="border:1px solid !important;color:black !important">
                                    <td></td>
                                    <td></td>
                                    <td><b>{{ $t('VatReportPrint.Total') }}</b></td>
                                    <td><b>{{(parseFloat(list.totalvatpayable)).toFixed(2)}}</b></td>

                                </tr>

                                <tr style="border:none !important;border-color:transparent !important">
                                    <td style="padding-top:40px"></td>
                                    <td></td>
                                    <td><b>{{ $t('VatReportPrint.VATPayable')}}</b></td>
                                    <td><b>{{(parseFloat(list.VatPayable)).toFixed(2)}}</b></td>
                                </tr>


                            </table>

                        </div>
                    </div>
                    <div class="col-md-12  " style="height:30mm;background-color:white">
                        <div class="row pt-4" style=" background-color:white;font-size:16px">
                            <table class="table text-center">
                                <tr>
                                    <td style="width: 33%;text-decoration-line: overline;border:0px;color:black;font-weight:bold">
                                        Prepared By
                                    </td>
                                    <td style="width: 33%;text-decoration-line: overline;border:0px;color:black;font-weight:bold">
                                        Approved By
                                    </td>
                                    <td style="width: 33%;text-decoration-line: overline;border:0px;color:black;font-weight:bold">
                                        Received By

                                    </td>
                                </tr>


                            </table>
                            <table class="table text-center">

                                <tr>
                                    <td style="width: 20%;border:0px;color:black;font-weight:bold">
                                    </td>
                                    <td style="width: 60%;border:0px;color:black;font-weight:bold">
                                        Tel: &nbsp; &nbsp; &nbsp;{{headerFooters.company.phoneNo}} &nbsp; &nbsp; &nbsp; Email:&nbsp; &nbsp; &nbsp;{{headerFooters.company.email}}
                                    </td>
                                    <td style="width: 20%;border:0px;color:black;font-weight:bold">
                                    </td>
                                </tr>

                            </table>
                        </div>
                    </div>

                </div>

                <!--Footer-->

            </div>
        </div>
    </div>

</template>

<script>
    const options = {
        name: '',
        specs: [
            'fullscreen=no',
            'titlebar=yes',
            'scrollbars=yes'
        ],
        styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css'
        ],
        timeout: 700,
        autoClose: true,
        windowTitle: window.document.title,

    }
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        props: ['printDetails', 'headerFooter'],
       
        data: function () {
            return {
              
              
                isHeaderFooter: '',
                invoicePrint: '',
                arabic: '',
                english: '',
                list: {
                    fromDate: '',
                    toDate: '',
                    paidaccounts: [],
                    payableaccounts: [],
                    totalvatpaid: 0,
                    totalvatpayable:0,
                    VatPayable:0,
                },
                IsPaksitanClient: false,

                render: 0,
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                }
            }
        },
        filters: {
            toWords: function (value) {
                var converter = require('number-to-words');
                if (!value) return ''
                return converter.toWords(value);
            }
        },
        computed: {
            
        },
        methods: {
           
            printInvoice: function () {

                this.$htmlToPaper('purchaseInvoice', options, () => {
                    

                   

                });
            },



        },
        mounted: function () {
            
            this.IsPaksitanClient = localStorage.getItem('IsPaksitanClient') == "true" ? true : false;

            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            this.invoicePrint = localStorage.getItem('InvoicePrint');
            var root = this;
            if (this.printDetails != null && this.printDetails != undefined) {
                this.list.fromDate = this.printDetails[0].fromDate;
                this.list.toDate = this.printDetails[0].toDate;
                this.list.paidaccounts = this.printDetails[0].paidaccounts;
                this.list.payableaccounts = this.printDetails[0].payableaccounts;
                this.list.totalvatpaid = this.printDetails[0].totalvatpaid;
                this.list.totalvatpayable = this.printDetails[0].totalvatpayable;
                this.list.VatPayable = this.printDetails[0].VatPayable;
            }
           
                this.headerFooters = this.headerFooter;
                

                setTimeout(function () {
                    root.printInvoice();
                }, 125)
        },

    }
</script>

